import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { TranslateService } from "@ngx-translate/core";
import isToday from 'dayjs/plugin/isToday';
dayjs.extend(isToday);
@Injectable({
    providedIn: 'root'
})

export class DateTimeService {

    constructor(
        private translateService: TranslateService
    ) {
    }

    /**
     * Returns string in format "DD/MM/YYYY hh:mm:ss" or "MM/DD/YYYY hh:mm:ss" depending on location. Returns "Today" if date is today
     * @param {number} timestamp - Provided timestamp in seconds.
     * @param excludeTime - If true, returns date without time.
     * @param excludeSeconds - If true, returns date and time with seconds.
     */
    getDateAndTime(timestamp: number, excludeTime: boolean, excludeSeconds: boolean): string {
        const isEnglishUS: boolean = navigator.language === 'en-US' && this.translateService.currentLang === 'en';

        const timeFormat = isEnglishUS ? 'h:mm A' : 'HH:mm';
        const timeFormatSeconds = isEnglishUS ? 'h:mm:ss A' : 'HH:mm:ss';
        const dateFormat = isEnglishUS ? 'MM/DD/YYYY' : 'DD/MM/YYYY';

        const zoneAdaptedTimestamp = dayjs.unix(timestamp).locale(navigator.language);
        const date = zoneAdaptedTimestamp.format(dateFormat).replace(/([.])/g, '/')
        const time = zoneAdaptedTimestamp.format(excludeSeconds ? timeFormat : timeFormatSeconds);

        if (excludeTime) {
            return `${date}`
        }

        if (dayjs(zoneAdaptedTimestamp).isToday()) {
            return `${this.translateService.instant("overview.today")} ${time}`
        }

        return `${date} - ${time}`
    }
}
