<app-custom-modal>
    <ng-container modal-title>
        <h4>{{'account.select_language' | translate}}</h4>
    </ng-container>
    <ng-container modal-body>
        <div class="input-group-wrapper">
            <div class="input-group-wrapper">
                <label>{{'account.select_language_desc' | translate}}</label>
                <select class="form-select" (change)="languageSelect($event)">
                    <option [selected]="translate.currentLang === language.language_code" [ngValue]="language.name"
                        *ngFor="let language of supportedLanguages">
                        {{language.name}}
                    </option>
                </select>
            </div>
        </div>
    </ng-container>
    <ng-container modal-footer>
        <div class="d-flex flex-column align-items-center">
            <button (click)="useLanguage()" class="btn btn-secondary mb-3 mt-3" style="width: 100%">
                {{('account.change_language' | translate)}}
            </button>
        </div>
    </ng-container>
</app-custom-modal>