import { KeyValue, NgIf, NgFor, KeyValuePipe } from '@angular/common';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ExternalAccount } from '../../../../../../shared_models/external_accounts';
import { ExternalAccountService } from '../../../../services/external-account/external-account.service';
import { HelperService } from '../../../../services/helper/helper.service';
import { Details } from 'shared_models/details';
import { InvitedUserDetails } from 'shared_models/invited-user';
import { DashboardUser } from 'shared_models/dashboard-user';
import { StripeRegions } from 'shared_models/stripe';
import { TranslateModule } from '@ngx-translate/core';
import { ExternalAccountFormComponent } from './external-account-form/external-account-form.component';
import { LoadingComponent } from '../../../loading/loading.component';

@Component({
    selector: 'app-external-account-list',
    templateUrl: './external-account-list.component.html',
    styleUrls: ['./external-account-list.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, LoadingComponent, ExternalAccountFormComponent, KeyValuePipe, TranslateModule]
})

export class ExternalAccountListComponent implements OnInit {
    @Input() customerDetails: Details | InvitedUserDetails;
    externalAccounts: Record<string, ExternalAccount>;
    resetForm = false;
    externalAccount: ExternalAccount;
    isInitialAccount: boolean;
    onlyDefault: boolean;
    isEnterprise = false;
    hasExternalAccount = false;
    countrySpecs: { default_currency: string, currencies: Record<string, string[]> };
    user: DashboardUser = this.helper.getUser();
    country: string = this.user.settings.country;
    region: StripeRegions = this.user.settings.stripe_region;
    stripeRegionUS: StripeRegions = StripeRegions.US;
    constructor(
        protected modalService: NgbModal,
        public helper: HelperService,
        private externalAccountService: ExternalAccountService,
    ) { }

    async ngOnInit(): Promise<void> {
        this.countrySpecs = await this.externalAccountService.getCurrenciesForCountry(this.user.settings.country)
        const details: Details = this.customerDetails as Details;
        this.isEnterprise = details.enterprise_config ? true : false;
        this.externalAccounts = await this.externalAccountService.getAllExternalAccounts().catch(() => { throw new Error('Client message: Could not fetch my external accounts') });
        this.variableSetter();
    }

    variableSetter() {
        this.isInitialAccount = Object.keys(this.externalAccounts).length ? false : true;
        this.onlyDefault = Object.keys(this.externalAccounts).length === 1 ? true : false;

        if (Object.keys(this.externalAccounts).length > 0) {
            this.hasExternalAccount = true;
        }
    }

    async openModal(modal: TemplateRef<any>, externalAccount?: ExternalAccount) {
        this.externalAccount = externalAccount ? externalAccount : null;

        const open = async (modal: TemplateRef<any>, options?: NgbModalOptions): Promise<any> => {
            const modalPromise: Promise<any> = this.modalService.open(
                modal,
                {
                    ...{
                        ariaLabelledBy: 'modal-basic-title',
                    },
                    ...options ? options : {}
                }
            ).result.catch(() => {
                // empty for now, should be here to avoid an uncaught promise on modal cancel action.
            });

            return modalPromise;
        }

        await open(modal);

        this.variableSetter();
    }

    performListChange(externalAccount: ExternalAccount) {
        if (externalAccount.archived) {
            delete this.externalAccounts[externalAccount.key];
        } else {
            this.externalAccounts[externalAccount.key] = externalAccount;
        }

        if (externalAccount.is_default) {
            // if default on the account passed in this function, 
            // then loop through all to check if it is a new default,
            // and therefore the old default should be unset
            for (const key in this.externalAccounts) {
                if (key !== externalAccount.key) {
                    this.externalAccounts[key].is_default = false;
                }
            }
        }

        this.variableSetter();
    }

    archiver(key: string) {
        delete this.externalAccounts[key];
    }

    orderRecord = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        if (a.value['is_default'] || b.value['is_default']) {
            return a.value['is_default'] > b.value['is_default'] ? -1 : 1; // always place default in top
        } else {
            // alphabetically order by names
            return a.value['name'].localeCompare(b.value['name'], 'en', { numeric: true, sensitivity: 'base' });
        }
    }

}