<app-page-layout [pageTitle]="'navbar.users' | translate">
    <ng-container buttons>
        <app-aw-filter-button
            [style]="isMobile ? '' : 'margin-right: 15px'"
            [filterOptions]="filterOptions"
            (emitDependencyChange)="handleFilterValueChange($event)"
            [popupAnchor]="isMobile ? 'left' : 'right'"
            (filterChanged)="catchFilterChanged($event)"
        />
        <div class="add-user">
            <div>
                <button class="btn btn-primary btn-tiny" (click)="openModal(createUser)">
                    <img class="plus-icon-on-btn" src="../../../assets/icons/plus-for-create.svg" alt="create customer" />
                    {{ "users.add_user" | translate }}
                </button>
            </div>
        </div>
    </ng-container>
    <ng-container body>
        <div *ngIf="initialLoading" class="loading-height">
            <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
        </div>

        <div class="main-container">
            <app-aw-table
                *ngIf="!isMobile && !initialLoading"
                [tableHeaders]="tableHeaderOptions"
                [emptytext]="'users.no_users' | translate"
                [pageNumber]="pageNumber"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                [totalItems]="usersCount"
                [hasExpandableOrClickableRows]="true"
                (handlePageChange)="handlePage($event)"
                (sortBy)="catchSortChanged($event)"
                [loadingData]="loadingUsers"
                [initalSort]="this.params.sortBy"
            >
                <ng-container table-body>
                    <div class="row-container" *ngFor="let userObj of users | paginate: pageSize : pageNumber; let i = index">
                        <row style="width: 100%; cursor: pointer" (click)="openUser(userObj)">
                            <!-- Name  -->
                            <cell style="width: 20%">
                                <div class="name-icon">
                                    <span style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
                                        {{ userObj.name ? userObj.name.replaceAll("_", " ") : "-" }}
                                    </span>
                                    <app-aw-hover-icon *ngIf="getLocationName(userObj.location_id)" [hoverText]="getLocationName(userObj.location_id)" />
                                </div>
                            </cell>

                            <!-- Phone -->
                            <cell style="width: 15%">
                                {{ userObj.phone !== "-" ? userObj.call_code + " " + userObj.phone : "-" }}
                            </cell>

                            <!-- platform  -->
                            <cell style="width: 9%">
                                {{ userObj.platform ? ("users.platforms." + userObj.platform | translate) : ("misc.none" | translate) }}
                            </cell>

                            <!-- Payment method -->
                            <cell style="width: 15%">
                                <div style="display: flex; gap: 0.5rem; justify-content: flex-end">
                                    <span style="width: 75px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden" [title]="'users.payment_methods.type.' + userObj.pm.toLowerCase() | translate">
                                        {{ getUserPaymentDisplayText(userObj) | translate }}
                                    </span>
                                    @if (userObj.pm !== "no_payment_type") {
                                        <span style="display: flex; gap: 0.3125rem; justify-content: flex-end; align-items: center">
                                            <img
                                                style="width: 19px; aspect-ratio: 1"
                                                [title]="'users.payment_methods.type.' + userObj.pm.toLowerCase() | translate"
                                                [src]="'../../../assets/icons/payment-methods/' + userObj.pm.toLowerCase() + '.svg'"
                                                alt="card"
                                            />
                                            <img
                                                *ngIf="userObj.wallet && userObj.wallet !== 'unknown'"
                                                style="width: 19px; aspect-ratio: 1"
                                                [title]="getTitleForWallet(userObj.wallet)"
                                                alt="wallet"
                                                [src]="'../../../assets/icons/payment-methods/' + userObj.wallet.toLowerCase() + '.svg'"
                                            />
                                        </span>
                                    }
                                </div>
                                <!-- <div *ngIf="userObj.pm === 'test'" style="display: flex; gap: 0.75rem; justify-content: flex-end">
                                    <span>{{ "users.user.test" | translate }}</span>
                                    <img style="width: 19px; aspect-ratio: 1" [src]="'../../../assets/icons/payment-methods/test.svg'" />
                                </div> -->
                            </cell>

                            <cell style="width: 12%; text-align: right">
                                <span style="width: 100%">
                                    {{ helperService.localizeNumberWithCurrency((userObj.total_spend_sum ?? 0) / 100, undefined, undefined, userObj.currency) }}
                                </span>
                            </cell>
                            <cell style="width: 9%; text-align: right">
                                <span style="width: 100%">
                                    {{ helperService.localizeNumber(userObj.payments, 0, 0) }}
                                </span>
                            </cell>
                            <cell style="width: 10%; text-align: right">
                                <span style="width: 100%">
                                    {{ helperService.localizeNumberWithCurrency(userObj.refunds_sum / 100, undefined, undefined, userObj.currency) }}
                                </span>
                            </cell>
                            <cell style="width: 10%; text-align: right">
                                <span style="width: 100%">{{ helperService.createdLabelForLists(userObj.created) }}</span>
                            </cell>
                        </row>
                    </div>
                </ng-container>
            </app-aw-table>
        </div>

        <!--Mobile-->
        <div class="cont mobile-cont" style="min-height: 200px; margin-bottom: 100px" *ngIf="isMobile">
            <div *ngIf="!loadingUsers" class="mobile-body">
                <div *ngFor="let userObj of users | paginate: pageSize : pageNumber : true" (click)="openUser(userObj)">
                    <div class="mobile-card">
                        <div class="card-title">
                            <p>
                                <strong>{{ userObj.name ? userObj.name.replaceAll("_", " ") : "-" }}</strong>
                            </p>
                            <p>
                                <strong>{{ userObj.platform ? helperService.makeFirstCharUppercase(userObj.platform) : "-" }}</strong>
                            </p>
                        </div>
                        <div style="margin-top: 5px">
                            <p style="font-size: 14px">
                              {{ userObj.phone !== "-" ? userObj.call_code + " " + userObj.phone : "-" }}
                            </p>
                            <div class="d-flex column" style="justify-content: space-between">
                                <strong style="font-size: 14px"> {{ helperService.createdLabelForLists(userObj.created) }}</strong>
                                <p style="font-size: 14px">
                                    {{ userObj.payment_method ? (userObj.payment_method.last4 ? convertCardNumber(userObj.payment_method) : ("users.payment_methods.type." + userObj.payment_method.type | translate)) : "-" }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mobile-paginator">
                    <mat-paginator id="matpage" class="pagination" (page)="handlePage($event)" [length]="usersCount" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageNumber" showFirstLastButtons> </mat-paginator>
                </div>
            </div>
        </div>
    </ng-container>
</app-page-layout>

<ng-template #createUser let-modal>
    <app-create-user-modal (userCreatedEmitter)="loadUsers(true)"></app-create-user-modal>
</ng-template>
