import { Component, OnInit, ViewChild, WritableSignal } from '@angular/core';
import { LoadingComponent } from '../loading/loading.component';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { environment } from '../../../environments/environment';
import { MaintenanceService } from 'src/app/maintenance.service';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AuthPageService } from '@services/auth/auth-page.service';
import { trigger, transition, style, animate, state, group, query, animateChild } from '@angular/animations';
import { fade } from 'src/app/animations/fade'; // Import the fade animation

@Component({
    selector: 'app-auth-page',
    standalone: true,
    imports: [LoadingComponent, SignInComponent, SignUpComponent, LottieComponent, NgIf, TranslateModule],
    templateUrl: './auth-page.component.html',
    styleUrl: './auth-page.component.scss',
    animations: [
        trigger('containerHeight', [
            state('sign-in', style({ height: '183px' })),
            state('sign-up', style({ height: '402px' })),
            state('mfa', style({ height: '125px' })),
            transition('sign-in <=> sign-up, sign-in <=> mfa', [
                group([
                    animate('500ms ease'), // Resizes container height
                    query('@fade', animateChild(), { optional: true }) // Animates opacity of inner view
                ])
            ])
        ]),
        fade
    ]
})
export class AuthPageComponent implements OnInit {
    @ViewChild(SignInComponent) signInComponent: SignInComponent;
    @ViewChild(SignUpComponent) signUpComponent: SignUpComponent;

    loading: WritableSignal<boolean>;
    isMfaSignIn: WritableSignal<boolean>;

    view: string;
    options: AnimationOptions = {
        // https://www.npmjs.com/package/ngx-lottie
        path: '../../assets/animations/AW_waves.json'
    };
    supportPhone: {
        phoneNumber: string;
        telLink: string;
    };
    version: string = environment.VERSION;
    backAt: string;
    isUnderMaitenance: boolean;
    showLoad = true;

    constructor(
        private router: Router,
        private location: Location,
        private maintenanceService: MaintenanceService,
        private authPageService: AuthPageService
    ) {
        this.view = this.router.url.split('/').pop().split('?')[0];
        this.loading = this.authPageService.loading;
        this.isMfaSignIn = this.authPageService.mfaSignIn;
    }

    async ngOnInit() {
        await this.waitForMaintenance();
        if (this.maintenanceService.getIsUnderMaintenance()) {
            this.isUnderMaitenance = true;
            this.backAt = this.maintenanceService.getBackAt();
            this.showLoad = false;
        } else {
            this.showLoad = false;
            this.supportPhone = environment.supportPhone;
        }
    }

    /**
     * Waits for the maintenance service to be initialized.
     * Needs to wrap it in promise, as it relies on a subscription.
     */
    async waitForMaintenance(): Promise<void> {
        return new Promise<void>(resolve => {
            const checkBoolean = () => {
                if (typeof this.maintenanceService.getIsUnderMaintenance() !== 'undefined') {
                    resolve();
                } else {
                    setTimeout(checkBoolean, 100); // Check again after 100ms
                }
            };
            checkBoolean();
        });
    }

    toggleAuthPage() {
        if (this.view === 'sign-in') {
            this.view = 'sign-up';
            this.location.replaceState('/sign-up');
        } else {
            this.view = 'sign-in';
            this.location.replaceState('/sign-in');
        }
    }

    handleSubmit(): void {
        if (this.isMfaSignIn()) {
            this.signInComponent.handleMfa();
        } else if (this.view === 'sign-in') {
            this.signInComponent.signIn();
        } else {
            this.signUpComponent.signUp();
        }
    }
}
