
export interface DeleteModalOptions {
    titleTranslationString: string,
    descriptionTranslateString: string,
    buttonTranslationString: string
}

export enum DeleteConfirmState {
    confirmed = 'confirmed',
    cancelled = 'cancelled',
    reset = 'reset'
}
