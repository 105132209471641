import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter, HostListener,
    Input,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingComponent } from '../../loading/loading.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-aw-dot-popup-button',
    templateUrl: './aw-dot-popup-button.component.html',
    styleUrls: ['./aw-dot-popup-button.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingComponent, NgFor, TranslateModule]
})
export class AwDotPopupButtonComponent implements AfterViewInit {

    public openPopupBoxIndex = true;
    public marginForLeftHover = 0;
    public isButtonDisabled = true;

    @Input() menuOptions: string[] = [];
    @Input() isLoading = false;
    @Output() clickedOption: EventEmitter<string> = new EventEmitter<string>();
    @Output() popupOpened: EventEmitter<AwDotPopupButtonComponent> = new EventEmitter<AwDotPopupButtonComponent>();
    @Output() dropdownOpened: EventEmitter<AwDotPopupButtonComponent> = new EventEmitter<AwDotPopupButtonComponent>();
    @Output() componentInitialized: EventEmitter<AwDotPopupButtonComponent> = new EventEmitter<AwDotPopupButtonComponent>();


    @ViewChild('popupDiv', {static: false}) popupDiv: ElementRef;


    constructor(private cdr: ChangeDetectorRef, private renderer: Renderer2) {
    }

    ngAfterViewInit() {        
        this.componentInitialized.emit(this);
        this.renderer.setStyle(this.popupDiv.nativeElement, 'opacity', '0');
        setTimeout(() => {
            if (this.popupDiv && this.popupDiv.nativeElement) {
                const contentWidth: number = -this.popupDiv.nativeElement.offsetWidth;
                this.marginForLeftHover = contentWidth + 24;
            }
            this.openPopupBoxIndex = false
            this.isButtonDisabled = false
            this.cdr.detectChanges();
        }, 0)
    }

    togglePopUp(event: any): void {
        event.stopPropagation()
        this.openPopupBoxIndex = !this.openPopupBoxIndex;
        if (this.openPopupBoxIndex) {
            this.dropdownOpened.emit(this);
            this.popupOpened.emit(this);
        }
    }

    @HostListener('document:click', ['$event'])
    clickOut(event) {
        if (this.popupDiv && !this.popupDiv.nativeElement.contains(event.target)) {
            this.togglePopUp(event)
        }
    }

    closeDropdown(): void {
        this.openPopupBoxIndex = false;
    }

    emitClickedOption(option: string): void {
        this.clickedOption.emit(option);
        this.openPopupBoxIndex = false;
    }

}
