/**
 * Slovene translation for bootstrap-datepicker
 * Gregor Rudolf <gregor.rudolf@gmail.com>
 */
export default {
  sl: {
    days: ["Nedelja", "Ponedeljek", "Torek", "Sreda", "Četrtek", "Petek", "Sobota"],
    daysShort: ["Ned", "Pon", "Tor", "Sre", "Čet", "Pet", "Sob"],
    daysMin: ["Ne", "Po", "To", "Sr", "Če", "Pe", "So"],
    months: ["Januar", "Februar", "Marec", "April", "Maj", "Junij", "Julij", "Avgust", "September", "Oktober", "November", "December"],
    monthsShort: ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Avg", "Sep", "Okt", "Nov", "Dec"],
    today: "Danes",
    weekStart: 1
  }
};