import { enableProdMode, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { HttpLoaderFactory, appInitializerFactory } from './app/app.config';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatRadioModule } from '@angular/material/radio';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { ToastrModule } from 'ngx-toastr';
import { provideRouter } from '@angular/router';
import { AngularFireModule } from '@angular/fire/compat';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { REGION, AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ActivityLoggingService } from './app/services/users/logging/activity-logging.service';
import { ResetPasswordService } from './app/services/reset-password/reset-password.service';
import { UsersService } from './app/services/users/users.service';
import { HttpService } from './app/services/helper/http.service';
import { ChatbotService } from './app/services/chatbot/chatbot.service';
import { SearchService } from './app/services/search/search-service';
import { TerminalModalComponent } from './app/components/location/terminal-modal/terminal-modal.component';
import { LocationComponent } from './app/components/location/location.component';
import { CardService } from './app/services/location/card/card.service';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ExternalAccountService } from './app/services/external-account/external-account.service';
import { ThemeService, NgChartsModule } from 'ng2-charts';
import { CreditPaymentService } from './app/services/credit-payment/credit-payment.service';
import { DeveloperService } from './app/services/developer/developer.service';
import { SettlementsService } from './app/services/settlements/settlements.service';
import { TransactionService } from './app/services/transaction/transaction.service';
import { SupportService } from './app/services/support/support.service';
import { LocationService } from './app/services/location/location.service';
import { ExportExcelService } from '@services/excel/export-excel.service';
import { TerminalService } from './app/services/terminal/terminal.service';
import { DeviceService } from './app/services/device/device.service';
import { ChartService } from './app/services/charts/chart.service';
import { AuthGuardService } from './app/services/auth-guard/auth-guard.service';
import { AuthService } from './app/services/auth/auth.service';
import { LocalStorageService } from './app/services/local-storage/local-storage.service';
import { provideLottieOptions } from 'ngx-lottie';
import player from 'lottie-web';
import routes from './app/routes/global.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { errorInterceptor } from './app/interceptors/error.interceptor';
import { headerInterceptor } from './app/interceptors/header.interceptor';
import { CustomValidators } from '@validators/custom.validators';

if (environment.production) {
    enableProdMode();
    if (window) {
        // removing all console logs in runtime from production build
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        window.console.log = function () {};
        // window.console.error = function () { }; // not applied as of march 2022, because we wanna use this for form validation and endpoint catches debugging purposes in prod.
    }
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(
            BrowserModule,
            AngularFireModule.initializeApp(environment.firebase),
            ToastrModule.forRoot({
                preventDuplicates: true,
                //Global setting to give all toast 7 seconds
                timeOut: 7000,
                //Make toast go away after 1 second when not hovering over it
                extendedTimeOut: 1000
            }),
            AngularFireDatabaseModule,
            AngularFireAuthModule,
            AngularFireFunctionsModule,
            FormsModule,
            ReactiveFormsModule,
            NgbModule,
            NgChartsModule,
            MatPaginatorModule,
            BrowserModule,
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient]
                }
            }),
            MatRadioModule,
            ScrollingModule,
            MatCheckboxModule,
            DragDropModule,
            MatInputModule,
            MatSelectModule,
            NgSelectModule
        ),
        provideLottieOptions({
            player: () => player
        }),
        AuthService,
        AuthGuardService,
        ChartService,
        DeviceService,
        TerminalService,
        ExportExcelService,
        LocationService,
        SupportService,
        TransactionService,
        SettlementsService,
        DeveloperService,
        CreditPaymentService,
        ThemeService, // this is a fix duo to version 2.4.x of ng2-charts has broken the import of this. So the workaround is to include it. https://github.com/valor-software/ng2-charts/issues/1255
        ExternalAccountService,
        NgbActiveModal,
        CardService,
        LocationComponent,
        TerminalModalComponent,
        SearchService,
        ChatbotService,
        HttpService,
        UsersService,
        ResetPasswordService,
        LocalStorageService,
        provideAnimations(),
        ActivityLoggingService,
        CustomValidators,
        HttpClient,
        { provide: REGION, useValue: 'europe-west1' },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, LocalStorageService],
            multi: true
        },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useFactory: (authService: AuthService) => new ErrorInterceptor(authService),
        //     deps: [AuthService], // This tells Angular to inject AuthService into the useFactory function
        //     multi: true,
        // },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useFactory: (authService: AuthService) => new HeaderInterceptor(authService),
        //     deps: [AuthService], // Dependency array for HeaderInterceptor
        //     multi: true,
        // },
        provideHttpClient(withInterceptors([headerInterceptor, errorInterceptor])),
        provideRouter(routes)
        // Remove the empty function declaration
    ]
}).catch(err => console.error(err));
