import { Component, Input, OnInit } from '@angular/core';
import { Reason } from 'shared_models/order';
import { Transaction } from 'shared_models/search';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { CustomModalComponent } from '../custom-modal/custom-modal.component';

@Component({
    selector: 'app-refund-reason-modal',
    templateUrl: './refund-reason-modal.component.html',
    styleUrls: ['./refund-reason-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, NgIf, TranslateModule]
})

export class RefundReasonModalComponent implements OnInit {

    @Input() transaction: Transaction;

    constructor() { }

    ngOnInit(): void { }

    refundReasonCorrector(reason: Reason): string {
        if (reason === 'duplicate') {
            return 'Duplicate'
        }
        if (reason === 'fraudulent') {
            return 'Fraudulent'
        }
        if (reason === 'requested_by_customer') {
            return 'Requested by customer'
        }
        if (reason === 'other') {
            return 'Other'
        }
    }

}