/**
 * Macedonian translation for bootstrap-datepicker
 * Marko Aleksic <psybaron@gmail.com>
 */
export default {
  mk: {
    days: ["Недела", "Понеделник", "Вторник", "Среда", "Четврток", "Петок", "Сабота"],
    daysShort: ["Нед", "Пон", "Вто", "Сре", "Чет", "Пет", "Саб"],
    daysMin: ["Не", "По", "Вт", "Ср", "Че", "Пе", "Са"],
    months: ["Јануари", "Февруари", "Март", "Април", "Мај", "Јуни", "Јули", "Август", "Септември", "Октомври", "Ноември", "Декември"],
    monthsShort: ["Јан", "Фев", "Мар", "Апр", "Мај", "Јун", "Јул", "Авг", "Сеп", "Окт", "Ное", "Дек"],
    today: "Денес",
    format: "dd.mm.yyyy"
  }
};