import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { HelperService } from '../../helper/helper.service';
import { PriceUnit } from 'shared_models/device';
import * as Terminal from 'shared_models/terminal';
import * as Device from 'shared_models/device';

@Injectable({
    providedIn: 'root'
})
export class CardService {
    private terminal: Terminal.Unit;

    constructor(private translate: TranslateService, private helperService: HelperService) {}

    getLastUsedLabel(last_used: number, created_on: number): { label: string; value: string | null } {
        if (last_used === created_on) {
            return {
                label: '',
                value: null
            };
        } else if (last_used > parseInt(moment().format('X'))) {
            // "Available: in 1h 25m"
            return {
                label: `${this.translate.instant('device.available')}:`,
                value: `${moment().locale(this.translate.currentLang).to(moment(last_used, 'X'))}`
            };
        } else {
            // "Last cycle ended: 8 hours ago"
            return {
                label: this.translate.instant('location.cycle_ended'),
                value: `${moment(last_used, 'X').locale(this.translate.currentLang).fromNow()}`
            };
        }
    }

    localizeNumber = (number: number, currency: string): string => {
        return this.helperService.localizeNumberWithCurrency(number / 100, 2, 2, currency);
    };

    getPriceUnit = (unit: Device.Unit): string => {
        if (unit.price_unit.includes('fixed')) return ' ';

        if (unit.price_unit === PriceUnit.Increment) {
            return `/${unit['pulse_increment']} ${this.translate.instant(`device.min`)}`;
        }

        return '/' + this.translate.instant('location.' + unit.price_unit);
    };

    setTerminal(terminal: Terminal.Unit) {
        this.terminal = terminal;
    }

    getTerminal(): Terminal.Unit {
        return this.terminal;
    }
}
