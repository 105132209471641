import { Component, Input, OnInit } from '@angular/core';
import { ContractService } from "../../../services/contract/contract.service";
import { PerformingContract } from "../../../../../shared_models/operator/contracts";
import { OperatorService } from "../../../services/operator/operator.service";
import moment from "moment/moment";
import hash from "object-hash";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { HelperService } from 'src/app/services/helper/helper.service';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingComponent } from '../../loading/loading.component';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';
import { CustomToolTipComponent } from '../../misc/custom-tool-tip/custom-tool-tip.component';


@Component({
    selector: 'app-contract-performance-list',
    templateUrl: './contract-performance-list.component.html',
    styleUrls: ['./contract-performance-list.component.scss'],
    standalone: true,
    imports: [CustomToolTipComponent, NgIf, RouterLink, NgFor, LoadingComponent, TranslateModule]
})
export class ContractPerformanceListComponent implements OnInit {

    loading = false
    initLoading = true
    performanceFingerPrint: string

    isMobile: boolean

    fromDate: number
    toDate: number

    contracts: Record<string, (PerformingContract | '')[]>;
    showInfoHoverPerformance: boolean;

    constructor(
        private contractService: ContractService,
        private operatorService: OperatorService,
        private breakpointObserver: BreakpointObserver,
        private helperService: HelperService
    ) {
        this.breakpointObserver.observe([
            "(max-width: 768px)"

        ]).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
        operatorService.selectedFrom$.subscribe((value) => {
            this.fromDate = value
            if (!this.initLoading) {
                this.getContracts()
            }
        })
    }

    async ngOnInit(): Promise<void> {
        this.initLoading = true
        this.fromDate = moment().subtract(29, 'days').startOf('day').unix() + moment().utcOffset() * 60
        this.toDate = moment().startOf('day').unix() + moment().utcOffset() * 60
        await this.getContracts()
        this.initLoading = false
    }

    async getContracts() {
        this.loading = true
        let params = `fromDate=${this.fromDate}&toDate=${this.toDate}`;
        this.performanceFingerPrint = hash.keys(`${params}${moment()}`)
        params += `&fingerprint=${this.performanceFingerPrint}`

        this.contracts = await this.contractService.getPerformanceContracts(params).then((response: Record<string, (PerformingContract | '')[]>) => {
            if (this.performanceFingerPrint === response['info']['fingerprint']) {
                for (const group in response) {
                    for (let i = response[group].length; i < 5; i++) {
                        response[group].push('')
                    }
                }
                this.loading = false
                return response
            }
        })
    }

    round(num: number): string {
        return num ? `${num < 0 ? '' : '+'}${String(this.helperService.roundToTwoDecimals(num * 100))}%` : '';
    }

}
