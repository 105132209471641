import { Injectable } from '@angular/core';
import { DeviceWithDiscount, DiscountCoupon } from '../../../../shared_models/billing/discount-coupon';
import { BillingDevice, DeviceWithHoverIconProperties } from '../../../../shared_models/billing/billing';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '@services/helper/helper.service';
import { HttpService, RequestTypes } from '@services/helper/http.service';

@Injectable({
    providedIn: 'root'
})
export class DiscountCouponService {
    constructor(
        private http: HttpService,
        private translate: TranslateService,
        private helperService: HelperService
    ) {}

    /**
     * Creates a discount coupon and applies it to users and/or devices as specified.
     * This backend call handles the creation of a new discount coupon, potentially applying it globally to a user or to specific devices.
     * It also handles the removal of any pre-existing global discounts when a new global discount is applied,
     * so that only one global discount exists for a user at once.
     *
     * @param {string} uid - The user ID for whom the discount coupon is being created.
     * @param {DiscountCoupon} discountData - The data of the discount coupon to be created.
     * @param {DeviceForDiscount} devices - The devices associated with the discount coupon.
     * @returns {Promise<any>} A promise that resolves with the response from the backend.
     */
    async createDiscountCoupon(uid: string, discountData: DiscountCoupon, devices?: Array<DeviceWithDiscount>): Promise<any> {
        return this.http.dynamicHttp(`api_support/create_discount_coupon/${uid}`, RequestTypes.POST, { body: { discountData, devices } });
    }

    /**
     * Handles the removal of a discount coupon from specified devices.
     * This backend call sends a request containing user IDs, coupon data, and device information,
     * and handles the removal of the specified coupons from the users and devices.
     * It involves updating the database records to remove the coupon references and ensuring
     * that the application's state remains consistent after the removal.
     *
     * @param uid
     * @param {DeviceForDiscount} devices - The devices from which the discount coupon is being removed.
     * @returns {Promise<any>} A promise that resolves with the response from the backend, indicating the success or failure of the removal operation.
     */
    async removeCoupon(uid: string, devices: Array<DeviceWithDiscount>): Promise<any> {
        return this.http.dynamicHttp(`api_support/remove_discount_coupon/${uid}`, RequestTypes.POST, { body: { devices } });
    }

    async getGlobalDiscount(uid?: string): Promise<any> {
        return this.http.dynamicHttp(`api_billing/get_global_discount/${uid ?? ''}`, RequestTypes.GET)
    }

    /**
     * Computes the properties of the hover icon for a device.
     * Is placed in the order that takes presedence, when choosing the icon and properties.
     *
     * Good luck trying to understand the criteria for the different states :))
     * @param device
     * @param global_discount
     */
    computeHoverIconProperties(device: DeviceWithDiscount | BillingDevice, global_discount: number | null): Partial<DeviceWithHoverIconProperties> {
        const willApply: boolean = (!!device.next_discount_ref && device.next_discount_ref !== device.current_discount_ref) || (!device.next_discount_ref && !!global_discount);
        const wouldHaveApplied: boolean = (willApply && !device.renewable && !!device.billing_ref) || (willApply && device.renewable && device.discount_expiration_date && device.discount_expiration_date < device.period_end);
        const currentlyActive: boolean = device.next_discount_ref === device.current_discount_ref;
        const retired: boolean = !device.next_discount_ref && device.current_discount_ref === device.retired_discount_ref && !global_discount;

        let hoverText = wouldHaveApplied
            ? `${device.discount}% ${this.translate.instant('discount_tool.would_have_applied')}`
            : willApply
              ? `${device.discount}% ${this.translate.instant(device.renewable && device.billing_ref ? 'discount_tool.will_apply_next' : 'discount_tool.will_apply')}`
              : currentlyActive
                ? `${device.discount}% ${this.translate.instant('discount_tool.currently_active')}`
                : retired
                  ? `${device.discount}% ${this.translate.instant('discount_tool.active_until')}`
                  : '';

        if (device.discount_expiration_date) {
            hoverText += ` - ${this.translate.instant('users.user.expires')}: ${this.helperService.createdLabelForLists(device.discount_expiration_date)}`;
        }

        return {
            hoverText: hoverText,
            label: wouldHaveApplied ? `${device.discount}%` : willApply ? `${device.discount || global_discount}%` : currentlyActive ? `${device.discount}%` : retired ? `${device.discount}%` : '',
            icon: wouldHaveApplied ? 'cross-orange.svg' : willApply ? 'clock-blue.svg' : currentlyActive ? 'clock-green.svg' : retired ? 'clock-orange.svg' : '',
            startColor: wouldHaveApplied ? '#FC9124' : willApply ? '#03045E' : currentlyActive ? '#67C2AC' : retired ? '#FC9124' : '',
            endColor: wouldHaveApplied ? '#FFB66B' : willApply ? '#0150B7' : currentlyActive ? '#29E1B4' : retired ? '#FFB66B' : ''
        };
    }
}
