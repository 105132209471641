import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class CustomValidators {
    uniqueName(names: string[], excludedName?: string): ValidatorFn {
        console.log("excludedName", excludedName);
        console.log(names)
        return (control: AbstractControl): ValidationErrors | null => {
            const isForbidden = names.includes(control.value) && control.value !== excludedName ? { uniqueName: { value: control.value } } as ValidationErrors : null;
            console.log("isForbidden", isForbidden);
            console.log(names)
            return isForbidden;
        }
    }
}
