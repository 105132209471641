import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Coupon, CouponSortParams } from '../../../../../shared_models/coupon';
import { CouponService } from '../../../services/settings/coupon/coupon.service';
import { Router } from '@angular/router';
import { HelperService } from 'src/app/services/helper/helper.service';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { FilterOption, FilterType } from '../../../../../shared_models/aw-components/filterOption';
import { FilterSortParams, Sort } from '../../../../../shared_models/search-params/FilterSortParams';
import { TableHeaderOptions } from '../../../../../shared_models/aw-components/tableHeaderOptions';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { PaginatedData, PaginateFetchCacheService } from '../../../services/paginate-fetch-cache/paginate-fetch-cache.service';
import _ from 'lodash';
import { PaginatePipe } from '../../../pipe/paginate.pipe';
import { CouponFormComponent } from './coupon-form/coupon-form.component';
import { LoadingComponent } from '../../loading/loading.component';
import { AwTableComponent } from '../../misc/aw-table/aw-table.component';
import { AwFilterButtonComponent } from '../../misc/aw-filter-button/aw-filter-button.component';
import { PageLayoutComponent } from '../../misc/aw-page-layout/page-layout.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-coupon',
    templateUrl: './coupon.component.html',
    styleUrls: ['./coupon.component.scss'],
    standalone: true,
    imports: [NgIf, PageLayoutComponent, AwFilterButtonComponent, AwTableComponent, NgFor, MatPaginator, LoadingComponent, CouponFormComponent, TranslateModule, PaginatePipe]
})
export class CouponComponent implements OnInit {
    coupons: Coupon[] = [];
    couponCount = 0;
    isMobile: boolean;
    initLoading: boolean;
    pageSizeOptions = [10, 50, 100];
    pageSize = this.pageSizeOptions[0];
    pageNumber = 0;
    terms: string;
    loadingCoupons = true;
    couponId: string;
    sortingAscending: boolean;
    sortBy: CouponSortParams = CouponSortParams.CREATED;
    isEdit = false;
    specificCouponParams: any;
    couponCodes: string[] = [];
    tableHeaderOptions: TableHeaderOptions[] = [
        {
            sortKey: 'name',
            title: this.translate.instant('coupon.name'),
            width: '20%'
        },
        {
            sortKey: '',
            title: this.translate.instant('coupon.terms'),
            width: '40%'
        },
        {
            sortKey: 'entries',
            title: this.translate.instant('coupon.entries'),
            width: '10%',
            alignment: 'right'
        },
        {
            sortKey: 'redemptions',
            title: this.translate.instant('coupon.redemptions'),
            width: '10%',
            alignment: 'right'
        },
        {
            sortKey: 'expire',
            title: this.translate.instant('coupon.expire'),
            width: '10%',
            alignment: 'right'
        },
        {
            sortKey: 'creation_date',
            title: this.translate.instant('coupon.created'),
            width: '10%',
            alignment: 'right'
        }
    ];

    filterOptions: FilterOption[] = [
        {
            key: 'creation_date',
            type: FilterType.DATE_RANGE,
            value: null,
            label: 'Created',
            selectOptions: [],
            isDateRange: true
        }
    ];

    params: FilterSortParams = {
        filter: {},
        sortBy: { key: 'creation_date', order: 'desc' },
        pageSize: this.pageSize,
        pageNumber: this.pageNumber
    };

    constructor(
        private router: Router,
        private couponService: CouponService,
        private breakpointObserver: BreakpointObserver,
        protected helperService: HelperService,
        protected translate: TranslateService,
        private pfcService: PaginateFetchCacheService<Coupon>
    ) {
        this.breakpointObserver.observe(['(max-width: 768px)']).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    async ngOnInit(): Promise<void> {
        this.initLoading = true;
        await this.getCoupons(true);
        console.log('logging coupons: ', this.coupons);
    }

    async getCoupons(initLoading?: boolean): Promise<void> {
        this.loadingCoupons = true;
        const paramsAsString: string = JSON.stringify(this.params);
        const endpoint = `${environment.baseUrl}/api_account/coupon?params=${paramsAsString}`;
        const cacheExists = this.pfcService.cacheExists(endpoint, this.params);
        await this.pfcService
            .getData(endpoint, this.params)
            .then((response: PaginatedData<Coupon>) => {
                this.coupons = response.pages[this.pageNumber];
                this.couponCount = response.totalItems;
            })
            .catch(err => {
                console.error(err);
                this.helperService.defaultHtmlToast(this.translate.instant('billing.something_wrong'), `${this.translate.instant('billing.try_again')}`, 'Warning');
            })
            .finally(() => {
                this.initLoading = false;
                this.loadingCoupons = false;

                if (initLoading && cacheExists) {
                    this.updateCachedData(endpoint);
                }
            });
    }

    private updateCachedData(endpoint: string) {
        this.pfcService.updateDataIfDifferentFromCache(endpoint, this.params).then(fetchedData => {
            if (!_.isEqual(fetchedData.pages[this.pageNumber], this.coupons)) {
                this.coupons = fetchedData.pages[this.pageNumber];
            }
        });
    }

    navigateToCouponCreation() {
        this.router.navigate(['coupon/coupon-create']);
    }

    navigateToCouponEdit(couponParams?: Coupon) {
        this.couponService.setSelectedCoupon(couponParams);
        this.router.navigate(['/coupon', couponParams?.key]);
        this.isEdit = true;
        this.specificCouponParams = couponParams;
    }

    getTerms(coupon: Coupon): string {
        return this.helperService.parseTranslationArray(coupon.description);
    }

    shortenTerms(fullTerms: string) {
        const maxLength = 57;
        return fullTerms.length > maxLength ? `${fullTerms.substring(0, maxLength)}...` : fullTerms;
    }

    //region Table Functions
    async catchFilterChanged(event: any) {
        if (JSON.stringify(this.params.filter) !== JSON.stringify(event)) {
            this.pageNumber = 0;
        }
        this.params.filter = event;
        this.params.pageNumber = this.pageNumber;
        await this.getCoupons();
    }

    async handlePage(e: PageEvent) {
        this.pageSize = e.pageSize;
        this.pageNumber = e.pageIndex;
        this.params.pageSize = this.pageSize;
        this.params.pageNumber = this.pageNumber;
        await this.getCoupons();
    }

    async catchSortChanged(event: Sort) {
        if (this.params.sortBy.key !== event.key) {
            this.pageNumber = 0;
        }
        this.params.sortBy = event;
        this.params.pageNumber = this.pageNumber;
        await this.getCoupons();
    }
    //endregion
}
