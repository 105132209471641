import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeviceService } from "../../../services/device/device.service";
import { Subscription } from "rxjs";
import moment from 'moment';
import { Basic } from 'shared_models/device';
import { MachineSpecificationService, resetMachineSpecificationsServiceParams } from 'shared_models/machine-specifications';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingComponent } from '../../loading/loading.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { NgIf } from '@angular/common';
import { CustomModalComponent } from '../../misc/custom-modal/custom-modal.component';


@Component({
    selector: 'app-machine-services-reset-modal',
    templateUrl: './machine-services-reset-modal.component.html',
    styleUrls: ['./machine-services-reset-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, NgIf, MatRadioGroup, FormsModule, MatRadioButton, MatCheckbox, LoadingComponent, TranslateModule]
})
export class MachineServicesResetModalComponent implements OnInit, OnDestroy {

    @Input() location_id: string;
    @Input() device_id: string;
    @Input() customer_id: string;
    @Input() machine_service: string;

    @Output() closeAction = new EventEmitter<boolean>();
    @Output() closeEntireLocation = new EventEmitter<any>();

    currentDevice: Basic;
    allDevicesOnLocation: Basic[] = [];
    allDevicesAndMachineSpecService: { key: string, spec: MachineSpecificationService }[] = [];

    devicesSub: Subscription;

    maintenanceChecked = false;
    soapChecked = false;
    resetScope = 'machine'

    canResetSoap = false;
    sendingData = false;
    initialLoading = true

    soapLocked = false
    maintenanceLocked = false

    constructor(
        public modalService: NgbModal,
        private deviceService: DeviceService,
    ) { }

    ngOnDestroy(): void {
        this.devicesSub ? this.devicesSub.unsubscribe() : null;
    }

    ngOnInit(): void {
        this.initialLoading = true
        this.lockCheckboxesIfAction()
        this.getCurrentDeviceAndSetAllDevices()
    }

    lockCheckboxesIfAction(): void {
        this.soapLocked = this.machine_service === 'soap'
        this.maintenanceLocked = this.machine_service === 'maintenance'
        this.soapChecked = this.soapLocked
        this.maintenanceChecked = this.maintenanceLocked
    }

    getCurrentDeviceAndSetAllDevices(): void {
        this.devicesSub = this.deviceService.readDevice(this.customer_id, this.location_id, this.device_id).snapshotChanges().subscribe(snapshot => {
            this.devicesSub.unsubscribe();
            this.currentDevice = snapshot.payload.val();
            this.initialLoading = false;
            this.getAllDevices()
        });
    }

    getAllDevices() {
        this.devicesSub = this.deviceService.readDevices(this.customer_id, this.currentDevice.location_id).snapshotChanges().subscribe(snapshot => {
            this.devicesSub.unsubscribe();
            const devices: Record<string, Basic> = snapshot.payload.val();
            for (const devicesKey in devices) {
                this.allDevicesOnLocation.push(devices[devicesKey])
                const resatSpec: MachineSpecificationService = devices[devicesKey].machine_specifications.machine_spec_service
                this.allDevicesAndMachineSpecService.push({ key: devicesKey, spec: resatSpec })
            }
            this.shouldSoapBeActiveForDevice()
        });
    }

    shouldSoapBeActiveForDevice(): void {
        this.canResetSoap = !!this.currentDevice.machine_specifications.machine_spec_service.soap_limit;
        for (const deviceSpec of this.allDevicesAndMachineSpecService) {
            if (!!deviceSpec.spec.soap_limit && this.resetScope === 'location') {
                this.canResetSoap = true
            }
        }
    }

    changeResetScope() {
        this.shouldSoapBeActiveForDevice()
    }

    checkMaintenance(event) {
        this.maintenanceChecked = event.checked === true
    }

    checkSoap(event) {
        this.soapChecked = event.checked === true
    }

    async resetService() {
        if (this.resetScope === 'machine') {
            this.currentDevice.machine_specifications.machine_spec_service = this.resetMaintenanceAndOrSoap(this.currentDevice.machine_specifications.machine_spec_service)
        } else if (this.resetScope === 'location') {
            for (const devicesKey in this.allDevicesAndMachineSpecService) {
                this.allDevicesAndMachineSpecService[devicesKey].spec = this.resetMaintenanceAndOrSoap(this.allDevicesAndMachineSpecService[devicesKey].spec)
            }
        }

        const resetData: resetMachineSpecificationsServiceParams = {
            uid: this.customer_id,
            currentDeviceAndMachineSpecService: { key: this.currentDevice.id, spec: this.currentDevice.machine_specifications.machine_spec_service },
            locationKey: this.currentDevice.location_id,
            allDevicesAndMachineSpecService: this.allDevicesAndMachineSpecService,
            resetEntireLocation: this.resetScope === 'location'
        }

        this.sendingData = true
        await this.deviceService.resetDeviceMachineServices(resetData).then((response) => {
            this.sendingData = false
            this.modalService.dismissAll()
            this.resetScope === 'machine' ? this.closeActionEmit() : this.closeLocationEmit();
        })
    }

    closeActionEmit(): void {
        this.closeAction.next(this.soapChecked && this.maintenanceChecked)
    }

    closeLocationEmit(): void {
        this.closeEntireLocation.next(this.soapChecked && this.maintenanceChecked)
    }

    completeWithoutReset() {
        this.closeActionEmit()
        this.modalService.dismissAll()
    }

    resetMaintenanceAndOrSoap(machineSpecService: MachineSpecificationService): MachineSpecificationService {
        if (this.maintenanceChecked) {
            machineSpecService.current_maintenance_count = 0
        }
        if (this.soapChecked) {
            machineSpecService.current_soap_count = 0
        }
        return machineSpecService
    }
}
