<div style="width: 100%; height: 300px;">
    <div *ngIf="lineChartReady && !showLoadingIndicator" style="width: 100%; height: 100%; position:relative">
        <app-chart-host chartType="line">
            <canvas #myChart baseChart id="chart" height="300" class="lineChart" [data]="lineChartData"
                [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                [type]="lineChartType">
            </canvas>
        </app-chart-host>

    </div>
    <div *ngIf="showLoadingIndicator" style="padding: 100px">
        <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
    </div>
</div>