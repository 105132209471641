import { Component, Input, OnInit } from '@angular/core';
import { Details, OnboardingState } from "../../../../../shared_models/details";
import { SubCustomerPermission } from "../../../../../shared_models/sub-customer";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { AuthService } from "../../../services/auth/auth.service";
import { Router } from "@angular/router";
import { OnboardingService } from "../../../services/onboarding/onboarding.service";
import { HelperService } from "../../../services/helper/helper.service";
import { DashboardUser } from "../../../../../shared_models/dashboard-user";
import { CustomerService } from "../../../services/customer/customer.service";
import { Observable } from "rxjs";
import * as Claims from "../../../../../shared_models/claims";
import { LoadingComponent } from '../../loading/loading.component';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingComponent, AsyncPipe, TranslateModule]
})
export class OnboardingComponent implements OnInit {

    @Input() customerDetails: Details

    subCustomerPermissions: SubCustomerPermission = {
        allow_billing: true, allow_location: true, allow_refund: true, allow_change_billing: true, allow_advanced_share: true, lock_advanced_share: false,
        allow_coupon: true
    }
    noShare: boolean;
    state: OnboardingState;
    isLife: boolean;
    user: DashboardUser;
    role$: Observable<Claims.Roles> = this.authService.getRole;
    onboardingComplete = true;
    showLoadingIndicator = false;
    showSmallLoadingIndicator = false;
    hasCompletedStep1: boolean;
    shouldCompleteStep2: boolean;
    shouldCompleteStep3: boolean;

    constructor(
        public translate: TranslateService,
        private router: Router,
        private onboardingService: OnboardingService,
        private helperService: HelperService,
        private customerService: CustomerService,
        public authService: AuthService
    ) { }

    async ngOnInit() {
        this.user = this.helperService.getUser();
        this.isLife = this.customerDetails && this.customerDetails.agreement_type === 'life';
        this.state = this.customerDetails.onboarding_state;
        await this.handleOnboardingCompleteCheck();
        this.calculateHasCompletedStep1();
        await this.calculateShouldCompleteStep2();;
        this.calculateShouldCompleteStep3();
    }

    calculateHasCompletedStep1() {
        this.hasCompletedStep1 = this.state.external_account || this.noShare
    }

    async calculateShouldCompleteStep2() {
        this.shouldCompleteStep2 = this.state.external_account && this.state.capabilities !== 'active' && this.authService.hasLimitedAccess('edit_kyc', (await this.authService.getRoleStatus())) || this.noShare
    }

    calculateShouldCompleteStep3() {
        this.shouldCompleteStep3 = this.state.capabilities === 'active' || this.noShare
    }

    async getSubcustomerPermissionsFromSub(): Promise<SubCustomerPermission> {
        return new Promise((resolve, reject) => {
            const subCustomerPermissionSub = this.customerService.readSubCustomerPermission(this.user.uid).snapshotChanges().subscribe(action => {
                subCustomerPermissionSub.unsubscribe();
                const subCustomerPermissions = action.payload.val();
                return resolve(subCustomerPermissions)
            });
        });
    }

    async handleOnboardingCompleteCheck(): Promise<void> {
        if (this.user.uid.includes('_operated_by_')) {
            this.subCustomerPermissions = await this.getSubcustomerPermissionsFromSub()
        }
        if (this.subCustomerPermissions.no_share) {
            this.noShare = true
        }
        this.onboardingComplete = await this.onboardingService.getOnboardingStatus(this.customerDetails, this.user)
    }

    onboardingAction(action: 'external_account' | 'capabilities' | 'billing') {
        if (action === 'capabilities') {
            this.goToStripeKYC();
            return;
        } else {
            this.router.navigate([`${action === 'billing' ? 'billing' : 'account/onboarding'}`]);
        }
    }

    goToStripeKYC() {
        this.showSmallLoadingIndicator = true;
        this.onboardingService.getStripeKYCUrl().catch((error) => {
            this.showSmallLoadingIndicator = false
        })
    }
}
