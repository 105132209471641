<div class="mb-5 container mt-4 {{ this.path === '/actions' ? 'container_padding' : '' }}" style="position: relative">
    <div class="toggleMenu">
        <div class="toggle-title">
            <app-custom-tool-tip [spaceBetween]="true">
                <div title>
                    <h4 class="tiny-title">{{ "misc.filters" | translate }}</h4>
                </div>
                <div description>
                    <strong>{{ "operator.actions.alerts" | translate }}:</strong><br />
                    <span>{{ "operator.actions.tooltip.alerts" | translate }}</span
                    ><br /><br />
                    <strong>{{ "operator.actions.advices" | translate }}:</strong><br />
                    <span>{{ "operator.actions.tooltip.advices" | translate }}</span
                    ><br /><br />
                    <strong>{{ "operator.actions.closed" | translate }}:</strong><br />
                    <span>{{ "operator.actions.tooltip.closed" | translate }}</span>
                </div>
                <div tooltip-title>
                    <span class="tooltip-title">{{ "misc.filters" | translate }}</span>
                </div>
            </app-custom-tool-tip>
        </div>
        <div class="toggleWrapper">
            <div class="toggleBox">
                <div>
                    <label class="label">{{ "operator.actions.alerts" | translate }}</label>
                </div>
                <div>
                    <app-aw-switch [shouldBeChecked]="showAlerts" (activateClick)="toggleAlerts()"></app-aw-switch>
                </div>
            </div>
            <div class="toggleBox">
                <div>
                    <label class="label">{{ "operator.actions.advices" | translate }}</label>
                </div>
                <div>
                    <app-aw-switch [shouldBeChecked]="showAdvices" (activateClick)="toggleAdvices()"></app-aw-switch>
                </div>
            </div>
            <div class="toggleBox">
                <div>
                    <label class="label">{{ "operator.actions.closed" | translate }}</label>
                </div>
                <div>
                    <app-aw-switch [shouldBeChecked]="false" (activateClick)="toggleClosed()"></app-aw-switch>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="loading">
        <div class="action-box">
            <div class="d-flex top-half align-items-center justify-content-center" style="height: 100%">
                <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
            </div>
            <div class="bottom-half d-flex"></div>
        </div>
    </div>
    <ng-container *ngIf="!!actions && !loading">
        <div [@.disabled]="noAnimation">
            <ng-container *ngFor="let action of actions; let i = index">
                <div *ngIf="showAction(action)" @inOutPaneAnimation class="d-flex" style="width: 100%; position: relative">
                    <div class="animation-height-handler"></div>
                    <div class="animation-item mb-3">
                        <app-actions-card
                            id="{{ action.key }}"
                            [action]="action"
                            [isSkeleton]="isSkeleton"
                            [backendLoading]="backendLoading"
                            (emitMoveActionToOpen)="moveActionBackToOpen($event)"
                            (emitRemoveAction)="moveActionToClosed($event)"
                            (emitReloadActions)="setActionsFromBackend()"
                            style="width: 100%"
                        >
                        </app-actions-card>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>
    <ng-container *ngIf="(showNoActionsBox() || actions.length === 0 || (!showNoActionsBox() && showNoActionOfTypeBox())) && !loading">
        <div class="empty-container d-flex align-items-center justify-content-center flex-column">
            <div class="content-container">
                <div class="empty-top-half">
                    <div class="lottie-container">
                        <ng-lottie [options]="options" class="lottie-waves"></ng-lottie>
                    </div>
                    <p>{{ "operator.actions.empty_boxes.no_active_actions_hooray" | translate }}</p>
                </div>
                <p>{{ "operator.actions.empty_boxes.no_active_actions" | translate }}</p>
            </div>
        </div>
    </ng-container>
</div>
