/*
Data merged into one single array from JSON object hosted on this website: http://country.io/data/
*/

export const phoneNumberCountryData: {
    code: string;
    country: string;
    callCode: string;
    currency: string;
    phLengthMax: number;
    phLengthMin: number;
    phLength: string;
}[] = [
    {
        code: 'AR',
        country: 'Argentina',
        callCode: '+54',
        currency: 'ARS',
        phLengthMax: 10,
        phLengthMin: 10,
        phLength: '10'
    },
    {
        code: 'CR',
        country: 'Costa Rica',
        callCode: '+506',
        currency: 'CRC',
        phLengthMax: 8,
        phLengthMin: 8,
        phLength: '8'
    },
    {
        code: 'AM',
        country: 'Armenia',
        callCode: '+374',
        currency: 'AMD',
        phLengthMax: 8,
        phLengthMin: 8,
        phLength: '8'
    },
    {
        code: 'AU',
        country: 'Australia',
        callCode: '+61',
        currency: 'AUD',
        phLengthMax: 15,
        phLengthMin: 5,
        phLength: '5 to 15'
    },
    {
        code: 'AT',
        country: 'Austria',
        callCode: '+43',
        currency: 'EUR',
        phLengthMax: 13,
        phLengthMin: 4,
        phLength: '4 to 13'
    },
    {
        code: 'BY',
        country: 'Belarus',
        callCode: '+375',
        currency: 'BYR',
        phLengthMax: 10,
        phLengthMin: 9,
        phLength: '9 to 10'
    },
    {
        code: 'BE',
        country: 'Belgium',
        callCode: '+32',
        currency: 'EUR',
        phLengthMax: 9,
        phLengthMin: 8,
        phLength: '8 to 9'
    },
    {
        code: 'BR',
        country: 'Brazil',
        callCode: '+55',
        currency: 'BRL',
        phLengthMax: 10,
        phLengthMin: 10,
        phLength: '10'
    },
    {
        code: 'BG',
        country: 'Bulgaria',
        callCode: '+359',
        currency: 'BGN',
        phLengthMax: 9,
        phLengthMin: 7,
        phLength: '7 to 9'
    },
    {
        code: 'CA',
        country: 'Canada',
        callCode: '+1',
        currency: 'CAD',
        phLengthMax: 10,
        phLengthMin: 10,
        phLength: '10'
    },
    {
        code: 'CL',
        country: 'Chile',
        callCode: '+56',
        currency: 'CLP',
        phLengthMax: 9,
        phLengthMin: 8,
        phLength: '8 to 9'
    },
    {
        code: 'CN',
        country: 'China',
        callCode: '+86',
        currency: 'CNY',
        phLengthMax: 12,
        phLengthMin: 5,
        phLength: '5 to 12'
    },
    {
        code: 'CO',
        country: 'Colombia',
        callCode: '+57',
        currency: 'COP',
        phLengthMax: 10,
        phLengthMin: 8,
        phLength: '8 or 10'
    },
    {
        code: 'HR',
        country: 'Croatia',
        callCode: '+385',
        currency: 'HRK',
        phLengthMax: 12,
        phLengthMin: 8,
        phLength: '8 to 12'
    },
    {
        code: 'CY',
        country: 'Cyprus',
        callCode: '+357',
        currency: 'EUR',
        phLengthMax: 11,
        phLengthMin: 8,
        phLength: '8 or 11'
    },
    {
        code: 'CZ',
        country: 'Czech Republic',
        callCode: '+420',
        currency: 'CZK',
        phLengthMax: 12,
        phLengthMin: 4,
        phLength: '4 to 12'
    },
    {
        code: 'DK',
        country: 'Denmark',
        callCode: '+45',
        currency: 'DKK',
        phLengthMax: 8,
        phLengthMin: 8,
        phLength: '8'
    },
    {
        code: 'EC',
        country: 'Ecuador',
        callCode: '+593',
        currency: 'USD',
        phLengthMax: 8,
        phLengthMin: 8,
        phLength: '8'
    },
    {
        code: 'EG',
        country: 'Egypt',
        callCode: '+20',
        currency: 'EGP',
        phLengthMax: 9,
        phLengthMin: 7,
        phLength: '7 to 9'
    },
    {
        code: 'EE',
        country: 'Estonia',
        callCode: '+372',
        currency: 'EUR',
        phLengthMax: 10,
        phLengthMin: 7,
        phLength: '7 to 10'
    },
    {
        code: 'FO',
        country: 'Faroe Islands',
        callCode: '+298',
        currency: 'DKK',
        phLengthMax: 6,
        phLengthMin: 6,
        phLength: '6'
    },
    {
        code: 'FI',
        country: 'Finland',
        callCode: '+358',
        currency: 'EUR',
        phLengthMax: 12,
        phLengthMin: 5,
        phLength: '5 to 12'
    },
    {
        code: 'FR',
        country: 'France',
        callCode: '+33',
        currency: 'EUR',
        phLengthMax: 9,
        phLengthMin: 9,
        phLength: '9'
    },
    {
        code: 'GE',
        country: 'Georgia',
        callCode: '+995',
        currency: 'GEL',
        phLengthMax: 9,
        phLengthMin: 9,
        phLength: '9'
    },
    {
        code: 'DE',
        country: 'Germany',
        callCode: '+49',
        currency: 'EUR',
        phLengthMax: 13,
        phLengthMin: 6,
        phLength: '6 to 13'
    },
    {
        code: 'GI',
        country: 'Gibraltar',
        callCode: '+350',
        currency: 'GIP',
        phLengthMax: 8,
        phLengthMin: 8,
        phLength: '8'
    },
    {
        code: 'GR',
        country: 'Greece',
        callCode: '+30',
        currency: 'EUR',
        phLengthMax: 10,
        phLengthMin: 10,
        phLength: '10'
    },
    {
        code: 'GL',
        country: 'Greenland',
        callCode: '+299',
        currency: 'DKK',
        phLengthMax: 6,
        phLengthMin: 6,
        phLength: '6'
    },
    {
        code: 'HK',
        country: 'Hong Kong',
        callCode: '+852',
        currency: 'HKD',
        phLengthMax: 9,
        phLengthMin: 4,
        phLength: '4, 8 to 9'
    },
    {
        code: 'HU',
        country: 'Hungary',
        callCode: '+36',
        currency: 'HUF',
        phLengthMax: 9,
        phLengthMin: 8,
        phLength: '8 to 9'
    },
    {
        code: 'IS',
        country: 'Iceland',
        callCode: '+354',
        currency: 'EUR', // us stripe does not support ISK as default currency
        phLengthMax: 9,
        phLengthMin: 7,
        phLength: '7 or 9'
    },
    {
        code: 'IN',
        country: 'India',
        callCode: '+91',
        currency: 'INR',
        phLengthMax: 10,
        phLengthMin: 7,
        phLength: '7 to 10'
    },
    {
        code: 'ID',
        country: 'Indonesia',
        callCode: '+62',
        currency: 'IDR',
        phLengthMax: 10,
        phLengthMin: 5,
        phLength: '5 to 10'
    },
    {
        code: 'IR',
        country: 'Iran',
        callCode: '+98',
        currency: 'IRR',
        phLengthMax: 10,
        phLengthMin: 6,
        phLength: '6 to 10'
    },
    {
        code: 'IQ',
        country: 'Iraq',
        callCode: '+964',
        currency: 'IQD',
        phLengthMax: 10,
        phLengthMin: 8,
        phLength: '8 to 10'
    },
    {
        code: 'IE',
        country: 'Ireland',
        callCode: '+353',
        currency: 'EUR',
        phLengthMax: 11,
        phLengthMin: 7,
        phLength: '7 to 11'
    },
    {
        code: 'IL',
        country: 'Israel',
        callCode: '+972',
        currency: 'ILS',
        phLengthMax: 9,
        phLengthMin: 8,
        phLength: '8 to 9'
    },
    {
        code: 'IT',
        country: 'Italy',
        callCode: '+39',
        currency: 'EUR',
        phLengthMax: 11,
        phLengthMin: 0,
        phLength: 'up to 11'
    },
    {
        code: 'JM',
        country: 'Jamaica',
        callCode: '+1-876',
        currency: 'JMD',
        phLengthMax: 10,
        phLengthMin: 10,
        phLength: '(876) + 7'
    },
    {
        code: 'JP',
        country: 'Japan',
        callCode: '+81',
        currency: 'JPY',
        phLengthMax: 13,
        phLengthMin: 5,
        phLength: '5 to 13'
    },
    {
        code: 'LV',
        country: 'Latvia',
        callCode: '+371',
        currency: 'EUR',
        phLengthMax: 8,
        phLengthMin: 7,
        phLength: '7 or 8'
    },
    {
        code: 'LB',
        country: 'Lebanon',
        callCode: '+961',
        currency: 'LBP',
        phLengthMax: 8,
        phLengthMin: 7,
        phLength: '7 to 8'
    },
    {
        code: 'LY',
        country: 'Libya',
        callCode: '+218',
        currency: 'LYD',
        phLengthMax: 9,
        phLengthMin: 8,
        phLength: '8 to 9'
    },
    {
        code: 'LI',
        country: 'Liechtenstein',
        callCode: '+423',
        currency: 'CHF',
        phLengthMax: 9,
        phLengthMin: 7,
        phLength: '7 to 9'
    },
    {
        code: 'LT',
        country: 'Lithuania',
        callCode: '+370',
        currency: 'EUR',
        phLengthMax: 8,
        phLengthMin: 8,
        phLength: '8'
    },
    {
        code: 'LU',
        country: 'Luxembourg',
        callCode: '+352',
        currency: 'EUR',
        phLengthMax: 11,
        phLengthMin: 4,
        phLength: '4 to 11'
    },
    {
        code: 'MK',
        country: 'Macedonia',
        callCode: '+389',
        currency: 'MKD',
        phLengthMax: 0,
        phLengthMin: 0,
        phLength: ''
    },
    {
        code: 'MY',
        country: 'Malaysia',
        callCode: '+60',
        currency: 'MYR',
        phLengthMax: 9,
        phLengthMin: 7,
        phLength: '7 to 9'
    },
    {
        code: 'MT',
        country: 'Malta',
        callCode: '+356',
        currency: 'EUR',
        phLengthMax: 8,
        phLengthMin: 8,
        phLength: '8'
    },
    {
        code: 'MX',
        country: 'Mexico',
        callCode: '+52',
        currency: 'MXN',
        phLengthMax: 10,
        phLengthMin: 10,
        phLength: '10'
    },
    {
        code: 'MD',
        country: 'Moldova',
        callCode: '+373',
        currency: 'MDL',
        phLengthMax: 8,
        phLengthMin: 8,
        phLength: '8'
    },
    {
        code: 'MC',
        country: 'Monaco',
        callCode: '+377',
        currency: 'EUR',
        phLengthMax: 9,
        phLengthMin: 5,
        phLength: '5 to 9'
    },
    {
        code: 'ME',
        country: 'Montenegro',
        callCode: '+382',
        currency: 'EUR',
        phLengthMax: 12,
        phLengthMin: 4,
        phLength: '4 to 12'
    },
    {
        code: 'MA',
        country: 'Morocco',
        callCode: '+212',
        currency: 'MAD',
        phLengthMax: 9,
        phLengthMin: 9,
        phLength: '9'
    },
    {
        code: 'NL',
        country: 'Netherlands',
        callCode: '+31',
        currency: 'EUR',
        phLengthMax: 9,
        phLengthMin: 9,
        phLength: '9'
    },
    {
        code: 'NZ',
        country: 'New Zealand',
        callCode: '+64',
        currency: 'NZD',
        phLengthMax: 10,
        phLengthMin: 3,
        phLength: '3 to 10'
    },
    {
        code: 'NG',
        country: 'Nigeria',
        callCode: '+234',
        currency: 'NGN',
        phLengthMax: 10,
        phLengthMin: 7,
        phLength: '7 to 10'
    },
    {
        code: 'NO',
        country: 'Norway',
        callCode: '+47',
        currency: 'NOK',
        phLengthMax: 8,
        phLengthMin: 5,
        phLength: '5 or 8'
    },
    {
        code: 'OM',
        country: 'Oman',
        callCode: '+968',
        currency: 'OMR',
        phLengthMax: 8,
        phLengthMin: 7,
        phLength: '7 to 8'
    },
    {
        code: 'PK',
        country: 'Pakistan',
        callCode: '+92',
        currency: 'PKR',
        phLengthMax: 11,
        phLengthMin: 8,
        phLength: '8 to 11'
    },
    {
        code: 'PA',
        country: 'Panama',
        callCode: '+507',
        currency: 'PAB',
        phLengthMax: 8,
        phLengthMin: 7,
        phLength: '7 or 8'
    },
    {
        code: 'PY',
        country: 'Paraguay',
        callCode: '+595',
        currency: 'PYG',
        phLengthMax: 9,
        phLengthMin: 5,
        phLength: '5 to 9'
    },
    {
        code: 'PE',
        country: 'Peru',
        callCode: '+51',
        currency: 'PEN',
        phLengthMax: 11,
        phLengthMin: 8,
        phLength: '8 to 11'
    },
    {
        code: 'PH',
        country: 'Philippines',
        callCode: '+63',
        currency: 'PHP',
        phLengthMax: 10,
        phLengthMin: 8,
        phLength: '8 to 10'
    },
    {
        code: 'PL',
        country: 'Poland',
        callCode: '+48',
        currency: 'PLN',
        phLengthMax: 9,
        phLengthMin: 6,
        phLength: '6 to 9'
    },
    {
        code: 'PT',
        country: 'Portugal',
        callCode: '+351',
        currency: 'EUR',
        phLengthMax: 11,
        phLengthMin: 9,
        phLength: '9 or 11'
    },
    {
        code: 'QA',
        country: 'Qatar',
        callCode: '+974',
        currency: 'QAR',
        phLengthMax: 8,
        phLengthMin: 3,
        phLength: '3 to 8'
    },
    {
        code: 'RO',
        country: 'Romania',
        callCode: '+40',
        currency: 'RON',
        phLengthMax: 9,
        phLengthMin: 9,
        phLength: '9'
    },
    {
        code: 'RU',
        country: 'Russia',
        callCode: '+7',
        currency: 'RUB',
        phLengthMax: 10,
        phLengthMin: 10,
        phLength: '10'
    },
    {
        code: 'SA',
        country: 'Saudi Arabia',
        callCode: '+966',
        currency: 'SAR',
        phLengthMax: 9,
        phLengthMin: 8,
        phLength: '8 to 9'
    },
    {
        code: 'RS',
        country: 'Serbia',
        callCode: '+381',
        currency: 'RSD',
        phLengthMax: 12,
        phLengthMin: 4,
        phLength: '4 to 12'
    },
    {
        code: 'SG',
        country: 'Singapore',
        callCode: '+65',
        currency: 'SGD',
        phLengthMax: 12,
        phLengthMin: 8,
        phLength: '8 to 12'
    },
    {
        code: 'SK',
        country: 'Slovakia',
        callCode: '+421',
        currency: 'EUR',
        phLengthMax: 9,
        phLengthMin: 4,
        phLength: '4 to 9'
    },
    {
        code: 'SI',
        country: 'Slovenia',
        callCode: '+386',
        currency: 'EUR',
        phLengthMax: 8,
        phLengthMin: 8,
        phLength: '8'
    },
    {
        code: 'KR',
        country: 'South Korea',
        callCode: '+82',
        currency: 'KRW',
        phLengthMax: 11,
        phLengthMin: 8,
        phLength: '8 to 11'
    },
    {
        code: 'ES',
        country: 'Spain',
        callCode: '+34',
        currency: 'EUR',
        phLengthMax: 9,
        phLengthMin: 9,
        phLength: '9'
    },
    {
        code: 'SE',
        country: 'Sweden',
        callCode: '+46',
        currency: 'SEK',
        phLengthMax: 13,
        phLengthMin: 7,
        phLength: '7 to 13'
    },
    {
        code: 'CH',
        country: 'Switzerland',
        callCode: '+41',
        currency: 'CHF',
        phLengthMax: 12,
        phLengthMin: 4,
        phLength: '4 to 12'
    },
    {
        code: 'SY',
        country: 'Syria',
        callCode: '+963',
        currency: 'SYP',
        phLengthMax: 10,
        phLengthMin: 8,
        phLength: '8 to 10'
    },
    {
        code: 'TW',
        country: 'Taiwan',
        callCode: '+886',
        currency: 'TWD',
        phLengthMax: 9,
        phLengthMin: 8,
        phLength: '8 to 9'
    },
    {
        code: 'TH',
        country: 'Thailand',
        callCode: '+66',
        currency: 'THB',
        phLengthMax: 9,
        phLengthMin: 8,
        phLength: '8 or 9'
    },
    {
        code: 'TN',
        country: 'Tunisia',
        callCode: '+216',
        currency: 'TND',
        phLengthMax: 8,
        phLengthMin: 8,
        phLength: '8'
    },
    {
        code: 'TR',
        country: 'Turkey',
        callCode: '+90',
        currency: 'TRY',
        phLengthMax: 10,
        phLengthMin: 10,
        phLength: '10'
    },
    {
        code: 'UA',
        country: 'Ukraine',
        callCode: '+380',
        currency: 'UAH',
        phLengthMax: 9,
        phLengthMin: 9,
        phLength: '9'
    },
    {
        code: 'AE',
        country: 'United Arab Emirates',
        callCode: '+971',
        currency: 'AED',
        phLengthMax: 9,
        phLengthMin: 8,
        phLength: '8 to 9'
    },
    {
        code: 'GB',
        country: 'United Kingdom',
        callCode: '+44',
        currency: 'GBP',
        phLengthMax: 10,
        phLengthMin: 7,
        phLength: '7 to 10'
    },
    {
        code: 'US',
        country: 'United States',
        callCode: '+1',
        currency: 'USD',
        phLengthMax: 10,
        phLengthMin: 10,
        phLength: '10'
    },
    {
        code: 'UY',
        country: 'Uruguay',
        callCode: '+598',
        currency: 'UYU',
        phLengthMax: 11,
        phLengthMin: 4,
        phLength: '4 to 11'
    },
    {
        code: 'UZ',
        country: 'Uzbekistan',
        callCode: '+998',
        currency: 'UZS',
        phLengthMax: 9,
        phLengthMin: 9,
        phLength: '9'
    },
    {
        code: 'VA',
        country: 'Vatican',
        callCode: '+379',
        currency: 'EUR',
        phLengthMax: 0,
        phLengthMin: 0,
        phLength: ''
    },
    {
        code: 'VE',
        country: 'Venezuela',
        callCode: '+58',
        currency: 'VEF',
        phLengthMax: 10,
        phLengthMin: 10,
        phLength: '10'
    },
    {
        code: 'VN',
        country: 'Vietnam',
        callCode: '+84',
        currency: 'VND',
        phLengthMax: 10,
        phLengthMin: 7,
        phLength: '7 to 10'
    },
    {
        code: 'YE',
        country: 'Yemen',
        callCode: '+967',
        currency: 'YER',
        phLengthMax: 9,
        phLengthMin: 6,
        phLength: '6 to 9'
    },
    {
        code: 'ZA',
        country: 'South Africa',
        callCode: '+27',
        currency: 'ZAR',
        phLengthMax: 10,
        phLengthMin: 10,
        phLength: '10'
    }
];
