<div class="action-box pointer" *ngIf="showAction()" [routerLink]="(isOperator$ | async) ? ['/operator/actions'] : ['/actions']" [queryParams]="{key: action.key}">
    <div class="top-half">
        <div class="d-flex top-top-half justify-content-between">
            <div class="path pointer d-flex align-items-center">
                <p>{{action.statics.location_name ? action.statics.location_name : ''}}</p>
                <img *ngIf="!!action.statics.device_name" class="arrow-right col-1" src="assets/icons/arrow-up-blue.svg" alt="arrow icon">
                <p>{{action.statics.device_name ? action.statics.device_name : ''}}</p>
            </div>
            <div class="state-box {{latestLogValue.state}}">
                {{getState(latestLogValue)}}
            </div>
        </div>
        <div class="top-bottom-half">
            <p style="font-size: 16px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis"><strong>{{getTitle(latestLogValue, false)}}</strong></p>
            <p class="timestamp">{{timeSince(latestLogValue.timestamp)}}</p>
        </div>
    </div>
    <div class="bottom-half" style="line-height: 17px">
        <p>{{getDescription(latestLogValue, action)}}</p>
    </div>
</div>
