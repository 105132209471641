<app-custom-modal>
    <ng-container modal-title>
        <h4>{{'device.reset_service_title' | translate}}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a (click)="modalService.dismissAll('Cancel')">
            <img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog">
        </a>
    </ng-container>
    <ng-container modal-body>
        <div class="d-flex flex-column reset-body" style="margin-top: 6px" *ngIf="!initialLoading">
            <p style="font-weight: 600; margin-bottom: 0">{{'device.reset_for' | translate}}</p>
            <mat-radio-group class="aw-radio-wrapper mt-2 flex-column" [(ngModel)]="resetScope" (change)="changeResetScope()" style="margin-bottom: 10px">
                <mat-radio-button class="aw-radio" value="machine" labelPosition="after" [checked]="resetScope === 'machine'">
                    {{'device.this_machine' | translate}} ({{currentDevice.name}})
                </mat-radio-button>
                <mat-radio-button class="aw-radio" value="location" labelPosition="after">
                    {{'device.entire_location' | translate}} ({{currentDevice.location_name}})
                </mat-radio-button>
            </mat-radio-group>

            <p style="font-weight: 600; margin-bottom: 12px">
                {{resetScope === 'machine' ? ('device.this_machine' | translate) : ('device.entire_location' | translate)}} {{'device.serviced_with' | translate}}:
            </p>
            <div style="margin-bottom: 0">
                <mat-checkbox (change)="checkMaintenance($event)" [checked]="maintenanceChecked" name="maintenanceChecked" disableRipple="true" style="width:18px;height:18px" [disabled]="maintenanceLocked">
                    {{'device.maintenance_check' | translate}}
                </mat-checkbox>
            </div>
            <div>
                <mat-checkbox (change)="checkSoap($event)" [checked]="soapChecked" name="soapChecked" disableRipple="true" style="width:18px;height:18px;" [disabled]="!canResetSoap || soapLocked">
                    {{'device.soap_refill' | translate}}
                </mat-checkbox>
            </div>
        </div>
        <div class="d-flex flex-column align-items-center">
            <button (click)="resetService()" class="btn btn-secondary mb-3 mt-3" style="width: 100%" [disabled]="!soapChecked && !maintenanceChecked">
                {{sendingData ? '' : resetScope === 'machine' ? ('device.reset_machine' | translate) : ('device.reset_location' | translate)}}
                <loading-indicator [size]="'small'" [color]="'white'" *ngIf="sendingData"></loading-indicator>
            </button>
        </div>
    </ng-container>
    <ng-container modal-footer *ngIf="!!machine_service && resetScope !== 'location'">
        <div class="d-flex justify-content-center">
            <p class="m-0 pointer" (click)="completeWithoutReset()"><u>{{'device.complete_wo_reset' | translate}}</u></p>
        </div>
    </ng-container>
</app-custom-modal>
