export const languages: Record<string, string> = {
    // https://meta.wikimedia.org/wiki/Template:List_of_language_names_ordered_by_code
    cs: 'Česky',
    da: 'Dansk',
    de: 'Deutsch',
    en_us: 'English (American)',
    en_gb: 'English (British)',
    en_ie: 'English (Irish)',
    es: 'Español',
    et: 'Eesti',
    fr: 'Français',
    it: 'Italiano',
    nl: 'Nederlands',
    no: 'Norsk',
    pl: 'Polski',
    pt: 'Português',
    fi: 'Suomi',
    cy: 'Welsh'
    // 'sv': 'Svenska', // translations are missing
};
