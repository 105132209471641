import { Route } from "@angular/router";
import { CustomersComponent } from "../components/customers/customers.component";
import { ActionsOverviewComponent } from "../components/operator/actions/actions-overview/actions-overview.component";
import { AntonHealthComponent } from "../components/operator/anton-health/anton-health.component";
import { ContractOverviewComponent } from "../components/operator/contracts/contract-overview/contract-overview.component";
import { DeviceOverviewComponent } from "../components/operator/devices/device-overview/device-overview.component";
import { AuthGuardService } from "../services/auth-guard/auth-guard.service";

export default [
    {
        path: 'customers',
        component: CustomersComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'actions',
        component: ActionsOverviewComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'contracts',
        component: ContractOverviewComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'devices',
        component: DeviceOverviewComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'anton-health',
        component: AntonHealthComponent,
        canActivate: [AuthGuardService]
    }
] satisfies Route[];