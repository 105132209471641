<p>apply-credit-payment-modal works!</p>
<app-custom-modal>
    <ng-container modal-title>
        <h4>{{'users.user.apply_credit_payment' | translate}}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a (click)="closeModal()"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog"></a>
    </ng-container>
    <ng-container modal-body>
        <div class="modal-body-wrapper">

        </div>
    </ng-container>
    <ng-container modal-footer>
        <div class="no-footer"></div>
    </ng-container>
</app-custom-modal>