import { Injectable } from '@angular/core';
import { HttpService, RequestTypes } from '../helper/http.service';

@Injectable({
    providedIn: 'root'
})
export class ChartService {
    constructor(
        private httpService: HttpService,
    ) {}

    async getChartData(translates: any): Promise<any> {
        return this.httpService.dynamicHttp('api_insights/chart_data', RequestTypes.GET, {params: {translates: JSON.stringify(translates)}})
    }
    async getUserStats(): Promise<any> {
        return this.httpService.dynamicHttp('api_insights/users_stats', RequestTypes.GET)
    }
}