<div class="page-container">
    <!--Top navigation bar-->
    <div class="aw-title-area">
        <h4 class="page-title">{{pageTitle}}</h4>
        <div class="page-title-buttons">
            <ng-content select="[buttons]"></ng-content>
        </div>
    </div>

    <!--Tab buttons-->
    <div class="tab-buttons">
        <ng-container *ngIf="tabOptions.length > 0">
            <div *ngFor="let tab of tabOptions"
                 [className]="view == tab ? 'active-button' : 'unactive-button'"
                 (click)="toggleActiveInactive(tab)">
                {{tab | translate}}
            </div>
        </ng-container>
    </div>

    <!--Body Content-->
    <section class="body">
        <ng-content select="[body]"></ng-content>
    </section>
</div>