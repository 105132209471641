<!-- CREATE TERMINAL MODAL -->
<app-custom-modal>
    <ng-container modal-title>
        <h4>{{ "location.create_terminal" | translate }}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a (click)="modalService.dismissAll('Cancel')"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog" /></a>
    </ng-container>
    <ng-container modal-body>
        <div class="input-group-wrapper-feedback mb-1">
            <label>{{ "location.how_get_serial" | translate }}</label>
        </div>
        <div>
            <form tabindex="0" [formGroup]="terminalForm">
                <div class="illustration-wrapper" style="margin-bottom: 2rem">
                    <img
                        [src]="region === eRegion.US ? 'assets/illustrations/anton_us_locate_sn.svg' : 'assets/illustrations/anton_locate_sn_illustration.svg'"
                        class="illustration-svg"
                        alt="{{ 'location.illustration_get_serial' | translate }}"
                    />
                </div>

                <!-- Terminal serial number -->
                <div class="input-group-wrapper-feedback">
                    <label for="serial_number">{{ "location.terminal_serial" | translate }}</label>
                    <input
                        (change)="makeUppercase()"
                        style="text-transform: uppercase"
                        autocomplete="new-terminal_serial_number"
                        name="terminal_serial_number"
                        class="form-control"
                        type="text"
                        formControlName="terminal_serial_number"
                        id="serial_number"
                    />
                    <!-- <div class="input-description">The terminal serial number</div> -->

                    <div class="feedback-container">
                        @if (createTerminalSubmitted && terminal_serial_number.invalid) {
                            <div class="error-msg" *ngIf="terminal_serial_number.errors && terminal_serial_number.errors.custom_validation">
                                {{ this.testSerialNumber(terminal_serial_number.value).message }}
                            </div>
                            <div class="error-msg" *ngIf="terminal_serial_number.errors.required">
                                {{ "location.terminal_serial_req" | translate }}
                            </div>
                        }
                    </div>
                </div>

                <div class="input-group-wrapper-feedback">
                    <label for="language">{{ "location.default_lang" | translate }}</label>
                    <select id="language" class="form-select" formControlName="default_language">
                        <option selected disabled value="null">{{ "location.select_defualt_lang" | translate }}</option>
                        <option [ngValue]="item.key" *ngFor="let item of languages | keyvalue">{{ item.value }}</option>
                    </select>
                    <div class="feedback-container">
                        @if (createTerminalSubmitted && default_language.invalid) {
                            <div class="error-msg" *ngIf="default_language.errors.required">
                                {{ "location.default_lang_req" | translate }}
                            </div>
                        }
                    </div>
                </div>

                <!-- booking on anton -->
                <div class="input-group-wrapper">
                    <div>
                        <app-custom-tool-tip
                            class="fw-bold"
                            forInput="true"
                            label="{{ 'location.booking' | translate }}"
                            [style]="{ 'white-space': 'pre-line' }"
                            description="{{ 'location.booking_description' | translate }}"
                        ></app-custom-tool-tip>
                    </div>
                    <div class="booking-fixer">
                        <app-aw-switch [controlName]="booking_enabled" [shouldBeChecked]="true" (activateClick)="toggleBookingEnabled($event)"> </app-aw-switch>
                        <span class="booking-enabled-subtext text-box-fs">{{ "location.enable_booking_on_terminal" | translate }}</span>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
    <ng-container modal-footer>
        <div class="d-grid gap-2">
            <button class="btn btn-secondary" [disabled]="creatingTerminal" (click)="createTerminal()">
                {{ creatingTerminal ? "" : ("location.create_terminal" | translate) }}
                <loading-indicator *ngIf="creatingTerminal" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
        </div>
    </ng-container>
</app-custom-modal>
