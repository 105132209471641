<!--    Desktop-->
<div class="cont" style="min-height: 200px" *ngIf="!isMobile">
    <div class="box pt-4 content">
        <table style="width: 100%">
            <thead>
                <tr>
                    <th style="width: 12%" class="sort-label noselect pointer" (click)="sort('device_name')">
                        {{'location.name' | translate}}
                        <img *ngIf="sortBy === 'device_name'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th style="width: 14%" class="sort-label noselect pointer" (click)="sort('location_name')">
                        {{'misc.location' | translate}}
                        <img *ngIf="sortBy === 'location_name'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th style="width: 12%" class="sort-label noselect pointer" (click)="sort('customer_name')">
                        {{'misc.customer' | translate}}
                        <img *ngIf="sortBy === 'customer_name'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th style="width: 8%" class="sort-label noselect pointer right" (click)="sort('contract_id')"
                        *ngIf="authService.hasLimitedAccess('all_devices_stats', (role$ | async))">
                        {{'operator.contract_modal.contract_id' | translate}}
                        <img *ngIf="sortBy === 'contract_id'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th style="width: 10%" class="sort-label noselect pointer right" (click)="sort('revenue')"
                        *ngIf="authService.hasLimitedAccess('all_devices_stats', (role$ | async))">
                        {{'operator.contract_overview.revenue' | translate}}
                        <img *ngIf="sortBy === 'revenue'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th style="width: 12%" class="sort-label noselect pointer right" (click)="sort('price')">
                        {{'device.price' | translate}}
                        <img *ngIf="sortBy === 'price'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th style="width: 10%" class="sort-label noselect pointer right" (click)="sort('last_used')">
                        {{'misc.last_used' | translate}}
                        <img *ngIf="sortBy === 'last_used'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th style="width: 12%" class="sort-label noselect pointer right" (click)="sort('starts_day')"
                        *ngIf="authService.hasLimitedAccess('all_devices_stats', (role$ | async))">
                        {{'operator.contract_overview.starts_day' | translate}}
                        <img *ngIf="sortBy === 'starts_day'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                </tr>
            </thead>
        </table>
        <div style="min-height:1px; background:#BEC9D1; width:calc(100% - 3rem); margin: 0 1.5rem"></div>
        <table style="width: 100%" *ngIf="!loading">
            <tbody *ngIf="devices.length">
                <tr *ngFor="let device of devices | paginate:pageSize:pageNumber;">
                    <td style="width: 12%" class="pointer overflow-column"
                        (click)="getLink(device.device_name, device.customer_uid, device.location_key, device.device_key)">
                        <u>{{device.device_name}}</u>
                    </td>
                    <td style="width: 14%" class="pointer overflow-column"
                        (click)="getLink(device.location_name, device.customer_uid, device.location_key)">
                        <u>{{device.location_name}}</u>
                    </td>
                    <td style="width: 12%" class="pointer overflow-column"
                        (click)="getLink(device.customer_name, device.customer_uid)">
                        <u>{{device.customer_name}}</u>
                    </td>
                    <td style="width: 8%" class="right overflow-column {{!!device.contract_key ? 'pointer' : ''}}"
                        [routerLink]="!!device.contract_key ? ['/operator/contracts/'] : []"
                        [queryParams]="{id: device.contract_key}"
                        *ngIf="authService.hasLimitedAccess('all_devices_stats', (role$ | async))">
                        <u>{{device.contract_id}}</u>
                    </td>
                    <td style="width: 10%" class="right"
                        *ngIf="authService.hasLimitedAccess('all_devices_stats', (role$ | async))">
                        {{helperService.formatCurrency(device.revenue, device.currency)}}</td>
                    <td style="width: 12%" class="right">
                        {{helperService.formatCurrency(device.price,
                        device.currency)}}{{helperService.getDeviceUnitPriceTranslated(device.price_unit,
                        device.pulse_increment)}}
                    </td>
                    <td style="width: 10%" class="right">{{getPeriod(device.last_used)}}</td>
                    <td style="width: 12%" class="right"
                        *ngIf="authService.hasLimitedAccess('all_devices_stats', (role$ | async))">
                        {{round(device.starts_day)}}</td>
                </tr>
            </tbody>
        </table>
        <div *ngIf="loading" class="loading-box">
            <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
        </div>

        <p style="height: 4.5rem;" class="m-5 d-flex justify-content-around" *ngIf="!devices.length && !loading">
            {{ filterByMaintenance ? ('operator.device_overview.no_devices_maintenance' | translate) : ('operator.device_overview.no_devices_yet' | translate) }}
        </p>

        <div *ngIf="!!devices.length"
            style="min-height:1px; background:#BEC9D1; width:calc(100% - 3rem); margin: 0 1.5rem"></div>

        <div *ngIf="!loading && !!devices.length" class="bottom-paginator">
            <mat-paginator id="matpage" [length]="devices.length" [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageNumber" (page)="handlePage($event)"
                showFirstLastButtons class="pagination"></mat-paginator>
        </div>
    </div>
</div>

<!--    Mobile-->
<div class="row cont" style="min-height: 200px" *ngIf="isMobile">
    <div class="mobile-card empty-card" *ngIf="loading">
        <div>
            <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
        </div>
    </div>

    <div class="mobile-card empty-card" *ngIf="devices.length === 0 && !loading">
        <div>
            <p> {{'operator.device_overview.no_devices_yet' | translate}}</p>
        </div>
    </div>
    <div *ngIf="!loading" class="mobile-body">
        <div *ngIf="devices.length">
            <div *ngFor="let device of devices | paginate:pageSize:pageNumber"
                (click)="openModal(specificDeviceModal, device)">
                <div class="mobile-card">
                    <p class="card-title"><strong>{{device.location_name}}</strong></p>
                    <p>{{device.device_name}}</p>
                    <div class="d-flex justify-content-between">
                        <p><strong>{{'operator.contract_overview.starts_day' | translate}}</strong></p>
                        <p>{{round(device.starts_day)}}</p>
                    </div>
                </div>
            </div>
            <div class="mobile-paginator">
                <mat-paginator id="matpage" [length]="devices.length" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageNumber" (page)="handlePage($event)"
                    showFirstLastButtons class="pagination"></mat-paginator>
            </div>
        </div>
    </div>
</div>

<ng-template #specificDeviceModal>
    <app-specific-device [device]="specificDevice"></app-specific-device>
</ng-template>