<app-custom-modal>
    <ng-container modal-title>
        <h4 class="modal-title">{{'misc.machine_specification' | translate}}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a (click)="modalService.dismissAll('Cancel')"><img src="../../../../assets/icons/modal-close-icon.svg"
                alt="close dialog"></a>
    </ng-container>
    <ng-container modal-body>
        <div [formGroup]="shallowDeviceForm">
            <div class="modal-padding-wrapper">
                <div class="input-group-wrapper">
                    <label>{{'misc.product_type' | translate}}</label>
                    <select class="form-select" formControlName="product_type">
                        <option value="{{deviceType}}" *ngFor="let deviceType of recordOfTypes">
                            {{deviceType}}
                        </option>
                        
                    </select>
                    <div  class="error-msg" *ngIf="formSubmitted && product_type.invalid">

                    </div>
                </div>
                <div class="input-group-wrapper" formGroupName="machine_specifications">
                    <app-custom-tool-tip forInput="true" label="{{'misc.brand' | translate}}"
                        description="{{'device_setup.machine_brand' | translate}}"></app-custom-tool-tip>
                    <select class="form-select" formControlName="brand">
                        <option selected disabled value="null">{{'misc.select_opt' | translate}}
                        </option>
                        <option value="{{brand}}" *ngFor="let brand of brandList">{{brand}}</option>
                    </select>
                    <div *ngIf="formSubmitted && getControl('brand').invalid">
                        <div class="error-msg" *ngIf="getControl('brand').errors.required">
                            {{'device_setup.brand_req' | translate}}
                        </div>
                    </div>
                </div>
                <div class="input-group-wrapper" formGroupName="machine_specifications">
                    <app-custom-tool-tip forInput="true" label="{{'device.model' | translate}}"
                        description="{{'device_setup.machine_model' | translate}}"></app-custom-tool-tip>
                    <input autocomplete="new-name" name="model" class="form-control" type="text"
                        formControlName="model">
                    <div *ngIf="formSubmitted && getControl('model').invalid">
                        <div class="error-msg" *ngIf="getControl('model').errors.required">
                            {{'device_setup.model_req' | translate}}
                        </div>
                        <div class="error-msg" *ngIf="getControl('model').errors.maxlength">
                            {{'device_setup.model_max' | translate}}
                            {{getControl('model').errors.maxlength.requiredLength}} {{'misc.characters_long' |
                            translate}}
                        </div>
                    </div>
                </div>
                <div class="input-group-wrapper" formGroupName="machine_specifications">
                    <app-custom-tool-tip forInput="true" label="{{'device.serial_num' | translate}}"
                        description="{{'device_setup.serial_num_machine' | translate}}"></app-custom-tool-tip>
                    <input autocomplete="new-name" name="serial_number" class="form-control" type="text"
                        formControlName="serial_number">
                    <div *ngIf="formSubmitted && getControl('serial_number').invalid">
                        <div class="error-msg" *ngIf="getControl('serial_number').errors.maxlength">
                            {{'device_setup.serial_max' | translate}}
                            {{getControl('serial_number').errors.maxlength.requiredLength}} {{'misc.characters_long' |
                            translate}}
                        </div>
                    </div>
                </div>
                <div class="input-group-wrapper" formGroupName="machine_specifications">
                    <app-custom-tool-tip forInput="true" label="{{'device.production_year' | translate}}"
                        description="{{'device_setup.machine_produced_year' | translate}}"></app-custom-tool-tip>
                    <input autocomplete="new-name" name="production_year" class="form-control" type="text"
                        formControlName="production_year">
                    <div class="error-msg" *ngIf="formSubmitted && getControl('production_year').invalid">
                        <div class="error-msg" *ngIf="getControl('production_year').errors.required">
                            {{'device_setup.production_year_req' | translate}}
                        </div>
                        <div class="error-msg" *ngIf="getControl('production_year').errors.min">
                            {{'device_setup.production_year_max' | translate}}
                        </div>
                        <div class="error-msg" *ngIf="getControl('production_year').errors.max">
                            {{'device_setup.production_year_min' | translate}}
                        </div>
                        <div class="error-msg" *ngIf="getControl('production_year').errors.pattern">
                            {{'device_setup.productions_year_format' | translate}}
                        </div>
                    </div>
                </div>
                <div class="input-group-wrapper" formGroupName="machine_specifications">
                    <app-custom-tool-tip forInput="true" label="{{'device.comments' | translate}}"
                        description="{{'device_setup.additional_details' | translate}}"></app-custom-tool-tip>
                    <input autocomplete="new-name" name="note" class="form-control" type="text" formControlName="note">
                    <div class="error-msg" *ngIf="formSubmitted && getControl('note').invalid">
                        <div class="error-msg" *ngIf="getControl('note').errors.maxlength">
                            {{'device_setup.additional_details' | translate}}
                            {{getControl('note').errors.maxlength.requiredLength}} {{'misc.characters_long' |
                            translate}}
                        </div>
                    </div>
                </div>

                <div class="input-group-wrapper">
                    <app-custom-tool-tip forInput="true" label="{{'device.coin_drop_installed' | translate}}"
                        description="{{'device_setup.coin_drop_installed' | translate}}"></app-custom-tool-tip>
                    <div class='coin-drop-wrapper'>
                        <div class="coin-drop-radio-wrapper">
                            <div class="coin-drop-radio coin-drop-true">
                                <input type="radio" class="" [value]="true" name="coin_drop_installed"
                                    id="coin_dropTrue" [checked]='true' formControlName="coin_drop_installed">
                                <label class="" for="coin_dropTrue">
                                    {{'misc.yes' | translate}}
                                </label>
                            </div>
                            <div class="coin-drop-radio">
                                <input type="radio" class="" [value]="false" name="coin_drop_installed"
                                    id="coin_dropFalse" formControlName="coin_drop_installed">
                                <label class="" for="coin_dropFalse">
                                    {{'misc.no' | translate}}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-group-wrapper" *ngIf="open_door.value !== null">
                    <app-custom-tool-tip forInput="true" label="{{'device.installation_type' | translate}}"
                        description="{{'device_setup.installation_type_desc' | translate}}"></app-custom-tool-tip>
                    <select class="form-select" formControlName="open_door">
                        <option [selected]="!open_door.value" [ngValue]="false">{{'device.with_fixed_power' |
                            translate}}</option>
                        <option [selected]="open_door.value" [ngValue]="true">{{'device.only_when_paid_for' |
                            translate}}</option>
                    </select>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container modal-footer>
        <div class="d-flex flex-column align-items-center">
            <button (click)="saveSpecifications()" class="btn btn-secondary mb-3 mt-3" style="width: 100%">
                {{sendingData ? '' : ('device.save_changes' | translate)}}
                <loading-indicator [size]="'small'" [color]="'white'" *ngIf="sendingData"></loading-indicator>
            </button>
        </div>
    </ng-container>

</app-custom-modal>