<app-custom-modal>
    <ng-container modal-title>
        <h4 class="title">{{ isEdit ? ("team_members.edit_team_member" | translate) : ("team_members.invite_team_member" | translate) }}</h4>
    </ng-container>
    <ng-container modal-body>
        <form [formGroup]="inviteForm">
            <div class="input-group-wrapper-feedback">
                <label>{{ "account.email_addr" | translate }}</label>
                <input type="text" class="form-control" formControlName="email" name="email" placeholder="Enter email" />
                <div class="feedback-container">
                    @if (formSubmitted && email.invalid) {
                        <div class="error-msg" *ngIf="email.errors.required">
                            {{ "team_members.provide_mail" | translate }}
                        </div>
                        <div class="error-msg" *ngIf="email.errors.email">
                            {{ "team_members.provide_valid_mail" | translate }}
                        </div>
                    }
                </div>
            </div>
            <div class="input-group-wrapper team-member-group" style="margin-bottom: 20px">
                <label>{{ "support.select_role" | translate }}</label>
                <div class="role-box">
                    <mat-radio-group class="mt-2" formControlName="role" name="role">
                        <div class="radio-text-wrapper">
                            <mat-radio-button class="aw-radio" value="editor" labelPosition="after" [checked]="role.value === 'editor'">
                                {{ "team_members.editor" | translate }}
                            </mat-radio-button>
                            <p class="role-desc">{{ "team_members.roles_desc.editor" | translate }}</p>
                        </div>
                        <div class="radio-text-wrapper">
                            <mat-radio-button class="aw-radio" value="technician" labelPosition="after">
                                {{ "team_members.technician" | translate }}
                            </mat-radio-button>
                            <p class="role-desc">{{ "team_members.roles_desc.technician" | translate }}</p>
                        </div>
                        <div class="radio-text-wrapper">
                            <mat-radio-button class="aw-radio" value="user" labelPosition="after">
                                {{ "team_members.user" | translate }}
                            </mat-radio-button>
                            <p class="role-desc">{{ "team_members.roles_desc.user" | translate }}</p>
                        </div>
                        <div class="radio-text-wrapper" *ngIf="!isEdit && loggedInUser.role === 'owner'">
                            <mat-radio-button class="aw-radio" value="bookkeeper" labelPosition="after">
                                {{ "team_members.bookkeeper" | translate }}
                            </mat-radio-button>
                            <p class="role-desc">{{ "team_members.roles_desc.bookkeeper" | translate }}</p>
                        </div>
                    </mat-radio-group>
                </div>
                <div *ngIf="formSubmitted && role.invalid">
                    <div class="error-msg" *ngIf="role.errors.required">
                        {{ "team_members.select_role" | translate }}
                    </div>
                </div>
            </div>
        </form>
        <button class="btn btn-secondary" style="width: 100%" (click)="onSubmit()" [disabled]="sendingData">
            {{
                sendingData
                    ? ""
                    : isEdit
                      ? ("device.save_changes" | translate)
                      : role.value !== "bookkeeper"
                        ? ("team_members.send_invite" | translate)
                        : ("team_members.add_bookkeeper" | translate)
            }}
            <loading-indicator [size]="'small'" [color]="'white'" *ngIf="this.sendingData"></loading-indicator>
        </button>
    </ng-container>
    <ng-container modal-footer>
        <div class="position-relative">
            <div class="bottom-link" *ngIf="!isMobile">
                <a type="button" class="btn-link-dark-dark" href="https://wiser.airwallet.net/en/article/team-member-roles-9j82m7/?bust=1691428519383" target="_blank">
                    {{ "team_members.read_more" | translate }}
                </a>
            </div>
            <div class="bottom-link" *ngIf="isMobile && isEdit">
                <p (click)="emitRemoveMember()">{{ "team_members.remove" | translate }}</p>
            </div>
        </div>
    </ng-container>
</app-custom-modal>
