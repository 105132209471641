export const euCountries = [
    "AT",
    "BE",
    "BG",
    "CY",
    "CZ",
    "HR",
    "DK",
    "EE",
    "FI",
    "FR",
    "MC",
    "DE",
    "GR",
    "HU",
    "IE",
    "IT",
    "LV",
    "LT",
    "LU",
    "MT",
    "NL",
    "PL",
    "PT",
    "RO",
    "SK",
    "SI",
    "ES",
    "SE",
    "IM",
]