<app-page-layout [pageTitle]="'discount_tool.discount_tool' | translate">
    <ng-container buttons>
        <app-aw-filter-button [disabled]="!selectedUid" [filterOptions]="filterOptions" [popupAnchor]="isMobile ? 'left' : 'right'" (filterChanged)="catchFilterChanged($event)" />
        <app-discount-tool-manage
            [style]="isMobile ? '' : 'margin-left: 15px'"
            [globalDiscount]="globalDiscount"
            [loadingDevices]="loadingDevices"
            [popupAnchor]="isMobile ? 'right' : 'left'"
            (globalDiscountEmitter)="catchDiscountChanged($event)"
            (uidEmitter)="catchUid($event)"
        />
        <button
            [disabled]="!selectedUid || !selectedDevices.length"
            class="btn btn-primary btn-tiny"
            [style]="isMobile ? '' : 'margin-left: 15px'"
            (click)="openBillingSubscriptionModal(discountCreateModal)"
        >
            <img class="plus-icon-on-btn" src="../../../../assets/icons/plus-for-create.svg" alt="{{ '' | translate }}" />
            {{ "discount_tool.apply_discount" | translate }}
        </button>
        <button [disabled]="!selectedUid || !selectedDevices.length" class="btn btn-secondary btn-tiny" [style]="isMobile ? '' : 'margin-left: 15px'" (click)="deleteDiscount()">
            <img class="plus-icon-on-btn" src="../../../../assets/icons/plus-for-create.svg" alt="{{ '' | translate }}" />
            {{ "discount_tool.remove_discount" | translate }}
        </button>
    </ng-container>
    <ng-container body>
        <div *ngIf="!initialLoading">
            <app-aw-table
                [tableHeaders]="tableHeaderOptions"
                [emptytext]="'discount_tool.no_data' | translate"
                [pageNumber]="pageNumber"
                [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions"
                [totalItems]="totalItems"
                (handlePageChange)="handlePage($event)"
                (sortBy)="catchSortChanged($event)"
                [loadingData]="loadingDevices"
                [showCheckBoxPosition]="isMobile ? 'start' : 'end'"
                [sidescrollable]="isMobile"
                [initalSort]="this.params.sortBy"
            >
                <ng-container header-checkbox>
                    <div [ngStyle]="{ display: 'flex', 'justify-content': isMobile ? 'start' : 'end', 'align-items': 'center', height: '8px', position: 'relative' }">
                        <app-aw-checkbox
                            style="position: absolute"
                            [checkedBackgroundColor1]="'var(--primary-color-dark)'"
                            [checkedBackgroundColor2]="'var(--primary-color-light)'"
                            [checkbox]="areAllDevicesSelectedCheck()"
                            (checkStateEmitter)="selectAllDevices()"
                        />
                    </div>
                </ng-container>

                <ng-container table-body>
                    <row *ngFor="let device of discountDevices | paginate: pageSize : pageNumber; let i = index">
                        <cell style="width: 5%" *ngIf="isMobile">
                            <div style="display: flex; justify-content: start; width: 100%">
                                <app-aw-checkbox
                                    [checkbox]="isDeviceSelected(device)"
                                    [initialCheckedValue]="device.is_checked"
                                    [checkedBackgroundColor1]="'var(--primary-color-dark)'"
                                    [checkedBackgroundColor2]="'var(--primary-color-light)'"
                                    (checkStateEmitter)="toggleDeviceSelection(device, $event)"
                                />
                            </div>
                        </cell>
                        <cell style="width: 15%">
                            <span>{{ device.serial_number }}</span>
                        </cell>
                        <cell style="width: 10%">
                            <span>{{ "billing." + device.state | translate }}</span>
                        </cell>
                        <cell style="width: 15%">
                            <span>{{ device.customer_name }}</span>
                        </cell>
                        <cell style="width: 15%">
                            <span>{{ device.in_use ? device.location_name : null }}</span>
                            <div style="display: flex; align-items: center">
                                <app-custom-tool-tip
                                    *ngIf="!device.in_use"
                                    [spaceBetween]="true"
                                    [label]="'billing.not_in_use' | translate"
                                    [gap]="0.25"
                                    [fullWidth]="false"
                                    [labelClass]="'regular-label'"
                                    [description]="'billing.device_on_active_subscription' | translate"
                                />
                            </div>
                        </cell>
                        <cell style="width: 15%">
                            <span>{{ device.in_use ? (device.device_name === "TERMINAL" ? "Anton terminal " : device.device_name) : null }}</span>
                            <div style="display: flex; align-items: center">
                                <app-custom-tool-tip
                                    *ngIf="!device.in_use"
                                    [spaceBetween]="true"
                                    [label]="'billing.not_in_use' | translate"
                                    [gap]="0.25"
                                    [fullWidth]="false"
                                    [labelClass]="'regular-label'"
                                    [description]="'billing.device_on_active_subscription' | translate"
                                />
                            </div>
                        </cell>
                        <cell style="width: 15%">
                            <app-aw-hover-icon
                                [hoverText]="device.hoverText"
                                [label]="device.label"
                                [hoverIcon]="'../../../assets/icons/' + (device.renewable ? 'clock-white.svg' : 'cross-white.svg')"
                                [icon]="'../../../assets/icons/' + device.icon"
                                [startColor]="device.startColor"
                                [endColor]="device.endColor"
                                [iconWidth]="13"
                                *ngIf="(globalDiscount && globalDiscount != 0) || device.discount"
                            />
                        </cell>
                        <cell style="width: 10%; text-align: right">
                            <div style="width: 100%">
                                <span>{{ helperService.createdLabelForLists(device.created) }}</span>
                            </div>
                        </cell>
                        <cell style="width: 5%" *ngIf="!isMobile">
                            <div style="display: flex; justify-content: end; width: 100%">
                                <app-aw-checkbox
                                    [checkbox]="isDeviceSelected(device)"
                                    [initialCheckedValue]="device.is_checked"
                                    [checkedBackgroundColor1]="'var(--primary-color-dark)'"
                                    [checkedBackgroundColor2]="'var(--primary-color-light)'"
                                    (checkStateEmitter)="toggleDeviceSelection(device, $event)"
                                />
                            </div>
                        </cell>
                    </row>
                </ng-container>
            </app-aw-table>
        </div>
        <loading-indicator *ngIf="initialLoading" [size]="'large'" [color]="'secondary'" />
    </ng-container>
</app-page-layout>

<ng-template #discountCreateModal>
    <app-discount-create-modal [uid]="this.selectedUid" [selectedDevices]="selectedDevices" (discountCreated)="onSubscriptionCreated()"></app-discount-create-modal>
</ng-template>
