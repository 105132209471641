import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActionsService } from "../../../../services/actions/actions.service";
import { Action, ActionLog, actionState } from "../../../../../../shared_models/operator/actions";
import { animate, group, query, state, style, transition, trigger } from "@angular/animations";
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { DashboardUser } from "../../../../../../shared_models/dashboard-user";
import { HelperService } from "../../../../services/helper/helper.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SortService } from "../../../../services/sorting/sort.service";
import { TranslateModule } from '@ngx-translate/core';
import { ActionsCardComponent } from '../actions-card/actions-card.component';
import { LoadingComponent } from '../../../loading/loading.component';
import { NgIf, NgFor } from '@angular/common';
import { CustomToolTipComponent } from '../../../misc/custom-tool-tip/custom-tool-tip.component';
import { AwSwitchComponent } from "../../../misc/aw-switch/aw-switch.component";


@Component({
    selector: 'app-actions-overview',
    templateUrl: './actions-overview.component.html',
    styleUrls: ['./actions-overview.component.scss'],
    animations: [
        trigger("inOutPaneAnimation", [
            transition(':leave', [
                group([
                    query(".animation-height-handler", [
                        animate("500ms 500ms ease-in-out", style({ height: 0 }))
                    ]),
                    query(".animation-item", [
                        style({
                            position: "absolute",
                            opacity: 1,
                        }),
                        animate("500ms", style({
                            opacity: 0,
                            height: 0
                        }))
                    ])
                ])
            ]),
            transition(':enter', [
                query(".animation-item", [
                    style({
                        opacity: 1
                    }),
                    animate('100ms', style({
                        height: '*'
                    })),
                ])
            ]),
        ])
    ],
    standalone: true,
    imports: [
    CustomToolTipComponent,
    NgIf,
    LoadingComponent,
    NgFor,
    ActionsCardComponent,
    LottieComponent,
    TranslateModule,
    AwSwitchComponent
],
})
export class ActionsOverviewComponent implements OnInit, OnDestroy {

    actions: Action[] = [];
    loading = false;
    isSkeleton = true;
    initLoading = true;
    backendLoading = false;

    showClosed = false;
    showAlerts = true;
    showAdvices = true;

    noAnimation = true;
    path: string;

    user: DashboardUser

    options: AnimationOptions = { // https://www.npmjs.com/package/ngx-lottie
        path: '../../../assets/animations/jumping_star.json',
    };

    constructor(
        private actionService: ActionsService,
        private helperService: HelperService,
        private route: ActivatedRoute,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.path = this.router.url
        this.user = this.helperService.getUser();
        this.loading = true
        this.getActions(this.user.uid)
        this.initLoading = false
    }

    ngOnDestroy() {
    }

    async getActions(uid: string) {
        this.actions = await this.actionService.getActionsFromDB(uid).then((response) => {
            this.loading = false
            const actions: Action[] = this.actionService.convertActionsToArray(response)
            actions.sort(SortService.byPropertiesOf(['log'], 'timestamp'))
            return actions
        })
        const key: string = this.route.snapshot.queryParams.key;
        if (key) {
            this.jumpToAction(key)
        }
        await this.setActionsFromBackend()
    }

    async setActionsFromBackend() {
        this.backendLoading = true
        this.actions = await this.actionService.getActionsFromBackend().then((response) => {
            const actions: Action[] = this.actionService.convertActionsToArray(response)
            actions.sort(SortService.byPropertiesOf(['log'], 'timestamp'))
            this.backendLoading = false;
            this.isSkeleton = false;
            return actions;
        })
    }


    jumpToAction(key: string) {
        setTimeout(() => {
            const actionElement: HTMLElement = document.getElementById(key)
            if (actionElement) {
                actionElement.scrollIntoView({ behavior: 'smooth' });
            }
        }, 1)
    }

    showNoActionsBox(): boolean {
        return this.actionService.showNoActionsBox(this.actions, this.showClosed)
    }

    showNoActionOfTypeBox(): boolean {
        let adviceIncrement = 0;
        let alertIncrement = 0;

        for (const action of this.actions) {
            if (action.active_actions) {
                for (const logKey in action.active_actions) {
                    if (action.log[logKey].state === actionState.advice) {
                        adviceIncrement++
                    } else if ((action.log[logKey].state === actionState.complete || action.log[logKey].state === actionState.error || action.log[logKey].state === actionState.observing)) {
                        alertIncrement++
                    }
                }
            }
        }

        const showAdvicesButNoAdvices: boolean = this.showAdvices && adviceIncrement === 0 && !this.showAlerts
        const showAlertsButNoAlerts: boolean = this.showAlerts && alertIncrement === 0 && !this.showAdvices

        return (showAdvicesButNoAdvices && !this.showClosed) || (showAlertsButNoAlerts && !this.showClosed)
    }

    showAction(action: Action): boolean {
        let hasAlert = false;
        let hasAdvice = false;
        const firstElementKey: string = Object.keys(action.log).reverse()[0]
        const latestLogValue: ActionLog = action.log[firstElementKey]

        if (action.active_actions) {
            for (const activeAction in action.active_actions) {
                if (action.log[activeAction].state === actionState.advice) {
                    hasAdvice = true
                }
                if (action.log[activeAction].state !== actionState.advice) {
                    hasAlert = true
                }
            }
        } else {
            if (latestLogValue.state === actionState.advice) {
                hasAdvice = true
            }
            if (latestLogValue.state !== actionState.advice) {
                hasAlert = true
            }
        }
        if ((!action.active_actions && this.showClosed) || (!!action.active_actions)) {
            if (hasAdvice && this.showAdvices) {
                return true
            } else if (hasAlert && this.showAlerts) {
                return true
            }
        }
        return false
    }
    moveActionToClosed(event): void {
        this.noAnimation = false;
        const tempAction = event.action
        const changedAction: Action = this.actions.find(action => action.key === tempAction.key)
        const actionIndex: number = this.actions.indexOf(changedAction)
        this.actions[actionIndex] = tempAction
        this.actions.sort(SortService.byPropertiesOf(['log'], 'timestamp'))
        setTimeout(() => {
            this.noAnimation = true
        }, 1)
    }

    moveActionBackToOpen(event): void {
        const actionKey = event.actionKey
        const actionLogKey = event.logKey
        const tempAction: Action = this.actions.find(action => action.key === actionKey)
        const actionIndex: number = this.actions.indexOf(tempAction)
        this.actions[actionIndex].active_actions = {}
        this.actions[actionIndex].active_actions[actionLogKey] = this.actions[actionIndex].log[actionLogKey].action_type
        this.actions.sort(SortService.byPropertiesOf(['log'], 'timestamp'))
    }

    toggleClosed() {
        this.showClosed = !this.showClosed
    }
    toggleAlerts() {
        this.showAlerts = !this.showAlerts
        this.actionService.setAlertToggled(this.showAlerts)
        if (this.showAdvices === false) {
            this.showAdvices = !this.showAdvices
            this.actionService.setAdviceToggled(this.showAdvices)
        }
    }
    toggleAdvices() {
        this.showAdvices = !this.showAdvices
        this.actionService.setAdviceToggled(this.showAdvices)
        if (this.showAlerts === false) {
            this.showAlerts = !this.showAlerts
            this.actionService.setAlertToggled(this.showAlerts)
        }
    }

    protected readonly actionState = actionState;
}
