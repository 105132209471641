import { Component, Input, OnInit } from '@angular/core';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import { HelperService } from '../../../services/helper/helper.service';
import { LocationService } from '../../../services/location/location.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CardService } from '../../../services/location/card/card.service';
import * as Claims from 'shared_models/claims';
import { Basic, Unit } from 'shared_models/device';
import { ProductType } from 'shared_models/product-type';
import { DashboardUser } from 'shared_models/dashboard-user';
import { LoadingComponent } from '../../loading/loading.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-device-cards-list',
    templateUrl: './device-cards-list.component.html',
    styleUrls: ['./device-cards-list.component.scss'],
    standalone: true,
    imports: [NgIf, LoadingComponent, NgFor, AsyncPipe, TranslateModule]
})
export class DeviceCardsListComponent implements OnInit {
    @Input() locId: string;
    showLoadingIndicator = true;
    deviceList: Unit[];
    @Input() inputDeviceList: BehaviorSubject<Unit[]> = new BehaviorSubject<Unit[]>(null);
    role$: Observable<Claims.Roles> = this.authService.getRole;
    user: DashboardUser;
    isCustomerOperated: boolean;
    uid: string;
    hasDevices: boolean;
    constructor(
        private locationService: LocationService,
        public translate: TranslateService,
        public authService: AuthService,
        private helperService: HelperService,
        private router: Router,
        private route: ActivatedRoute,
        public cardService: CardService
    ) {}

    async ngOnInit(): Promise<void> {
        this.user = this.helperService.getUser();
        this.isCustomerOperated = window.location.pathname.split('/').includes('customers') ? true : false;
        this.uid = this.isCustomerOperated ? `${this.route.snapshot.paramMap.get('sub_customer_id')}_operated_by_${this.user.uid}` : this.user.uid;
        this.inputDeviceList.subscribe(value => {
            if (!value) return;
            this.hasDevices = value.length === 0 ? false : true;
            this.showLoadingIndicator = false;
            this.deviceList = value;
            this.sortDevices();
            this.locationService.setDevices(value);
        });
    }

    sortDevices() {
        this.deviceList.sort(function (a: Basic, b: Basic) {
            const priority = {
                [ProductType.Washer]: 1, // lowest is first
                [ProductType.Dryer]: 2 // higehst is last
            };

            if (a.sort_weight || b.sort_weight) {
                return a.sort_weight - b.sort_weight;
            } else if (a.name && b.name) return (isNaN(priority[a.product_type]) ? 999 : priority[a.product_type]) - (isNaN(priority[b.product_type]) ? 999 : priority[b.product_type]) || a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' });

            return;
        });
    }

    cardClickAction(unit: Unit) {
        this.router.navigate([this.isCustomerOperated ? `customers/${this.route.snapshot.paramMap.get('sub_customer_id')}/locations/${this.locId}/devices/${unit.id}` : `locations/${this.locId}/devices/${unit.id}`], {
            queryParams: {
                name: unit.name
            }
        });
    }
}
