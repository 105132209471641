import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Datepicker} from "vanillajs-datepicker";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import {DatePickerLanguages} from "../../../constants/datepickerLanguages";
import moment from "moment-timezone";

@Component({
    selector: 'app-aw-datepicker',
    templateUrl: './aw-datepicker.component.html',
    styleUrls: ['./aw-datepicker.component.scss'],
    standalone: true,
    imports: [TranslateModule]
})
export class AwDatepickerComponent implements AfterViewInit, OnChanges {
    @ViewChild('datepickerInput') datepickerInput: ElementRef;

    @Input() size = 'md';
    @Input() placeholder = '';
    @Input() date: number;
    @Output() dateChange = new EventEmitter<number>();

    @Input() minDate: number;
    @Input() maxDate: number;
    @Input() useEndOfDay: boolean = false; // New input property

    datePicker: Datepicker

    datePickerInitialized = false;

    constructor(
        private translate: TranslateService
    ) {
    }

    ngAfterViewInit() {
        this.initDatePicker()
        Object.assign(Datepicker.locales, DatePickerLanguages());
        Datepicker.locales['da'] ? (Datepicker.locales['da']['today'] = 'I dag') : null; // fix for incorrect danish translation in the library
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.minDate && this.datePicker) {
            this.datePicker.setOptions({ minDate: new Date(changes.minDate.currentValue * 1000) });
        }
        if (changes.maxDate && this.datePicker) {
            this.datePicker.setOptions({ maxDate: new Date(changes.maxDate.currentValue * 1000) });
        }
        if(changes.date && this.datePickerInitialized) {
            if (changes.date.currentValue === null) {
                this.datePicker.destroy();
                this.datePicker.setDate(null);
                this.datepickerInput.nativeElement.value = '';
                this.initDatePicker();
            }
        }
    }

    initDatePicker() {
        this.datePicker = new Datepicker(this.datepickerInput.nativeElement, {
            language: this.translate.currentLang,
            todayButton: true,
            clearButton: true,
            autohide: true,
            format: navigator.language === 'en-US' ? 'mm/dd/yyyy' : 'dd/mm/yyyy',
            minDate: new Date(this.minDate * 1000) ,// Convert the timestamp to milliseconds
            maxDate: new Date(this.maxDate * 1000) // Convert the timestamp to milliseconds
        });

        if (this.date) {
            const initialDate = new Date(this.date * 1000); // Convert the timestamp to milliseconds
            this.datePicker.setDate(initialDate);
        }

        // Changes behavior of the datepicker "today"-button to set the date to the current date instead of just highlighting it
        const todayButton = this.datePicker.pickerElement.querySelector('.today-button');
        if (todayButton) {
            todayButton.addEventListener('click', () => {
                console.log('today button clicked');
                const today = moment().startOf('day');
                this.date = parseInt(today.format('X'));
                this.datePicker.setDate(today.toDate());
                this.dateChange.emit(this.date);
            });
        }

        // Changes behavior of the datepicker "clear"-button to clear the date
        const clearButton = this.datePicker.pickerElement.querySelector('.clear-button');
        if (clearButton) {
            clearButton.addEventListener('click', () => {
                console.log('clear button clicked');
                this.date = null;
                this.datePicker.setDate(null);
                this.dateChange.emit(this.date);
            });
        }

        this.datePickerInitialized = true;
    }

    datePickerChanged() {
        const selectedDate = moment(this.datePicker.dates[0]);
        this.date = parseInt(this.useEndOfDay ? selectedDate.endOf('day').format('X') : selectedDate.startOf('day').format('X'));
        this.dateChange.emit(this.date);
    }
}
