/**
 * Serbian cyrillic translation for bootstrap-datepicker
 * Bojan Milosavlević <milboj@gmail.com>
 */
export default {
  sr: {
    days: ["Недеља", "Понедељак", "Уторак", "Среда", "Четвртак", "Петак", "Субота"],
    daysShort: ["Нед", "Пон", "Уто", "Сре", "Чет", "Пет", "Суб"],
    daysMin: ["Н", "По", "У", "Ср", "Ч", "Пе", "Су"],
    months: ["Јануар", "Фебруар", "Март", "Април", "Мај", "Јун", "Јул", "Август", "Септембар", "Октобар", "Новембар", "Децембар"],
    monthsShort: ["Јан", "Феб", "Мар", "Апр", "Мај", "Јун", "Јул", "Авг", "Сеп", "Окт", "Нов", "Дец"],
    today: "Данас",
    weekStart: 1,
    format: "dd.mm.yyyy"
  }
};