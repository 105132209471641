<div *ngIf="externalAccounts">
    <div *ngFor='let externalAccount of externalAccounts | keyvalue : orderRecord' class=''
        (click)="openModal(externalAccountModal,externalAccount.value)">

        <div class="external-account-card">
            <div class="bank-title-wrapper">
                <div class="bank-name">{{externalAccount.value.name}}</div>
                <div *ngIf="externalAccount.value.is_default" class="is-default-label">{{'account.default'|translate}}
                </div>
            </div>
            <div class="account-details">
                <div>
                    <p>{{externalAccount.value.bank_name}}</p>
                    <p class="last4">{{(externalAccount.value.routing_number ? 'account.account_no' : 'account.iban') |
                        translate}}: <span class="bold-text">••••{{externalAccount.value.last4}}</span></p>
                </div>
                <div class="text-end">
                    <p>
                        {{'account.country'|translate}}:
                        <span class="bold-text">{{helper.getCountryTranslated(externalAccount.value.country,true)}}</span>
                    </p>
                    <p>{{'account.currency' | translate}}: {{externalAccount.value.currency.toUpperCase()}}</p>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-container *ngIf="externalAccounts && !(hasExternalAccount && (isEnterprise || country === 'IS'))">
    <div class="add-external-account-card" (click)="openModal(externalAccountModal)">

        <img src="../../../assets/icons/add_new_bank_icon.svg" alt="close dialog">
        <span>
            {{'account.add_new_bank'|translate}}
        </span>

    </div>
</ng-container>

<span *ngIf="!externalAccounts">
    <loading-indicator [size]="'small'" [color]="'secondary'"></loading-indicator>
</span>
<ng-template #externalAccountModal>
    <app-external-account-form [externalAccount]="externalAccount" [onlyDefault]="onlyDefault"
        [isInitialAccount]="isInitialAccount" [countrySpecs]="countrySpecs" [modalService]="modalService"
        (performListChangeEmitter)="performListChange($event)" (archiveEmitter)="archiver($event)"
        (createExternalAccEmitter)="createExternalAccInList($event)"
        (deleteExternalAccEmitter)="deleteExternalAccInList($event)">
    </app-external-account-form>
</ng-template>