import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from 'src/app/services/users/users.service';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingComponent } from '../../../loading/loading.component';
import { NgIf } from '@angular/common';
import { AnimatedButtonComponent } from '../../../misc/animated-button/animated-button.component';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';
@Component({
    selector: 'app-credit-payment-info-modal',
    templateUrl: './credit-payment-info-modal.component.html',
    styleUrls: ['./credit-payment-info-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, FormsModule, ReactiveFormsModule, AnimatedButtonComponent, NgIf, LoadingComponent, TranslateModule]
})
export class CreditPaymentInfoModalComponent implements OnInit {
    @Input() userId: string;
    paymentInfoForm: FormGroup;
    showSmallIndicator = false;

    constructor(
        private modal: NgbModal,
        private userrsService: UsersService
    ) {}

    ngOnInit(): void {
        this.paymentInfoForm = new FormGroup({
            user_id: new FormControl<string>(this.userId, [Validators.required])
        });
    }

    closeModal() {
        this.modal.dismissAll();
    }

    saveChanges() {
        if (this.paymentInfoForm.valid) {
            this.showSmallIndicator = true;
            this.userrsService
                .updateUserPaymentInfo(this.paymentInfoForm.value)
                .then(() => {
                    this.showSmallIndicator = false;
                    this.closeModal();
                })
                .catch(() => {
                    this.showSmallIndicator = false;
                });
        }
    }

    newPaymentCode() {}

    removeCreditPayment() {}

    get user_id() {
        return this.paymentInfoForm.get('user_id');
    }
}
