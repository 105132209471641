<app-custom-modal>
    <ng-container modal-title>
        <h4>{{'location.credit_payment_reports' | translate}}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a (click)="modalService.dismissAll('Cancel')"><img src="../../../assets/icons/modal-close-icon.svg"
                alt="close dialog"></a>
    </ng-container>
    <ng-container modal-body>
        <div class="table-div">
            <table style="width: 100%">
                <thead>
                    <tr>
                        <th class="start-th">{{'settlements.period' | translate}}</th>
                        <th class="mid-th">{{'transactions.amount' | translate}}</th>
                        <th class="end-th">{{'settlements.link' | translate}}</th>
                    </tr>
                </thead>
            </table>
        </div>

        <div class="table-div">
            <table style="width: 100%">
                <tbody class="credit-reports">
                    <tr *ngFor="let report of reports | paginate:pageSize:pageNumber;">
                        <td class="start-td">{{periodDateFormat(report.start_date)}}</td>
                        <td class="mid-td">{{formatAmount(report.total_amount)}}</td>
                        <td class="end-td download" (click)="downloadCSV(report.location_id, report.start_date)">
                            {{downloading===report.start_date?'':('location.download_csv' | translate)}}
                            <loading-indicator *ngIf="downloading===report.start_date" [size]="'large'"
                                [color]="'primary'"></loading-indicator>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="reports.length > pageSize" style="width:100%; border-bottom: 1px solid #BEC9D1;">
        </div>
        <div [className]="isMobile?'mobile-paginator':''">
            <mat-paginator *ngIf="reports.length > pageSize" hidePageSize="true" id="matpage" [length]="reports.length"
                [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageNumber"
                (page)="handlePage($event)" showFirstLastButtons>
            </mat-paginator>
        </div>



    </ng-container>
    <ng-container modal-footer>
        <div class="no-footer"></div>
    </ng-container>
</app-custom-modal>