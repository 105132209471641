import { Component, OnInit } from '@angular/core';
import {DetailedContract, ExpiringContract} from "../../../../../shared_models/operator/contracts";
import {ContractService} from "../../../services/contract/contract.service";
import moment from "moment/moment";
import {BreakpointObserver, BreakpointState} from "@angular/cdk/layout";
import { TranslateModule } from '@ngx-translate/core';
import { LoadingComponent } from '../../loading/loading.component';
import { RouterLink } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';
import { CustomToolTipComponent } from '../../misc/custom-tool-tip/custom-tool-tip.component';

@Component({
    selector: 'app-contract-expiring-list',
    templateUrl: './contract-expiring-list.component.html',
    styleUrls: ['./contract-expiring-list.component.scss'],
    standalone: true,
    imports: [CustomToolTipComponent, NgIf, RouterLink, NgFor, LoadingComponent, TranslateModule]
})
export class ContractExpiringListComponent implements OnInit {

    loading = false
    isMobile: boolean
    contracts: (ExpiringContract | '')[];
    showInfoHoverExpiring: boolean;

    constructor(
        private contractService: ContractService,
        private breakpointObserver: BreakpointObserver
    ) {
        this.breakpointObserver.observe([
            "(max-width: 768px)"

        ]).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
    }

    async ngOnInit(): Promise<void> {
        await this.getContracts()
    }

    async getContracts() {
        this.loading = true
        this.contracts = await this.contractService.getExpiringContracts().then((response: ExpiringContract[]) => {
            this.loading = false
            return response
        })

        for (let i = this.contracts.length; i < 11; i++) {
            this.contracts.push('')
        }
    }

    getPeriod(milsStamp: number) {
        return milsStamp ? moment.tz(moment(milsStamp, 'X'), 'UTC').tz(moment.tz.guess(false)).format('L').replace(/([.])/g, '/'): ' ';
    }

    isExpiring(timestamp: number): boolean {
        return moment().add(6, 'month').unix() > timestamp;
    }

    isSemiExpiring(timestamp: number): boolean {
        return moment().add(12, 'month').unix() > timestamp;
    }
}
