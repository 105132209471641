import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import hash from 'object-hash';
import { DashboardUser } from 'shared_models/dashboard-user';
import { phoneNumberCountryData } from 'src/app/constants/phone_number_data';
import { HelperService } from 'src/app/services/helper/helper.service';
import { UsersService } from 'src/app/services/users/users.service';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { Observable } from 'rxjs';
import { LoadingComponent } from '../../../loading/loading.component';
import { CustomToolTipComponent } from '../../../misc/custom-tool-tip/custom-tool-tip.component';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';

@Component({
    selector: 'app-create-user-modal',
    templateUrl: './create-user-modal.component.html',
    styleUrls: ['./create-user-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, FormsModule, ReactiveFormsModule, NgIf, CustomToolTipComponent, NgFor, LoadingComponent, AsyncPipe, TranslateModule]
})
export class CreateUserModalComponent implements OnInit {
    @Input() userId: string;
    @Output() userCreatedEmitter = new EventEmitter<void>();

    createUserForm: FormGroup;
    user: DashboardUser;
    showSmallIndicator = false;
    createUserFormSubmitted = false;
    phoneCodes: string[] = phoneNumberCountryData.map(phoneCountry => phoneCountry.callCode).sort((a, b) => Number(a.replace('+', '')) - Number(b.replace('+', '')));
    isFetching = false;
    lastCodeKey = '';
    lastPhoneNumber = '';
    hasCheckedPhoneNumber = false;
    phoneNumberPicker: { all: Record<string, string>[]; fav: Record<string, string>[] };
    userCountry: {
        code: string;
        country: string;
        callCode: string;
        currency: string;
        phLengthMax: number;
        phLengthMin: number;
        phLength: string;
    };
    checkUserFingerprint: string; // finferprint of the user that is being checked
    customers: { id: string; name: string }[] = [];
    isOperator$: Observable<boolean> = this.authService.isOperator;

    constructor(private authService: AuthService, private modal: NgbModal, private helperService: HelperService, private usersService: UsersService, private translate: TranslateService, private toastService: ToastrService, private customerService: CustomerService) {}

    ngOnInit(): void {
        this.user = this.helperService.getUser();
        //Select default phone code based on dashboard users country
        this.userCountry = phoneNumberCountryData.find(phoneCountry => phoneCountry.code === this.user.settings.country);
        this.phoneNumberPicker = this.getCallCodesAndCountry();
        this.createUserForm = new FormGroup({
            name: new FormControl(null, Validators.required),
            phone_number_call_code: new FormControl(this.getCallCode(null, this.userCountry.callCode).label, Validators.required),
            phone_number: new UntypedFormControl(null, [Validators.required, Validators.pattern('[- +()0-9]+')]),
            customer: new FormControl(null, Validators.required)
        });
        this.customer.setValue(this.user.uid);
        console.log(this.phone_number_call_code.value);
        this.setSubscriptions();
    }

    getCallCode(label: string | null, code?: string): { label: string; code: string } {
        for (const countryDetails of this.getCallCodesAndCountry().all) {
            if (label === countryDetails.label) {
                return { code: countryDetails.callCode, label: countryDetails.label };
            } else if (!label && code === countryDetails.callCode) {
                console.log('code', code);
                return { code: countryDetails.callCode, label: countryDetails.label };
            }
        }
    }

    setSubscriptions() {
        const subCustomersSub = this.customerService
            .readSubCustomers(this.user.uid)
            .snapshotChanges()
            .subscribe(action => {
                subCustomersSub.unsubscribe();
                for (const key in action.payload.val()) {
                    if (action.payload.val()[key]) {
                        this.customers.push({ id: key, name: action.payload.val()[key].name });
                    }
                }
                this.customers.sort((a, b) => (a && b && a.name && b.name ? a.name.localeCompare(b.name, 'en', { sensitivity: 'base' }) : null)); // sorting in alphabetical order to easier scan the list in the dropdown for filter search.
            });
    }

    closeModal() {
        this.modal.dismissAll();
    }

    phoneNumberLengthValidate(control) {
        if (control.value && (control.value.length < this.userCountry.phLengthMin || control.value.length > this.userCountry.phLengthMax)) {
            return false;
        }
        return true;
    }

    async checkPhoneNumberInUse() {
        if (this.phone_number.value && (this.phone_number.value.length < this.userCountry.phLengthMin || this.phone_number.value.length > this.userCountry.phLengthMax)) {
            this.updateInputs(false);
            return;
        }
        this.isFetching = true;
        const callCode = this.getCallCode(this.phone_number_call_code.value).code;
        this.checkUserFingerprint = hash.keys(`${callCode + this.phone_number.value}${moment()}`);
        const { user_exists, user_name, as_yours, fingerprint } = await this.usersService.checkUserExists(callCode + this.phone_number.value, this.checkUserFingerprint);
        if (fingerprint !== this.checkUserFingerprint) {
            return;
        }

        if (as_yours) {
            this.phone_number.setErrors({ invalid: true, user_exists_as_yours: true });
        } else {
            this.phone_number.setErrors(null);
        }

        this.updateInputs(user_exists, user_name);
        this.isFetching = false;
    }

    getCallCodesAndCountry(): {
        all: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[];
        fav: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[];
    } {
        const allList: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[] = [];
        const favList: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[] = [];
        const favCountries: string[] = ['UK', 'BE', 'DK', 'NL', 'NO', 'FI', 'FR', 'ES'];
        for (const item of phoneNumberCountryData) {
            const { callCode, country } = item;
            allList.push({ callCode, country, label: `${callCode} - ${country}`, list: 'all' });

            if (favCountries.includes(item.code)) favList.push({ callCode, country, label: `${callCode} - ${country}`, list: 'fav' });
        }

        // sorting in alphabetical order to easier scan the list in the dropdown
        const res = {
            all: allList.sort((a, b) => (a && b && a.country && b.country ? a.country.localeCompare(b.country, 'en', { sensitivity: 'base' }) : null)),
            fav: favList.sort((a, b) => (a && b && a.country && b.country ? a.country.localeCompare(b.country, 'en', { sensitivity: 'base' }) : null))
        };

        return res;
    }

    // removeOptions function is a fix for safari and firefox that does not accept hiding options in a optgroup tag. Instead we just remove the tags from the DOM.
    removeOptions() {
        const options = Array.from(document.getElementsByClassName('removeOption'));
        if (options.length)
            options.forEach(option => {
                option.remove();
            });
    }

    updateInputs(user_exists: boolean, user_name?: string) {
        if (user_exists) {
            this.name.setValue(user_name.split('_').join(' '));
            this.name.disable();
        } else {
            this.name.enable();
        }
    }

    async createUser() {        
        this.createUserFormSubmitted = true;
        if (this.createUserForm.valid) {
            //Validate form
            console.log("creating user")
            this.showSmallIndicator = true;
            try {
                await this.usersService
                    .createUser({
                        displayName: this.name.value,
                        phoneNumber: this.getCallCode(this.phone_number_call_code.value).code + this.phone_number.value
                    }, this.customer.value)
                    .then(() => {
                        console.log("siccess")
                        this.toastService.success(this.translate.instant('users.user_created_desc'), this.translate.instant('users.user_created'));
                        this.userCreatedEmitter.next();
                    })
                    .catch(err => {
                        console.log("error", err)
                        if (err?.error?.error?.message?.message) {
                            this.phone_number.setErrors({ invalid: true, [err?.error?.error?.message?.message]: true });
                        }
                        throw err;
                    });
                this.showSmallIndicator = false;
                this.closeModal();
            } catch (error) {
                this.showSmallIndicator = false;
            }
        }
    }

    phoneCodeChanged() {
        console.log(this.getCallCode(this.phone_number_call_code.value).code);
        this.userCountry = phoneNumberCountryData.find(phoneCountry => phoneCountry.callCode === this.getCallCode(this.phone_number_call_code.value).code);
        this.createUserForm.patchValue({ phone_number: null });
        this.name.enable();
    }

    get name() {
        return this.createUserForm.get('name');
    }
    get phone_number_call_code() {
        return this.createUserForm.get('phone_number_call_code');
    }
    get phone_number() {
        return this.createUserForm.get('phone_number');
    }
    get customer() {
        return this.createUserForm.get('customer');
    }
}
