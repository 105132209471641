import { PriceUnit } from './device';
import { Reason, Status } from './order';
import { UserPaymentMethod } from './user-management';

export enum SearchScopes {
    PHONE = 'phone', // Exact search in ['users', 'transactions'] - determines on: only numbers, may: include '()+'
    PAYMENT_ID = 'payment_id', // Exact search in ['transactions'] - determines on: starts with 'pi_' / 'cp-' / 'wp-
    DEVICE_SERIAL = 'device_serial', // Exact search in ['devices'] - determines on: starts with 'AW' and end with 'R1' / 'R2'
    TERMINAL_SERIAL = 'terminal_serial', // Exact search in ['terminals'] - determines on: starts with 'WPC' / 'STRM'
    SETTLEMENT = 'settlement', // Exact search in ['settlements'] - determines on: starts with 'po_' / 'po-'
    FUZZY = 'fuzzy', // Fuzzy search in ['users.name', 'locations.name', 'location.address', 'devices.name', 'customers.name', 'customers.mail', 'contracts.name']
    // prefix searches. Prefix your search with xxx: to search in a specific field

    LAST4 = 'last4', // exact search in ['users'] - determines on: starts with last4:
    CUSTOM_LOCATION_ID = 'custom_location_id', // Exact search in ['locations'] - determines on: starts with 'cl:'
    LOCATION_EXACT = 'location', // Exact search in ['locations'] - determines on: starts with 'l:'
    // DEVICE_EXACT = 'device', // Exact search in ['devices'] - determines on: starts with 'd:'  . With current db structure locatioKey is required, consider moving devices to owne path
    CONTRACT_EXACT = 'contract', // Exact search in ['contracts'] - determines on: starts with 'c:'
    // TERMINAL_EXACT = 'terminal', // Exact search in ['terminals'] - determines on: starts with 't:' with current db structure locationKey is required, consider moving terminals to own path
    USER_EXACT = 'user' // Exact search in ['users'] - determines on: starts with 'u:'
}

export interface Search {
    k: string; // key
    v: string; // value
}
export interface SearchOutput {
    s: number; // score
    p: string; // path
    _p?: string; // optional additional path
    t: 'user' | 'location' | 'device' | 'contract' | 'customer';
}

export interface SearchResult {
    locations: Array<Location>;
    customers: Array<Customer>;
    devices: Array<Device>;
    terminals: Array<Terminal>;
    contracts: Array<Contract>;
    settlements: Array<Settlement>;
    transactions: Array<Transaction>;
    users: Array<User>;
}

export interface Location {
    name: string;
    daily_starts: number;
    revenue: number;
    deviceCount: number;
    location_key: string;
    owner_uid: string;
    currency: string;
    location_custom_id?: string;
    contract_id?: string;
}

export interface Customer {
    name: string;
    status: any; // Find this somewhere else,
    balance: number;
    my_share: number;
    customer_uid: string;
    customer_share: number;
    currency: string;
}

export interface Device {
    customer_name: string;
    name: string;
    location_name: string;
    owner_uid: string;
    device_key: string;
    location_key: string;
    revenue: number; // revenue of last 30 days
    price_unit: PriceUnit;
    last_used: number;
    daily_starts: number;
    currency: string;
    price?: number;
    pulse_increment?: number;
}

export interface Terminal {
    serial: string;
    location_name: string;
    customer_name: string;
    contract_id?: string;
    owner_uid: string; //subcustomer
    payments: number;
    status: 'active' | 'incident' | 'created' | '-';
    terminal_key: string;
    location_key: string;
}

export interface Contract {
    id: string;
    location_names: Array<string>;
    location_addresses?: Array<string>;
    location_custom_ids?: Array<string>;
    users: number;
    daily_starts: number;
    revenue_target: number;
    revenue: number;
    billable_amount: number | null;
    performance: number;
    contract_key: string;
    customer_name?: string;
}

export interface Settlement {
    created: number;
    amount: number;
    link: string;
    payout_id: string;
}

export interface Transaction {
    id: string;
    timestamp: number;
    amount: number;
    location_name: string;
    device_name: string;
    phone_number?: string;
    refund: boolean;
    refund_reason?: Reason;
    refund_details?: string; // only if reason is "other" this can be applied
    refund_status?: Status;
    user_name?: string;
    machine_serial_number?: string;
    user_id: string;
    uid: string;
    currency: string;
}

export interface User {
    id: string;
    name: string;
    phone_number: string;
    phone_number_call_code: string;
    platform: string;
    user_role: 'normal' | 'test';
    total_spend: Record<string, number>;
    payments: number;
    refunds: Record<string, number>;
    created: number;
    payment_method?: UserPaymentMethod;
    owner_uid: string;
}

export enum SearchTypes {
    customer = 'customer',
    location = 'location',
    device = 'device',
    terminal = 'terminal',
    platform = 'platform',
    user_role = 'user_role',
    contract = 'contract',
    settlement = 'settlement',
    transaction = 'transaction',
    user = 'user'
}
