<div class="filter-wrapper">
    <!--The filter button-->
    <div class="filter-button" (click)="togglePopdown()">
        <div class="filter-button-content">
            <p class="filter-button-text">{{ "discount_tool.edit_columns" | translate }}</p>
        </div>
        <img src="../../../../assets/icons/arrow-down-blue.svg" [className]="isExpanded ? 'arrow arrow-clicked' : 'arrow'" />
    </div>

    <!--The Popup-->
    <div [hidden]="!isExpanded" [className]="popupAnchor === 'left' ? 'filter-popup left' : 'filter-popup right'">
        <!-- Manage -->
        <div class="filter-popup-accordion">
            <div class="filter-popup-accordion-button">
                <div class="filter-popup-accordion-button-inner">
                    <p>
                        <strong>{{ "discount_tool.columns" | translate }}</strong>
                    </p>
                </div>
                <p (click)="checkAll()">
                    <u>{{ (areAllSelectedCheck() ? "operator.anton_health.uncheck_all" : "operator.anton_health.check_all") | translate }}</u>
                </p>
            </div>
            <div class="filter-popup-accordion-unfolded">
                <div (click)="$event.stopPropagation()" style="width: 100%">
                    <div *ngFor="let tableSetting of tableHeaderSettings.columns" class="w-padding" [ngClass]="{ isChecked: tableSetting.show === true }">
                        @if (!nonEditableColumnKeys.includes(tableSetting.key)) {
                            <div class="common-input" style="width: 100%" *ngIf="!!tableSetting.title">
                                <app-aw-checkbox [initialCheckedValue]="tableSetting.show" [text]="tableSetting.title" [checkbox]="tableSetting.show" [noTextFormatting]="true" (checkStateEmitter)="tableSetting.show = $event" />
                            </div>
                        }
                    </div>
                    <div class="filter-popup-header">
                        <button class="submit-button" (click)="submit()">
                            <loading-indicator size="small" color="white" *ngIf="settingTableHeaders" />
                            <ng-container *ngIf="!settingTableHeaders">{{ "misc.apply" | translate }}</ng-container>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
