<div id="top-nav-bar-wrapper" class="prevent-select">
    <!-- admin : {{(isAdmin$ | async)}} ---- developer : {{(isDeveloper$ | async )}} ----  operator : {{(isOperator$ | async)}}  ---- SignedIn: {{(isSignedIn$ | async)}} ---- role: {{(role$ | async)}} ---- isBillingAlloed: {{isBillingAllowed$ | async}} --- coupon {{isCouponAllowed$ | async}} -  -->
    <!-- update info bar -->
    <div *ngIf="displayUpdateBar" class="update-bar">
        <div *ngIf="majorUpdate">
            {{ 'misc.majorUpdate' | translate }} {{ secondsToSignOut }} {{ 'misc.majorUpdate_2' | translate }}
        </div>
        <div *ngIf="minorUpdate">
            {{ 'misc.minorUpdate' | translate }} <a href="javascript:void(0);" (click)="refreshWindow()">{{
                'misc.click_to' | translate
            }}</a> {{ 'misc.to_refresh' | translate }}
        </div>
    </div>

    <!-- controlled uid info bar -->
    <div class="controlled-info" *ngIf="controlActive && !loadingControlledUid">
        <div class="container controlled-info-wrapper">
            <div class="account-details">
                <label>{{ 'navbar.account_name' | translate }}:</label>
                <label class="name">{{ getNameString() }}</label>
            </div>
            <a (click)="setControlledUid()">{{ 'navbar.exit_control' | translate }}</a>
        </div>
    </div>

    <!-- nav bar -->
    <nav class="navbar navbar-expand-lg navbar-light p-0" id="navbar" *ngIf="(isSignedIn$ | async)">
        <!-- nav bar content -->
        <div class="container">
            <div class="brand-wrapper" *ngIf="!mobileSearchOpen">
                <a role="button" class="navbar-brand" [routerLink]="controlActive ? ['/locations']:['/overview']">
                    <img class="logo" src="../../../assets/icons/dashboard-logo.svg">
                </a>

                <div class="custom-tool-tip-wrapper" [hidden]="!(operatorName$ | async)"
                     (mouseenter)="operatedInfo=true" (mouseleave)="operatedInfo=false">
                    <div class="operatored-notify-circle">
                        <img width=".875rem" height="auto" src="../../../assets/icons/operator-notify-icon.svg"
                             alt="Info">
                        <div class="custom-tool-tip" *ngIf="operatedInfo">
                            <div class="mb-2">
                                <span><img style="margin: 0 8px 3px 0;"
                                           src="../../../assets/icons/info-circle_white.svg"
                                           alt="{{'navbar.main_operator' | translate}}"></span>
                                <span class="tooltip-title">{{
                                        'navbar.main_operator' |
                                            translate
                                    }}</span>
                            </div>
                            <div>
                                <span style=" font-weight: 600;">
                                    {{ (operatorName$ | async) }} </span>{{
                                    'navbar.main_operator_description_long_pt1' |
                                        translate
                                }}
                            </div>
                            <div class="mt-2">
                                {{ 'navbar.main_operator_description_long_pt2' | translate }}
                            </div>
                            <div class="mt-2">
                                {{ 'navbar.main_operator_description_long_pt3' | translate }}
                                {{ (operatorName$ | async) }}
                                {{ 'navbar.main_operator_description_long_pt4' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="input-group-text search-wrapper" id="search-input-wrapper" *ngIf="!isMobile"
                     [formGroup]="searchForm">
                    <span class="search-icon"></span>
                    <input #searchInput
                           (keyup.enter)="!resultsShown && !suggestionShortCode && !showSearchSuggestions ? startSearch() : ''"
                           formControlName="input"
                           id="search-input-id" class="search-input" type="text" (focusout)="handleSearchAction($event)"
                           (focus)="handleSearchAction($event)" (keyup)="handleSearchAction($event)"
                           placeholder="{{'misc.search_placeholder' | translate}}">
                    <loading-indicator class="loading" *ngIf="loadingResults" [size]="'tiny'"
                                       [color]="'primary'"></loading-indicator>
                </div>


            </div>

            <!-- For mobile -->
            <div class="search-input-mobile" *ngIf="isMobile && mobileSearchOpen">
                <div class="input-group-text search-wrapper" id="search-mobile-input-wrapper">
                    <span>
                        <span class="search-icon"></span>
                        <form action="." [formGroup]="searchForm" (submit)="!resultsShown ? startSearch() : ''">
                            <input #searchInput (keyup.enter)="!resultsShown ? startSearch() : ''"
                                   formControlName="input" id="search-input-id" class="search-input" name="q"
                                   type='search'
                                   (focusout)="handleSearchAction($event)" (focus)="handleSearchAction($event, true)"
                                   placeholder="Search...">
                            <button (keyup.enter)="!resultsShown ? startSearch() : ''" type='submit'
                                    style="display: none;">Search</button>
                        </form>
                    </span>
                    <loading-indicator class="loading" *ngIf="loadingResults" [size]="'tiny'"
                                       [color]="'primary'"></loading-indicator>
                    <span *ngIf="!loadingResults" (click)="handleMobileSearch()" class="close-icon"></span>
                </div>
            </div>

            <div class="burger-fix">
                <!-- For mobile -->
                <div *ngIf="isMobile && !mobileSearchOpen" class="search-mobile-wrapper">
                    <span (click)="handleMobileSearch()" class="search-icon-mobile"></span>
                </div>

                <div class="navbar-toggler" style="margin-top: 3px;" data-bs-toggle="collapse"
                     data-bs-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false"
                     aria-label="Toggle navigation">
                    <div id="nav-icon3" (click)="openCloseHamburger('click')" [class]="burgerOpen ? 'open' : ''">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </div>


            <div class="collapse navbar-collapse dropdown-wrapper" id="navbarToggler">
                <ul class="navbar-nav ms-auto h-100 mt-lg-0">

                    <!-- Overview -->
                    <li class="nav-item" id="overview" *ngIf="authService.allowAccess('overview', (role$ | async))">
                        <a role="button" class="nav-link" [routerLink]="['/overview']">
                          <span class="linkTitle">{{ 'navbar.overview' | translate }}</span>
                        </a>
                    </li>

                    <!-- Transactions -->
                    <li class="nav-item" id="transactions"
                        *ngIf="authService.allowAccess('transactions', (role$ | async))">
                        <a role="button" class="nav-link" [routerLink]="['transactions']"><span
                                class="linkTitle">{{ 'navbar.transactions' | translate }}</span></a>
                    </li>

                    <!-- Locations -->
                    <li class="nav-item" id="locations" *ngIf="authService.allowAccess('locations', (role$ | async))">
                        <a role="button" class="nav-link" [routerLink]="['locations']"><span
                                class="linkTitle">{{
                                (!isOperator$ | async) ? ('misc.locations' | translate) :
                                    ('navbar.my_locations' | translate)
                            }}</span></a>
                    </li>

                    <!-- Actions -->
                    <li class="nav-item" id="actions"
                        *ngIf="!(isOperator$ | async) && authService.allowAccess('actions', (role$ | async))">
                        <a role="button" class="nav-link" [routerLink]="['actions']"><span
                                class="linkTitle">{{ 'operator.filter_bar.actions' | translate }}</span></a>
                    </li>
                    <!-- Operator -->
                    <li class="nav-item" id="operator"
                        *ngIf="(isOperator$ | async) && authService.allowAccess('operator/customers', (role$ | async))">
                        <a role="button" class="nav-link operator-link" [routerLink]="['operator/customers']"><span
                                class="linkTitle">{{ 'navbar.operator' | translate }}</span></a>
                        <!--            Only visible on mobile-->
                        <div class="dropdown operator-dropdown">
                            <a role="button" class="dropdown-toggle nav-link" data-bs-toggle="dropdown"
                               aria-expanded="false">
                                {{ 'navbar.operator' | translate }}
                            </a>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" id="operator-menu">

                                <a role="button" class="dropdown-item my-pointer" id="customers"
                                   *ngIf="authService.allowAccess('operator/customers', (role$ | async))"
                                   [routerLink]="['/operator/customers']">{{ 'customers.customers' | translate }}</a>
                                <div class="dropdown-divider"
                                     *ngIf="authService.allowAccess('operator/customers', (role$ | async))"></div>

                                <a role="button" class="dropdown-item my-pointer" id="actions"
                                   *ngIf="authService.allowAccess('operator/actions', (role$ | async))"
                                   [routerLink]="['/operator/actions']">{{
                                        'operator.filter_bar.actions' |
                                            translate
                                    }}</a>
                                <div class="dropdown-divider"
                                     *ngIf="authService.allowAccess('operator/actions', (role$ | async))"></div>

                                <a role="button" class="dropdown-item my-pointer" id="contracts"
                                   *ngIf="authService.allowAccess('operator/contracts', (role$ | async))"
                                   [routerLink]="['/operator/contracts']">{{
                                        'operator.filter_bar.contracts' |
                                            translate
                                    }}</a>
                                <div class="dropdown-divider"
                                     *ngIf="authService.allowAccess('operator/contracts', (role$ | async))"></div>

                                <a role="button" class="dropdown-item my-pointer" id="devices"
                                   *ngIf="authService.allowAccess('operator/devices', (role$ | async))"
                                   [routerLink]="['/operator/devices']">{{
                                        'operator.filter_bar.devices' |
                                            translate
                                    }}</a>
                                <div class="dropdown-divider"
                                     *ngIf="authService.allowAccess('operator/anton-health', (role$ | async))"></div>

                                <a role="button" class="dropdown-item my-pointer" id="anton-health"
                                   *ngIf="authService.allowAccess('operator/anton-health', (role$ | async))"
                                   [routerLink]="['/operator/anton-health']">{{
                                        'operator.filter_bar.anton-health' |
                                            translate
                                    }}</a>
                            </div>
                        </div>
                    </li>

                    <!-- Users -->
                    <li class="nav-item" id="users" *ngIf="authService.allowAccess('users', (role$ | async))">
                        <a role="button" class="nav-link" [routerLink]="['users']"><span
                                class="linkTitle">{{ 'navbar.users' | translate }}</span></a>
                    </li>

                    <!-- Settings -->
                    <li class="nav-item" id="settings">
                        <div class="dropdown">
                            <a role="button" class="dropdown-toggle nav-link" data-bs-toggle="dropdown"
                               aria-expanded="false">
                                {{ 'navbar.settings' | translate }} </a>
                            <div class="dropdown-menu" id="menuDropdown" aria-labelledby="dropdownMenuButton">

                                <div *ngIf="authService.allowAccess('account', (role$ | async))">
                                    <a role="button" class="dropdown-item my-pointer" id="account"
                                       [routerLink]="['account']">{{ 'navbar.settings_account' | translate }}</a>
                                    <div class="dropdown-divider"></div>
                                </div>
                                <div
                                        *ngIf="(isBillingAllowed$ | async) && authService.allowAccess('billing', (role$ | async)) && !user.settings.is_regular">
                                    <a role="button" class="dropdown-item my-pointer" id="billing"
                                       [routerLink]="['billing']">{{ 'misc.billing' | translate }}</a>
                                    <div class="dropdown-divider"></div>
                                </div>

                                <div *ngIf="authService.allowAccess('settlements', (role$ | async))">
                                    <a role="button" class="dropdown-item my-pointer" id="settlements"
                                       [routerLink]="['settlements']">{{ 'navbar.settings_settlements' | translate }}</a>
                                    <div class="dropdown-divider"></div>
                                </div>

                                <div *ngIf="authService.allowAccess('team-members', (role$ | async))">
                                    <a role="button" class="dropdown-item my-pointer" id="team-members"
                                       [routerLink]="['team-members']">{{ 'team_members.team_members' | translate }}</a>
                                    <div class="dropdown-divider"></div>
                                </div>

                                <div
                                        *ngIf="(isCouponAllowed$ | async) && authService.allowAccess('coupon', (role$ | async))">
                                    <a role="button" class="dropdown-item my-pointer" id="coupon"
                                       [routerLink]="['coupon']">{{ 'navbar.coupon' | translate }}</a>
                                    <div class="dropdown-divider"></div>
                                </div>

                                <div
                                        *ngIf="(isDeveloper$ | async) && authService.allowAccess('developer', (role$ | async))">
                                    <a role="button" class="dropdown-item my-pointer" id="developer"
                                       [routerLink]="['developer']">Developer</a>
                                    <div class="dropdown-divider"></div>
                                </div>

                                <a role="button" class="dropdown-item my-pointer"
                                   (click)="authService.signOut()">{{ 'navbar.settings_log_out' | translate }}</a>
                            </div>
                        </div>
                    </li>

                    <!-- Admin -->
                    <li *ngIf="(isAdmin$ | async) && !controlActive" class="nav-item" id="admin">
                        <div class="dropdown" id="admin-dropdown">
                            <a role="button" class="dropdown-toggle nav-link" data-bs-toggle="dropdown"
                               aria-expanded="false">
                                {{ 'navbar.admin' | translate }}
                            </a>
                            <div class="dropdown-menu admin" id="menuDropdown" aria-labelledby="dropdownMenuButton">
                                <li *ngIf="(isAdmin$ | async)" class="d-flex align-items-center control-uid-wrapper" (click)="stopDropdownPropagation($event)">
                                    <div class="input-group padding-wrapper d-flex flex-row">
                                        <input type="text" class="form-control control-uid-input"
                                               [(ngModel)]="controlledUid"
                                               placeholder="👨🏻‍💻">
                                        <div class="input-group-append">
                                            <span class="input-group-text control-uid-btn" (click)="setControlledUid()">
                                                <span id="timeglass" [hidden]="!loadingControlledUid">
                                                    ⏳
                                                </span>{{
                                                    loadingControlledUid ? '' : controlActive ? ('navbar.exit' | translate) :
                                                        ('navbar.control' | translate)
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </li>
                                <div class="dropdown-divider"></div>
                                <a role="button" *ngIf="(isAdmin$ | async)" class="dropdown-item my-pointer"
                                   id="support"
                                   [routerLink]="['/support']">
                                    {{ 'navbar.settings_support_tool' | translate }}
                                </a>

                                <div class="dropdown-divider"></div>
                                <a role="button" *ngIf="(isAdmin$ | async)" class="dropdown-item my-pointer"
                                   id="anton-health"
                                   [routerLink]="['/support/anton-health']">
                                    {{ 'operator.filter_bar.anton-health' | translate }}
                                </a>

                                <div class="dropdown-divider"></div>
                                <a role="button" *ngIf="(isAdmin$ | async)" class="dropdown-item my-pointer"
                                   id="discount-tool"
                                   [routerLink]="['/support/discount-tool']">
                                    {{ 'discount_tool.discount_tool' | translate }}
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

</div>

<div class="container gx-0 prevent-select" id="outer-search-result">
    <div class="search-result-wrapper" id="search-result-query-selector">
        <!-- for search results -->
        <app-search-preview *ngIf="resultsShown && !loadingResults" [searchQuery]="searchQuery"
                            [searchResultAmount]="searchResultAmount" [searchResult]="searchResult"
                            (itemClickedEvent)="handleItemClick($event)"></app-search-preview>
        <!-- for suggestions -->


        <app-search-preview *ngIf="showSearchSuggestions && !resultsShown && !loadingResults"
                            [showSearchSuggestions]="showSearchSuggestions"
                            (searchSuggestionSelected)="handleSuggestion($event)"></app-search-preview>
    </div>
</div>