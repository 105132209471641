<hr class="aw-hr">

<div class="credit-users-title-area">
    <div>
        <h4 class="credit-users-title">{{'location.credit_users' | translate}}</h4>
    </div>

    <div class="credit-users-title-area-btns">
        <button class="btn btn-secondary btn-tiny report-button" [disabled]="!reports.length"
            (click)="openModal(reports.length?creditReportsModal:null)">
            {{'misc.reports' | translate}}
        </button>
        <button class="btn btn-primary btn-tiny" (click)="openModal(creditUserModal)">
            <img class="plus-icon-on-btn" src='../../../../assets/icons/plus-for-create.svg'
                alt="{{'location.create_credit_user' | translate}}">
            {{'location.create_credit_user' | translate}}
        </button>
    </div>
</div>

<div *ngIf="loading" class="loading-height">
    <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
</div>

<div *ngIf="!loading && !allCreditUsersArr.length" class="empty-page-list">
    <p>{{'location.no_credit_users_on_location' | translate}}</p>
</div>

<div class="row cont" style="min-height: 200px"
    *ngIf="!loading && allCreditUsersArr.length && !isMobileForCreditUserTable">
    <div class="pt-4 col-12 box">
        <div style="padding: 0 1rem 1.25rem 1rem">
            <table style="width: 100%;" class="table-title">
                <tr>
                    <th colspan="0" [className]="'header1'">
                        {{'misc.user_id' | translate}}</th>
                    <th colspan="0" [className]="'header2'">
                        {{'location.name' | translate}}</th>
                    <th colspan="0" [className]="'header3'">
                        {{'transactions.phone_no' | translate}}</th>
                    <th colspan="0" [className]="'header4'">
                        {{'customers.created' | translate}}</th>
                    <th colspan="0" [className]="'header5'">
                        {{'location.payment_code_status' | translate}}</th>
                </tr>
            </table>
        </div>

        <div *ngFor="let item of allCreditUsersArr | paginate:pageSize:pageNumber">
            <div class="card-item-extended" (click)="openModal(creditUserModal, item)" *ngIf="item.created">
                <table style="width:100%" class="table-content">

                    <tr>
                        <th colspan="0" [className]="'header1'">
                            {{item.id_from_administration}}</th>
                        <th colspan="0" [className]="'header2'">
                            {{item.name}}</th>
                        <th colspan="0" [className]="'header3'">
                            {{item.phone_number_call_code}} {{item.phone_number}}</th>
                        <th colspan="0" [className]="'header4'">
                            {{created(item.created)}}</th>
                        <th colspan="0" [className]="item.status !== 'failed'?'header5':'header5 danger-text-color'">
                            {{'location.' + item.status | translate}}</th>
                    </tr>
                </table>
            </div>
        </div>

        <mat-paginator id="matpage" [length]="allCreditUsersArr.length" [pageSize]="pageSize"
            [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageNumber" (page)="handlePage($event)"
            showFirstLastButtons class="pagination"></mat-paginator>
    </div>
</div>

<div class="cont" style="min-height: 200px" *ngIf="!loading && allCreditUsersArr.length && isMobileForCreditUserTable">
    <div class="mobile-body">
        <div *ngFor="let item of allCreditUsersArr | paginate:pageSize:pageNumber"
            (click)="openModal(creditUserModal, item)">
            <div class="mobile-card">
                <div class="card-title">
                    {{'misc.user_id' | translate}}: {{item.id_from_administration}}
                </div>
                <div style="margin-top: 5px">
                    <p style="font-size: 14px">{{item.name}}</p>
                    <div style="display: flex; justify-content: space-between;">
                        <p style="font-size: 14px; font-weight: 600;">{{'transactions.phone_no'|translate}}</p>
                        <p style="font-size: 14px">{{item.phone_number_call_code}} {{item.phone_number}}</p>
                    </div>
                    <div style="display: flex; justify-content: space-between;">
                        <p style="font-size: 14px; font-weight: 600;">{{'transactions.status'|translate}}</p>
                        <p [className]="item.status !== 'failed'?'header5':'header5 danger-text-color'"
                            style="font-size: 14px">{{'location.' + item.status | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="mobile-paginator" *ngIf="allCreditUsersArr.length !== 0">
            <mat-paginator id="matpage" [length]="allCreditUsersArr.length" [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageNumber" (page)="handlePage($event)"
                showFirstLastButtons class="pagination"></mat-paginator>
        </div>
    </div>
</div>

<!-- CREDIT USER MODAL -->
<ng-template #creditUserModal let-modal>
    <app-credit-user-form-modal [creditUser]="creditUser?creditUser:null" [uid]="uid?uid:null"
        [modalService]="modalService" [locationId]="locationId" (deleteCreditUserEmitter)="deleteUserInList($event)"
        (updateCreditUserEmitter)="updateUserInList($event)"
        (createCreditUserEmitter)="createUserInList($event)"></app-credit-user-form-modal>
</ng-template>

<!-- CREDIT REPORTS MODAL -->
<ng-template #creditReportsModal let-modal class="reports-modal">
    <app-credit-report-list-modal [reports]="reports.length?reports:null"
        [isMobile]="isMobile?isMobile:null"
        [modalService]="modalService"></app-credit-report-list-modal>
</ng-template>