
<app-custom-modal *ngIf="!initLoading" class="small-padding">
    <ng-container modal-title>
        <div class="path pointer d-flex align-items-center" style="z-index: 1000">
            <p>{{action.statics.location_name ? action.statics.location_name : ''}}</p>
            <img *ngIf="!!action.statics.device_name" class="arrow-right col-1" src="assets/icons/arrow-up-blue.svg" alt="arrow icon">
            <p>{{action.statics.device_name ? action.statics.device_name : ''}}</p>
        </div>
    </ng-container>
    <ng-container modal-dismiss>
        <a class="text-end" style="width: 30px" (click)="modalService.dismissAll('Cancel')"><img
            src="../../../assets/icons/modal-close-icon.svg" alt="close dialog"></a>
    </ng-container>
    <ng-container modal-body>
        <div class="top-half">
            <ng-container *ngIf="!!reversedActiveLogKeys && !checkLoading">
                <ng-container *ngFor="let logKey of reversedActiveLogKeys">
                    <ng-container *ngIf="(action.log[logKey].state !== 'advice' && showAlertAction) || (action.log[logKey].state === 'advice' && showAdviceAction)">
                        <div class="action-card">
                            <p style="font-size: 16px; font-weight: 600; margin-bottom: 5px">{{getTitle(action.log[logKey], false)}}</p>
                            <p style="font-size: 12px; margin-bottom: 5px; color: #BEC9D1"><i>{{timeSince(action.log[logKey].timestamp)}}</i></p>
                            <p style="font-size: 14px; margin-bottom: 10px">{{getDescription(action.log[logKey], action)}}</p>
                            <div class="d-flex align-items-center">
                                <div class="state-box {{action.log[logKey].state}}">
                                    <p>{{getState(action.log[logKey])}}</p>
                                </div>
                                <div (click)="checkIfActionIsSoapOrMaintenance(logKey) ? openModal(resetMachineServiceModal, logKey) : removeAction(logKey)" class="check-box" *ngIf="authService.hasLimitedAccess('close_action', (role$ | async))">
                                    <svg id="Component_10_13" data-name="Component 10 – 13" xmlns="http://www.w3.org/2000/svg" width="68" height="32" viewBox="0 0 68 32">
                                        <g id="Rectangle_1157" data-name="Rectangle 1157" fill="none" stroke-width="1.5">
                                            <rect width="68" height="32" rx="2" stroke="none"/>
                                            <rect x="0.75" y="0.75" width="66.5" height="30.5" rx="1.25" fill="none"/>
                                        </g>
                                        <path id="Icon_awesome-angle-up" data-name="Icon awesome-angle-up" d="M7.859.172l3.321,3.321a.584.584,0,0,1,0,.828l-.552.552a.584.584,0,0,1-.828,0L7.444,2.521l-5.89,5.89a.584.584,0,0,1-.828,0L.172,7.859a.584.584,0,0,1,0-.828L7.029.174A.584.584,0,0,1,7.859.172Z" transform="translate(40.408 20.583) rotate(180)"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
            <!--        Shows latest log entry, when no active actions are in list-->
            <div class="top-bottom-half mb-1" *ngIf="!action.active_actions && !checkLoading">
                <ng-container *ngIf="(latestLogValue.state !== 'advice' && showAlertAction) || (latestLogValue.state === 'advice' && showAdviceAction)">
                    <div class="action-card">
                        <p style="font-size: 16px; font-weight: 600">{{getTitle(latestLogValue, false)}}</p>
                        <p style="font-size: 12px; color: #BEC9D1" ><i>{{timeSince(latestLogValue.timestamp)}}</i></p>
                        <p style="font-size: 14px">{{getDescription(latestLogValue, action)}}</p>
                        <div class="d-flex align-items-center">
                            <div class="state-box closed">
                                <p>{{'operator.actions.states.complete' | translate}}</p>
                            </div>
                            <div (click)="moveActionBackToOpen(latestLogValue)" class="check-box active" *ngIf="authService.hasLimitedAccess('close_action', (role$ | async))">
                                <svg id="Component_10_13" data-name="Component 10 – 13" xmlns="http://www.w3.org/2000/svg" width="68" height="32" viewBox="0 0 68 32">
                                    <g id="Rectangle_1157" data-name="Rectangle 1157" fill="none" stroke-width="1.5">
                                        <rect width="68" height="32" rx="2" stroke="none"/>
                                        <rect x="0.75" y="0.75" width="66.5" height="30.5" rx="1.25" fill="none"/>
                                    </g>
                                    <path id="Icon_awesome-angle-up" data-name="Icon awesome-angle-up" d="M7.859.172l3.321,3.321a.584.584,0,0,1,0,.828l-.552.552a.584.584,0,0,1-.828,0L7.444,2.521l-5.89,5.89a.584.584,0,0,1-.828,0L.172,7.859a.584.584,0,0,1,0-.828L7.029.174A.584.584,0,0,1,7.859.172Z" transform="translate(40.408 20.583) rotate(180)"/>
                                </svg>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!--    what is displayed when loading check-->
            <div class="top-bottom-half mb-1" *ngIf="checkLoading">
                <ng-container *ngIf="(latestLogValue.state !== 'advice' && showAlertAction) || (latestLogValue.state === 'advice' && showAdviceAction)">
                    <div class="d-flex top-half align-items-center justify-content-center" style="width: 100%; height: 30px">
                        <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
    <ng-container modal-footer>
        <div class="bottom-half">
            <div class="d-flex justify-content-start pointer full-width-div history"
                 [style]="toggleHistory ? null : 'border-bottom-left-radius: 8px;border-bottom-right-radius: 8px;'">
                <p (click)="toggleHistoryExpand()">
                    {{'misc.show_details' | translate}}
                    <svg *ngIf="toggleHistory" xmlns="http://www.w3.org/2000/svg" width="6.92" height="4.47" viewBox="0 0 11.5 7.426">
                        <path id="Icon_awesome-angle-up" data-name="Icon awesome-angle-up" d="M6.357,10.987l4.886,4.886a.859.859,0,0,1,0,1.218l-.812.812a.859.859,0,0,1-1.218,0l-3.467-3.46L2.283,17.906a.859.859,0,0,1-1.218,0L.25,17.094a.859.859,0,0,1,0-1.218l4.886-4.886a.86.86,0,0,1,1.221,0Z" transform="translate(0.004 -10.734)"
                              fill="#03045E"/>
                    </svg>
                    <svg *ngIf="!toggleHistory" xmlns="http://www.w3.org/2000/svg" width="6.92" height="4.47" viewBox="0 0 11.5 7.426">
                        <path id="Icon_awesome-angle-up" data-name="Icon awesome-angle-up" d="M6.357,10.987l4.886,4.886a.859.859,0,0,1,0,1.218l-.812.812a.859.859,0,0,1-1.218,0l-3.467-3.46L2.283,17.906a.859.859,0,0,1-1.218,0L.25,17.094a.859.859,0,0,1,0-1.218l4.886-4.886a.86.86,0,0,1,1.221,0Z" transform="translate(11.496 18.16) rotate(180)"
                              fill="#03045E"/>
                    </svg>
                </p>
            </div>
            <div *ngIf="toggleHistory" class="mt-4">
                <ng-container *ngFor="let logKey of reversedLogKeys; let index = index">
                    <div class="history-container mb-1">
                        <div class="d-flex flex-row justify-content-between" style="font-size: 16px">
                            <p class="col-8">{{getTitle(action.log[logKey], true)}}</p>
                            <p class="ms-1">{{timeSince(action.log[logKey].timestamp)}}</p>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>
</app-custom-modal>

<ng-template #resetMachineServiceModal>
    <app-machine-services-reset-modal
        [location_id]="action.statics.location_id"
        [device_id]="action.statics.device_id"
        [customer_id]="action.customer_id"
        [machine_service]="action.log[modalLogKey].action_type === actionType.machineMaintenanceDue ? 'maintenance': 'soap'"
        (closeAction)="emitSingleReset()"
        (closeEntireLocation)="emitMultipleReset()">
    </app-machine-services-reset-modal>
</ng-template>

