<app-page-layout [pageTitle]="'team_members.team_members' | translate">
    <ng-container buttons>
        <button
            *ngIf="authService.hasLimitedAccess('team-members-settings', role$ | async)"
            style="position: relative"
            [disabled]="initLoading"
            class="btn btn-primary btn-tiny"
            (click)="openModal(inviteMemberModal)"
        >
            <div>
                <img class="plus-icon-on-btn" src="../../../assets/icons/plus-for-create.svg" alt="create customer" />
                {{ "team_members.invite_member" | translate }}
            </div>
        </button>
    </ng-container>
    <ng-container body>
        <div *ngIf="initLoading" class="loading-height">
            <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
        </div>
        <app-aw-table
            [tableHeaders]="tableHeaderOptions"
            [emptytext]="'billing.no_active_devices' | translate"
            [pageNumber]="pageNumber"
            [pageSize]="pageSize"
            [totalItems]="totalItems"
            (handlePageChange)="handlePage($event)"
            (sortBy)="catchSortChanged($event)"
            [loadingData]="initLoading"
            [sidescrollable]="isMobile"
            [initalSort]="'name'"
        >
            <ng-container table-body>
                <row *ngFor="let member of teamMembers | paginate: pageSize : pageNumber; let i = index">
                    <cell [ngStyle]="{ width: tableHeaderOptions[0]?.width }">
                        <span
                            ><strong>{{ member.role !== "bookkeeper" ? member.name : getRoleString(member.role) }}</strong></span
                        >
                    </cell>
                    <cell [ngStyle]="{ width: tableHeaderOptions[1]?.width, 'text-align': 'left' }">
                        <p>{{ member.mail }}</p>
                    </cell>
                    <cell [ngStyle]="{ width: tableHeaderOptions[2]?.width, 'text-align': 'left' }">
                        <p>{{ member.phone }}</p>
                    </cell>
                    <cell [ngStyle]="{ width: tableHeaderOptions[3]?.width, 'text-align': 'left' }">
                        <p *ngIf="member.uid !== user.uid">
                            <strong>{{ getRoleString(member.role) }}</strong>
                        </p>
                        <div *ngIf="member.uid === user.uid">
                            <div class="owner">
                                <p>{{ "team_members.owner" | translate }}</p>
                            </div>
                        </div>
                    </cell>
                    <cell [ngStyle]="{ width: tableHeaderOptions[4]?.width, 'text-align': 'left' }">
                        <p>{{ getLastActiveLabel(member.last_active, member.role) }}</p>
                    </cell>
                    <cell [ngStyle]="{ width: tableHeaderOptions[5]?.width, 'text-align': 'left' }">
                        <div style="display: flex; justify-content: end; width: 100%">
                            <app-aw-dot-popup-button
                                *ngIf="member.role !== 'owner'"
                                [menuOptions]="getMenuOptions(member)"
                                [isLoading]="dotButtonLoadingIndex === i"
                                (clickedOption)="catchDotMenuClicked($event, member, i, editMemberModal)"
                                (dropdownOpened)="onDropdownOpened($event)"
                                (componentInitialized)="dropdownComponents.push($event)"
                            />
                        </div>
                    </cell>
                </row>
            </ng-container>
        </app-aw-table>
    </ng-container>
</app-page-layout>

<ng-template #inviteMemberModal>
    <app-invite-member-modal (emitTeamMember)="addOrEditEmittedTeamMember($event)"></app-invite-member-modal>
</ng-template>

<ng-template #editMemberModal>
    <app-invite-member-modal [editData]="teamMemberToEdit" (emitDelete)="removeMember($event)" (emitTeamMember)="addOrEditEmittedTeamMember($event)"></app-invite-member-modal>
</ng-template>
