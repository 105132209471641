import { Injectable } from '@angular/core';
import { HttpService, RequestTypes } from '../helper/http.service';
import { ShallowUserRecord } from 'shared_models/auth-user-record'
import {PayoutDetails, PayoutSettings} from 'shared_models/payouts';
@Injectable({
    providedIn: 'root'
})

export class OverviewService {

    sumOfNextPayout: number;

    constructor(
        private http: HttpService,
    ) { }


    async getNextPayoutAmount(): Promise<PayoutDetails> {
        return this.http.dynamicHttp('api_insights/sum_payout', RequestTypes.GET)
    }

    async getPayoutSettings(): Promise<PayoutSettings> {
        return this.http.dynamicHttp(`api_insights/payout_settings`, RequestTypes.GET);
    }

    async fetchUserAsAdmin(): Promise<ShallowUserRecord> {
        return this.http.dynamicHttp('api_account/fetch_user', RequestTypes.GET)
    }

}
