<app-custom-modal>
    <ng-container modal-title>
        <h4>{{ showDeleteTerminal ? ("location.delete_terminal" | translate) : ("location.terminal" | translate) }}</h4>
    </ng-container>
    <ng-container modal-dismiss> </ng-container>
    <ng-container modal-body>
        <form [formGroup]="terminalForm" *ngIf="!showDeleteTerminal">
            <div class="input-group-wrapper">
                <label>{{ "location.terminal_serial_num" | translate }}</label>
                <input disabled class="form-control" type="text" [value]="terminal.serial_number" />
            </div>

            <div class="input-group-wrapper">
                <label>{{ "location.default_lang" | translate }}</label>
                <select [hidden]="!(subCustomerAllowed && authService.hasLimitedAccess('edit_terminal', role$ | async))" class="form-select" formControlName="default_language">
                    <option [ngValue]="item.key" *ngFor="let item of languages | keyvalue">{{ item.value }}</option>
                </select>

                <input
                    *ngIf="!subCustomerAllowed || !authService.hasLimitedAccess('edit_terminal', role$ | async)"
                    disabled
                    class="form-control"
                    type="text"
                    [value]="selectedTerminalLanguage"
                />

                <div *ngIf="createTerminalSubmitted && default_language.invalid">
                    <div class="error-msg" *ngIf="default_language.errors.required">
                        {{ "location.default_lang_req" | translate }}
                    </div>
                </div>
            </div>

            <!-- booking on anton -->
            <div class="input-group-wrapper">
                <div>
                    <app-custom-tool-tip
                        class="fw-bold"
                        forInput="true"
                        label="{{ 'location.booking' | translate }}"
                        [style]="{ 'white-space': 'pre-line' }"
                        description="{{ 'location.booking_description' | translate }}"
                    ></app-custom-tool-tip>
                </div>
                <div class="booking-fixer">
                    <app-aw-switch [controlName]="booking_enabled" [shouldBeChecked]="booking_enabled.value" (activateClick)="toggleBookingEnabled($event)"> </app-aw-switch>
                    <span class="booking-enabled-subtext text-box-fs">{{ "location.enable_booking_on_terminal" | translate }}</span>
                </div>
            </div>
        </form>
        <div *ngIf="showDeleteTerminal">
            <div class="modal-padding-wrapper">
                <p>{{ "location.confirm_terminal_delete" | translate }}</p>
            </div>
        </div>
    </ng-container>
    <ng-container modal-footer>
        <div class="terminal-buttons">
            <app-animated-button>
                <div class="animated-button-btns-wrapper">
                    <button class="btn btn-secondary btn-tiny hasLoadingCapability" [disabled]="restartTerminalLoading" (click)="restartTerminal()">
                        {{ restartTerminalLoading ? "" : ("location.reboot_terminal" | translate) }}
                        <loading-indicator *ngIf="restartTerminalLoading" [size]="'small'" [color]="'white'"></loading-indicator>
                    </button>
                    <button
                        *ngIf="subCustomerAllowed && authService.hasLimitedAccess('edit_terminal', role$ | async)"
                        class="btn btn-secondary btn-tiny hasLoadingCapability"
                        [disabled]="deletingTerminalLoading"
                        (click)="deleteTerminal()"
                    >
                        {{ deletingTerminalLoading ? "" : ("location.delete_terminal" | translate) }}
                        <loading-indicator *ngIf="deletingTerminalLoading" [size]="'small'" [color]="'white'"></loading-indicator>
                    </button>
                </div>
            </app-animated-button>
            <button
                class="btn btn-primary btn-tiny save-changes-btn"
                [disabled]="updatingTerminal || !authService.hasLimitedAccess('edit_terminal', role$ | async)"
                (click)="updateTerminal()"
            >
                {{ updatingTerminal ? "" : ("location.save_changes" | translate) }}
                <loading-indicator *ngIf="updatingTerminal" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
        </div>

        <div *ngIf="showDeleteTerminal">
            <button class="btn btn-secondary mb-3 mt-3" style="width: 100%" [disabled]="deletingTerminalLoading" (click)="deleteTerminal()">
                {{ deletingTerminalLoading ? "" : ("location.delete_terminal" | translate) }}
                <loading-indicator *ngIf="deletingTerminalLoading" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
        </div>
    </ng-container>
</app-custom-modal>
