import { Component, Input, OnInit } from '@angular/core';
import { DetailedContract } from "../../../../../../shared_models/operator/contracts";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { HelperService } from "../../../../services/helper/helper.service";
import moment from "moment/moment";
import { NgFor, NgIf, KeyValuePipe } from '@angular/common';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';

@Component({
    selector: 'app-specific-contract',
    templateUrl: './specific-contract.component.html',
    styleUrls: ['./specific-contract.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, NgFor, NgIf, KeyValuePipe, TranslateModule]
})
export class SpecificContractComponent implements OnInit {

    @Input() contract: DetailedContract;
    constructor(
        public modalService: NgbModal,
        public translate: TranslateService,
        public helperService: HelperService
    ) { }

    ngOnInit(): void {
    }

    round(num: number | null): string {
        return num ? String(`${this.helperService.roundToTwoDecimals(num * 100)}%`) : '0.00%';
    }

    formatCurrency(value: number | null, currency: string) {
        if (isNaN(value)) {
            return '-'
        }
        return this.helperService.localizeNumberWithCurrency(value ? value / 100 : 0, 2, 2, currency);
    }

    getLocalTime(timestamp: number): string {
        return moment.tz(moment(timestamp, 'X'), 'UTC').tz(moment.tz.guess(false)).format('L').replace(/([.])/g, '/');
    }

    calculateDateProgress(start_date: number, end_date: number): number {
        const timespan = end_date - start_date
        const today = moment().unix()
        const progress = (1 - (end_date - today) / timespan) * 100
        return progress > 100 ? 100 : (progress < 3 ? 5 : progress)
    }

    isExpiring(timestamp: number): string {
        if (moment().add(6, 'month').unix() > timestamp) {
            return 'progressbar-inner-red'
        } else if (moment().add(12, 'month').unix() > timestamp) {
            return 'progressbar-inner-orange'
        } else {
            return 'progressbar-inner-green'
        }
    }
}