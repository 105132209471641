import { Injectable } from '@angular/core';
import { Billing, BillingDevice, DevicesToSubscribe } from 'shared_models/billing/billing';
import { AwSubscription, CardSubObject } from 'shared_models/billing/subscription';
import { AngularFireDatabase, AngularFireObject, SnapshotAction } from '@angular/fire/compat/database';
import { Subscription } from 'rxjs';
import { StripeRegions } from 'shared_models/stripe';
import { VatZoneOne, VatZoneThree, VatZoneTwo } from 'shared_models/vat-zones';
import { SelectOption } from '../../../../shared_models/aw-components/filterOption';
import { HttpService, RequestTypes } from '@services/helper/http.service';

@Injectable({ providedIn: 'root' })
export class BillingService {
    constructor(
        private db: AngularFireDatabase,
        private http: HttpService
    ) {}

    // * * * * * * * * * * * * * * *
    // * * * * FIREBASE SDK  * * * *
    // * * * * * * * * * * * * * * *
    updateBillingWithEanDetails(
        uid: string,
        eanDetails: {
            ean_number: string;
            economic_contact_name: string;
        }
    ): Promise<void> {
        return this.db.list(`customers/${uid}`).update('billing', { ...eanDetails });
    }

    async readBilling(uid: string): Promise<Billing> {
        return new Promise((resolve, reject) => {
            const billing_sub: Subscription = this.db
                .object(`customers/${uid}/billing`)
                .snapshotChanges()
                .subscribe((billing_snap: SnapshotAction<Billing>) => {
                    billing_sub.unsubscribe();
                    const billing: Billing = billing_snap.payload.val();
                    return resolve(billing);
                });
        });
    }

    readHasDiscounts(uid: string): AngularFireObject<any> {
        return this.db.object(`billing/customers/${uid}/discounts`);
    }

    readPrices(stripeRegion: StripeRegions, country: string): AngularFireObject<any> {
        // prices (from products) is stored in db from a Stripe webhook that stores on create and update.
        return this.db.object(`life_subscription_prices/${stripeRegion}/${country}`);
    }

    setStripeCustomerId(uid: string, customerId: string): Promise<void> {
        return this.db.list(`customers/${uid}/billing`).set('stripe_customer_id', customerId);
    }

    // * * * * * * * * * * * * * * *
    // * * * * * * HTTPS * * * * * *
    // * * * * * * * * * * * * * * *
    async getSelectBoxValues(uid?: string): Promise<Record<string, SelectOption[]>> {
        return this.http.dynamicHttp(`api_billing/select_box_values/${uid ? uid : ''}`, RequestTypes.GET);
    }

    async getCard(): Promise<{ paymentMethod: any; clientSecret: string }> {
        return this.http.dynamicHttp(`api_billing/card`, RequestTypes.GET);
    }

    async getClientSecret(): Promise<string> {
        return this.http.dynamicHttp(`api_billing/card/client_secret`, RequestTypes.GET);
    }

    async updateCard(paymentMethodId: string): Promise<{ paymentMethod: any; clientSecret: string }> {
        return this.http.dynamicHttp(`api_billing/card`, RequestTypes.POST, { body: { paymentMethodId } });
    }

    async validateEan(eanNumber: string, fingerprint: string): Promise<{ isValid: boolean; fingerprint: string }> {
        return this.http.dynamicHttp(`api_billing/validate_ean`, RequestTypes.GET, { params: { eanNumber, fingerprint } });
    }

    //New function to create a new subscription
    async createDeviceSubscription(devices: DevicesToSubscribe[], subscriptionData: AwSubscription): Promise<any> {
        return this.http.dynamicHttp(`api_billing/`, RequestTypes.POST, { body: { devices, subscriptionData } });
    }

    async resolveCardSub(cardSubObject: CardSubObject, sub: AwSubscription, devices: DevicesToSubscribe[]): Promise<any> {
        return this.http.dynamicHttp(`api_billing/resolve_card_sub`, RequestTypes.POST, { body: { cardSubObject, sub, devices } });
    }

    async changeSubscriptionStatus(device: BillingDevice): Promise<BillingDevice> {
        return this.http.dynamicHttp(`api_billing/status`, RequestTypes.PUT, { body: device });
    }

    async postStripeCustomer(data: any): Promise<any> {
        return this.http.dynamicHttp(`api_billing/stripe_customer`, RequestTypes.POST, { body: data });
    }

    async createCardSubscriptionSession(data: any): Promise<any> {
        return this.http.dynamicHttp(`api_billing/create_card_subscription_session`, RequestTypes.POST, { body: data });
    }

    async getVatZone(): Promise<VatZoneOne | VatZoneTwo | VatZoneThree> {
        return this.http.dynamicHttp(`api_billing/get_vat_zone`, RequestTypes.GET);
    }
}
