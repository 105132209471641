import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerService } from '../../../../services/customer/customer.service';
import { SetInvitedUserInfoParams } from '../../../../../../shared_models/invited-user';
import { environment } from '../../../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { phoneNumberCountryData } from '../../../../constants/phone_number_data';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { HelperService } from 'src/app/services/helper/helper.service';
import { LoadingComponent } from '../../../loading/loading.component';
import { NgIf, NgFor } from '@angular/common';
import { LottieComponent } from 'ngx-lottie';

@Component({
    selector: 'app-create-team-member',
    templateUrl: './create-team-member.component.html',
    styleUrls: ['./create-team-member.component.scss'],
    standalone: true,
    imports: [LottieComponent, NgIf, FormsModule, ReactiveFormsModule, NgFor, LoadingComponent, TranslateModule]
})
export class CreateTeamMemberComponent implements OnInit {
    teamMemberForm: UntypedFormGroup;
    formSubmitted = false;
    loading = false;
    userInfoSet = false;
    formrdy = false;
    gdprPolicy: string = environment.gdprPolicy;
    termsOfUse: string = environment.termsOfUse;
    supportPhone: { phoneNumber: string; telLink: string } = environment.supportPhone;
    teamMemberExists: boolean;
    phoneNumberPicker: { all: Record<string, string>[]; fav: Record<string, string>[] };

    constructor(
        private customerService: CustomerService,
        private route: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private toast: ToastrService,
        private translate: TranslateService,
        private helperService: HelperService
    ) {}

    async ngOnInit(): Promise<void> {
        this.teamMemberExists = await this.customerService.teamMemberExists(encodeURIComponent(this.route.snapshot.paramMap.get('email')));
        if (this.teamMemberExists) {
            this.router.navigate(['sign-in']).then(() => {
                window.location.reload();
            });
        }
        this.phoneNumberPicker = this.getCallCodesAndCountry();
        this.authService.signOut(true); // to secure that there are not two different users signed in, because it messes up the UI in the first opened window, and the navbar is also shown in this set-password page, which it not should be.

        this.teamMemberForm = new FormGroup({
            email: new UntypedFormControl(null, [Validators.required]),
            first_name: new UntypedFormControl(null, [Validators.required]),
            last_name: new UntypedFormControl(null, [Validators.required]),
            phone_number_call_code: new UntypedFormControl(null, [Validators.required]),
            phone_number: new UntypedFormControl(null, [Validators.required]),
            password: new UntypedFormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(255)]),
            repeat_password: new UntypedFormControl(null, [Validators.required]),
            terms: new UntypedFormControl(false, [Validators.requiredTrue]),
            gdpr: new UntypedFormControl(false, [Validators.requiredTrue])
        });
        if (this.route.params) {
            this.email.setValue(this.route.snapshot.paramMap.get('email'));
            this.email.updateValueAndValidity();
            this.email.disable();
        }

        this.onChanges();
        this.formrdy = true;
    }

    get email() {
        return this.teamMemberForm.get('email');
    }
    get first_name() {
        return this.teamMemberForm.get('first_name');
    }
    get last_name() {
        return this.teamMemberForm.get('last_name');
    }
    get phone_number_call_code() {
        return this.teamMemberForm.get('phone_number_call_code');
    }
    get phone_number() {
        return this.teamMemberForm.get('phone_number');
    }
    get password() {
        return this.teamMemberForm.get('password');
    }
    get repeat_password() {
        return this.teamMemberForm.get('repeat_password');
    }
    get terms() {
        return this.teamMemberForm.get('terms');
    }
    get gdpr() {
        return this.teamMemberForm.get('gdpr');
    }

    getCallCodesAndCountry(): {
        all: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[];
        fav: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[];
    } {
        const allList: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[] = [];
        const favList: { callCode: string; country: string; label: string; list: 'all' | 'fav' }[] = [];
        const favCountries: string[] = ['UK', 'BE', 'DK', 'NL', 'NO', 'FI', 'FR', 'ES'];
        for (const item of phoneNumberCountryData) {
            const { callCode, country } = item;
            allList.push({ callCode, country, label: `${callCode} - ${country}`, list: 'all' });

            if (favCountries.includes(item.code)) favList.push({ callCode, country, label: `${callCode} - ${country}`, list: 'fav' });
        }

        // sorting in alphabetical order to easier scan the list in the dropdown
        const res = {
            all: allList.sort((a, b) => (a && b && a.country && b.country ? a.country.localeCompare(b.country, 'en', { sensitivity: 'base' }) : null)),
            fav: favList.sort((a, b) => (a && b && a.country && b.country ? a.country.localeCompare(b.country, 'en', { sensitivity: 'base' }) : null))
        };

        return res;
    }

    // removeOptions function is a fix for safari and firefox that does not accept hiding options in a optgroup tag. Instead we just remove the tags from the DOM.
    removeOptions() {
        const options = Array.from(document.getElementsByClassName('removeOption'));
        if (options.length)
            options.forEach(option => {
                option.remove();
            });
    }

    onChanges(): void {
        this.teamMemberForm.valueChanges.subscribe(val => {
            const repeatPassword: string = val.repeatPassword;
            const password: string = val.password;

            if (password === repeatPassword) {
                this.repeat_password.setErrors(null);
                this.repeat_password.setValidators([Validators.required]);
                this.repeat_password.updateValueAndValidity();
            }

            if (this.formSubmitted && !this.teamMemberForm.get('password').pristine && password && this.helperService.isStrongPassword(password).is_strong) {
                // valid state
                this.teamMemberForm.get('password').setErrors(null);
                this.teamMemberForm.get('password').setValidators([Validators.required, Validators.minLength(12), Validators.maxLength(255)]);
            }
        });
    }

    createCustomer() {
        this.authService.signOut(true);

        this.formSubmitted = true;

        if (this.teamMemberForm.valid) {
            // Validating password
            if (!this.helperService.isStrongPassword(this.password.value).is_strong) {
                this.teamMemberForm.get('password').setErrors({ weakPassword: true });
            } else if (this.password.value !== this.repeat_password.value) {
                // no match!
                this.teamMemberForm.get('repeatPassword').setErrors({ pwNoMatch: true });
            }

            this.formSubmitted = true;
            if (this.teamMemberForm.valid) {
                const invitedUserInfoParams: SetInvitedUserInfoParams = {
                    email: this.route.snapshot.paramMap.get('email'),
                    tokenIdVerification: this.route.snapshot.queryParamMap.get('tokenIdVerification'),
                    ...this.teamMemberForm.value
                };
                invitedUserInfoParams.phone_number_call_code = this.getCallCode(this.phone_number_call_code.value).code;
                this.loading = true;
                this.customerService
                    .createTeamMember(invitedUserInfoParams)
                    .then(() => {
                        this.signIn();
                    })
                    .catch(err => {
                        if (err.error.error === 'Unautherized') {
                            const boldTitle = this.translate.instant('team_members.failed_to_create_team_member');
                            const body = this.translate.instant('team_members.expired_link');
                            this.helperService.defaultHtmlToast(boldTitle, body, 'Error');
                        }
                        this.loading = false;
                    });
            } else {
                this.loading = false;
            }
        }
    }

    getCallCode(label: string | null, code?: string): { label: string; code: string } {
        for (const countryDetails of this.getCallCodesAndCountry().all) {
            if (label === countryDetails.label) {
                return { code: countryDetails.callCode, label: countryDetails.label };
            } else if (!label && code === countryDetails.callCode) {
                return { code: countryDetails.callCode, label: countryDetails.label };
            }
        }
    }

    async signIn() {
        this.authService.signOut(true).then(async () => {
            await this.wait(200);
            this.authService
                .signIn(this.email.value, this.password.value, true)
                .then(() => {
                    this.router.navigate(['/overview']);
                })
                .catch(errorMsg => {
                    this.toast.warning(errorMsg ? errorMsg : this.translate.instant('customers-set-password.login_fail'), this.translate.instant('misc.attention'), {
                        timeOut: 999999
                    });
                    this.loading = false;
                });
        });
    }
    async wait(ms: number): Promise<void> {
        return new Promise<void>(resolve => setTimeout(resolve, ms));
    }
}
