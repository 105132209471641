import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { lastValueFrom } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class ResetPasswordService {

    constructor(
        private http: HttpClient,
    ) {
    }

    async updatePassword(resetKey: string, password: string): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.put(
                `${environment.baseUrl}/api_account/reset_password`,
                { resetKey, password }
            )).then((res: any) => {
                return resolve(res);
            }).catch((err) => {
                return reject(err);
            });
        });
    }

    async sendResetPasswordMail(email: string): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.post(
                `${environment.baseUrl}/api_account/reset_password`,
                { email }
            )).then((res: any) => {
                return resolve(res);
            }).catch((err) => {
                return reject(err);
            });
        });
    }

    async fetchResetPasswordData(key: string): Promise<any> {
        console.log('Loggign if the gets through the service: ', key)
        return new Promise(async (resolve: any, reject: any) => {
            return lastValueFrom(this.http.get(
                `${environment.baseUrl}/api_account/reset_password?key=${key}`
            )).then((res: any) => {
                return resolve(res);
            }).catch((err) => {
                return reject(err);
            });
        });
    }
}