import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SupportService {

    uid: string;
    baseUrl: string = environment.baseUrl

    constructor(
        private http: HttpClient,
    ) { }

    async supportCall(req: any): Promise<any> {
        return new Promise(async (resolve: any, reject: any) => {
            switch (req.action.method) {
                case 'POST':
                    return lastValueFrom(this.http.post(
                        `${environment.baseUrl}/${req.action.endpoint}`,
                        req.data,
                    )).then((res: any) => {
                        return resolve(res);
                    }).catch((err) => {
                        return reject(err);
                    });

                case 'GET':
                    return lastValueFrom(this.http.get(
                        `${environment.baseUrl}/${req.action.endpoint}?data=${JSON.stringify(req.data)}`,
                    )).then((res: any) => {
                        return resolve(res);
                    }).catch((err) => {
                        return reject(err);
                    });

                case 'PUT':
                    const body = req.data;
                    return lastValueFrom(this.http.put(
                        `${environment.baseUrl}/${req.action.endpoint}`,
                        body,
                    )).then((res: any) => {
                        return resolve(res);
                    }).catch((err) => {
                        return reject(err);
                    });

                case 'DELETE':
                    const options = {
                        body: req.data
                    }
                    return lastValueFrom(this.http.delete(
                        `${this.baseUrl}/${req.action.endpoint}`, options)).then(() => {
                            return resolve(true)
                        }).catch((err) => {
                            return reject(err);
                        });

                default:
                    return reject('No http call');
            }
        })
    }
}