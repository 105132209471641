import { Route } from "@angular/router";
import { AuthGuardService } from "../services/auth-guard/auth-guard.service";
import { SearchCategoryResultComponent } from "../components/search/search-category-result/search-category-result.component";

export default [
    {
        path: 'locations',
        component: SearchCategoryResultComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'customers',
        component: SearchCategoryResultComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'devices',
        component: SearchCategoryResultComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'terminals',
        component: SearchCategoryResultComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'contracts',
        component: SearchCategoryResultComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'settlements',
        component: SearchCategoryResultComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'transactions',
        component: SearchCategoryResultComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'users',
        component: SearchCategoryResultComponent,
        canActivate: [AuthGuardService]
    },
] satisfies Route[];