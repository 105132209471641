<div class="box welcome-box" *ngIf="!onboardingComplete">
    <div class="welcome-header-wrapper" *ngIf="isLife">
        <!-- welcome text block -->
        <h1 class="welcome-title">{{'overview.welcome_title' | translate}}</h1>
        <h3 class="welcome-subtitle">{{'overview.welcome_subtitle' | translate}}</h3>
        <li class="welcome-bullets">{{'overview.welcome_create_new' | translate}}</li>
        <li class="welcome-bullets">{{(!noShare ? 'overview.welcome_recieve_payouts' : 'overview.welcome_laundry_no_pay') | translate}}</li>
        <p class="pt-2">{{(!noShare ? 'overview.welcome_activate' : 'overview.welcome_activate_2') | translate}}</p>
        <!-- divider -->
        <hr class="welcome-divider">

        <!-- title -->
        <h2 class="get-started">{{'overview.get_started' | translate}}</h2>
    </div>

    <div class="welcome-header-wrapper" *ngIf="!isLife">
        <!-- welcome text block -->
        <h1 class="welcome-title">{{'overview.welcome_almost_there' | translate}}</h1>
        <p class="welcome-bullets">{{'overview.welcome_users_can_start' | translate}}</p>
        <p class="welcome-bullets">{{'overview.started_collecting_money' | translate}}</p>

        <!-- divider -->
        <hr class="welcome-divider">

    </div>

    <div class="welcome-steps">
        <!-- STEP 1 -->
        <div class="step-item">
            <div class="step-item-upper-part">
                <div class="step-progress">
                    <div [className]="hasCompletedStep1 ? 'step-number done' : 'step-number active'">1</div>
                    <div [className]="hasCompletedStep1 ? 'col-12 step-bar done' : 'col-12 step-bar'"></div>
                </div>
                <div class="step-details">
                    <div [className]="'step-title'">
                        <h3>{{(noShare ? 'overview.account_details' : 'overview.account_information') | translate}}</h3>
                        <img *ngIf="hasCompletedStep1 && noShare" src="../../../assets/icons/check-circle.svg"
                             alt="{{'overview.check_mark' | translate}}">
                    </div>
                    <p class="step-description">
                        {{(noShare ? 'overview.fill_in_remaining_info' : 'overview.which_account_to_send_money') | translate}}
                    </p>
                </div>
            </div>
            <button (click)="onboardingAction('external_account')"
                    [className]="hasCompletedStep1 ? 'step-btn btn btn-primary' : 'step-btn btn btn-secondary'">
                {{hasCompletedStep1 ? ('overview.change' | translate) : ('overview.begin' | translate)}}
            </button>
        </div>

        <!-- STEP 2 -->
        <div class="step-item" *ngIf="!noShare || !isLife">
            <div class="step-item-upper-part">
                <div class="step-progress">
                    <div
                        [className]="shouldCompleteStep2? 'step-number' : (state.capabilities === 'active' ? 'step-number done' : 'step-number inactive')">
                        2</div>
                    <div *ngIf="isLife && subCustomerPermissions?.allow_billing"
                         [className]="shouldCompleteStep2 ? 'col-12 step-bar active' : (state.capabilities === 'active' ? 'col-12 step-bar done' : 'col-12 step-bar')">
                    </div>
                </div>
                <div class="step-details">
                    <div
                        [className]="shouldCompleteStep2 ? 'step-title' : (state.capabilities === 'active' ? 'step-title' : 'step-title inactive')">
                        <h3>{{'overview.stripe_kyc' | translate}}</h3>
                        <img *ngIf="state.capabilities === 'active'" src="../../../assets/icons/check-circle.svg"
                             alt="{{'overview.check_mark' | translate}}">
                    </div>
                    <p
                        [className]="shouldCompleteStep2 ? 'step-description' : (state.capabilities === 'active' ? 'step-description' : 'step-description inactive')">
                        {{'overview.stripe_requires_info' | translate}}
                    </p>
                </div>
            </div>
            <div class="step-item-lower-part">
                <button (click)="onboardingAction('capabilities')"
                        [disabled]="showSmallLoadingIndicator || state.capabilities === 'pending' || !state.external_account || !authService.hasLimitedAccess('edit_kyc', (role$ | async))"
                        [className]="shouldCompleteStep2 && state.capabilities !== 'pending' ? 'step-btn btn btn-secondary' : state.capabilities === 'pending' ? 'step-btn btn btn-secondary inactive' : (state.capabilities === 'active' ? 'step-btn btn btn-primary' : 'step-btn btn btn-secondary inactive')">
                    {{ showSmallLoadingIndicator
                    ? ''
                    :state.capabilities === 'active'
                        ? ('overview.change' | translate)
                        : state.capabilities === 'missing_document'
                            ? ('overview.provide' | translate)
                            : state.capabilities === 'pending'
                                ? ('overview.pending' | translate)
                                : ('overview.begin' | translate)
                    }}
                    <loading-indicator *ngIf="showSmallLoadingIndicator" [size]="'large'"
                                       [color]="'white'"></loading-indicator>
                </button>
                <div class="step-notifications mt-2">
                    <div *ngIf="state.capabilities === 'missing_document'">
                        <img src="../../../assets/icons/missing.svg"
                             alt="{{'overview.missing_details' | translate}}">
                        <p class="missing">{{'overview.missing_details' | translate}}</p>
                    </div>
                    <div *ngIf="state.capabilities === 'pending'">
                        <img src="../../../assets/icons/pending.svg" alt="Pending verification of provided details">
                        <p>{{'overview.waiting_verification' | translate}}</p>
                    </div>
                </div>
            </div>
        </div>

        <!-- STEP 3 -->
        <div class="step-item" *ngIf="!isLife  && !subCustomerPermissions?.allow_billing"></div>
        <div class="step-item" *ngIf="isLife && subCustomerPermissions?.allow_billing">
            <div class="step-item-upper-part">
                <div class="step-progress last-item">
                    <div [className]="shouldCompleteStep3 ? 'step-number' : 'step-number inactive'">
                        {{noShare ? '2' : '3'}}
                    </div>
                    <div class="col-12"></div>
                </div>
                <div class="step-details">
                    <div [className]="shouldCompleteStep3 ? 'step-title' : 'step-title inactive'">
                        <h3>{{'misc.billing' | translate}}</h3>
                    </div>
                    <p
                        [className]="shouldCompleteStep3 ? 'step-description' : 'step-description inactive'">
                        {{'overview.activate_airwallet' | translate}}
                    </p>
                </div>
            </div>
            <!--TODO: Fix boolean for disabled -->
            <button (click)="onboardingAction('billing')" [disabled]="!shouldCompleteStep3"
                    [className]="shouldCompleteStep3 ? 'step-btn btn btn-secondary' : 'step-btn btn btn-primary inactive'">{{'overview.begin' | translate}}</button>
        </div>
    </div>
</div>
