import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Contract, DetailedContract } from "shared_models/operator/contracts";
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';

@Injectable({
  providedIn: 'root'
})
export class OperatorService {

  private from$ = new BehaviorSubject<any>({});
  private to$ = new BehaviorSubject<any>({});
  private newContract$ = new BehaviorSubject<DetailedContract | null>(null);
  private maintenance$ = new BehaviorSubject<boolean>(false);
  selectedFrom$ = this.from$.asObservable();
  selectedTo$ = this.to$.asObservable();
  selectedNewContract$ = this.newContract$.asObservable();
  selectedMaintenance$ = this.maintenance$.asObservable();
  constructor(
    private db: AngularFireDatabase,
  ) { }

  setFrom(date: number) {
    this.from$.next(date);
  }
  setTo(date: number) {
    this.to$.next(date);
  }
  setNewContract(newContract: DetailedContract) {
    this.newContract$.next(newContract);
  }

  setMaintenanceValue(value: boolean) {
    this.maintenance$.next(value);
  }

  readContracts(uid: string): AngularFireObject<Record<string, Contract>> {
    return this.db.object(`customers/${uid}/contracts`);
  }
  readLocationName(uid: string, location_id: string): AngularFireObject<string> {
    return this.db.object(`customers/${uid}/locations/${location_id}/name`);
  }
}