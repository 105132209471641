import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import hash from "object-hash";
import randomString from 'random-string';
import { HttpService, RequestTypes } from '../helper/http.service';
import * as yaml from 'js-yaml';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class DeveloperService {

    constructor(
        private db: AngularFireDatabase,
        private http: HttpClient,
        private httpService: HttpService,
    ) { }

    async getApiKey(): Promise<string> {
        return this.httpService.dynamicHttp('api_account/get_api_key', RequestTypes.GET);
    }

    async getSecretApiKeys(): Promise<Record<string, { allowed_paths: Record<string, boolean>; }>> {
        return this.httpService.dynamicHttp('api_account/secret_key', RequestTypes.GET);
    }

    async updateSecretApiKeys(keys: Record<string, { allowed_paths: Record<string, boolean>; }>): Promise<Record<string, { allowed_paths: Record<string, boolean>; }>> {
        return this.httpService.dynamicHttp('api_account/secret_key', RequestTypes.PUT, { body: keys });
    }

    async generateSecretKey(): Promise<{ prefix: string, key: string }> {
        return this.httpService.dynamicHttp('api_account/secret_key', RequestTypes.POST);
    }

    async deleteSecretKey(prefix: string): Promise<string> {
        return this.httpService.dynamicHttp(`api_account/secret_key/${prefix}`, RequestTypes.DELETE);
    }

    async readYAML(url: string): Promise<Record<any, any>> {
        return new Promise((resolve, reject) => {
            this.http.get(url, { responseType: 'text' })
                .subscribe(
                    (yamlString: string) => {
                        try {
                            const yamlObject = yaml.load(yamlString);
                            resolve(yamlObject);
                        } catch (e) {
                            console.error('Error parsing YAML:', e);
                            reject(e);
                        }
                    },
                    error => {
                        console.error('Error reading YAML file:', error);
                        reject(error);
                    }
                );
        });
    }



    /* * * * * * * * * * * * * * * * * * * * * * * * * *
    * * * * * * * * * * * * * * * * * * * * * * * * * *
    * * * * * * * * * Legacy below  * * * * * * * * * *
    * * * * * * * * * * * * * * * * * * * * * * * * * *
    * * * * * * * * * * * * * * * * * * * * * * * * * */
    legacyGeneratePrivateKey(uid: string, hashed_keys: string[]): string {
        const prefixExists = true;
        let prefix: string;
        while (prefixExists) {
            prefix = randomString({
                length: 6,
                numeric: false,
                letters: true,
                special: false,
            }).toUpperCase();
            if (!hashed_keys.includes(prefix)) {
                break;
            }
        }
        const key: string = randomString({ length: 40 })
        const itemsRef = this.db.list(`api_keys/${uid}/keys`);
        itemsRef.set(prefix, hash.keys(key));
        return prefix + "." + key;
    }

    legacyRevokePrivateKey(uid: string, key_prefix: string) {
        const itemRef = this.db.object(`api_keys/${uid}/keys/${key_prefix}`);
        itemRef.remove();
    }

    legacyGeneratePublicKey(uid: string): string {
        const key: string = randomString({
            length: 30,
            numeric: false,
            letters: true,
            special: false,
        })
        this.db.object(`api_keys/${uid}/public_key`).valueChanges();
        this.db.object(`api_keys/${uid}/public_key`).set(hash.keys(key))
        return key;
    }

    readPrivateKeys(uid: string): AngularFireObject<Record<string, string>> {
        return this.db.object(`api_keys/${uid}/keys`);
    }

    readPublicKey(uid: string): AngularFireObject<Record<string, string>> {
        return this.db.object(`api_keys/${uid}/public_key`);
    }


}