import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {AsyncPipe, NgIf} from "@angular/common";
import {CustomToolTipComponent} from "../../misc/custom-tool-tip/custom-tool-tip.component";
import {LoadingComponent} from "../../loading/loading.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {DiscountCouponService} from "../../../services/discount-coupon/discount-coupon.service";
import {DiscountCoupon} from "../../../../../shared_models/billing/discount-coupon";
import {HelperService} from "../../../services/helper/helper.service";
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-discount-tool-manage',
  standalone: true,
    imports: [
        AsyncPipe,
        CustomToolTipComponent,
        LoadingComponent,
        NgIf,
        ReactiveFormsModule,
        TranslateModule,
        FormsModule
    ],
  templateUrl: './discount-tool-manage.component.html',
  styleUrl: './discount-tool-manage.component.scss'
})
export class DiscountToolManageComponent implements OnInit {

    @Input() popupAnchor: 'right' | 'left' = 'right'
    @Input() globalDiscount: number;
    @Input() loadingDevices: boolean;
    @Output() uidEmitter: EventEmitter<string> = new EventEmitter<string>();
    @Output() globalDiscountEmitter: EventEmitter<number> = new EventEmitter<number>();
    sendingGlobalDiscount: boolean = false;

    isExpanded: boolean = false;
    openAccordions: number[] = [];
    selectedUid: string = this.route.snapshot.queryParams['uid'];

    constructor(
        private helperService: HelperService,
        protected translate: TranslateService,
        private elementRef: ElementRef,
        private discountCouponService: DiscountCouponService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        if(!this.selectedUid) {
            this.togglePopdown();
            this.openAccordion(0);
        }
    }

    openAccordion(index: number): void {
        if (this.openAccordions.includes(index)) {
            this.openAccordions = this.openAccordions.filter(item => item !== index);
        } else {
            this.openAccordions.push(index);
        }
    }

    togglePopdown(): void {
        this.isExpanded = !this.isExpanded;
        if (this.isExpanded) {
            this.openAccordions = [];
        }
    }

    emitUid(): void {
        if(this.selectedUid == this.route.snapshot.queryParams['uid']) {
            this.helperService.defaultHtmlToast('', this.translate.instant('discount_tool.already_managing'), 'Info');
            return
        }
        this.loadingDevices = true;
        this.uidEmitter.emit(this.selectedUid);
    }

    @HostListener('document:mousedown', ['$event'])
    clickOut(event: MouseEvent): void {
        if (this.isExpanded && !this.elementRef.nativeElement.contains(event.target)) {
            this.togglePopdown()
        }
    }

    async saveDiscount(): Promise<void> {
        console.log("this.globalDiscount", this.globalDiscount)
        this.sendingGlobalDiscount = true;
        const discountData: DiscountCoupon = {
            key: 'temp',
            created: 1,
            is_global: true,
            percent_off: this.globalDiscount,
        }
        await this.discountCouponService.createDiscountCoupon(this.selectedUid, discountData).then(() => {
            this.globalDiscountEmitter.emit(this.globalDiscount);
            this.helperService.defaultHtmlToast('', this.translate.instant('discount_tool.global_discount_applied'), 'Success')
        }).catch(() => {
            this.helperService.defaultHtmlToast('', this.translate.instant('discount_tool.global_discount_error'), 'Error')
        }).finally(() => {
            this.sendingGlobalDiscount = false;
        });
    }

}
