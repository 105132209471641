import { ShallowAccountDetails } from "./shallow-account-details";
import { SubscriptionModel } from "./subscription";
import { CompanyRegistrationDetails } from "./company-registration-details";
import { SubscriptionProductType } from "../subscription-product-type";
import { DeviceWithDiscount } from "./discount-coupon";

export interface Billing {
    shallow_account_details: ShallowAccountDetails;
    company_registration_details?: CompanyRegistrationDetails;

    // TO-DO: active_subscriptions should be deleted when cleaning old billing data, it is not used anymore.
    active_subscriptions?: Record<string, SubscriptionModel>; // tracks all the running subscriptions a customer have.

    // TO-DO: stripe_customer_id should be required in the future, but for now it is optional because we created it later in the flow and not in the creation of an account.
    // this is a reference for the Stripe customer that is used for card billing, and also to verify VAT number.
    stripe_customer_id?: string; // >>Must never be deleted!<< 

    // TO-DO: economic_customer_number should be required in the future, but for now it is optional because we created it later in the flow and not in the creation of an account.
    // Economic is chosen over Stripe/card. NOTE: this may also be a reference to the account in Economic for accounting reporting when doing yearly reports.
    economic_customer_number?: number; // >>Must never be deleted!<< 

    // EAN related props
    // NOTE: that we can convert the invoice customer to be using EAN by setting a valid (validate before setting it) EAN number on the "ean_number" prop in the "billing/" path and in Economic make a contact person.
    // By setting those two values things up the customer will be converted to EAN the next time they create a subscription (new or renew). The economic_contact_number and economic_contact_name will be set automatically.
    economic_contact_number?: number; // If EAN contact person
    economic_contact_name?: string; // the name of the economic_contact_number
    ean_number?: string; // the ean value as a string
};

export interface DevicesToSubscribe {
    serial: string;
    id: string;
    discount_ref?: string;
}

export interface DeviceWithDiscountRef {
    device_key: string;
    discount_ref: string;
}

export interface BillingDevice {
    created: number,
    customer_name: string,
    device_type: SubscriptionProductType,
    serial_number: string,
    in_use: boolean, // is wether the device is in use or not
    is_checked?: boolean, // only used in the billing table front end
    subcustomer_uid?: string, // is optional because it is only used on subcustomer devices that are billed by operator

    // Are always set, except on !in_use devices.
    device_key?: string,
    device_name?: string,
    location_key?: string,
    location_name?: string,

    // related to discounts
    next_discount_ref?: string,
    current_discount_ref?: string, //Is only used if the device has a discount
    retired_discount_ref?: string, //Is only used for devices that have a discount that is removed/expired
    discount?: number, //Is only used if the device has a discount
    discount_expiration_date?: number, //Is only used if the device has a discount

    // below is related to props on active devices
    period_end?: number,
    period_start?: number,
    billing_ref?: string,
    billed_by?: 'invoice' | 'card', // only used in the billing table FE, to determine menu options
    renewable?: boolean,
    pending_card_payment?: boolean, // only used when creating a card subscription the first time, to make FE know that the device is pending payment
}

export interface BillingDeviceForExport extends BillingDevice {
    uid: string;
}

// Type guard to check if an object is a BillingDevice
export function isBillingDevice(obj: any): obj is BillingDevice {
    return 'created' in obj &&
        'in_use' in obj &&
        'customer_name' in obj &&
        'device_type' in obj &&
        'serial_number' in obj;
}

export interface DeviceWithHoverIconProperties extends DeviceWithDiscount {
    hoverText: string;
    label: string;
    icon: string;
    startColor: string;
    endColor: string;
}