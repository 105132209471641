import 'firebase/compat/functions';
import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, Optional, NgModule } from '@angular/core';
import * as i1 from '@angular/fire';
import { VERSION } from '@angular/fire';
import * as i2 from '@angular/fire/app-check';
import { ɵfirebaseAppFactory as _firebaseAppFactory, ɵcacheInstance as _cacheInstance, ɵlazySDKProxy as _lazySDKProxy, FIREBASE_OPTIONS, FIREBASE_APP_NAME, ɵapplyMixins as _applyMixins } from '@angular/fire/compat';
import { of, from } from 'rxjs';
import { observeOn, switchMap, map, shareReplay } from 'rxjs/operators';
import firebase from 'firebase/compat/app';

// DO NOT MODIFY, this file is autogenerated by tools/build.ts
// Export a null object with the same keys as firebase/compat/functions, so Proxy can work with proxy-polyfill in Internet Explorer
const proxyPolyfillCompat = {
  useEmulator: null,
  useFunctionsEmulator: null,
  httpsCallable: null
};
const ORIGIN = new InjectionToken('angularfire2.functions.origin');
const REGION = new InjectionToken('angularfire2.functions.region');
const USE_EMULATOR = new InjectionToken('angularfire2.functions.use-emulator');
class AngularFireFunctions {
  httpsCallable;
  constructor(options, name, zone, schedulers, region, origin, _useEmulator,
  // can't use the tuple here
  _appCheckInstances) {
    const useEmulator = _useEmulator;
    const functions = of(undefined).pipe(observeOn(schedulers.outsideAngular), switchMap(() => import('firebase/compat/functions')), map(() => _firebaseAppFactory(options, zone, name)), map(app => _cacheInstance(`${app.name}.functions.${region || origin}`, 'AngularFireFunctions', app.name, () => {
      let functions;
      if (region && origin) {
        throw new Error('REGION and ORIGIN can\'t be used at the same time.');
      }
      functions = app.functions(region || origin || undefined);
      if (useEmulator) {
        functions.useEmulator(...useEmulator);
      }
      return functions;
    }, [region, origin, useEmulator])), shareReplay({
      bufferSize: 1,
      refCount: false
    }));
    this.httpsCallable = (name, options) => data => from(functions).pipe(observeOn(schedulers.insideAngular), switchMap(functions => functions.httpsCallable(name, options)(data)), map(r => r.data));
    return _lazySDKProxy(this, functions, zone);
  }
  static ɵfac = function AngularFireFunctions_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AngularFireFunctions)(i0.ɵɵinject(FIREBASE_OPTIONS), i0.ɵɵinject(FIREBASE_APP_NAME, 8), i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.ɵAngularFireSchedulers), i0.ɵɵinject(REGION, 8), i0.ɵɵinject(ORIGIN, 8), i0.ɵɵinject(USE_EMULATOR, 8), i0.ɵɵinject(i2.AppCheckInstances, 8));
  };
  static ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
    token: AngularFireFunctions,
    factory: AngularFireFunctions.ɵfac,
    providedIn: 'any'
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularFireFunctions, [{
    type: Injectable,
    args: [{
      providedIn: 'any'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [FIREBASE_OPTIONS]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [FIREBASE_APP_NAME]
    }]
  }, {
    type: i0.NgZone
  }, {
    type: i1.ɵAngularFireSchedulers
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [REGION]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [ORIGIN]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [USE_EMULATOR]
    }]
  }, {
    type: i2.AppCheckInstances,
    decorators: [{
      type: Optional
    }]
  }], null);
})();
_applyMixins(AngularFireFunctions, [proxyPolyfillCompat]);
class AngularFireFunctionsModule {
  constructor() {
    firebase.registerVersion('angularfire', VERSION.full, 'fn-compat');
  }
  static ɵfac = function AngularFireFunctionsModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || AngularFireFunctionsModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: AngularFireFunctionsModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    providers: [AngularFireFunctions]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AngularFireFunctionsModule, [{
    type: NgModule,
    args: [{
      providers: [AngularFireFunctions]
    }]
  }], () => [], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AngularFireFunctions, AngularFireFunctionsModule, ORIGIN, REGION, USE_EMULATOR };
