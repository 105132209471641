<app-custom-modal>
    <ng-container modal-title>
        <h4>{{'users.user.add_comment' | translate}}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a (click)="closeModal()"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog"></a>
    </ng-container>
    <ng-container modal-body>
        <div class="modal-body-wrapper">
            <form [formGroup]="commentGroup">
                <div class="textarea-wrapper">
                    <textarea formControlName="comment"
                        [placeholder]="'users.user.type_your_comment_here' | translate"></textarea>
                </div>
            </form>
        </div>
    </ng-container>
    <ng-container modal-footer>
        <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
            <button class="btn btn-secondary mt-3" style="min-width: 100%; width: fit-content;"
                [disabled]="showSmallIndicator" (click)="addComment()">
                {{showSmallIndicator ? '' : 'users.user.add_comment' | translate}}
                <loading-indicator *ngIf="showSmallIndicator" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
        </div>
    </ng-container>
</app-custom-modal>