import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router, RouterLink, RouterOutlet } from "@angular/router";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { AuthService } from "../../services/auth/auth.service";
import { Observable } from 'rxjs';
import * as Claims from 'shared_models/claims'
import { FilterBarComponent } from './filter-bar/filter-bar.component';
import { NgIf, AsyncPipe, NgStyle} from '@angular/common';


@Component({
    selector: 'app-operator',
    templateUrl: './operator.component.html',
    styleUrls: ['./operator.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        RouterLink,
        FilterBarComponent,
        RouterOutlet,
        AsyncPipe,
        TranslateModule,
        NgStyle
    ],
})
export class OperatorComponent implements OnInit {

    role$: Observable<Claims.Roles>
    public view = ''
    public pageTitle: string;
    public from_date: number;
    public to_date: number;
    public showOnlyMaintenance = false;

    private dateForm: UntypedFormGroup;
    private subMenuStrings: string[] = [];

    isMobile: boolean;

    constructor(
        formBuilder: UntypedFormBuilder,
        public authService: AuthService, // used in html
        private router: Router,
        private translate: TranslateService,
        private breakpointObserver: BreakpointObserver
    ) {
        this.breakpointObserver.observe([
            "(max-width: 768px)"
        ]).subscribe((result: BreakpointState) => {
            this.isMobile = result.matches;
        });
        this.dateForm = formBuilder.group({
            start_date: new UntypedFormControl(null),
            end_date: new UntypedFormControl(null)
        })
    }

    async ngOnInit(): Promise<void> {
        this.role$ = this.authService.getRole;
        const route = this.router.url.split('/').pop().split('?')[0]
        this.changeRouteView(route)
        //Used for handling browser backbutton
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                const route = event.url.split('/').pop().split('?')[0]
                this.changeRouteView(route)
            }
        })
    }

    changeRouteView(route: string): void {
        switch (route) {
            case 'customers':
                this.view = 'Customers';
                this.pageTitle = this.translate.instant('customers.customers');
                break;
            case 'contracts':
                this.view = 'Contracts';
                this.pageTitle = this.translate.instant('operator.filter_bar.contracts');
                break;
            case 'actions':
                this.view = 'Actions';
                this.pageTitle = this.translate.instant('operator.filter_bar.actions');
                break;
            case 'devices':
                this.view = 'Devices';
                this.pageTitle = this.translate.instant('operator.filter_bar.devices');
                break;
            case 'anton-health':
                this.view = 'anton-health';
                this.pageTitle = this.translate.instant('operator.filter_bar.anton-health');
                break;
        }
    }

    toggle(event) {
        this.view = event.target.id
    }

    getFromDate(date: number) {
        this.from_date = date;
    }
    getToDate(date: number) {
        this.to_date = date
    }

    filterByMaintenance(isActive: boolean) {
        this.showOnlyMaintenance = isActive
    }
}
