import {Component, OnInit} from '@angular/core';
import { AnimationOptions, LottieComponent } from "ngx-lottie";
import { FormGroup, UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import {environment} from "../../../environments/environment";
import {CustomerService} from "../../services/customer/customer.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth/auth.service";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import {HelperService} from "../../services/helper/helper.service";
import {ResetPasswordService} from "../../services/reset-password/reset-password.service";
import {ResetPasswordData} from "../../../../shared_models/reset-password";
import { LoadingComponent } from '../loading/loading.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule, ReactiveFormsModule, LoadingComponent, LottieComponent, TranslateModule]
})
export class ResetPasswordComponent implements OnInit {
    options: AnimationOptions = {
        path: '../../assets/animations/AW_waves.json',
    };
    loading: boolean;
    initLoading: boolean;
    setNewPasswordDone: boolean;
    formSubmitted: boolean;
    setNewPasswordForm: FormGroup;
    supportPhone: {
        phoneNumber: string,
        telLink: string
    }
    email: string;
    uid: string;
    expirationTime: number;
    uniqKey: string;
    resetData: ResetPasswordData;
    invalidKey: boolean;

    constructor(
        private customerService: CustomerService,
        private resetPasswordService: ResetPasswordService,
        private formBuilder: UntypedFormBuilder,
        private route: ActivatedRoute,
        private toast: ToastrService,
        private router: Router,
        private authService: AuthService,
        private translate: TranslateService,
        private helperService: HelperService,
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.initLoading = true;
        this.setNewPasswordForm = this.formBuilder.group({
            password: [null, [Validators.required, Validators.maxLength(255)]],
            repeatPassword: [null, Validators.required],
        });
        this.supportPhone = environment.supportPhone;
        this.uniqKey = this.route.snapshot.paramMap.get('key')

        await this.authService.signOut(true); // to secure that there are not two different users signed in, because it messes up the UI in the first opened window, and the navbar is also shown in this set-password page, which it not should be.
        await this.fetchResetPasswordData()

        this.initLoading = false;
        this.onChanges();
    }

    onChanges(): void {
        this.setNewPasswordForm.valueChanges.subscribe(val => {
            const repeatPassword: string = val.repeatPassword
            const password: string = val.password

            if (password === repeatPassword && !this.setNewPasswordForm.pristine) {
                // valid state
                this.setNewPasswordForm.get('repeatPassword').setErrors(null);
                this.setNewPasswordForm.get('repeatPassword').setValidators([Validators.required]);
            }

            if (this.formSubmitted && !this.setNewPasswordForm.get('password').pristine && this.helperService.isStrongPassword(password).is_strong) {
                // valid state
                this.setNewPasswordForm.get('password').setErrors(null);
                this.setNewPasswordForm.get('password').setValidators([Validators.required, Validators.minLength(12), Validators.maxLength(255)]);
            }
        })
    }

    async setNewPassword() {
        this.formSubmitted = true

        // Validating password
        if (
            !this.helperService.isStrongPassword(this.password.value).is_strong
        ) {
            this.setNewPasswordForm.get('password').setErrors({'weakPassword': true})
        } else if (
            this.password.value !== this.repeatPassword.value
        ) {
            // no match!
            this.setNewPasswordForm.get('repeatPassword').setErrors({'pwNoMatch': true});
        }
        await this.fetchResetPasswordData() //Double-checking that the data still exists and the link is not too old

        if (this.resetData) {
            if (this.setNewPasswordForm.valid) {
                this.loading = true;
                this.resetPasswordService.updatePassword(this.uniqKey, this.password.value).then((res) => {
                    this.loading = false;
                    this.setNewPasswordDone = true;
                    this.toast.success(this.translate.instant("customers-set-password.password_succes"), this.translate.instant("misc.success"));
                }).catch((err) => {
                    const {error, status} = err;
                    console.log(error.error);
                    this.toast.warning(this.translate.instant("customers-set-password.password_error"), this.translate.instant("misc.attention"));

                    this.loading = false;
                });
            }
        }
    }

    async fetchResetPasswordData() {
        this.resetData = await this.resetPasswordService.fetchResetPasswordData(this.uniqKey) //Fetching the data needed to reset the password from the unique key in the URL
        if (this.resetData) {
            this.email = this.resetData.email
            this.expirationTime = this.resetData.expiration_time
            this.uid = this.resetData.uid
        } else {
            this.invalidKey = true //Invalidating the link if no data is found for the key
        }
    }

    async signIn() {
        this.loading = true
        await this.authService.signIn(this.email, this.password.value)
        this.loading = false
    }

    get password() {
        return this.setNewPasswordForm.get('password');
    }

    get repeatPassword() {
        return this.setNewPasswordForm.get('repeatPassword');
    }

}
