/**
 * Galician translation
 */
export default {
  gl: {
    days: ["Domingo", "Luns", "Martes", "Mércores", "Xoves", "Venres", "Sábado"],
    daysShort: ["Dom", "Lun", "Mar", "Mér", "Xov", "Ven", "Sáb"],
    daysMin: ["Do", "Lu", "Ma", "Me", "Xo", "Ve", "Sa"],
    months: ["Xaneiro", "Febreiro", "Marzo", "Abril", "Maio", "Xuño", "Xullo", "Agosto", "Setembro", "Outubro", "Novembro", "Decembro"],
    monthsShort: ["Xan", "Feb", "Mar", "Abr", "Mai", "Xun", "Xul", "Ago", "Sep", "Out", "Nov", "Dec"],
    today: "Hoxe",
    clear: "Limpar",
    weekStart: 1,
    format: "dd/mm/yyyy"
  }
};