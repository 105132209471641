import { PriceUnit } from '../device';

export interface Device {
    device_name: string,
    device_key: string,
    location_name: string,
    location_key: string,
    customer_name: string,
    customer_uid: string,
    contract_id?: string,
    contract_key?: string,
    revenue: number,
    price?: number | null,
    price_unit: PriceUnit,
    currency: string,
    last_used: number,
    starts_day: number,
    pulse_increment?: number | null,
    maintenance?: boolean,
}

export enum sortParams {
    device_name = 'device_name', // on contract
    location_name = 'location_name',
    customer_name = 'customer_name',
    contract_id = 'contract_id',
    revenue = 'revenue',
    price = 'price',
    last_used = 'last_used',
    starts_day = 'starts_day',
}
