/**
 * Swahili translation for bootstrap-datepicker
 * Edwin Mugendi <https: //github.com/edwinmugendi>
 * Source: http: //scriptsource.org/cms/scripts/page.php?item_id=entry_detail&uid=xnfaqyzcku
 */
export default {
  sw: {
    days: ["Jumapili", "Jumatatu", "Jumanne", "Jumatano", "Alhamisi", "Ijumaa", "Jumamosi"],
    daysShort: ["J2", "J3", "J4", "J5", "Alh", "Ij", "J1"],
    daysMin: ["2", "3", "4", "5", "A", "I", "1"],
    months: ["Januari", "Februari", "Machi", "Aprili", "Mei", "Juni", "Julai", "Agosti", "Septemba", "Oktoba", "Novemba", "Desemba"],
    monthsShort: ["Jan", "Feb", "Mac", "Apr", "Mei", "Jun", "Jul", "Ago", "Sep", "Okt", "Nov", "Des"],
    today: "Leo"
  }
};