import { Component, OnDestroy, OnInit } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'; // If using Firebase auth
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { AuthService } from './services/auth/auth.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { HelperService } from './services/helper/helper.service';
import { MaintenanceService } from './maintenance.service';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { Crisp } from 'crisp-sdk-web';
import { ChatbotService } from './services/chatbot/chatbot.service';
import { take } from 'rxjs/operators';
import { Language } from './constants/supportedLanguages';
import { NgSelectConfig } from '@ng-select/ng-select';
import dayjs from 'dayjs';
import { RouterOutlet } from '@angular/router';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { locales } from './constants/supportedLanguages';
import * as en from 'dayjs/locale/en';
import * as en_gb from 'dayjs/locale/en-gb';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [NgIf, NavbarComponent, RouterOutlet, AsyncPipe]
})
export class AppComponent implements OnDestroy, OnInit {
    maintenanceSub: Subscription;
    isSignedIn$: Observable<boolean> = this.authService.isSignedIn;
    refreshWindowMaintain() {
        window.location.href = `${window.location.href}${window.location.href.includes('?') ? '&' : '?'}updated=${moment().unix()}`;
    }

    constructor(
        private authService: AuthService,
        private localStorageService: LocalStorageService,
        private helperService: HelperService,
        private maintenanceService: MaintenanceService,
        private chatbotService: ChatbotService,
        private config: NgSelectConfig,
        private translate: TranslateService
    ) {
        // below is translations set in the config for ng-select to use in the entire app
        this.config.notFoundText = this.translate.instant('ng_select.not_found');
        this.config.typeToSearchText = this.translate.instant('ng_select.type_to_search');
        this.config.addTagText = this.translate.instant('ng_select.add_item');
        this.config.loadingText = this.translate.instant('ng_select.loading');
        this.config.clearAllText = this.translate.instant('ng_select.clear_all');

        firebase.auth().onIdTokenChanged(async user => {
            if (!user) {
                if (!this.helperService.unguardedUrl(window.location.pathname)) {
                    await this.authService.signOut();
                }
            }
        });

        this.maintenanceSub = this.helperService
            .getMaintenanceState()
            .snapshotChanges()
            .subscribe(state => {
                if (state.payload.val() > moment().unix()) {
                    if (this.helperService.getUser(true)) {
                        this.authService.signOut(true);
                        window.location.href = `sign-in?updated=${moment().unix()}`;
                    }
                    if (parseInt(window.location.search.split('=')[1]) < moment().subtract(10, 'second').unix()) {
                        this.maintenanceService.setIsUnderMaintenance(true);
                        this.refreshWindowMaintain();
                    }
                    this.maintenanceService.setIsUnderMaintenance(true);
                    this.maintenanceService.setBackAt(`${moment(state.payload.val(), 'X').format('LLLL')}`);
                } else {
                    if (parseInt(window.location.search.split('=')[1]) < moment().subtract(10, 'second').unix()) {
                        this.maintenanceService.setIsUnderMaintenance(false);
                        this.refreshWindowMaintain();
                    }
                    this.maintenanceService.setIsUnderMaintenance(false);
                }
            });
    }

    async ngOnInit() {
        Crisp.configure('6a5a5077-f61b-4a5f-9fd9-4f514ab13ecf', {
            cookieExpire: 3600 * 24,
            locale: this.localStorageService.getItem('language') ? this.localStorageService.getItem('language').language_code : 'en'
        });
        Crisp.setZIndex(999999999999999); // to make sure it's on top of everything
        Crisp.session.setSegments(['dashboard'], true);
        Crisp.session.onLoaded(async () => {
            if (await lastValueFrom(this.isSignedIn$.pipe(take(1)))) {
                this.chatbotService.setupChatBot();
            }
        });
        $crisp.push(['safe', true]);
        if (!window.location.pathname.includes('/sign-in')) {
            this.localStorageService.setItem('callbackUrl', window.location.pathname); // trying to access a guarded page, will remember for redirect after sign in.
        }
        this.isSignedIn$.subscribe(async (val: boolean) => {
            if (val === true) {
                this.chatbotService.setupChatBot();
                await this.authService.onPageRefresh();
            }
        });
        this.setDayjsLocale();
    }

    // Dayjs is getting deprecated, we should switch to something like Date-fns, which works great with typescript
    async setDayjsLocale() {
        let browserLang = navigator.language;

        const lang: Language = this.localStorageService.getItem('language');
        if (lang) {
            browserLang = lang.language_code;
        }

        let LOCALE = browserLang.split('-')[0];
        let end = browserLang.split('-')[1];
        if (navigator.language.substring(0, 2) === 'nb' || navigator.language.substring(0, 2) === 'nn') {
            LOCALE = 'no';
            end = undefined;
        }

        const fullString = (end ? LOCALE + '_' + end : LOCALE).toLocaleLowerCase();
        if (locales[fullString] === undefined) {
            if (browserLang === 'en-US') {
                const localeObject = { ...en };

                dayjs.locale('en-us', localeObject);
            }
        } else {
            // Update Dayjs
            dayjs.updateLocale('en', { ...en_gb });
            dayjs.locale(fullString);
        }
    }

    ngOnDestroy(): void {
        if (this.maintenanceSub) {
            this.maintenanceSub.unsubscribe();
        }
    }
}