/**
 * Malay translation for bootstrap-datepicker
 * Ateman Faiz <noorulfaiz@gmail.com>
 */
export default {
  ms: {
    days: ["Ahad", "Isnin", "Selasa", "Rabu", "Khamis", "Jumaat", "Sabtu"],
    daysShort: ["Aha", "Isn", "Sel", "Rab", "Kha", "Jum", "Sab"],
    daysMin: ["Ah", "Is", "Se", "Ra", "Kh", "Ju", "Sa"],
    months: ["Januari", "Februari", "Mac", "April", "Mei", "Jun", "Julai", "Ogos", "September", "Oktober", "November", "Disember"],
    monthsShort: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Ogo", "Sep", "Okt", "Nov", "Dis"],
    today: "Hari Ini",
    clear: "Bersihkan"
  }
};