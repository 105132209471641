<div class="action-box {{backendLoading ? 'disabled' : ''}}" *ngIf="!initLoading"
    (click)="isMobile ? openModal(actionMobileModal) : null">
    <div class="top-half position-relative">
        <div class="position-absolute d-flex top-half align-items-center justify-content-center"
            style="width: 100%; height: 100%" *ngIf="backendLoading">
            <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
        </div>
        <div class="d-flex top-top-half">
            <p class="path-p pointer">
                <span [style]="!isMobile ? 'text-decoration: underline;' : ''"
                    [routerLink]="!isMobile ? [getPath(action.customer_id)] : null"
                    [queryParams]="{name: action.customer_name}">
                    {{getStringForCustomerOrMyAccount(action.customer_name)}}
                </span>
                <span *ngIf="getStringForCustomerOrMyAccount(action.customer_name) !== ''"><img class="arrow-right"
                        src="assets/icons/arrow-up-blue.svg" alt="arrow icon"></span>
                <span [style]="!isMobile ? 'text-decoration: underline;' : ''"
                    [routerLink]="!isMobile ? [getPath(action.customer_id, action.statics.location_id)] : null"
                    [queryParams]="{name: action.statics.location_name}">{{action.statics.location_name ?
                    action.statics.location_name : ''}}</span>
                <span *ngIf="!!action.statics.device_name"><img class="arrow-right" src="assets/icons/arrow-up-blue.svg"
                        alt="arrow icon"></span>
                <span [style]="!isMobile ? 'text-decoration: underline;' : ''"
                    [routerLink]="!isMobile ? [getPath(action.customer_id, action.statics.location_id, action.statics.device_id)] : null"
                    [queryParams]="{name: action.statics.device_name}">{{action.statics.device_name ?
                    action.statics.device_name : ''}}</span>
            </p>
        </div>
        <ng-container *ngIf="!!reversedActiveLogKeys && !checkLoading">
            <ng-container *ngFor="let logKey of reversedActiveLogKeys">
                <ng-container
                    *ngIf="(action.log[logKey].state !== 'advice' && showAlertAction) || (action.log[logKey].state === 'advice' && showAdviceAction)">
                    <div class="top-bottom-half">
                        <div class="d-flex align-items-center">
                            <p class="action-title">{{getTitle(action.log[logKey], false)}}</p>
                        </div>
                        <div class="d-flex align-items-center">
                            <p class="timestamp me-2" *ngIf="!isMobile">{{getLocaleDate(action.log[logKey].timestamp)}}
                            </p>
                            <div class="state-box {{action.log[logKey].state}}">
                                {{getState(action.log[logKey])}}
                            </div>
                            <div (click)="checkIfActionIsSoapOrMaintenance(logKey) ? openModal(resetMachineServiceModal, logKey) : removeAction(logKey)"
                                class="check-box"
                                *ngIf="!isMobile && authService.hasLimitedAccess('close_action', (role$ | async))">
                                <svg id="Component_13_5" data-name="Component 13 – 5" xmlns="http://www.w3.org/2000/svg"
                                    width="20" height="26" viewBox="0 0 20 26">
                                    <g id="Rectangle_1157" data-name="Rectangle 1157" fill="none" stroke-width="1.5">
                                        <rect width="20" height="26" rx="2" stroke="none" />
                                        <rect x="0.75" y="0.75" width="18.5" height="24.5" rx="1.25" fill="none" />
                                    </g>
                                    <path id="check" data-name="Icon awesome-angle-up"
                                        d="M7.859.172l3.321,3.321a.584.584,0,0,1,0,.828l-.552.552a.584.584,0,0,1-.828,0L7.444,2.521l-5.89,5.89a.584.584,0,0,1-.828,0L.172,7.859a.584.584,0,0,1,0-.828L7.029.174A.584.584,0,0,1,7.859.172Z"
                                        transform="translate(15.788 17.508) rotate(180)" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <p class="timestamp" *ngIf="isMobile">{{getLocaleDate(action.log[logKey].timestamp)}}</p>
                </ng-container>
            </ng-container>
        </ng-container>
        <!--        Shows latest log entry, when no active actions are in list-->
        <ng-container *ngIf="!action.active_actions && !checkLoading">
            <ng-container
                *ngIf="(latestLogValue.state !== 'advice' && showAlertAction) || (latestLogValue.state === 'advice' && showAdviceAction)">
                <div class="top-bottom-half">
                    <div class="d-flex align-items-center">
                        <p class="action-title">{{getTitle(latestLogValue, false)}}</p>
                    </div>
                    <div class="d-flex align-items-center">
                        <p class="timestamp me-2" *ngIf="!isMobile">{{getLocaleDate(latestLogValue.timestamp)}}</p>
                        <div class="state-box closed">
                            {{'operator.actions.states.complete' | translate}}
                        </div>
                        <div (click)="moveActionBackToOpen(latestLogValue)"
                            class="check-box active {{backendLoading ? 'disabled' : ''}}"
                            *ngIf="!isMobile && authService.hasLimitedAccess('close_action', (role$ | async))">
                            <svg id="Component_13_5" data-name="Component 13 – 5" xmlns="http://www.w3.org/2000/svg"
                                width="20" height="26" viewBox="0 0 20 26">
                                <g id="Rectangle_1157" data-name="Rectangle 1157" fill="none" stroke-width="1.5">
                                    <rect width="20" height="26" rx="2" stroke="none" />
                                    <rect x="0.75" y="0.75" width="18.5" height="24.5" rx="1.25" fill="none" />
                                </g>
                                <path id="check" data-name="Icon awesome-angle-up"
                                    d="M7.859.172l3.321,3.321a.584.584,0,0,1,0,.828l-.552.552a.584.584,0,0,1-.828,0L7.444,2.521l-5.89,5.89a.584.584,0,0,1-.828,0L.172,7.859a.584.584,0,0,1,0-.828L7.029.174A.584.584,0,0,1,7.859.172Z"
                                    transform="translate(15.788 17.508) rotate(180)" />
                            </svg>
                        </div>
                    </div>
                </div>
                <p class="timestamp" *ngIf="isMobile">{{getLocaleDate(latestLogValue.timestamp)}}</p>
            </ng-container>
        </ng-container>
        <!--    what is displayed when loading check-->
        <div class="top-bottom-half mb-1" *ngIf="checkLoading">
            <ng-container
                *ngIf="(latestLogValue.state !== 'advice' && showAlertAction) || (latestLogValue.state === 'advice' && showAdviceAction)">
                <div class="d-flex top-half align-items-center justify-content-center"
                    style="width: 100%; height: 30px">
                    <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="bottom-half" *ngIf="!isMobile">
        <div class="d-flex justify-content-end pointer" style="line-height: 17px">
            <p (click)="toggleHistoryExpand()">
                {{'misc.show_details' | translate}}
                <svg *ngIf="toggleHistory" xmlns="http://www.w3.org/2000/svg" width="6.92" height="4.47"
                    viewBox="0 0 11.5 7.426">
                    <path id="Icon_awesome-angle-up" data-name="Icon awesome-angle-up"
                        d="M6.357,10.987l4.886,4.886a.859.859,0,0,1,0,1.218l-.812.812a.859.859,0,0,1-1.218,0l-3.467-3.46L2.283,17.906a.859.859,0,0,1-1.218,0L.25,17.094a.859.859,0,0,1,0-1.218l4.886-4.886a.86.86,0,0,1,1.221,0Z"
                        transform="translate(0.004 -10.734)" fill="#03045E" />
                </svg>
                <svg *ngIf="!toggleHistory" xmlns="http://www.w3.org/2000/svg" width="6.92" height="4.47"
                    viewBox="0 0 11.5 7.426">
                    <path id="Icon_awesome-angle-up" data-name="Icon awesome-angle-up"
                        d="M6.357,10.987l4.886,4.886a.859.859,0,0,1,0,1.218l-.812.812a.859.859,0,0,1-1.218,0l-3.467-3.46L2.283,17.906a.859.859,0,0,1-1.218,0L.25,17.094a.859.859,0,0,1,0-1.218l4.886-4.886a.86.86,0,0,1,1.221,0Z"
                        transform="translate(11.496 18.16) rotate(180)" fill="#03045E" />
                </svg>
            </p>
        </div>
        <div *ngIf="toggleHistory" class="border-top mt-3">
            <ng-container *ngFor="let logKey of reversedActiveLogKeys">
                <p><strong>{{getTitle(action.log[logKey], false)}}:</strong> {{getDescription(action.log[logKey],
                    action)}}</p>
            </ng-container>
            <ng-container *ngIf="!action.active_actions">
                <p><strong>{{getTitle(latestLogValue, false)}}:</strong> {{getDescription(latestLogValue, action)}}</p>
            </ng-container>
            <p class="border-top mt-2" *ngIf="!!reversedLogKeys"><strong>{{'misc.history' | translate}}:</strong></p>
            <ng-container *ngFor="let logKey of reversedLogKeys; let index = index">
                <div class="history-container">
                    <div class="d-flex flex-row">
                        <p class="col-9">{{getTitle(action.log[logKey], true)}}</p>
                        <p class="col-3 right">{{getLocaleDate(action.log[logKey].timestamp)}}</p>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<ng-template #actionMobileModal>
    <app-actions-mobile-modal [action]="action" (emitMoveMobileActionToOpen)="moveMobileActionBackToOpen($event)"
        (emitRemoveMobileAction)="removeMobileAction($event)" (singleEmitter)="handleMobileSingleReloadRequest($event)"
        (multipleEmitter)="handleMobileMultipleReloadRequest($event)"></app-actions-mobile-modal>
</ng-template>

<ng-template #resetMachineServiceModal>
    <app-machine-services-reset-modal [location_id]="action.statics.location_id" [device_id]="action.statics.device_id"
        [customer_id]="action.customer_id"
        [machine_service]="action.log[modalLogKey].action_type === actionType.machineMaintenanceDue ? 'maintenance': 'soap'"
        (closeAction)="handleResetServiceEvent($event)" (closeEntireLocation)="handleResetMultipleServiceEvent($event)">
    </app-machine-services-reset-modal>
</ng-template>