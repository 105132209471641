/**
 * Uzbek cyrillic translation for bootstrap-datepicker
 * Kakhramonov Javlonbek <kakjavlon@gmail.com>
 */
export default {
  'uz-cyrl': {
    days: ["Якшанба", "Душанба", "Сешанба", "Чоршанба", "Пайшанба", "Жума", "Шанба"],
    daysShort: ["Якш", "Ду", "Се", "Чор", "Пай", "Жу", "Ша"],
    daysMin: ["Як", "Ду", "Се", "Чо", "Па", "Жу", "Ша"],
    months: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"],
    monthsShort: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    today: "Бугун",
    clear: "Ўчириш",
    format: "dd.mm.yyyy",
    weekStart: 1,
    monthsTitle: 'Ойлар'
  }
};