<section>
    <div class="input-container" >
        <div class="card-details-wrapper">
            <div style="display: flex; justify-content: space-between">
                <label class='input-label'><strong>{{ 'users.payment_method' | translate }}</strong></label>
                <button class="edit-btn"  [disabled]="loading" (click)="toggleUpdateCard()">Edit</button>
            </div>
            <div *ngIf="initialLoading" style="height: 30.8px; display: flex; justify-content: center; align-items: center">
                <loading-indicator  [size]="'small'" [color]="'secondary'"></loading-indicator>
            </div>
            <div [hidden]="(paymentMethod || initialLoading) && !isUpdate" class="form-control" id="card-element"></div>
            <div *ngIf="paymentMethod && paymentMethod.card && !isUpdate && !initialLoading"
                 style="display: flex; justify-content: flex-start; align-items: center; gap: .5rem; height: 30.8px">
                <div>
                    {{'• • • •' + paymentMethod.card.last4}}
                </div>
                <div style="display: flex; gap: .3125rem; justify-content: flex-end; align-items: center;">
                    <img style="width: 19px; aspect-ratio: 1;"
                         [title]="paymentMethod.card.brand"
                         [src]="'../../../assets/icons/payment-methods/'+ paymentMethod.card.brand + '.svg'">
                </div>
            </div>
        </div>
    </div>
</section>
