import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../../services/auth/auth.service';
import { HelperService } from '../../../services/helper/helper.service';
import { TerminalService } from '../../../services/terminal/terminal.service';
import { TerminalCreateParams } from 'shared_models/create-terminal';
import { DashboardUser } from 'shared_models/dashboard-user';
import { languages } from '../../../constants/languages';
import { Subscription } from 'rxjs';
import { StripeRegions } from 'shared_models/stripe';
import { Unit } from 'shared_models/terminal';
import { LoadingComponent } from '../../loading/loading.component';
import { NgIf, NgFor, KeyValuePipe } from '@angular/common';
import { CustomModalComponent } from '../../misc/custom-modal/custom-modal.component';
import { CustomToolTipComponent } from '../../misc/custom-tool-tip/custom-tool-tip.component';
import { AwSwitchComponent } from '../../misc/aw-switch/aw-switch.component';
@Component({
    selector: 'app-terminal-create-modal',
    templateUrl: './terminal-create-modal.component.html',
    styleUrls: ['./terminal-create-modal.component.scss'],
    standalone: true,
    imports: [AwSwitchComponent, CustomModalComponent, FormsModule, ReactiveFormsModule, NgIf, NgFor, LoadingComponent, KeyValuePipe, TranslateModule, CustomToolTipComponent]
})
export class TerminalCreateModalComponent implements OnInit, OnDestroy {
    @Input() creatingTerminal: boolean;
    @Input() locationId: string;
    @Input() isCustomerOperated: boolean;
    @Output() createTerminalEmitter = new EventEmitter<Unit>();
    terminalForm: UntypedFormGroup;
    createTerminalSubmitted: boolean;
    deletingTerminalLoading: boolean;
    uid: string;
    user: DashboardUser;
    languages: Record<string, string> = languages;
    formSub: Subscription;
    region: StripeRegions;
    eRegion = StripeRegions;

    constructor(
        private terminalService: TerminalService,
        private helperService: HelperService,
        private toast: ToastrService,
        public translate: TranslateService,
        public modalService: NgbModal,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        public authService: AuthService
    ) {}
    ngOnDestroy(): void {
        this.formSub.unsubscribe();
    }

    ngOnInit(): void {
        this.user = this.helperService.getUser();
        this.region = this.user.settings.stripe_region;
        this.uid = this.isCustomerOperated ? `${this.route.snapshot.paramMap.get('sub_customer_id')}_operated_by_${this.user.uid}` : this.user.uid;
        this.setupTerminalForm();
        this.terminalForm.patchValue(
            {
                terminal_serial_number: null,
                default_language: null
            },
            { emitEvent: false }
        );
        this.terminalForm.get('terminal_serial_number').setErrors(null);
        this.terminalForm.get('default_language').setErrors(null);
        this.terminalForm.updateValueAndValidity();
        this.setSubscriptions();
    }

    setupTerminalForm() {
        this.terminalForm = this.fb.group({
            terminal_serial_number: [null, Validators.required],
            default_language: [null, Validators.required],
            booking_enabled: [true, Validators.required]
        });
    }

    setSubscriptions() {
        this.formSub = this.terminalForm.valueChanges.subscribe(val => {
            if (val.terminal_serial_number) {
                this.terminalForm.get('terminal_serial_number').setErrors(null);
                this.terminalForm.get('terminal_serial_number').setValidators([Validators.required]);
                if (!this.testSerialNumber(val.terminal_serial_number).valid) {
                    this.terminalForm.get('terminal_serial_number').setErrors({ custom_validation: true });
                } else {
                    this.terminalForm.get('terminal_serial_number').setErrors(null);
                }
            }
        });
    }

    async createTerminal(): Promise<void> {
        this.createTerminalSubmitted = true;

        if (this.terminal_serial_number.value && this.testSerialNumber(this.terminal_serial_number.value).valid && this.default_language.value) {
            const createTerminalParams: TerminalCreateParams = {
                locationId: this.locationId,
                serial: this.terminal_serial_number.value,
                default_language: this.default_language.value,
                booking_enabled: this.booking_enabled.value,
                uid: this.uid
            };

            this.creatingTerminal = true;

            return this.terminalService
                .createTerminal(this.uid, createTerminalParams)
                .then(value => {
                    this.createTerminalEmitter.next(value);
                    this.creatingTerminal = false;
                    this.toast.success(this.translate.instant('location.terminal_succes'), this.translate.instant('misc.success'));
                    this.modalService.dismissAll();
                    this.createTerminalSubmitted = false;
                })
                .catch(err => {
                    console.log(err);
                    let message: string = this.translate.instant('transactions.unknown');
                    if (err.error) {
                        if (err.error.errorType === 'terminal_serial_already_in_use') {
                            message = this.translate.instant('location.terminal_serial_in_use');
                        }
                    }
                    this.helperService.defaultHtmlToast(this.translate.instant('location.error_terminal'), `${message}`, 'Warning');
                    this.creatingTerminal = false;
                });
        }
        return;
    }

    testSerialNumber(sn: string): { valid: boolean; message?: string } {
        if (!sn) {
            return { valid: false };
        }

        if (!this.validateSn(sn).valid) {
            return this.validateSn(sn);
        }
        return {
            valid: true,
            message: 'ok'
        };
    }

    validateSn(str: string): { valid: boolean; message: string } {
        if (this.region === StripeRegions.EU) {
            if (!str.startsWith('WPC')) {
                return {
                    valid: false,
                    message: this.translate.instant('location.serial_requirements')
                };
            }

            const arr = str.split('WPC');
            const postfix: any = arr[1];

            if (isNaN(postfix)) {
                return {
                    valid: false,
                    message: this.translate.instant('location.wpc_only_digits')
                };
            }

            if (postfix.length !== 12) {
                return {
                    valid: false,
                    message: this.translate.instant('location.wpc_postfix')
                };
            }
        } else {
            if (!str.startsWith('STRM')) {
                return {
                    valid: false,
                    message: this.translate.instant('location.serial_requirements_us')
                };
            }

            const arr = str.split('STRM');
            const postfix: any = arr[1];

            if (isNaN(postfix)) {
                return {
                    valid: false,
                    message: this.translate.instant('location.wpc_only_digits')
                };
            }

            if (postfix.length !== 11) {
                return {
                    valid: false,
                    message: this.translate.instant('location.strm_postfix')
                };
            }
        }

        return { valid: true, message: 'ok' };
    }

    makeUppercase() {
        this.terminal_serial_number.setValue(this.terminal_serial_number.value.toUpperCase());
    }

    toggleBookingEnabled(event: boolean) {
        this.booking_enabled.setValue(!this.booking_enabled.value);
    }
    get booking_enabled() {
        return this.terminalForm.get('booking_enabled');
    }
    get terminal_serial_number() {
        return this.terminalForm.get('terminal_serial_number');
    }
    get default_language() {
        return this.terminalForm.get('default_language');
    }
}
