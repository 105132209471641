<app-custom-modal>
    <ng-container modal-title>
        <h4>{{device.device_name}}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a class="text-end" style="width: 30px" (click)="modalService.dismissAll('Cancel')"><img
                src="../../../assets/icons/modal-close-icon.svg" alt="close dialog"></a>
    </ng-container>
    <div modal-body class="device-body">
        <div class="device-links">
            <div (click)="getLink(device.customer_uid, device.location_key, device.device_key)">
                <p class="bold"><u class="p-0">{{device.device_name}}</u></p>
            </div>
            <div (click)="getLink(device.customer_uid, device.location_key)">
                <p class="bold"><u class="p-0">{{device.location_name}}</u></p>
            </div>
            <div (click)="getLink(device.customer_uid)">
                <p class="bold"><u class="p-0">{{device.customer_name}}</u></p>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <p>{{'operator.contract_modal.contract_id' | translate}}</p>
            <p>{{device.contract_id}}</p>
        </div>
        <div class="d-flex justify-content-between">
            <p>{{'operator.contract_overview.revenue' | translate}}</p>
            <p>{{helperService.formatCurrency(device.revenue, device.currency)}}</p>
        </div>
        <div class="d-flex justify-content-between">
            <p>{{'device.price' | translate}}</p>
            <p>{{helperService.formatCurrency(device.price,
                device.currency)}}{{helperService.getDeviceUnitPriceTranslated(device.price_unit,
                device.pulse_increment)}}</p>
        </div>
        <div class="d-flex justify-content-between">
            <p>{{'misc.last_used' | translate}}</p>
            <p>{{getPeriod(device.last_used)}}</p>
        </div>
        <div class="d-flex justify-content-between">
            <p>{{'operator.contract_overview.starts_day' | translate}}</p>
            <p>{{round(device.starts_day)}}</p>
        </div>
    </div>
    <div modal-footer class="no-footer"></div>
</app-custom-modal>