import { Injectable } from '@angular/core';
import { HttpService, RequestTypes } from '@services/helper/http.service';
import { DisplaySettlement } from '@shared_models/payouts';

@Injectable({
    providedIn: 'root'
})
export class SettlementsService {
    constructor(private httpSerivce: HttpService) {}

    async getSettlements(uid: string): Promise<DisplaySettlement[]> {
        return this.httpSerivce.dynamicHttp(`api_account/settlements`, RequestTypes.GET, { onBehalf: uid });
    }
}
