import { HttpRequest, HttpHandlerFn, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { AuthService } from "../services/auth/auth.service";
import { inject } from '@angular/core';


export function errorInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const authService = inject(AuthService);
  return next(request).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error?.error?.error == "The Firebase ID token has been revoked.") {
        console.log("err")
        authService.signOut();
      }
      return throwError(() => error);
    })
  );
}
