import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { Language } from './constants/supportedLanguages';
import { LocalStorageService } from '@services/local-storage/local-storage.service';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService, localStorageService: LocalStorageService) {
    return () => {
        translate.setDefaultLang('en');
        const lang: Language = localStorageService.getItem('language');

        if (lang) {
            return translate.use(lang.language_code);
        }

        // Overwriting Bokmål "nb" or Nynorsk "nn" to Norsk "no"
        if (window.navigator.language.substring(0, 2) === 'nb' || window.navigator.language.substring(0, 2) === 'nn') return translate.use('no');

        if (environment.locals.includes(window.navigator.language.substring(0, 2))) return translate.use(window.navigator.language.substring(0, 2));

        return translate.use('en');
    };
}
