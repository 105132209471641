import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Order, Reason, RefundParams } from 'shared_models/order';
import { LoggedInDashboardUser, DashboardUser } from 'shared_models/dashboard-user';
import { UserTransaction } from 'shared_models/user-management';
import { TransactionService } from 'src/app/services/transaction/transaction.service';
import * as Search from 'shared_models/search';
import { FormGroup, FormsModule } from '@angular/forms';
import { LoadingComponent } from '../../loading/loading.component';
import { NgIf } from '@angular/common';
import { CustomModalComponent } from '../custom-modal/custom-modal.component';
import { LocalStorageService } from '@services/local-storage/local-storage.service';
import { ControlledUser } from '@shared_models/controlled-user';

type CombinedTransaction = UserTransaction | Search.Transaction;

@Component({
    selector: 'app-refund-modal',
    templateUrl: './refund-modal.component.html',
    styleUrls: ['./refund-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, FormsModule, NgIf, LoadingComponent, TranslateModule]
})
export class RefundModalComponent implements OnInit {
    @Input() transaction: CombinedTransaction;
    @Input() phoneNumberOrUid: string;
    @Input() onBehalf: string;
    @Output() onStateChange: EventEmitter<CombinedTransaction> = new EventEmitter<CombinedTransaction>();
    refundReason: Reason = Reason.requested_by_customer;
    otherReason = '';
    showSmallLoadingIndicator = false;
    transactionIndex: number;
    refundForm: FormGroup;
    constructor(
        public modalService: NgbModal,
        private transactionService: TransactionService,
        private translate: TranslateService,
        private toast: ToastrService,
        private localStorageService: LocalStorageService
    ) {}

    ngOnInit(): void {
        console.log(this.transaction);
    }

    async refund(refundReason: Reason) {
        if (!this.transaction) return;

        if (this.otherReason.length > 255) {
            console.log('Reason too long');

            this.toast.info(this.translate.instant('transactions.reason_too_long'), this.translate.instant('misc.info'));
            return;
        }

        this.showSmallLoadingIndicator = true;
        if (this.transaction.timestamp > parseInt(moment().subtract(180, 'days').format('X'))) {
            const refundParams: RefundParams = {
                paymentId: '',
                refundReason: refundReason,
                otherReason: this.otherReason,
                userName: this.getUserName(),
                phoneNumberOrUid: this.phoneNumberOrUid,
                customerUid: this.onBehalf,
                currency: this.transaction.currency
            };

            refundParams.paymentId = await this.getId(this.transaction);
            refundParams.paymentId === 'TEST' ? (refundParams.testOrder = this.transaction) : null;
            await this.transactionService
                .refund(refundParams)
                .then((res: Order) => {
                    console.log(res);
                    if (res.payment_id.includes('pi_')) {
                        const tran: CombinedTransaction = Object.assign({}, this.transaction);
                        tran.refund = res.refund;
                        tran.refund_status = res.refund_status;
                        this.onStateChange.emit(tran);
                    } else {
                        const tran: CombinedTransaction = Object.assign({}, this.transaction);
                        tran.refund = true;
                        //Needs to be used to get the new value of this transaction.
                        this.onStateChange.emit(tran);
                    }
                })
                .catch((httpResponseError: HttpErrorResponse) => {
                    const { error } = httpResponseError.error;
                    this.toast.info(error, this.translate.instant('misc.info'), { timeOut: 15000 });
                });
            this.showSmallLoadingIndicator = false;
            this.modalService.dismissAll();
        } else {
            this.toast.info(this.translate.instant('transactions.order_too_old'), this.translate.instant('misc.info'));
            this.showSmallLoadingIndicator = false;
        }
    }

    getUserName(): string {
        const controlledUser: ControlledUser | null = this.localStorageService.getItem('controlled_user') as ControlledUser;
        if (controlledUser) {
            return 'Airwallet';
        } else {
            return this.localStorageService.getItem('loggedInUser').name;
        }
    }

    isInstanceOfAppUser(transaction: any): transaction is UserTransaction {
        return (transaction as UserTransaction).payment_id !== undefined;
    }

    isInstanceOfSearch(transaction: any): transaction is Search.Transaction {
        return (transaction as Search.Transaction).id !== undefined;
    }

    getId(transaction: CombinedTransaction) {
        return new Promise<string>((resolve, reject) => {
            if (this.isInstanceOfAppUser(transaction)) {
                resolve((transaction as UserTransaction).payment_id);
            } else if (this.isInstanceOfSearch(transaction)) {
                resolve((transaction as Search.Transaction).id);
            } else {
                reject('Transaction is not of type UserTransaction or Search.Transaction');
            }
        });
    }
}
