<app-custom-modal>
    <ng-container modal-title>
        <h4>ID: {{contract.contract_id}}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a class="text-end" style="width: 30px" (click)="modalService.dismissAll('Cancel')"><img
                src="../../../assets/icons/modal-close-icon.svg" alt="close dialog"></a>
    </ng-container>
    <div modal-body>
        <div>
            <p class="text-end">{{'location.last_thirty' | translate}}</p>
        </div>

        <!--        Location loop-->
        <ng-container *ngFor="let location of contract.locations | keyvalue;">
            <div class="full-width-div">
                <p class="bold">{{location.value.name}}</p>
            </div>
            <div class="d-flex justify-content-between">
                <p>{{'operator.contract_overview.users' | translate}}</p>
                <p>{{location.value.users}}</p>
            </div>
            <div class="d-flex justify-content-between">
                <p>{{'operator.contract_overview.adoption' | translate}}</p>
                <p>{{round(location.value.adoption)}}</p>
            </div>
            <div class="d-flex justify-content-between">
                <p> {{'operator.contract_overview.starts_day' | translate}}</p>
                <p>{{location.value.starts_day}}</p>
            </div>
            <div class="d-flex justify-content-between">
                <p>{{'operator.contract_overview.revenue' | translate}}</p>
                <p>{{formatCurrency(location.value.revenue, contract.currency)}}</p>
            </div>
        </ng-container>

        <!--        Total-->
        <div class="full-width-div">
            <p class="bold">{{'customers.total' | translate}}</p>
        </div>
        <div class="d-flex justify-content-between">
            <p>{{'operator.contract_overview.users' | translate}}</p>
            <p>{{contract.summed_users}}</p>
        </div>
        <div class="d-flex justify-content-between">
            <p>{{'operator.contract_overview.adoption' | translate}}</p>
            <p>{{round(contract.avg_adoption)}}</p>
        </div>
        <div class="d-flex justify-content-between">
            <p> {{'operator.contract_overview.starts_day' | translate}}</p>
            <p>{{contract.summed_starts_day}}</p>
        </div>
        <div class="d-flex justify-content-between">
            <p>{{'operator.contract_modal.revenue_target' | translate}}</p>
            <p>{{formatCurrency(contract.revenue_target, contract.currency)}}</p>
        </div>
        <div class="d-flex justify-content-between">
            <p>{{'operator.contract_overview.revenue' | translate}}</p>
            <p>{{formatCurrency(contract.summed_revenue, contract.currency)}}</p>
        </div>
        <div class="d-flex justify-content-between">
            <p>{{'operator.contract_overview.performance' | translate}}</p>
            <p [style]="contract.performance < 0 ? 'color: #D81E5B;' : 'color: #29E1B4;'">
                <strong>{{round(contract.performance)}}</strong></p>
        </div>
        <div class="d-flex justify-content-between">
            <p>{{'operator.contract_overview.billable_amount' | translate}}</p>
            <p><strong>{{formatCurrency(contract.billable_amount, contract.currency)}}</strong></p>
        </div>
        <div class="d-flex justify-content-between full-width-div" style="background-color: #F1F5F8">
            <p class="bold">{{'misc.customer' | translate}}</p>
            <p>{{contract.customer_name === 'my_account' ?
                ('operator.contract_modal.my_account' | translate) : contract.customer_name}}</p>
        </div>

    </div>
    <ng-container modal-footer>
        <div class="d-flex justify-content-between align-items-center">
            <p>{{contract.start_date ?
                getLocalTime(contract.start_date) : ''}}</p>
            <div class="progressbar-background" *ngIf="contract.start_date && contract.end_date">
                <div class="{{isExpiring(contract.end_date)}}"
                    [style.width.%]="calculateDateProgress(contract.start_date, contract.end_date)">
                </div>
            </div>
            <p>{{contract.end_date ?
                getLocalTime(contract.end_date) : ''}}</p>
        </div>
    </ng-container>
</app-custom-modal>