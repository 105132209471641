import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { HttpService, RequestTypes } from '../../services/helper/http.service';
import { ExportParams, FilterSortParams } from '@shared_models/search-params/FilterSortParams';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
})
export class ExportExcelService {
    constructor(private httpService: HttpService) {}

    async exportTransactionsToExcel(params: FilterSortParams, filename: string): Promise<string> {
        return this.httpService
            .dynamicHttp(`api_export/transactions?params=${JSON.stringify(params)}`, RequestTypes.GET, { onBehalf: params.filter.customer_key })
            .then((res: any) => {
                if (res.content) {
                    const binaryString = window.atob(res.content);
                    const binaryLen = binaryString.length;
                    const bytes = new Uint8Array(binaryLen);
                    for (let i = 0; i < binaryLen; i++) {
                        const ascii = binaryString.charCodeAt(i);
                        bytes[i] = ascii;
                    }
                    const blob = new Blob([bytes], { type: EXCEL_TYPE });
                    const fileName = `${filename}-${moment().format('DD-MM-YYYY_HH.mm.ss')}${EXCEL_EXTENSION}`;
                    saveAs(blob, fileName);
                }
                return res;
            })
            .catch(err => {
                return err;
            });
    }

    async exportTransactionsOnMail(params: ExportParams): Promise<string> {
        return await this.httpService.dynamicHttp(`api_export/transactions?params=${JSON.stringify(params)}`, RequestTypes.GET, { onBehalf: params.filter.customer_key });
    }

    async exportToExcel(path: string, params: string, filename: string): Promise<void> {
        return this.httpService
            .dynamicHttp(path, RequestTypes.GET, { params: { params } })
            .then((res: any) => {
                if (res.content) {
                    const binaryString = window.atob(res.content);
                    const binaryLen = binaryString.length;
                    const bytes = new Uint8Array(binaryLen);
                    for (let i = 0; i < binaryLen; i++) {
                        const ascii = binaryString.charCodeAt(i);
                        bytes[i] = ascii;
                    }
                    const blob = new Blob([bytes], { type: EXCEL_TYPE });
                    const fileName = `${filename}-${moment().format('DD-MM-YYYY_HH.mm.ss')}${EXCEL_EXTENSION}`;
                    saveAs(blob, fileName);
                }
            })
            .catch(err => {
                return err;
            });
    }

    async exportOnMail(path: string, params: string): Promise<string> {
        return await this.httpService.dynamicHttp(path, RequestTypes.GET, { params: { params } });
    }
}
