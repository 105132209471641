import { Component, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { DeleteModalService } from "../../../services/delete-modal/delete-modal.service";
import { LoadingComponent } from '../../loading/loading.component';
import { NgIf } from '@angular/common';
import { CustomModalComponent } from '../custom-modal/custom-modal.component';

@Component({
    selector: 'app-delete-confirm-modal',
    templateUrl: './delete-confirm-modal.component.html',
    styleUrls: ['./delete-confirm-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, NgIf, LoadingComponent]
})
export class DeleteConfirmModalComponent implements OnInit {

    title: string;
    description: string;
    buttonText: string;
    isLoading = false;

    constructor(
        public modalService: NgbModal,
        public deleteModalService: DeleteModalService
    ) {
        this.deleteModalService.selectedIsLoading$.subscribe((isLoading: boolean) => {
            this.isLoading = isLoading;
        })
    }

    ngOnInit(): void {
        this.title = this.deleteModalService.title;
        this.description = this.deleteModalService.description;
        this.buttonText = this.deleteModalService.buttonText;
    }

    confirmDelete() {
        this.deleteModalService.confirmDelete();
    }

}