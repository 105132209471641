import { Component, OnInit } from '@angular/core';
import { PageLayoutComponent } from '../misc/aw-page-layout/page-layout.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AwFilterButtonComponent } from '../misc/aw-filter-button/aw-filter-button.component';
import { NgForOf, NgIf, NgStyle } from '@angular/common';
import { AwTableComponent } from '../misc/aw-table/aw-table.component';
import { FilterOption, FilterType, SelectOption } from '../../../../shared_models/aw-components/filterOption';
import { FilterSortParams, Sort } from '../../../../shared_models/search-params/FilterSortParams';
import { TableHeaderOptions } from '../../../../shared_models/aw-components/tableHeaderOptions';
import { BreakpointObserver } from '@angular/cdk/layout';
import { PageEvent } from '@angular/material/paginator';
import { environment } from '../../../environments/environment';
import { PaginateFetchCacheService } from '../../services/paginate-fetch-cache/paginate-fetch-cache.service';
import { DeviceWithDiscount } from '../../../../shared_models/billing/discount-coupon';
import { HelperService } from '../../services/helper/helper.service';
import _ from 'lodash';
import { PaginatePipe } from '../../pipe/paginate.pipe';
import { DiscountCouponService } from '../../services/discount-coupon/discount-coupon.service';
import { AwCheckboxComponent } from '../misc/aw-checkbox/aw-checkbox.component';
import { BillingDevice, DeviceWithHoverIconProperties } from '../../../../shared_models/billing/billing';
import { DiscountToolManageComponent } from './discount-tool-manage/discount-tool-manage.component';
import { AwHoverIconComponent } from '../misc/aw-hover-icon/aw-hover-icon.component';
import { DeleteModalService } from '../../services/delete-modal/delete-modal.service';
import { DeleteModalOptions } from '../../../../shared_models/deleteModalOptions';
import { BillingService } from '../../services/billing/billing.service';
import { NgbModal, NgbModalModule, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DiscountCreateModalComponent } from './discount-create-modal/discount-create-modal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingComponent } from '../loading/loading.component';
import { CustomToolTipComponent } from '../misc/custom-tool-tip/custom-tool-tip.component';

@Component({
    selector: 'app-discount-tool',
    standalone: true,
    imports: [PageLayoutComponent, TranslateModule, AwFilterButtonComponent, NgIf, AwTableComponent, PaginatePipe, NgForOf, AwCheckboxComponent, NgStyle, DiscountToolManageComponent, AwHoverIconComponent, DiscountCreateModalComponent, LoadingComponent, CustomToolTipComponent],
    templateUrl: './discount-tool.component.html',
    styleUrl: './discount-tool.component.scss'
})
export class DiscountToolComponent implements OnInit {
    isMobile = false;
    discountDevices: DeviceWithHoverIconProperties[] = [];
    selectedUid: string;
    selectedDevices: DeviceWithDiscount[] = [];
    globalDiscount = 0;
    modalRef: NgbModalRef;

    tableHeaderOptions: TableHeaderOptions[] = [
        {
            sortKey: 'serial_number',
            title: this.translate.instant('device.serial_num'),
            width: '15%'
        },
        {
            sortKey: 'state',
            title: this.translate.instant('locations.state'),
            width: '10%'
        },
        {
            sortKey: 'customer_name',
            title: this.translate.instant('misc.customer'),
            width: '15%'
        },
        {
            sortKey: 'location_name',
            title: this.translate.instant('misc.location'),
            width: '15%'
        },
        {
            sortKey: 'device_name',
            title: this.translate.instant('misc.device'),
            width: '15%'
        },
        {
            sortKey: 'discount',
            title: this.translate.instant('billing.discount'),
            width: '15%',
            sortDirection: 'desc'
        },
        {
            sortKey: 'created',
            title: this.translate.instant('customers.created'),
            width: '10%',
            alignment: 'right',
            sortDirection: 'desc'
        },
        {
            sortKey: '',
            title: '',
            width: '5%',
            alignment: 'right'
        }
    ];

    filterOptions: FilterOption[] = [
        {
            key: 'subcustomer_uid',
            type: FilterType.SELECT_MULTIPLE,
            value: null,
            label: 'Customers',
            selectOptions: []
        },
        {
            key: 'state',
            type: FilterType.SELECT,
            value: null,
            label: 'Status',
            selectOptions: [
                {
                    value: 'active',
                    label: 'Active'
                },
                {
                    value: 'inactive',
                    label: 'Inactive'
                }
            ]
        },
        {
            key: 'location_key',
            type: FilterType.SELECT_MULTIPLE,
            value: null,
            label: 'Locations',
            selectOptions: []
        },
        {
            key: 'device_type',
            type: FilterType.SELECT_MULTIPLE,
            value: null,
            label: 'Device types',
            selectOptions: []
        },
        { key: 'created', type: FilterType.DATE_RANGE, value: null, isDateRange: true, label: 'customers.created' }
    ];

    // Loading states
    loadingDevices = false;
    initialLoading = true;

    // Necessary inputs for FE pagination
    pageNumber = 0;
    pageSizeOptions: number[] = [15, 50, 250];
    pageSize: number = this.pageSizeOptions[0];
    totalItems = 0;

    params: FilterSortParams = {
        filter: {},
        sortBy: { key: 'created', order: 'desc' },
        pageSize: this.pageSize,
        pageNumber: this.pageNumber
    };

    deleteModalOptions: DeleteModalOptions = {
        titleTranslationString: 'discount_tool.remove_discount',
        descriptionTranslateString: 'discount_tool.confirm_delete_text',
        buttonTranslationString: 'discount_tool.remove_discount'
    };

    constructor(
        private breakpoint: BreakpointObserver,
        public helperService: HelperService,
        private pfcService: PaginateFetchCacheService<DeviceWithDiscount>,
        protected translate: TranslateService,
        private deleteModalService: DeleteModalService,
        private modalService: NgbModal,
        private billingService: BillingService,
        private route: ActivatedRoute,
        private router: Router,
        private discountCouponService: DiscountCouponService
    ) {
        this.breakpoint.observe(['(max-width: 768px)']).subscribe(result => {
            this.isMobile = result.matches;
        });
    }

    async ngOnInit() {
        this.initialLoading = true;
        const uid = this.route.snapshot.queryParams['uid'];
        if (uid) {
            this.selectedUid = uid;
            await this.assignSelectValuesToFilterButton();
            await this.getGlobalDiscount();
            await this.loadDiscountDevices(true);
        }
        this.reorderInactiveTableHeadersForMobile();
        this.initialLoading = false;
    }

    async getGlobalDiscount(): Promise<void> {
        try {
            const globalDiscount = await this.discountCouponService.getGlobalDiscount(this.selectedUid);
            this.globalDiscount = globalDiscount.globalDiscount;
        } catch {
            this.globalDiscount = 0;
        }
    }

    async assignSelectValuesToFilterButton() {
        try {
            const selectBoxValues: Record<string, SelectOption[]> = await this.billingService.getSelectBoxValues(this.selectedUid);
            const keys = ['location_key', 'device_type', 'renewable', 'subcustomer_uid'];

            keys.forEach(key => {
                // Find the filter option in filterOptionsActive
                const filterOption: FilterOption = this.filterOptions.find(option => option.key === key);
                if (filterOption) {
                    filterOption.selectOptions = selectBoxValues[key];
                }
            });
        } catch {
            this.loadingDevices = false;
        }
    }

    async openBillingSubscriptionModal(modal: NgbModalModule) {
        this.modalRef = this.modalService.open(modal, {
            ariaLabelledBy: 'modal-basic-title'
        });
    }

    async onSubscriptionCreated() {
        this.pfcService.bustAllCacheByUrl(`${environment.baseUrl}/api_billing/get_devices/${this.selectedUid}`);
        this.selectedDevices = [];
        await this.getGlobalDiscount();
        await this.loadDiscountDevices();
    }

    areAllDevicesSelectedCheck(): boolean {
        if (!this.discountDevices || this.discountDevices.length === 0) {
            return false;
        }
        for (const device of this.discountDevices) {
            if (!device.is_checked) {
                return false;
            }
        }
        return true;
    }

    isDeviceSelected(device: BillingDevice): boolean {
        return this.selectedDevices.some(d => d.device_key === device.device_key && d.serial_number === device.serial_number);
    }

    async deleteDiscount(): Promise<void> {
        const isConfirmed: boolean = await this.deleteModalService.userHasConfirmedDeletion(this.deleteModalOptions);
        if (isConfirmed) {
            await this.discountCouponService.removeCoupon(this.selectedUid, this.selectedDevices).then(async () => {
                const includesGlobalDiscount: boolean = this.selectedDevices.some(d => (!d.next_discount_ref && d.discount) || !d.discount);
                const hasRemovableDiscounts: boolean = this.selectedDevices.some(d => d.discount && d.next_discount_ref);
                if (includesGlobalDiscount && !hasRemovableDiscounts) {
                    this.helperService.defaultHtmlToast('', `${this.translate.instant('discount_tool.cannot_delete_global')}`, 'Warning');
                    await this.deleteDiscount();
                } else if (hasRemovableDiscounts) {
                    this.helperService.defaultHtmlToast('', `${this.translate.instant('discount_tool.successfully_removed')}`, 'Success');
                    this.selectedDevices = [];
                    this.pfcService.bustAllCacheByUrl(`${environment.baseUrl}/api_billing/get_devices/${this.selectedUid}`);
                    await this.loadDiscountDevices();
                    this.deleteModalService.closeAndResetModal();
                }
            });
        }
    }

    toggleDeviceSelection(device: DeviceWithDiscount, isChecked: boolean): void {
        if (isChecked) {
            this.selectedDevices.push(device);
            device.is_checked = this.isDeviceSelected(device);
        } else {
            this.selectedDevices = this.selectedDevices.filter(d => !(d.device_key === device.device_key && d.serial_number === device.serial_number));
            device.is_checked = this.isDeviceSelected(device);
        }
    }

    selectAllDevices() {
        const selectAll = !this.areAllDevicesSelectedCheck();

        if (selectAll) {
            this.selectedDevices = [];
            for (const device of this.discountDevices) {
                if (!device.pending_card_payment) {
                    device.is_checked = selectAll;
                    this.selectedDevices.push(device);
                }
            }
        } else {
            for (const device of this.discountDevices) {
                device.is_checked = selectAll;
            }
            this.selectedDevices = [];
        }
    }

    async loadDiscountDevices(initLoading?: boolean): Promise<void> {
        this.loadingDevices = true;
        const paramsAsString: string = JSON.stringify(this.params);
        const endpoint = `${environment.baseUrl}/api_billing/get_devices/${this.selectedUid}?params=${paramsAsString}`;
        const cacheExists = this.pfcService.cacheExists(endpoint, this.params);
        await this.pfcService
            .getData(endpoint, this.params)
            .then(response => {
                this.discountDevices = response.pages[this.pageNumber].map(device => {
                    const hoverIconProperties = this.discountCouponService.computeHoverIconProperties(device, this.globalDiscount);
                    return {
                        ...device,
                        ...hoverIconProperties
                    } as DeviceWithHoverIconProperties;
                });
                this.totalItems = response.totalItems;
            })
            .catch(err => {
                this.helperService.defaultHtmlToast('', `${this.translate.instant('billing.discount_uid_error')}`, 'Warning');
                throw new Error(err);
            })
            .finally(() => {
                this.loadingDevices = false;
                this.initialLoading = false;
                this.selectedDevices = [];

                if (initLoading && cacheExists) {
                    this.updateCachedData(endpoint);
                }
            });
    }

    private updateCachedData(endpoint: string) {
        this.pfcService.updateDataIfDifferentFromCache(endpoint, this.params).then(fetchedData => {
            if (!_.isEqual(fetchedData.pages[this.pageNumber], this.discountDevices)) {
                this.discountDevices = fetchedData.pages[this.pageNumber].map(device => {
                    const hoverIconProperties = this.discountCouponService.computeHoverIconProperties(device, this.globalDiscount);
                    return {
                        ...device,
                        ...hoverIconProperties
                    } as DeviceWithHoverIconProperties;
                });
                this.selectedDevices = [];
                this.totalItems = fetchedData.totalItems;
            }
        });
    }

    async catchUid(uid: string): Promise<void> {
        this.selectedUid = uid;
        await this.assignSelectValuesToFilterButton();
        await this.getGlobalDiscount();
        await this.loadDiscountDevices().then(async () => {
            // Navigate to the current route with the new uid parameter
            await this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { uid: this.selectedUid },
                queryParamsHandling: 'merge' // Merge with existing query params
            });
        });

        this.loadingDevices = false;
    }

    async catchDiscountChanged(discount: number): Promise<void> {
        this.globalDiscount = discount;
        this.pfcService.bustAllCacheByUrl(`${environment.baseUrl}/api_billing/get_devices/${this.selectedUid}`);
        await this.loadDiscountDevices();
    }

    //region Table Functions
    async catchFilterChanged(event: any) {
        if (JSON.stringify(this.params.filter) !== JSON.stringify(event)) {
            this.pageNumber = 0;
        }
        this.params.filter = event;
        this.params.pageNumber = this.pageNumber;
        await this.loadDiscountDevices();
    }

    async handlePage(e: PageEvent) {
        this.pageSize = e.pageSize;
        this.pageNumber = e.pageIndex;
        this.params.pageSize = this.pageSize;
        this.params.pageNumber = this.pageNumber;
        await this.loadDiscountDevices();
    }

    async catchSortChanged(event: Sort) {
        if (this.params.sortBy.key !== event.key) {
            this.pageNumber = 0;
        }
        this.params.sortBy = event;
        this.params.pageNumber = this.pageNumber;
        await this.loadDiscountDevices();
    }

    reorderInactiveTableHeadersForMobile() {
        if (this.isMobile) {
            const lastElement = this.tableHeaderOptions.pop();
            this.tableHeaderOptions.unshift(lastElement);
        }
    }
    //endregion
}
