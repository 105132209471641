import { Component, Input, OnInit } from '@angular/core';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { HelperService } from '../../../../services/helper/helper.service';
import moment from 'moment';
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import { CreditReport } from '../../../../../../shared_models/credit-payment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreditPaymentService } from '../../../../services/credit-payment/credit-payment.service';
import { saveAs } from "file-saver";
import { DashboardUser } from 'shared_models/dashboard-user';
import { ActivatedRoute } from '@angular/router';
import { PaginatePipe } from '../../../../pipe/paginate.pipe';
import { LoadingComponent } from '../../../loading/loading.component';
import { NgFor, NgIf } from '@angular/common';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';


@Component({
    selector: 'app-credit-report-list-modal',
    templateUrl: './credit-report-list-modal.component.html',
    styleUrls: ['./credit-report-list-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, NgFor, NgIf, LoadingComponent, MatPaginator, TranslateModule, PaginatePipe]
})
export class CreditReportListModalComponent implements OnInit {
    @Input() reports: CreditReport[];
    @Input() modalService: NgbModal;
    @Input() isMobile: boolean;

    pageSizeOptions: number[] = [10, 50, 100];
    pageSize = 10;
    pageNumber = 0;
    downloading: number; // using the start date of the report as a unique identifier
    user: DashboardUser;
    isCustomerOperated: boolean;



    constructor(
        private helperService: HelperService,
        private translate: TranslateService,
        private creditPaymentService: CreditPaymentService,
        private route: ActivatedRoute,

    ) { }

    ngOnInit(): void {
        this.user = this.helperService.getUser();
        this.isCustomerOperated = window.location.pathname.split('/').includes('customers') ? true : false;

    }

    async downloadCSV(locationId: string, startDate: number): Promise<void> {
        this.downloading = startDate;
        await this.creditPaymentService.reportReadCSV(locationId, startDate, this.isCustomerOperated ? `${this.route.snapshot.paramMap.get('sub_customer_id')}_operated_by_${this.user.uid}` : this.user.uid).then(async (csvData) => {
            if (csvData.status && csvData.status === 204) {   // no data to build file
                this.downloading = 0;
                return;
            } else if (csvData.status && csvData.status === 400) {   // bad request, maybe multiple data sources to build file found, duplicate on start_date maybe
                this.downloading = 0;
                return;
            }

            const data: Blob = new Blob([csvData.text], {
                type: "text/csv;charset=utf-8"
            });

            // you may improve this code to customize the name 
            // of the export based on date or some other factors
            saveAs(data, csvData.filename);

            await this.helperService.sleep(500);
        }).catch((err) => {
            console.error(err);
            this.downloading = 0;

            return;
        });

        this.downloading = 0;
    }


    handlePage(e: PageEvent) {
        this.pageSize = e.pageSize;
        this.pageNumber = e.pageIndex;
    }

    formatAmount(totalAmount: number): string {
        return `${this.helperService.localizeNumberWithCurrency(totalAmount / 100, 2, 2, this.user.settings.currency)}`;
    }

    periodDateFormat(timestamp: number): string {
        return moment(timestamp, 'X').locale(this.translate.currentLang).format('YYYY, MMM');
    }


}
