<div class="row cont" style="min-height: 200px" *ngIf="!isMobile">
    <div class="pt-4 col-12 box">
        <table style="width: 100%;">
            <div style="display: table-header-group">
                <div style="display: table-row" class="table-title">
                    <th colspan="0" class="sort-label noselect pointer" (click)="sort('contract_id')"
                        style="width: 120px">
                        {{'operator.contract_modal.contract_id' | translate}}
                        <img *ngIf="sortBy === 'contract_id'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th colspan="0" class="sort-label noselect pointer" (click)="sort('location_names')"
                        style="width: 160px">
                        {{'misc.locations' | translate}}
                        <img *ngIf="sortBy === 'location_names'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th colspan="0" class="sort-label noselect right pointer" (click)="sort('summed_users')"
                        style="width: 80px">
                        {{'operator.contract_overview.users' | translate}}
                        <img *ngIf="sortBy === 'summed_users'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th colspan="0" class="sort-label noselect right pointer" (click)="sort('avg_adoption')"
                        style="width: 100px">
                        {{'operator.contract_overview.adoption' | translate}}
                        <img *ngIf="sortBy === 'avg_adoption'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th colspan="0" class="sort-label noselect right pointer" (click)="sort('summed_starts_day')"
                        style="width: 100px">
                        {{'operator.contract_overview.starts_day' | translate}}
                        <img *ngIf="sortBy === 'summed_starts_day'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th colspan="0" class="sort-label noselect right pointer" (click)="sort('revenue_target')"
                        style="width: 140px">
                        {{'operator.contract_modal.revenue_target' | translate}}
                        <img *ngIf="sortBy === 'revenue_target'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th colspan="0" class="sort-label noselect right pointer" (click)="sort('summed_revenue')"
                        style="width: 100px">
                        {{'operator.contract_overview.revenue' | translate}}
                        <img *ngIf="sortBy === 'summed_revenue'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th colspan="0" class="sort-label noselect right pointer" (click)="sort('billable_amount')"
                        style="width: 140px">
                        {{'operator.contract_overview.billable_amount' | translate}}
                        <img *ngIf="sortBy === 'billable_amount'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th colspan="0" class="sort-label noselect right pointer" (click)="sort('performance')"
                        style="width: 120px">
                        {{'operator.contract_overview.performance' | translate}}
                        <img *ngIf="sortBy === 'performance'"
                            src='../../../../assets/icons/{{ sortingAccending ? "arrow-up-blue" : "arrow-down-blue"}}.svg'
                            alt="{{'customers.company_name' | translate}}" class="arrow">
                    </th>
                    <th style="width: 30px"></th>
                </div>
            </div>
        </table>
        <div *ngIf="contracts.length">
            <div *ngFor="let contract of contracts | paginate:pageSize:pageNumber; let outerIndex = index"
                style="width: 100%;">
                <div class="card-item-expanded-closed" *ngIf="!checkIfExpanded(outerIndex)"
                    (click)="toggleContractExpand(outerIndex)">
                    <table style="width: 100%;">
                        <div style="display:table-row-group">
                            <tr>
                                <td class="overflow-column" style="width: 120px">{{contract.contract_id}}</td>
                                <td class="overflow-column" style="width: 160px">
                                    <div class="location-flex">
                                        <p class="location-names"
                                            [style]="contract.location_names.length > 1 ? 'width: 80%': 'width: 100%'">
                                            {{contract.location_names[0]}}</p>
                                        <div [style]="contract.location_names.length > 1 ? '': 'display: none'">
                                            <div class="location-extras">
                                                <p>+{{contract.location_names.length - 1}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="right" style="width: 80px">{{contract.summed_users}}</td>
                                <td class="right" style="width: 100px">{{round(contract.avg_adoption)}}</td>
                                <td class="right" style="width: 100px">
                                    {{helperService.roundToTwoDecimals(contract.summed_starts_day)}}</td>
                                <td class="right" style="width: 140px">{{formatCurrency(contract.revenue_target,
                                    contract.currency)}}</td>
                                <td class="right" style="width: 100px">{{formatCurrency(contract.summed_revenue,
                                    contract.currency)}}</td>
                                <td class="right" style="width: 140px">{{formatCurrency(contract.billable_amount,
                                    contract.currency)}}</td>
                                <td class="right"
                                    [style]="contract.performance < 0 ? 'color: #D81E5B;width:120px;' : 'color: #29E1B4;width:120px;'">
                                    {{roundPerform(contract.performance)}}
                                </td>
                                <td style="width: 30px"><img src='../../../assets/icons/arrow-down-blue.svg'
                                        alt="arrow-down"></td>
                            </tr>
                        </div>
                    </table>
                </div>
                <div class="card-item-expanded" *ngIf="checkIfExpanded(outerIndex)">
                    <table style="width: 100%">
                        <tr *ngFor="let location of contract.locations | keyvalue; let i = index" class="pointer"
                            (click)="toggleContractExpand(outerIndex)">
                            <td class="overflow-column" style="width: 120px">{{i === 0 ? contract.contract_id: ''}}
                            </td>
                            <td class="overflow-column" style="width: 160px">{{location.value.name}}</td>
                            <td class="right" style="width: 80px">{{location.value.users}}</td>
                            <td class="right" style="width: 100px">{{round(location.value.adoption)}}</td>
                            <td class="right" style="width: 100px">{{location.value.starts_day}}</td>
                            <td class="right" style="width: 140px"></td>
                            <td class="right" style="width: 100px">{{formatCurrency(location.value.revenue,
                                contract.currency)}}</td>
                            <td class="right" style="width: 140px"></td>
                            <td class="right" style="width: 120px"></td>
                            <td style="width: 30px"><img *ngIf="i === 0" src='../../../assets/icons/arrow-up-blue.svg'
                                    alt="arrow-up"></td>
                        </tr>
                        <tr (click)="toggleContractExpand(outerIndex)" class="total-tr pointer">
                            <td class="" style="width: 120px"></td>
                            <td class="overflow-column" style="width: 160px"><strong>Total</strong></td>
                            <td class="right" style="width: 80px"><strong>{{contract.summed_users}}</strong></td>
                            <td class="right" style="width: 100px"><strong>{{round(contract.avg_adoption)}}</strong>
                            </td>
                            <td class="right" style="width: 100px">
                                <strong>{{helperService.roundToTwoDecimals(contract.summed_starts_day)}}</strong>
                            </td>
                            <td class="right" style="width: 140px"><strong>{{formatCurrency(contract.revenue_target,
                                    contract.currency)}}</strong></td>
                            <td class="right" style="width: 100px"><strong>{{formatCurrency(contract.summed_revenue,
                                    contract.currency)}}</strong></td>
                            <td class="right" style="width: 140px">
                                <strong>{{formatCurrency(contract.billable_amount,
                                    contract.currency)}}</strong>
                            </td>
                            <td class="right"
                                [style]="contract.performance < 0 ? 'color: #D81E5B; width:120px;' : 'color: #29E1B4;width:120px;'">
                                <strong>{{roundPerform(contract.performance)}}</strong>
                            </td>
                            <td style="width: 30px"></td>
                        </tr>
                        <tr class="pointer">
                            <div style="padding:1rem" class="divider">
                                <div
                                    style="position: absolute; min-height:1px; background:#BEC9D1; width:calc(100% - 5rem);">
                                </div>
                            </div>
                        </tr>
                        <tr>
                            <td style="width: 120px"><strong>{{'misc.customer' | translate}}:</strong></td>
                            <td class="overflow-column" style="width: 160px">{{contract.customer_name === 'my_account' ?
                                ('operator.contract_modal.my_account' | translate) : contract.customer_name}}</td>
                            <td style="width: 80px"></td>
                            <td class="right" style="width: 100px">{{contract.start_date ?
                                getLocalTime(contract.start_date) : ''}}</td>
                            <td colspan="2" style="width: 240px">
                                <div class="progressbar-background" *ngIf="contract.start_date && contract.end_date">
                                    <div class="{{isExpiring(contract.end_date)}}"
                                        [style.width.%]="calculateDateProgress(contract.start_date, contract.end_date)">
                                    </div>
                                </div>
                            </td>
                            <td class="right" style="width: 100px">{{contract.end_date ?
                                getLocalTime(contract.end_date) : ''}}</td>
                            <td class="right pointer" style="width: 260px" colspan="2"
                                *ngIf="authService.hasLimitedAccess('control_panel_settings', (role$ | async))"
                                (click)="openModal(editContractModal, contract)">
                                <u>{{'operator.contract_overview.edit_contract' | translate}}</u>
                            </td>
                            <td style="width: 30px"></td>
                        </tr>
                    </table>
                </div>
            </div>

            <div *ngIf="loading" class="mb-5">
                <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
            </div>
            <div *ngIf="!loading" style="padding-top: 1.25rem">
                <mat-paginator id="matpage" [length]="contracts.length" [pageSize]="pageSize"
                    [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageNumber" (page)="handlePage($event)"
                    showFirstLastButtons class="pagination"></mat-paginator>
            </div>
        </div>

        <p class="m-5 d-flex justify-content-around" *ngIf="!contracts.length && !loading">
            {{'operator.contract_overview.no_contracts_yet' | translate}}
        </p>

        <div *ngIf="loading" class="m-5">
            <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
        </div>
    </div>
</div>

<!--Mobile-->
<div class="cont" style="min-height: 200px" *ngIf="isMobile">
    <div class="mobile-card empty-card" *ngIf="loading">
        <div>
            <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
        </div>
    </div>

    <div class="mobile-card empty-card" *ngIf="contracts.length === 0 && !loading">
        <div>
            <p>{{'operator.contract_overview.no_contracts_yet' | translate}}</p>
        </div>
    </div>

    <div *ngIf="!loading" class="mobile-body">
        <div *ngFor="let contract of contracts | paginate:pageSize:pageNumber"
            (click)="openModal(specificContractModal, contract)">
            <div class="mobile-card">
                <div class="card-title">
                    <p><strong>ID: {{contract.contract_id}}</strong></p>
                    <p [style]="contract.performance < 0 ? 'color: #D81E5B;' : 'color: #29E1B4;'">
                        <strong>{{roundPerform(contract.performance)}}</strong>
                    </p>
                </div>
                <div *ngFor="let location of contract.locations | keyvalue; let i = index" style="margin-top: 5px">
                    <p style="font-size: 14px">{{location.value.name}}</p>
                </div>
            </div>
        </div>
        <div class="mobile-paginator" *ngIf="contracts.length !== 0">
            <mat-paginator id="matpage" [length]="contracts.length" [pageSize]="pageSize"
                [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageNumber" (page)="handlePage($event)"
                showFirstLastButtons class="pagination"></mat-paginator>
        </div>
    </div>
</div>

<ng-template #editContractModal>
    <app-contract-form [editableContract]="contractToBeEdited" [isEdit]="true"
        (parrentDeleteContract)="deleteContract($event)"
        (parrentUpdateContract)="updateContract($event)"></app-contract-form>
</ng-template>

<ng-template #specificContractModal>
    <app-specific-contract [contract]="contractToBeEdited"></app-specific-contract>
</ng-template>