import { Injectable, signal, WritableSignal } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AuthPageService {
    private loadingSignal: WritableSignal<boolean> = signal(false);
    private isMfaSignIn: WritableSignal<boolean> = signal(false);

    get mfaSignIn() {
        return this.isMfaSignIn;
    }

    get loading() {
        return this.loadingSignal;
    }

    setMfaSignIn(isMfaSignIn: boolean) {
        this.isMfaSignIn.set(isMfaSignIn);
    }

    setLoading(isLoading: boolean) {
        this.loadingSignal.set(isLoading);
    }
}
