/**
 * Uzbek latin translation for bootstrap-datepicker
 * Kakhramonov Javlonbek <kakjavlon@gmail.com>
 */
export default {
  'uz-latn': {
    days: ["Yakshanba", "Dushanba", "Seshanba", "Chorshanba", "Payshanba", "Juma", "Shanba"],
    daysShort: ["Yak", "Du", "Se", "Chor", "Pay", "Ju", "Sha"],
    daysMin: ["Ya", "Du", "Se", "Cho", "Pa", "Ju", "Sha"],
    months: ["Yanvar", "Fevral", "Mart", "Aprel", "May", "Iyun", "Iyul", "Avgust", "Sentabr", "Oktabr", "Noyabr", "Dekabr"],
    monthsShort: ["Yan", "Fev", "Mar", "Apr", "May", "Iyn", "Iyl", "Avg", "Sen", "Okt", "Noy", "Dek"],
    today: "Bugun",
    clear: "O'chirish",
    format: "dd.mm.yyyy",
    weekStart: 1,
    monthsTitle: 'Oylar'
  }
};