// Currently supported countries are:
/**
 * - Colombia
 * - Uzbekistan
 */
export const banks: Record<string, { name: string; code: string }[]> = {
    UZ: [
        { name: 'ANOR BANK UZ', code: 'ANOBUZ22XXX' },
        { name: 'ASIA ALLIANCE BANK', code: 'ASACUZ22XXX' },
        { name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN', code: 'CBUZUZ22XXX' },
        { name: 'CREDIT-STANDARD BANK', code: 'CRIDUZ21XXX' },
        { name: 'HI-TECH BANK PCJSB', code: 'HPCJUZ22XXX' },
        { name: 'INVEST FINANCE BANK', code: 'INFBUZ2XXXX' },
        { name: 'IPOTEKA-BANK', code: 'UZHOUZ22XXX' },
        { name: 'JSC ALOQABANK', code: 'JSCLUZ22XXX' },
        { name: 'JSC ASAKABANK', code: 'ASBKUZ22XXX' },
        { name: 'JSC BANK APELSIN', code: 'APELUZ22XXX' },
        { name: 'JSC MADAD INVEST BANK', code: 'JSMIUZ22XXX' },
        { name: 'JSC POYTAXT BANK', code: 'JOCPUZ22XXX' },
        { name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN', code: 'SCPEUZ22XXX' },
        { name: 'JSC ZIRAAT BANK UZBEKISTAN', code: 'TCZBUZ22XXX' },
        { name: 'JSCB AGROBANK', code: 'PAKHUZ22XXX' },
        { name: 'JSCB HAMKORBANK', code: 'KHKKUZ22XXX' },
        { name: 'JSCB SAVDOGAR', code: 'JSCSUZ22XXX' },
        { name: 'JSCB TBC BANK', code: 'JOCBUZ22XXX' },
        { name: 'JSCB TENGE BANK', code: 'HSBKUZ22XXX' },
        { name: 'JSCB TURONBANK', code: 'TUROUZ22XXX' },
        { name: 'JSCB UZAGROEXPORTBANK', code: 'UZGOUZ22XXX' },
        { name: 'JSIC BANK IPAK YULI', code: 'INIPUZ22XXX' },
        { name: 'KAPITALBANK, JSCB', code: 'KACHUZ22XXX' },
        { name: 'KDB BANK UZBEKISTAN JSC', code: 'KODBUZ22XXX' },
        { name: 'MICROCREDITBANK', code: 'MICDUZ22XXX' },
        { name: 'MINISTRY OF FINANCE THE REPUBLIC OF UZBEKISTAN', code: 'MFRUUZ22XXX' },
        { name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN', code: 'NBFAUZ31XXX' },
        { name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN', code: 'NBFAUZ2XXXX' },
        { name: 'OJSC MICROCREDITBANK', code: 'OJMIUZ21XXX' },
        { name: "PJSCB 'ORIENT FINANCE'", code: 'ORFBUZ22XXX' },
        { name: 'PJSCB DAVR BANK', code: 'DVRBUZ22XXX' },
        { name: 'PJSCB TURKISTON', code: 'TRKSUZ22XXX' },
        { name: 'PJSCB UNIVERSAL BANK', code: 'PJUNUZ22XXX' },
        { name: 'QISHLOQ QURILISH BANK JSCB', code: 'GJSOUZ22XXX' },
        { name: 'RAVNAQ-BANK', code: 'RVBKUZ22XXX' },
        { name: 'REPUBLICAN STOCK EXCHANGE (TOSHKENT)', code: 'XSTEUZ21XXX' },
        { name: "THE STATE ENTERPRISE 'CENTRAL SECURITIES DEPOSITORY'", code: 'SNCUUZ22XXX' },
        { name: 'THE UZBEKISTAN REPUBLICAN CURRENCY EXCHANGE', code: 'XCUEUZ21XXX' },
        { name: 'TRUSTBANK, PJSB', code: 'TRSAUZ22XXX' },
        { name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB', code: 'UJSIUZ22XXX' }
    ],
    CO: [
        {
            name: 'BBVA',
            code: '013'
        },
        {
            name: 'Bancamia S.A.',
            code: '059'
        },
        {
            name: 'Banco Agrario',
            code: '040'
        },
        {
            name: 'Banco Av. Villas',
            code: '052'
        },
        {
            name: 'Banco Caja Social BCSC',
            code: '032'
        },
        {
            name: 'Banco Colpatria',
            code: '019'
        },
        {
            name: 'Banco Credifinanciera S.A.',
            code: '558'
        },
        {
            name: 'Banco Dale',
            code: '097'
        },
        {
            name: 'Banco Davivienda',
            code: '051'
        },
        {
            name: 'Banco Falabella S.A.',
            code: '062'
        },
        {
            name: 'Banco Finandina S.A.',
            code: '063'
        },
        {
            name: 'Banco J.P. Morgan Colombia S.A.',
            code: '071'
        },
        {
            name: 'Banco Multibank S.A.',
            code: '064'
        },
        {
            name: 'Banco Mundo Mujer',
            code: '047'
        },
        {
            name: 'Banco Pichincha',
            code: '060'
        },
        {
            name: 'Banco Popular',
            code: '002'
        },
        {
            name: 'Banco Procredit',
            code: '058'
        },
        {
            name: 'Banco Santander de Negocios Colombia S.A.',
            code: '065'
        },
        {
            name: 'Banco Serfinanza S.A.',
            code: '069'
        },
        {
            name: 'Banco Sudameris',
            code: '012'
        },
        {
            name: 'Banco W S.A.',
            code: '053'
        },
        {
            name: 'Banco de Bogota',
            code: '001'
        },
        {
            name: 'Banco de Occidente',
            code: '023'
        },
        {
            name: 'Bancoldex S.A.',
            code: '031'
        },
        {
            name: 'Bancolombia',
            code: '007'
        },
        {
            name: 'Bancoomeva',
            code: '061'
        },
        {
            name: 'Citibank',
            code: '009'
        },
        {
            name: 'Coltefinanciera S.A.',
            code: '370'
        },
        {
            name: 'Confiar',
            code: '292'
        },
        {
            name: 'Coofinep Cooperativa Financiera',
            code: '291'
        },
        {
            name: 'Coopcentral S.A.',
            code: '066'
        },
        {
            name: 'Cooperativa Financiera de Antioquia',
            code: '283'
        },
        {
            name: 'Corpbanca Itau',
            code: '006'
        },
        {
            name: 'Cotrafa Cooperativa Financiera',
            code: '289'
        },
        {
            name: 'Daviplata',
            code: '551'
        },
        {
            name: 'Financiera Juriscoop',
            code: '121'
        },
        {
            name: 'Giros y Finanzas CF',
            code: '303'
        },
        {
            name: 'Iris',
            code: '637'
        },
        {
            name: 'Itau',
            code: '014'
        },
        {
            name: 'LULO BANK S.A.',
            code: '070'
        },
        {
            name: 'MiBanco S.A.',
            code: '067'
        },
        {
            name: 'Movii',
            code: '801'
        },
        {
            name: 'Nequi',
            code: '507'
        },
        {
            name: 'Rappipay',
            code: '151'
        }
    ]
};
