<app-page-layout [pageTitle]="'navbar.transactions' | translate">
  <ng-container buttons>
    <app-aw-filter-button [style]="isMobile ? '' : 'margin-right: 15px'" [popupAnchor]="isMobile ? 'left' : 'right'"
                          (emitDependencyChange)="handleFilterValueChange($event)" [filterOptions]="filterOptions"
                          (filterChanged)="catchFilterChanged($event)" />
    <app-aw-export-button
      flag="transactions"
      filename="Airwallet_transactions"
      [limitBeforeMail]="20000"
      [disabled]="!totalItems || exportingExcel || fetchingData"
      [itemCount]="totalItems"
      [filterSortParams]="filterSortParams"
      [customerName]="'customerName'"
    />
  </ng-container>
  <ng-container body>
    <app-aw-table
      [tableHeaders]="tableHeaderOptions"
      [emptytext]="'transactions.no_transactions_yet' | translate"
      [pageNumber]="pageNumber"
      [pageSize]="pageSize"
      [totalItems]="totalItems"
      [sidescrollable]="isMobile"
      (handlePageChange)="handlePage($event)"
      [loadingData]="fetchingData"
      [initalSort]="{ order: 'asc', key: '' }"
    >
      <ng-container table-body>
        <row *ngFor="let order of orders | paginate: pageSize : pageNumber">
          <cell [style.width]="tableHeaderOptions[0].width">
            <div class="payment-id-wrapper">
              <div [title]="order.transaction_id" class="clip" id="{{ order.key }}">
                {{ order.transaction_id }}
              </div>
              <button class="my-mini-btn" (click)="helperService.copySomeText(order.transaction_id, order.key)">
                <img src="../../../assets/icons/copy-icon.svg" alt="" />
              </button>
            </div>
          </cell>
          <cell [style.width]="tableHeaderOptions[1].width" [title]="getLocalTime(order.timestamp)">
            <span>{{ getLocalTime(order.timestamp) }}</span>
          </cell>

          <!-- Showing a dash instead of amount on subs -->
          <cell [style.width]="tableHeaderOptions[2].width"
                [title]="order.transaction_id === 'SUB' ? '-' : localizeNumber(order.amount / 100, order.currency)">
            <span>{{ order.transaction_id === "SUB" ? "-" : localizeNumber(order.amount / 100, order.currency) }}</span>
          </cell>

          <cell [style.width]="tableHeaderOptions[3].width" [title]="getCustomerName(order.uid)"
                class="clip clip-customer-name">
            <span>{{ getCustomerName(order.uid) }}</span>
          </cell>
          <cell [style.width]="tableHeaderOptions[4].width" [title]="order.location_name ? order.location_name : '-'"
                class="clip clip-location-name">
            <span>{{ order.location_name ? order.location_name : "-" }}</span>
          </cell>
          <cell [style.width]="tableHeaderOptions[5].width" [title]="order.device_name" class="clip clip-device-name">
            <span>{{ order.device_name }}</span>
          </cell>
          <cell [style.width]="tableHeaderOptions[6].width"
                [title]="order.phone_number ? helperService.normalizePhoneNumber(order.phone_number) : '-'">
            <span>{{ order.phone_number ? helperService.normalizePhoneNumber(order.phone_number) : "-" }}</span>
          </cell>
          <!-- {{showRefund}} -->
          <cell [style.width]="tableHeaderOptions[7].width" *ngIf="showRefund">
            <div style="width: 100%; display: flex; justify-content: center">
              <button
                type="button"
                [class.disabled]="orderKeyToRefund === order.key || order.refund || order.refund_status === 'pending'"
                (click)="orderKeyToRefund === order.key || order.refund || order.refund_status === 'pending' ? openRefundModal(refundReasonModal, order) : openRefundModal(refundModal, order)"
                [class.active]="order.refund && orderKeyToRefund !== order.key"
                [class.btn-secondary-force-hover-effect]="orderToRefund && orderToRefund.key === order.key"
                class="btn btn-secondary btn-tiny my-mini-btn-refund btn-hoverable"
              >
                {{
                  orderKeyToRefund === order.key
                    ? ""
                    : order.refund
                      ? ("transactions.refunded" | translate)
                      : order.refund_status
                        ? ("transactions.refund_status." + order.refund_status | translate)
                        : ("transactions.refund" | translate)
                }}
                <loading-indicator *ngIf="orderKeyToRefund === order.key" [size]="'btn-tiny-refund'"
                                   [color]="'secondary'"></loading-indicator>
              </button>
            </div>
          </cell>
        </row>
      </ng-container>
    </app-aw-table>
  </ng-container>
</app-page-layout>

<ng-template #refundModal let-modal>
  <app-custom-modal>
    <ng-container modal-title>
      <h4>{{ "transactions.refund_transaction" | translate }}</h4>
    </ng-container>
    <ng-container modal-dismiss></ng-container>
    <ng-container modal-body>
      <p>{{ "transactions.refund_please_select" | translate }}</p>
      <div class="refund-modal">
        <p class="mb-1">{{ "transactions.refund_tooltip_reason" | translate }}</p>
        <select [(ngModel)]="refundReason" class="form-select">
          <option value="duplicate">{{ "transactions.refund_reason_duplicate" | translate }}</option>
          <option value="fraudulent">{{ "transactions.refund_reason_fraudulent" | translate }}</option>
          <option value="requested_by_customer">{{ "transactions.refund_reason_requested" | translate }}</option>
          <option value="other">{{ "transactions.refund_reason_other" | translate }}</option>
        </select>
        <div class="mt-3" *ngIf="refundReason === 'other'">
          <input
            class="form-control"
            [(ngModel)]="otherReason"
            [placeholder]="this.translate.instant('transactions.refund_enter_reason')"
            #reasonInput
            (focus)="placeholderText = reasonInput.placeholder; reasonInput.placeholder = ''"
            (blur)="reasonInput.placeholder = placeholderText"
          />
        </div>
      </div>
    </ng-container>
    <ng-container modal-footer>
      <div class="d-grid gap-2 mt-3">
        <button class="btn btn-secondary" tabindex="-1" (click)="refund(orderToRefund['transaction_id'], refundReason)">
          {{ "transactions.refund" | translate }}
        </button>
      </div>
    </ng-container>
  </app-custom-modal>
</ng-template>

<ng-template #refundReasonModal let-modal>
  <app-custom-modal>
    <ng-container modal-title>
      <h4>{{ "transactions.refund_tooltip_title" | translate }}</h4>
    </ng-container>
    <ng-container modal-body>
      <p>{{ "transactions.refund_by_user" | translate }}: {{ orderToRefund.user_name }}</p>
      <p>{{ "transactions.refund_tooltip_reason" | translate }}: {{ refundReasonCorrector() }}</p>
      <p
        *ngIf="orderToRefund && orderToRefund.refund_reason === 'other'">{{ "transactions.refund_tooltip_other_reason" | translate }}
        : {{ orderToRefund.refund_details }}</p>
    </ng-container>
    <ng-container modal-footer></ng-container>
  </app-custom-modal>
</ng-template>
