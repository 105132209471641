import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AwCheckboxComponent} from "../../misc/aw-checkbox/aw-checkbox.component";
import {CustomModalComponent} from "../../misc/custom-modal/custom-modal.component";
import {LoadingComponent} from "../../loading/loading.component";
import {MatRadioButton, MatRadioGroup} from "@angular/material/radio";
import {NgForOf, NgIf} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {DeviceWithDiscount, DiscountCoupon} from "../../../../../shared_models/billing/discount-coupon";
import {AwDatepickerComponent} from "../../misc/aw-datepicker/aw-datepicker.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import dayjs from "dayjs";
import {DiscountCouponService} from "../../../services/discount-coupon/discount-coupon.service";
import {HelperService} from "../../../services/helper/helper.service";

@Component({
    selector: 'app-discount-create-modal',
    standalone: true,
    imports: [
        AwCheckboxComponent,
        CustomModalComponent,
        LoadingComponent,
        MatRadioButton,
        MatRadioGroup,
        NgForOf,
        NgIf,
        ReactiveFormsModule,
        TranslateModule,
        AwDatepickerComponent,
        FormsModule
    ],
    templateUrl: './discount-create-modal.component.html',
    styleUrl: './discount-create-modal.component.scss'
})
export class DiscountCreateModalComponent {
    @Input() uid: string;
    @Input() selectedDevices: DeviceWithDiscount[] = [];
    @Output() discountCreated: EventEmitter<boolean> = new EventEmitter<boolean>();

    discount: number;
    expireDate: number;

    applyingDiscount: boolean = false;

    constructor(
        private modalService: NgbModal,
        private discountCouponService: DiscountCouponService,
        private helperService: HelperService,
        private translate: TranslateService
    ) {
    }

    async applyDiscount() {
        if (this.discount) {
            this.applyingDiscount = true;
            const discountData: DiscountCoupon = {
                key: "temp",
                created: 1, // Is set correctly in backend
                expires: this.expireDate,
                is_global: false,
                percent_off: this.discount,
            }

            await this.discountCouponService.createDiscountCoupon(this.uid, discountData, this.selectedDevices).then(() => {
                this.modalService.dismissAll()
                this.discountCreated.emit(true);
                this.helperService.defaultHtmlToast('', this.translate.instant('discount_tool.success_applied'), 'Success');
            }).catch((error) => {
                this.helperService.defaultHtmlToast('', error?.error?.error, 'Error');
            }).finally(() => {
                this.applyingDiscount = false;
            })
            console.log("applied")
        }
    }

    protected readonly dayjs = dayjs;
}
