import { Component, ContentChild, ContentChildren, Directive, EventEmitter, HostBinding, Input, Output, forwardRef } from '@angular/core';
import { Direction } from '../shared/moving.enum';
import { WizardStepSymbolDirective } from '../directives/wizard-step-symbol.directive';
import { WizardStepTitleDirective } from '../directives/wizard-step-title.directive';

export interface NavigationSymbol {
  symbol: string;
  fontFamily?: string;
}

@Directive()
export abstract class WizardStep {

  @ContentChild(WizardStepTitleDirective)
  public stepTitleTemplate: WizardStepTitleDirective;

  /**
   * A step symbol property that, if defined, overrides `navigationSymbol`.
   * Allows to display arbitrary content as a step symbol instead of plain text.
   */
  @ContentChild(WizardStepSymbolDirective)
  public stepSymbolTemplate: WizardStepSymbolDirective;

  @Input()
  stepId: string;

  @Input()
  stepTitle: string;

  @Input()
  navigationSymbol: NavigationSymbol = { symbol: '' };

  selected = false;

  completed = false;

  initiallyCompleted = false;

  defaultSelected = false;

  optional = false;

  editing = false;

  @Input()
  public canEnter: ((direction: Direction) => boolean) | ((direction: Direction) => Promise<boolean>) | boolean;

  @Input()
  public canExit: ((direction: Direction) => boolean) | ((direction: Direction) => Promise<boolean>) | boolean = true;

  /**
   * This [[EventEmitter]] is called when the step is entered.
   * The bound method should be used to do initialization work.
   */
  @Output()
  public stepEnter: EventEmitter<Direction> = new EventEmitter<Direction>();

  /**
   * This [[EventEmitter]] is called when the step is exited.
   * The bound method can be used to do cleanup work.
   */
  @Output()
  public stepExit: EventEmitter<Direction> = new EventEmitter<Direction>();


  private static canTransitionStep(condition: ((direction: Direction) => boolean) |
    ((direction: Direction) => Promise<boolean>) |
    boolean,
    direction: Direction): Promise<boolean> {
    if (typeof (condition) === typeof (true)) {
      return Promise.resolve(condition as boolean);
    } else if (condition instanceof Function) {
      return Promise.resolve(condition(direction));
    } else {
      return Promise.reject(new Error(`Input value '${condition}' is neither a boolean nor a function`));
    }
  }


  enter(direction: Direction): void {
    this.stepEnter.emit(direction);
  }

  exit(direction: Direction): void {
    this.stepExit.emit(direction);
  }

  public canEnterStep(direction: Direction): Promise<boolean> {
    return WizardStep.canTransitionStep(this.canEnter, direction);
  }

  public canExitStep(direction: Direction): Promise<boolean> {
    return WizardStep.canTransitionStep(this.canExit, direction);
  }

  @HostBinding('hidden')
  public get hidden(): boolean {
    return !this.selected;
  }

}

@Component({
    selector: 'aw-wizard-step',
    templateUrl: './aw-wizard-step.component.html',
    styleUrls: ['./aw-wizard-step.component.scss'],
    providers: [
        { provide: WizardStep, useExisting: forwardRef(() => AwWizardStepComponent) }
    ],
    standalone: true
})
export class AwWizardStepComponent extends WizardStep {

}
