<app-custom-modal>
    <ng-container modal-title>
        <h4>{{'device.edit_service_title' | translate}}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a [attr.tabindex]="0" href="#" (click)="modalService.dismissAll('Cancel')">
            <img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog">
        </a>
    </ng-container>
    <ng-container modal-body>
        <form [formGroup]="machineSpecForm">
            <h5>{{'device.maintenance' | translate}}</h5>
            <div class="input-group-wrapper  custom-tool-tip-wrapper" style="margin-bottom: 25px">
                <div class="d-flex">
                    <p class="col-6 mb-0 fw-bold">{{'device.cycles_since_last_maintenance' | translate}}</p>
                    <div class="col-1"></div>
                    <app-custom-tool-tip forInput="true" class="col-5" [fullWidth]="true" [spaceBetween]="true"
                        label="{{'device.interval' | translate}}"
                        description="{{'device.select_the_interval' |translate}}{{'device.interval_full_maintenance' | translate}}"></app-custom-tool-tip>
                </div>
                <div class="form-control d-flex">
                    <input class="col-6" required formControlName="current_maintenance_count"
                        name="current_maintenance_count" type="number">
                    <p class="col-1 mb-0 text-center" style="color: #BEC9D1;">/</p>
                    <input class="col-5" type="number" required formControlName="maintenance_limit"
                        name="maintenance_limit">
                </div>
                <!--                    Error messages-->
                <div class="d-flex">
                    <div class="col-7">
                        <div *ngIf="formSubmitted && current_maintenance_count.invalid">
                            <div class="error-msg" *ngIf="current_maintenance_count.errors.required">
                                Missing number of cycles
                            </div>
                            <div class="error-msg" *ngIf="current_maintenance_count.errors.min">
                                Must be more than 0
                            </div>
                            <div class="error-msg" *ngIf="current_maintenance_count.errors.max">
                                Must be less than interval
                            </div>
                        </div>
                    </div>
                    <div class="col-5">
                        <div *ngIf="formSubmitted && maintenance_limit.invalid">
                            <div class="error-msg" *ngIf="maintenance_limit.errors.required">
                                Missing interval
                            </div>
                            <div class="error-msg" *ngIf="maintenance_limit.errors.min">
                                Must be more than 0
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="device.product_type === ProductType.Washer">
                <h5 style="margin-bottom: 6px">{{'device.soap_system' | translate}}</h5>
                <mat-checkbox (change)="disableSoap($event)" [checked]="soapEnabled" name="soapDisabled"
                    disableRipple="true" style="width:18px;height:18px">
                    {{'device.installed_dispensing_system' | translate}}
                </mat-checkbox>
                <div class="input-group-wrapper custom-tool-tip-wrapper" style="margin-top: 6px">
                    <div class="d-flex">
                        <p class="col-6 mb-0 fw-bold">{{'device.cycles_since_last_refill' | translate}}</p>
                        <div class="col-1"></div>
                        <app-custom-tool-tip class="col-5" [fullWidth]="true" [spaceBetween]="true"
                            label="{{'device.interval' | translate}}"
                            description="{{'device.select_the_interval' | translate}}{{'device.interval_soap_refill' | translate}}"></app-custom-tool-tip>
                    </div>
                    <div class="form-control d-flex {{soapEnabled ? null : 'inactive'}}">
                        <input class="col-6 {{soapEnabled ? null : 'inactive'}}" type="number"
                            formControlName="current_soap_count" name="current_soap_count">
                        <p class="col-1 mb-0 text-center" style="color: #BEC9D1;">/</p>
                        <input class="col-5 {{soapEnabled ? null : 'inactive'}}" type="number"
                            formControlName="soap_limit" name="soap_limit">
                    </div>
                    <!--                    Error messages-->
                    <div class="d-flex">
                        <div class="col-7">
                            <div *ngIf="formSubmitted && current_soap_count.invalid">
                                <div class="error-msg" *ngIf="current_soap_count.errors.required">
                                    {{'device.missing_cycles' | translate}}
                                </div>
                                <div class="error-msg" *ngIf="current_soap_count.errors.min">
                                    {{'device.must_be_more_than_zero' | translate}}
                                </div>
                                <div class="error-msg" *ngIf="current_soap_count.errors.max">
                                    {{'device.must_be_less_than_interval' | translate}}
                                </div>
                            </div>
                        </div>
                        <div class="col-5">
                            <div *ngIf="formSubmitted && soap_limit.invalid">
                                <div class="error-msg" *ngIf="soap_limit.errors.required">
                                    {{'device.missing_interval' | translate}}
                                </div>
                                <div class="error-msg" *ngIf="soap_limit.errors.min">
                                    {{'device.must_be_more_than_zero' | translate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </form>
    </ng-container>
    <ng-container modal-footer>
        <div class="d-flex flex-column align-items-center">
            <button (click)="onSubmit()" class="btn btn-secondary mb-3 mt-3" style="width: 100%">
                {{sendingData ? '' : ('device.save_changes' | translate)}}
                <loading-indicator [size]="'small'" [color]="'white'" *ngIf="sendingData"></loading-indicator>
            </button>
        </div>
    </ng-container>
</app-custom-modal>