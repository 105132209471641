import { Injectable } from "@angular/core";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import { DeleteConfirmModalComponent } from "../../components/misc/delete-confirm-modal/delete-confirm-modal.component";
import { BehaviorSubject, Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { DeleteConfirmState, DeleteModalOptions } from "../../../../shared_models/deleteModalOptions";

@Injectable({
    providedIn: 'root'
})
export class DeleteModalService {
    private modalRef: NgbModalRef | null = null;

    constructor(
        public modalService: NgbModal,
        private translate: TranslateService
    ) { }
    title: string;
    description: string;
    buttonText: string;

    private deleteIsConfirmed$: BehaviorSubject<DeleteConfirmState> = new BehaviorSubject<DeleteConfirmState>(DeleteConfirmState.reset);
    selectedDeleteIsConfirmed$$: Observable<DeleteConfirmState> = this.deleteIsConfirmed$.asObservable();

    private isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
    selectedIsLoading$: Observable<boolean> = this.isLoading$.asObservable();

    /**
     * Handles the confirmation of deletion through a modal dialog.
     *
     * When the `userHasConfirmedDeletion` method is called, it checks if a modal is already open.
     * If no modal is open, it creates and opens a new modal with the provided options (title, description, button text).
     * If a modal is already open, it resets the modal state without creating a new one.
     *
     * The modal is reset to `null` when it is closed or dismissed.
     *
     * @param {DeleteModalOptions} deleteModalOptions - Options for configuring the delete modal.
     * @returns {Promise<boolean>} - A promise that resolves to `true` if the deletion is confirmed, `false` otherwise.
     */
    async userHasConfirmedDeletion(deleteModalOptions: DeleteModalOptions): Promise<boolean> {
        this.title = this.translate.instant(deleteModalOptions.titleTranslationString);
        this.description = this.translate.instant(deleteModalOptions.descriptionTranslateString);
        this.buttonText = this.translate.instant(deleteModalOptions.buttonTranslationString);
        console.log("calling userHasConfirmedDeletion")
        return await new Promise(async (resolve, reject) => {
            if (this.modalRef) {
                this.resetModalState();
            } else {
                this.modalRef = this.modalService.open(DeleteConfirmModalComponent, {
                    ariaLabelledBy: 'modal-basic-title',
                });

                this.modalRef.result.finally(() => {
                    this.modalRef = null;
                });
            }
            this.selectedDeleteIsConfirmed$$.subscribe(async (value: DeleteConfirmState) => {
                if (value === DeleteConfirmState.confirmed) {
                    this.isLoading$.next(true)
                    return resolve(true)
                } else if (value === DeleteConfirmState.cancelled) {
                    this.isLoading$.next(false)
                    this.modalService.dismissAll()
                    this.deleteIsConfirmed$.next(DeleteConfirmState.reset)
                    return resolve(false)
                }
            })
        })
    }



    confirmDelete() {
        this.deleteIsConfirmed$.next(DeleteConfirmState.confirmed)
    }

    closeAndResetModal() {
        this.deleteIsConfirmed$.next(DeleteConfirmState.cancelled)
        this.isLoading$.next(false)
        this.modalService.dismissAll()
    }

    resetModalState() {
        this.deleteIsConfirmed$.next(DeleteConfirmState.reset);
        this.isLoading$.next(false);
    }

}
