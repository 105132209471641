<app-custom-modal>
    <ng-container modal-title>
        <h4>{{'transactions.refund_tooltip_title' | translate}}</h4>
    </ng-container>
    <ng-container modal-body>
        <p>{{'transactions.refund_by_user' | translate}}: {{transaction.user_name}}</p>
        <p>{{'transactions.refund_tooltip_reason' | translate}}:
            {{refundReasonCorrector(transaction.refund_reason)}}</p>
        <p *ngIf="transaction.refund_reason === 'other'">
            {{'transactions.refund_tooltip_other_reason' | translate}}: {{transaction.refund_details}}</p>
    </ng-container>
    <ng-container modal-footer>
    </ng-container>
</app-custom-modal>