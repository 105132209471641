import { AfterContentInit, Component, ContentChildren, HostBinding, Input, OnInit, QueryList } from '@angular/core';
import { WizardStep } from '../aw-wizard-step/aw-wizard-step.component';
import { Direction } from '../shared/moving.enum';
import { AwWizardNavComponent } from '../aw-wizard-nav/aw-wizard-nav.component';
import { NgIf, NgClass } from '@angular/common';

// This component is a copy of the aw-wizard component from the aw-components library, re-factored to work with Angular 18
@Component({
    selector: 'aw-wizard',
    templateUrl: './aw-wizard.component.html',
    styleUrls: ['./aw-wizard.component.scss'],
    standalone: true,
    imports: [NgIf, AwWizardNavComponent, NgClass]
})
export class AwWizardComponent implements AfterContentInit {

    @ContentChildren(WizardStep, { descendants: true })
    public wizardStepsQueryList: QueryList<WizardStep>

    @Input()
    public navBarLayout = 'small';

    @Input()
    public navBarLocation = 'top';

    @Input()
    public get defaultStepIndex(): number {
        // This value can be either:
        // - the index of a wizard step with a `selected` directive, or
        // - the default step index, set in the [[WizardComponent]]

        const foundDefaultStep = this.wizardSteps.find(step => step.defaultSelected);

        if (foundDefaultStep) {
            return this.getIndexOfStep(foundDefaultStep);
        } else {
            return this._defaultStepIndex;
        }

    }

    public set defaultStepIndex(defaultStepIndex: number) {
        this._defaultStepIndex = defaultStepIndex;
    }

    private _defaultStepIndex = 0;

    public getIndexOfStep(step: WizardStep): number {
        return this.wizardSteps.indexOf(step);
    }

    @Input()
    public disableNavigationBar = false;

    private _wizardSteps: WizardStep[] = [];

    /**
     * The index of the currently visible and selected step inside the wizardSteps QueryList.
     * If this wizard contains no steps, currentStepIndex is -1
     *
     * Note: Do not modify this field directly.  Instead, use navigation methods:
     * [[goToStep]], [[goToPreviousStep]], [[goToNextStep]].
     */
    public currentStepIndex = -1;

    constructor() { }

    @HostBinding('class.horizontal')
    public get horizontalOrientation(): boolean {
        return this.navBarLocation === 'top' || this.navBarLocation === 'bottom';
    }

    @HostBinding('class.vertical')
    public get verticalOrientation(): boolean {
        return this.navBarLocation === 'left' || this.navBarLocation === 'right';
    }

    public ngAfterContentInit(): void {
        // add a subscriber to the wizard steps QueryList to listen to changes in the DOM
        this.wizardStepsQueryList.changes.subscribe(changedWizardSteps => {
            this.updateWizardSteps(changedWizardSteps.toArray());
        });


        // initialize the model
        this.updateWizardSteps(this.wizardStepsQueryList.toArray());

        // finally reset the whole wizard component
        setTimeout(() => this.reset());


    }

    public get currentStep(): WizardStep {
        if (this.hasStep(this.currentStepIndex)) {
            return this.wizardSteps[this.currentStepIndex];
        } else {
            return null;
        }
    }

    private updateWizardSteps(wizardSteps: WizardStep[]): void {
        // the wizard is currently not in the initialization phase
        if (this.wizardSteps.length > 0 && this.currentStepIndex > -1) {
            this.currentStepIndex = wizardSteps.indexOf(this.wizardSteps[this.currentStepIndex]);
        }

        this._wizardSteps = wizardSteps;
    }

    public get completed(): boolean {
        return this.wizardSteps.every(step => step.completed || step.optional);
    }

    public hasStep(stepIndex: number): boolean {
        return this.wizardSteps.length > 0 && 0 <= stepIndex && stepIndex < this.wizardSteps.length;
    }

    public goToStep(stepIndex: number): void {
        if (this.hasStep(stepIndex)) {
            if (stepIndex > this.currentStepIndex) {
                this.currentStep.completed = true;
            } else {
                this.currentStep.completed = false;
            }

            this.currentStep.exit(Direction.Forwards);
            this.currentStep.editing = false;
            this.currentStep.selected = false;
            this.currentStepIndex = stepIndex;

            // go to next step
            this.currentStep.enter(Direction.Forwards);
            this.currentStep.selected = true;
            this.currentStep.completed = false;
        }
    }

    public get wizardSteps(): WizardStep[] {
        return this._wizardSteps;
    }

    public reset(): void {
        this.currentStepIndex = this.defaultStepIndex;
        this.wizardSteps.forEach(step => {
            step.completed = step.initiallyCompleted;
            step.selected = false;
            step.editing = false;
        });

        this.currentStepIndex = this.defaultStepIndex;
        this.currentStep.selected = true;
        this.currentStep.enter(Direction.Forwards);

        this.wizardSteps.forEach(step => {
            if (step.selected) {
                console.log(step)
            }
        })
    }


}
