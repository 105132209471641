<app-custom-modal>
    <ng-container modal-title>
        <h4>{{ "users.create_user" | translate }}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a (click)="closeModal()"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog" /></a>
    </ng-container>
    <ng-container modal-body>
        <form tabindex="0" [formGroup]="createUserForm">
            <div class="modal-padding-wrapper">
                <!-- CUSTOMER -->
                <div class="input-group-wrapper uid" *ngIf="isOperator$ | async">
                    <app-custom-tool-tip
                        [forInput]="true"
                        [label]="'misc.customer' | translate"
                        [description]="'users.pick_customer_desc' | translate"
                        [spaceBetween]="true"
                    ></app-custom-tool-tip>
                    <select id="customer_uid" name="customer_uid" class="form-select" formControlName="customer">
                        <option selected [ngValue]="user.uid">
                            {{ "transactions.my_account" | translate }}
                        </option>
                        <option [ngValue]="customer.id" *ngFor="let customer of customers">{{ customer.name }}</option>
                    </select>
                </div>

                <!-- phone number -->
                <div class="input-group-wrapper-feedback">
                    <app-custom-tool-tip
                        [forInput]="true"
                        [label]="'users.phone_number' | translate"
                        [description]="'users.phone_number_desc' | translate"
                        [spaceBetween]="true"
                    ></app-custom-tool-tip>
                    <div class="phone-number-wrapper">
                        <select
                            class="form-select call-code-select"
                            (focus)="removeOptions()"
                            (mouseenter)="removeOptions()"
                            (change)="phoneCodeChanged()"
                            formControlName="phone_number_call_code"
                        >
                            <optgroup label="Most used countries">
                                <option [ngValue]="fav.label" *ngFor="let fav of phoneNumberPicker.fav" [className]="fav.list !== 'fav' ? 'removeOption' : ''">
                                    {{ fav.label }}
                                </option>
                            </optgroup>
                            <optgroup label="All countries">
                                <option [ngValue]="all.label" *ngFor="let all of phoneNumberPicker.all" [className]="all.list !== 'all' ? 'removeOption' : ''">
                                    {{ all.label }}
                                </option>
                            </optgroup>
                        </select>

                        <div style="position: relative">
                            <input
                                autocomplete="new-name"
                                id="phone-number"
                                placeholder="-"
                                name="phone_number"
                                class="form-control"
                                type="text"
                                formControlName="phone_number"
                                (keyup)="checkPhoneNumberInUse()"
                            />
                            <loading-indicator class="loading position" *ngIf="isFetching" [size]="'tiny'" [color]="'primary'"></loading-indicator>
                        </div>
                    </div>

                    <div class="feedback-container">
                        @if (createUserFormSubmitted && phone_number.invalid) {
                            <div class="error-msg" *ngIf="phone_number.errors.required">
                                {{ "location.phone_number_required" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="phone_number.errors.pattern">
                                {{ "location.phone_number_should_be_numbers_only" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="phone_number.errors.TOO_SHORT">
                                {{ "users.phone_to_short" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="phone_number.errors.TOO_LONG">
                                {{ "users.phone_to_long" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="phone_number.errors.user_exists_as_yours">
                                {{ "users.user_exisits_as_yours" | translate }}
                            </div>
                        }
                    </div>
                </div>

                <!-- name -->
                <div class="input-group-wrapper-feedback">
                    <app-custom-tool-tip
                        [forInput]="true"
                        [label]="'users.user_name' | translate"
                        [description]="'users.user_name_desc' | translate"
                        [spaceBetween]="true"
                    ></app-custom-tool-tip>
                    <input autocomplete="new-name" name="name" class="form-control" type="text" formControlName="name" placeholder="-" />
                    <div class="feedback-container">
                        @if (createUserFormSubmitted && name.invalid) {
                            <div class="error-msg" *ngIf="name.errors.required">
                                {{ "misc.name_required" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="name.errors.maxlength">
                                {{ "misc.name_max" | translate }} {{ name.errors.maxlength.requiredLength }}
                                {{ "misc.characters_long" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="name.errors.minlength">
                                {{ "misc.name_min" | translate }} {{ name.errors.minlength.requiredLength }}
                                {{ "misc.characters_long" | translate }}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container modal-footer>
        <div class="d-flex flex-column align-items-center">
            <button class="btn btn-secondary" style="width: 100%" [disabled]="showSmallIndicator || isFetching" (click)="createUser()">
                {{ showSmallIndicator || isFetching ? "" : ("users.add_user" | translate) }}
                <loading-indicator *ngIf="showSmallIndicator || isFetching" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
        </div>
    </ng-container>
</app-custom-modal>
