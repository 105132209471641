import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Coupon } from 'shared_models/coupon';
import { AllowedUpdateFields } from 'shared_models/user-management';
import { HelperService } from 'src/app/services/helper/helper.service';
import { UsersService } from 'src/app/services/users/users.service';
import { LoadingComponent } from '../../../loading/loading.component';
import { NgFor, NgIf } from '@angular/common';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';

@Component({
    selector: 'app-apply-coupon-modal',
    templateUrl: './apply-coupon-modal.component.html',
    styleUrls: ['./apply-coupon-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, FormsModule, ReactiveFormsModule, NgFor, NgIf, LoadingComponent, TranslateModule]
})
export class ApplyCouponModalComponent implements OnInit {
    @Input() userId: string;
    @Input() availableCoupons: Coupon[];
    @Output() onStateChange: EventEmitter<AllowedUpdateFields> = new EventEmitter<AllowedUpdateFields>();
    @Input() appliedCoupons: Coupon[];

    couponForm = new FormGroup({
        selected_coupon: new FormControl(null)
    });
    shouldBeGrey: boolean;
    hasSubmitted = false;

    constructor(
        private usersService: UsersService,
        private translate: TranslateService,
        private helperService: HelperService,
        private modal: NgbModal
    ) {}

    ngOnInit(): void {        
        this.availableCoupons = this.availableCoupons.filter(coupon => !this.appliedCoupons.find(c => c.key === coupon.key));
    }

    async applyCoupon() {
        this.hasSubmitted = true;
        const couponId: string = this.selected_coupon.value;
        const coupon: Coupon = this.availableCoupons.find(c => c.key === couponId);

        const toUpdate: AllowedUpdateFields = {
            applied_coupons: this.appliedCoupons.sort((a, b) => b.creation_date - a.creation_date),
            available_coupons: this.availableCoupons.filter(c => c.key !== couponId)
        };

        await this.usersService.applyCoupons([coupon], this.userId, coupon.owner_uid);
        this.onStateChange.emit(toUpdate);
        this.hasSubmitted = false;
        this.closeModal();
        this.helperService.defaultHtmlToast('', this.translate.instant('users.user.coupons.coupons_updated_succesfully'), 'Success');
    }

    getTerms(coupon: Coupon): string {
        return this.helperService.parseTranslationArray(coupon.description);
    }

    isFocused() {
        this.shouldBeGrey = false;
    }

    isBlurred() {
        this.shouldBeGrey = true;
    }

    closeModal() {
        this.modal.dismissAll();
    }

    get selected_coupon() {
        return this.couponForm.get('selected_coupon');
    }
}
