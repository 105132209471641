import { Component } from '@angular/core';
import { WizardStep } from '../aw-wizard-step/aw-wizard-step.component';
import { AwWizardComponent } from '../aw-wizard/aw-wizard.component';
import { NgFor, NgClass, NgIf, NgTemplateOutlet, NgStyle } from '@angular/common';

@Component({
    selector: 'aw-wizard-nav',
    templateUrl: './aw-wizard-nav.component.html',
    styleUrls: ['./aw-wizard-nav.component.scss'],
    standalone: true,
    imports: [
        NgFor,
        NgClass,
        NgIf,
        NgTemplateOutlet,
        NgStyle,
    ],
})
export class AwWizardNavComponent {
  /**
     * Constructor
     *
     * @param wizard The state the wizard currently resides in
     */
  constructor(public wizard: AwWizardComponent) {
  }

  /**
   * Returns all [[WizardStep]]s contained in the wizard
   *
   * @returns An array containing all [[WizardStep]]s
   */
  get wizardSteps(): WizardStep[] {
    return this.wizard.wizardSteps;
  }

  /**
   * Returns the number of wizard steps, that need to be displaced in the navigation bar
   *
   * @returns The number of wizard steps to be displayed
   */
  get numberOfWizardSteps(): number {
    return this.wizard.wizardSteps.length;
  }

  /**
   * Checks, whether a [[WizardStep]] can be marked as `current` in the navigation bar
   *
   * @param wizardStep The wizard step to be checked
   * @returns True if the step can be marked as `current`
   */
  public isCurrent(wizardStep: WizardStep): boolean {
    return wizardStep.selected;
  }

  /**
   * Checks, whether a [[WizardStep]] can be marked as `editing` in the navigation bar
   *
   * @param wizardStep The wizard step to be checked
   * @returns True if the step can be marked as `editing`
   */
  public isEditing(wizardStep: WizardStep): boolean {
    return wizardStep.editing;
  }

  /**
   * Checks, whether a [[WizardStep]] can be marked as `done` in the navigation bar
   *
   * @param wizardStep The wizard step to be checked
   * @returns True if the step can be marked as `done`
   */
  public isDone(wizardStep: WizardStep): boolean {
    return wizardStep.completed;
  }

  /**
   * Checks, whether a [[WizardStep]] can be marked as `optional` in the navigation bar
   *
   * @param wizardStep The wizard step to be checked
   * @returns True if the step can be marked as `optional`
   */
  public isOptional(wizardStep: WizardStep): boolean {
    return wizardStep.optional;
  }

  // /**
  //  * Checks, whether a [[WizardStep]] can be marked as `completed` in the navigation bar.
  //  *
  //  * The `completed` class is only applied to completion steps.
  //  *
  //  * @param wizardStep The wizard step to be checked
  //  * @returns True if the step can be marked as `completed`
  //  */
  public isCompleted(wizardStep: WizardStep): boolean {
    return wizardStep.completed;
  }

  /**
   * Checks, whether a [[WizardStep]] can be marked as `navigable` in the navigation bar.
   * A wizard step can be navigated to if:
   * - the step is currently not selected
   * - the navigation bar isn't disabled
   * - the navigation mode allows navigation to the step
   *
   * @param wizardStep The wizard step to be checked
   * @returns True if the step can be marked as navigable
   */
  public isNavigable(wizardStep: WizardStep): boolean {
    return !wizardStep.selected && !this.wizard.disableNavigationBar;
  }
}
