<app-custom-modal>
    <ng-container modal-title>
        <h4>{{'users.user.credit_payment_info' | translate}}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a (click)="closeModal()"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog"></a>
    </ng-container>
    <ng-container modal-body>
        <div class="modal-body-wrapper">
            <form [formGroup]="paymentInfoForm">
                <div class="input-group-wrapper">
                    <label>{{'users.user.user_id' | translate}}</label>
                    <input name="user_id" class="form-control" type="text" formControlName="user_id">
                </div>
            </form>
        </div>
    </ng-container>
    <ng-container modal-footer>
        <div class="button-wrapper">
            <app-animated-button>
                <div class="animated-button-btns-wrapper">
                    <button class="btn btn-secondary btn-tiny" (click)="newPaymentCode()">
                        {{'users.user.new_payment_code' | translate}}</button>
                    <button class="btn btn-secondary btn-tiny" (click)="removeCreditPayment()">
                        {{'users.user.remove_credit_payment' |translate}}</button>
                </div>
            </app-animated-button>
            <button class="btn btn-tiny btn-primary mb-3 mt-3" [disabled]="showSmallIndicator" (click)="saveChanges()">
                {{showSmallIndicator ? '' : ('device.save_changes' | translate)}}
                <loading-indicator *ngIf="showSmallIndicator" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
        </div>


    </ng-container>
</app-custom-modal>