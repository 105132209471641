import { Component, input, InputSignal, output, OutputEmitterRef } from '@angular/core';

@Component({
    selector: 'app-aw-switch',
    standalone: true,
    imports: [],
    templateUrl: './aw-switch.component.html',
    styleUrl: './aw-switch.component.scss'
})
/**
 * JSDOC
 *
 * @description This component is a switch component that can be used to toggle between two states.
 *
 * @param controlName: InputSignal<string> = input<string>();
 * @param shouldBeChecked: InputSignal<boolean> = input<boolean>();
 * @param activateClick: OutputEmitterRef<boolean> = output();
 *
 */
export class AwSwitchComponent {
    controlName: InputSignal<string> = input<string>('');
    shouldBeChecked: InputSignal<boolean> = input.required<boolean>();
    activateClick: OutputEmitterRef<boolean> = output();
    disableSwitch: InputSignal<boolean> = input<boolean>(false);

    emitClick() {
        this.activateClick.emit(true);
    }
}
