export interface BranchType {
    code: string;
    name: string;
}

export interface BankType extends BranchType {
    bank_name: string;
    bank_code: string;
}

export const bankNameWithBranches: Record<string, BranchType[]> = {
    'ANOR BANK UZ': [{ name: 'ANOR BANK UZ', code: '00000' }],
    'ASIA ALLIANCE BANK': [
        { name: 'Bosh ofisi', code: '01095' },
        { name: 'Buxoro filiali', code: '01137' },
        { name: 'Chilonzor filial', code: '01153' },
        { name: 'Mirobod filiali', code: '01124' },
        { name: "Mirzo Ulug'bek filiali", code: '01103' },
        { name: 'Olmazor filiali', code: '01102' },
        { name: 'Qarshi filiali', code: '01099' },
        { name: 'Samarqand filiali', code: '01143' },
        { name: 'Shayxontoxur filiali', code: '01057' }
    ],
    'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN': [
        { name: 'Andijon viloyati hisob-kitob kassa markazi', code: '00016' },
        { name: 'Buxoro viloyati hisob-kitob kassa markazi', code: '00017' },
        { name: "Farg'ona viloyati hisob-kitob kassa markazi", code: '00026' },
        { name: 'Jizzax viloyati hisob-kitob kassa markazi', code: '00018' },
        { name: "Markaziy bankning to'lov markazi", code: '09001' },
        { name: 'Namangan viloyati hisob-kitob kassa markazi', code: '00021' },
        { name: 'Navoiy viloyati hisob-kitob kassa markazi', code: '00020' },
        { name: 'Qashqadaryo viloyati hisob-kitob kassa markazi', code: '00019' },
        { name: "Qoraqalpog'iston Respublikasi hisob-kitob kassa markazi", code: '00015' },
        { name: 'Samarqand viloyati hisob-kitob kassa markazi', code: '00022' },
        { name: 'Sirdaryo viloyati hisob-kitob kassa markazi', code: '00023' },
        { name: 'Surxondaryo viloyati hisob-kitob kassa markazi', code: '00024' },
        { name: 'Toshkent shahar hisob-kitob kassa markazi', code: '00014' },
        { name: 'Toshkent viloyati hisob-kitob kassa markazi', code: '00025' },
        { name: 'Xorazm viloyati hisob-kitob kassa markazi', code: '00027' }
    ],
    'CREDIT-STANDARD BANK': [{ name: 'CREDIT-STANDARD BANK', code: '00000' }],
    'HI-TECH BANK PCJSB': [{ name: 'HI-TECH BANK PCJSB', code: '00000' }],
    'INVEST FINANCE BANK': [
        { name: 'Andijon viloyat filiali', code: '01114' },
        { name: 'Bosh ofisi', code: '01041' },
        { name: 'Buxoro viloyat filiali', code: '01107' },
        { name: "Farg'ona viloyat filiali", code: '01128' },
        { name: 'Namangan viloyat filiali', code: '01113' },
        { name: 'Navoiy viloyati filiali', code: '01148' },
        { name: 'Qashqadaryo viloyat filiali', code: '01135' },
        { name: "Qo'qon filiali", code: '01116' },
        { name: "Qoraqalpog'iston filiali", code: '01139' },
        { name: 'Samarqand viloyat filiali', code: '01133' },
        { name: 'Shayhontohur filiali', code: '01141' },
        { name: "Sirg'ali filiali", code: '01070' },
        { name: 'Toshkent viloyat filiali', code: '01056' },
        { name: 'Xorazm viloyat filiali', code: '01129' },
        { name: 'Zangiota filiali', code: '01080' }
    ],
    'IPOTEKA-BANK': [
        { name: 'Andijon viloyat filiali', code: '00059' },
        { name: 'Angren filiali', code: '00461' },
        { name: 'Bekobod filiali', code: '00489' },
        { name: 'Bosh ofisi', code: '00937' },
        { name: 'Buxoro viloyat filiali', code: '00088' },
        { name: 'Chilonzor filiali', code: '00997' },
        { name: 'Chinoz filiali', code: '01039' },
        { name: 'Chirchiq filiali', code: '00478' },
        { name: "Farg'ona viloyat filiali", code: '00544' },
        { name: 'Istiklol filiali', code: '00068' },
        { name: 'Jizzax viloyat filiali', code: '00122' },
        { name: "Ko'ksaroy filiali", code: '00262' },
        { name: 'Mexnat filiali', code: '00423' },
        { name: 'Mirobod filiali', code: '00420' },
        { name: 'Namangan viloyat filiali', code: '00223' },
        { name: 'Navoiy filiali', code: '00205' },
        { name: 'Ohangaron filiali', code: '00487' },
        { name: 'Olmaliq filiali', code: '00459' },
        { name: 'Olmazor filiali', code: '00901' },
        { name: 'Qashqadaryo viloyat filiali', code: '00190' },
        { name: "Qo'qon filiali", code: '01076' },
        { name: "Qoraqalpog'iston Respublikasi filiali", code: '00621' },
        { name: 'Samarkand viloyat filiali', code: '00312' },
        { name: 'Sardor filiali', code: '00119' },
        { name: 'Shahrisabz filiali', code: '00165' },
        { name: 'Shayxontoxur filiali', code: '00425' },
        { name: 'Sirdaryo viloyat filiali', code: '00364' },
        { name: "Sirg'ali filiali", code: '00978' },
        { name: 'Surxondaryo viloyat filiali', code: '00356' },
        { name: 'Tinchlik filiali', code: '00580' },
        { name: "To'lov markazi", code: '09033' },
        { name: 'Toshkent viloyat filiali', code: '00408' },
        { name: 'Xorazm viloyat filiali', code: '00579' },
        { name: 'Yakkasaroy filiali', code: '01017' },
        { name: 'Yashnobod filiali', code: '00959' },
        { name: 'Yunusobod filiali', code: '00837' },
        { name: 'Zangiota filiali', code: '01130' },
        { name: 'Zarafshon filiali', code: '00203' }
    ],
    'JSC ALOQABANK': [
        { name: 'Andijon filiali', code: '00923' },
        { name: 'Buxoro filiali', code: '00961' },
        { name: "Farg'ona filiali", code: '01011' },
        { name: 'Jizzax filiali', code: '01026' },
        { name: 'Mirobod filiali', code: '01091' },
        { name: 'Namangan filiali', code: '00874' },
        { name: 'Navoiy filiali', code: '01007' },
        { name: 'Qashqadaryo filiali', code: '00990' },
        { name: "Qo'qon filiali", code: '01092' },
        { name: "Qoraqalpog'iston filiali", code: '00623' },
        { name: 'Samarqand filiali', code: '00985' },
        { name: 'Surxondaryo filiali', code: '00967' },
        { name: "To'lov markazi", code: '09031' },
        { name: 'Xorazm filiali', code: '00861' }
    ],
    'JSC ASAKABANK': [
        { name: 'Afrosiyob filiali', code: '01004' },
        { name: 'Andijon viloyat filiali', code: '00069' },
        { name: 'Asaka filiali', code: '00073' },
        { name: 'Avtotransport filiali', code: '00417' },
        { name: 'Bosh ofisi', code: '00873' },
        { name: 'Buxoro shahar filiali', code: '01027' },
        { name: 'Buxoro viloyat filiali', code: '00120' },
        { name: "Farg'ona viloyat filiali", code: '00539' },
        { name: 'Farxod filiali', code: '00972' },
        { name: 'Jizzax viloyat filiali', code: '00140' },
        { name: "Marg'ilon filiali", code: '00971' },
        { name: 'Namangan viloyat filiali', code: '00231' },
        { name: 'Navoiy viloyat filiali', code: '00206' },
        { name: 'Oltiariq filiali', code: '00904' },
        { name: 'Qashqadaryo viloyat filiali', code: '00194' },
        { name: "Qo'qon filiali", code: '00631' },
        { name: "Qoraqalpog'iston filiali", code: '00625' },
        { name: 'Samarqand viloyat filiali', code: '00264' },
        { name: 'Shayxontoxur filiali', code: '00969' },
        { name: 'Sirdaryo viloyat filiali', code: '00373' },
        { name: "Sirg'ali filiali", code: '00958' },
        { name: 'Surxondaryo viloyat filiali', code: '00358' },
        { name: 'Toshkent shahar filiali', code: '00416' },
        { name: 'Toshkent viloyat filiali', code: '00411' },
        { name: 'Xorazm viloyat filiali', code: '00581' },
        { name: 'Yunusobod filiali', code: '00963' },
        { name: 'Zarafshon filiali', code: '01021' }
    ],
    'JSC BANK APELSIN': [{ name: 'JSC BANK APELSIN', code: '00000' }],
    'JSC MADAD INVEST BANK': [{ name: 'JSC MADAD INVEST BANK', code: '00000' }],
    'JSC POYTAXT BANK': [{ name: 'JSC POYTAXT BANK', code: '00000' }],
    'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN': [
        { name: 'Amudaryo filiali', code: '00634' },
        { name: 'Andijon viloyat filiali', code: '00649' },
        { name: 'Angor filiali', code: '00751' },
        { name: 'Angren filiali', code: '00784' },
        { name: 'Arnasoy filiali', code: '00683' },
        { name: 'Asaka filiali', code: '00650' },
        { name: 'Baliqchi filiali', code: '00655' },
        { name: 'Baxmal filiali', code: '00681' },
        { name: 'Bekobod filiali', code: '00783' },
        { name: 'Bektemir filiali', code: '00830' },
        { name: 'Beruniy filiali', code: '00636' },
        { name: 'Beshariq filiali', code: '00797' },
        { name: 'Beshkent filiali', code: '00695' },
        { name: "Bo'ka filiali", code: '00782' },
        { name: "Bo'stonliq filiali", code: '00770' },
        { name: "Bo'z filiali", code: '00628' },
        { name: "Bog'dod filiali", code: '00794' },
        { name: "Bog'ishamol filiali", code: '00724' },
        { name: "Bog'ot filiali", code: '00816' },
        { name: 'Boyovut filiali', code: '00762' },
        { name: 'Boysun filiali', code: '00746' },
        { name: 'Buloqboshi filiali', code: '00664' },
        { name: "Bulung'ur filiali", code: '00727' },
        { name: 'Buvayda filiali', code: '00793' },
        { name: 'Buxoro viloyat filiali', code: '00116' },
        { name: 'Chelak filiali', code: '00741' },
        { name: 'Chilonzor filiali', code: '00825' },
        { name: 'Chimboy filiali', code: '00635' },
        { name: 'Chinoz filiali', code: '00780' },
        { name: 'Chirchiq filiali', code: '00781' },
        { name: 'Chiroqchi filiali', code: '00689' },
        { name: 'Chortoq filiali', code: '00721' },
        { name: 'Chust filiali', code: '00715' },
        { name: "Dang'ara filiali", code: '00800' },
        { name: 'Dehqonobod filiali', code: '00693' },
        { name: 'Denov filiali', code: '00747' },
        { name: "Do'stlik filiali", code: '00682' },
        { name: "Do'stobod filiali", code: '00773' },
        { name: "Ellikqal'a filiali", code: '00646' },
        { name: "F.Xo'jayev filiali", code: '00118' },
        { name: "Farg'ona viloyat filiali", code: '00497' },
        { name: 'Forish filiali', code: '00678' },
        { name: 'Furqat filiali', code: '00807' },
        { name: "G'alaosiyo filiali", code: '00672' },
        { name: "G'allaorol filiali", code: '00677' },
        { name: "G'ijduvon filiali", code: '00666' },
        { name: "G'uzor filiali", code: '00692' },
        { name: 'Guliston filiali', code: '00764' },
        { name: 'Gurlan filiali', code: '00809' },
        { name: 'Hisob-kitob markazi', code: '09006' },
        { name: 'Ishtixon filiali', code: '00735' },
        { name: 'Izboskan filiali', code: '00654' },
        { name: 'Jalaquduq filiali', code: '00653' },
        { name: "Jarqo'rg'on filiali", code: '00749' },
        { name: 'Jizzax viloyat filiali', code: '00676' },
        { name: 'Jomboy filiali', code: '00732' },
        { name: 'Jondor filiali', code: '00671' },
        { name: 'Kasbi filiali', code: '00697' },
        { name: "Kattaqo'rg'on filiali", code: '00725' },
        { name: 'Kegeyli filiali', code: '00640' },
        { name: 'Keles filiali', code: '00786' },
        { name: 'Kitob filiali', code: '00694' },
        { name: 'Kogon filiali', code: '00665' },
        { name: 'Koson filiali', code: '00688' },
        { name: 'Kosonsoy filiali', code: '00718' },
        { name: 'Kuyganyor filiali', code: '00658' },
        { name: "Marg'ilon filiali", code: '00790' },
        { name: 'Marxamat filiali', code: '00652' },
        { name: 'Mingbuloq filiali', code: '00713' },
        { name: 'Mirishkor filiali', code: '00699' },
        { name: 'Mirobod filiali', code: '00820' },
        { name: "Mirzacho'l filiali", code: '00679' },
        { name: 'Mirzaobod filiali', code: '00767' },
        { name: "Mirzo Ulug'bek filiali", code: '00822' },
        { name: "Mo'ynoq filiali", code: '00637' },
        { name: 'Muborak filiali', code: '00700' },
        { name: 'Muzrabod filiali', code: '00752' },
        { name: 'Namangan viloyat filiali', code: '00711' },
        { name: 'Narpay filiali', code: '00731' },
        { name: 'Navbaxor filiali', code: '00710' },
        { name: 'Navoiy shahar filiali', code: '00708' },
        { name: 'Navoiy viloyat filiali', code: '00703' },
        { name: 'Nishon filiali', code: '00698' },
        { name: 'Norin filiali', code: '00722' },
        { name: 'Nurobod filiali', code: '00740' },
        { name: 'Nurota filiali', code: '00702' },
        { name: "O'zbekiston filiali", code: '00792' },
        { name: 'Ohangaron filiali', code: '00772' },
        { name: 'Olmaliq filiali', code: '00785' },
        { name: 'Olmazor filiali', code: '00828' },
        { name: 'Olot filiali', code: '00673' },
        { name: 'Oltiariq filiali', code: '00796' },
        { name: "Oltinko'l filiali", code: '00662' },
        { name: 'Oltinsoy filiali', code: '00755' },
        { name: 'Oqdaryo filiali', code: '00728' },
        { name: "Oqmang'it filiali", code: '00647' },
        { name: 'Oqoltin filiali', code: '00766' },
        { name: "Oqqo'rg'on filiali", code: '00779' },
        { name: 'Parkent filiali', code: '00788' },
        { name: "Pastdarg'om filiali", code: '00729' },
        { name: 'Paxtachi filiali', code: '00733' },
        { name: 'Paxtakor filiali', code: '00680' },
        { name: 'Paxtaobod filiali', code: '00657' },
        { name: 'Payariq filiali', code: '00726' },
        { name: 'Payshanba filiali', code: '00737' },
        { name: 'Peshku filiali', code: '00674' },
        { name: 'Pitnak filiali', code: '00818' },
        { name: 'Pop filiali', code: '00714' },
        { name: 'Pskent filiali', code: '00774' },
        { name: 'Qamashi filiali', code: '00696' },
        { name: 'Qashqadaryo viloyat filiali', code: '00687' },
        { name: 'Qibray filiali', code: '00776' },
        { name: 'Qirguli filiali', code: '00632' },
        { name: 'Qiziltepa filiali', code: '00706' },
        { name: 'Qiziriq filiali', code: '00753' },
        { name: "Qo'ng'irot filiali", code: '00639' },
        { name: "Qo'qon filiali", code: '00789' },
        { name: "Qo'rg'ontepa filiali", code: '00659' },
        { name: "Qo'shko'pir filiali", code: '00815' },
        { name: "Qo'shrabod filiali", code: '00739' },
        { name: "Qo'shtepa filiali", code: '00803' },
        { name: "Qonliko'l filiali", code: '00644' },
        { name: "Qorako'l filiali", code: '00667' },
        { name: "Qoraqalpog'iston Respublikasi filiali", code: '00638' },
        { name: 'Qorasuv filiali', code: '00663' },
        { name: 'Qoraul filiali', code: '00817' },
        { name: 'Qorauzak filiali', code: '00645' },
        { name: "Qumqo'rg'on filiali", code: '00754' },
        { name: 'Quva filiali', code: '00799' },
        { name: 'Quvasoy filiali', code: '00802' },
        { name: 'Rishton filiali', code: '00795' },
        { name: 'Romiton filiali', code: '00669' },
        { name: 'Samarqand filiali', code: '00734' },
        { name: 'Samarqand viloyat filiali', code: '01005' },
        { name: 'Sardoba filiali', code: '00763' },
        { name: 'Sariosiyo filiali', code: '00757' },
        { name: 'Sayxunobod filiali', code: '00765' },
        { name: 'Shahrisabz filiali', code: '00690' },
        { name: 'Shaxrixon filiali', code: '00651' },
        { name: 'Shayxontoxur filiali', code: '00821' },
        { name: 'Sherobod filiali', code: '00748' },
        { name: "Sho'rchi filiali", code: '00750' },
        { name: 'Shofirkon filiali', code: '00670' },
        { name: 'Shovot filiali', code: '00810' },
        { name: 'Shumanay filiali', code: '00642' },
        { name: 'Sirdaryo shahar filiali', code: '00760' },
        { name: 'Sirdaryo viloyati filiali', code: '00759' },
        { name: "Sirg'ali filiali", code: '00826' },
        { name: 'Siyob filiali', code: '00738' },
        { name: "So'g' filiali", code: '00806' },
        { name: 'Surxondaryo viloyati filiali', code: '00744' },
        { name: 'Taxiatosh filiali', code: '00643' },
        { name: "Taxtako'pir filiali", code: '00641' },
        { name: "Temiryo'l filiali", code: '00736' },
        { name: "To'raqo'rg'on filiali", code: '00719' },
        { name: "To'rtko'l filiali", code: '00633' },
        { name: "To'ytepa filiali", code: '00775' },
        { name: 'Toshbuloq filiali', code: '00720' },
        { name: "Toshkent shahar filiali amaliyot bo'limi", code: '01132' },
        { name: "Toshkent viloyat filiali amaliyot bo'limi", code: '00883' },
        { name: 'Toshloq filiali', code: '00798' },
        { name: 'Toyloq filiali', code: '00742' },
        { name: "Uchko'prik filiali", code: '00804' },
        { name: 'Uchqizil filiali', code: '00756' },
        { name: "Uchqo'rg'on filiali", code: '00717' },
        { name: 'Uchtepa filiali', code: '00684' },
        { name: 'Uchtepa filiali', code: '00829' },
        { name: 'Urgut filiali', code: '00730' },
        { name: 'Uychi filiali', code: '00716' },
        { name: 'Uzun filiali', code: '00745' },
        { name: 'Vobkent filiali', code: '00668' },
        { name: 'Vodil filiali', code: '00801' },
        { name: 'Xatirchi filiali', code: '00704' },
        { name: 'Xazorasp filiali', code: '00813' },
        { name: 'Xiva filiali', code: '00811' },
        { name: "Xo'jaobod filiali", code: '00656' },
        { name: "Xo'jayli filiali", code: '00839' },
        { name: 'Xonobod filiali', code: '00660' },
        { name: 'Xonqa filiali', code: '00812' },
        { name: 'Xorazm viloyat filiali', code: '00808' },
        { name: 'Xovos filiali', code: '01146' },
        { name: "Yakkabog' filiali", code: '00691' },
        { name: 'Yakkasaroy filiali', code: '00824' },
        { name: 'Yangiariq filiali', code: '00814' },
        { name: 'Yangibozor filiali', code: '00819' },
        { name: "Yangiqo'rg'on filiali", code: '00712' },
        { name: 'Yangiyer filiali', code: '00761' },
        { name: "Yangiyo'l filiali", code: '00777' },
        { name: 'Yashnobod filiali', code: '00827' },
        { name: 'Yozyovon filiali', code: '00805' },
        { name: 'Yunusobod filiali', code: '00823' },
        { name: 'Yuqorichirchiq filiali', code: '00771' },
        { name: 'Zafar filiali', code: '00787' },
        { name: 'Zafarobod filiali', code: '00686' },
        { name: 'Zangiota filiali', code: '00778' },
        { name: 'Zarafshon filiali', code: '00709' },
        { name: 'Zarbdor filiali', code: '00685' },
        { name: 'Zomin filiali', code: '00675' }
    ],
    'JSC ZIRAAT BANK UZBEKISTAN': [
        { name: 'AJ', code: '00395' },
        { name: 'AJ Yunusobod filiali', code: '01138' }
    ],
    'JSCB AGROBANK': [
        { name: 'Amudaryo filiali', code: '00619' },
        { name: 'Andijon filiali', code: '00062' },
        { name: 'Andijon viloyat xududiy boshqarmasi', code: '00077' },
        { name: 'Angor filiali', code: '00343' },
        { name: 'Arnasoy filiali', code: '00124' },
        { name: 'Asaka filiali', code: '00033' },
        { name: 'Baliqchi filiali', code: '00066' },
        { name: 'Batash filiali', code: '00183' },
        { name: 'Baxmal filiali', code: '00132' },
        { name: 'Bekobod filiali', code: '00468' },
        { name: 'Beruniy filiali', code: '00607' },
        { name: 'Beshariq filiali', code: '00513' },
        { name: "Bo'ka filiali", code: '00463' },
        { name: "Bo'z filiali", code: '00053' },
        { name: "Bog'dod filiali", code: '00504' },
        { name: "Bog'ot filiali", code: '00556' },
        { name: 'Boyovut filiali', code: '00382' },
        { name: 'Boysun filiali', code: '00331' },
        { name: 'Buloqboshi filiali', code: '00064' },
        { name: "Bulung'ur filiali", code: '00290' },
        { name: 'Buvayda filiali', code: '00525' },
        { name: 'Buxoro filiali', code: '00097' },
        { name: 'Buxoro viloyat xududiy boshqarmasi', code: '00089' },
        { name: 'Chelak filiali', code: '00318' },
        { name: 'Chimboy filiali', code: '00605' },
        { name: 'Chinoz filiali', code: '00476' },
        { name: 'Chiroqchi filiali', code: '00174' },
        { name: 'Chortoq filiali', code: '00256' },
        { name: 'Chust filiali', code: '00249' },
        { name: "Dang'ara filiali", code: '00510' },
        { name: 'Davlatobod filiali', code: '00259' },
        { name: 'Dehqonobod filiali', code: '00158' },
        { name: 'Denov filiali', code: '00360' },
        { name: "Do'stlik filiali", code: '00129' },
        { name: "Ellikqal'a filiali", code: '00614' },
        { name: "Farg'ona filiali", code: '00507' },
        { name: "Farg'ona viloyat xududiy filiali", code: '00495' },
        { name: 'Forish filiali', code: '00133' },
        { name: 'Furkat filiali', code: '00543' },
        { name: "G'allaorol filiali", code: '00128' },
        { name: "G'ijduvon filiali", code: '00092' },
        { name: "G'uzor filiali", code: '00157' },
        { name: "Go'zalkent filiali", code: '00307' },
        { name: 'Guliston filiali', code: '00386' },
        { name: 'Gurlan filiali', code: '00554' },
        { name: 'Hisob-kitob markazi', code: '09004' },
        { name: 'Ishtixon filiali', code: '00309' },
        { name: 'Izboskan filiali', code: '00045' },
        { name: 'Jalaquduq filiali', code: '00040' },
        { name: "Jarqo'rg'on filiali", code: '00332' },
        { name: 'Jizzax filiali', code: '00123' },
        { name: 'Jizzax viloyat xududiy filiali', code: '00126' },
        { name: 'Jomboy filiali', code: '00270' },
        { name: 'Jondor filiali', code: '00096' },
        { name: 'Karmana filiali', code: '00210' },
        { name: 'Kasbi filiali', code: '00179' },
        { name: "Kattaqo'rg'on filiali", code: '00314' },
        { name: 'Kegeyli filiali', code: '00589' },
        { name: 'Kitob filiali', code: '00176' },
        { name: 'Konimex filiali', code: '00209' },
        { name: 'Koson filiali', code: '00162' },
        { name: 'Kosonsoy filiali', code: '00234' },
        { name: "Marg'ilon filiali", code: '00528' },
        { name: 'Marxamat filiali', code: '00035' },
        { name: 'Mingbuloq filiali', code: '00232' },
        { name: 'Mirishkor filiali', code: '00184' },
        { name: "Mirzacho'l filiali", code: '00130' },
        { name: 'Mirzaobod filiali', code: '00391' },
        { name: "Mo'ynoq filiali", code: '00594' },
        { name: 'Muborak filiali', code: '00187' },
        { name: 'Muzrabod filiali', code: '00334' },
        { name: 'Namangan filiali', code: '00253' },
        { name: 'Namangan viloyat xududiy filiali', code: '00225' },
        { name: 'Narpay filiali', code: '00267' },
        { name: 'Navbaxor filiali', code: '00217' },
        { name: 'Navoiy viloyat xududiy filiali', code: '00197' },
        { name: 'Nishon filiali', code: '00181' },
        { name: 'Norin filiali', code: '00236' },
        { name: 'Nukus filiali', code: '00595' },
        { name: 'Nurobod filiali', code: '00291' },
        { name: 'Nurota filiali', code: '00214' },
        { name: "O'rtachirchiq filiali", code: '00475' },
        { name: "O'zbekiston filiali", code: '00533' },
        { name: 'Ohangaron filiali', code: '00413' },
        { name: 'Olot filiali', code: '00090' },
        { name: 'Oltiariq filiali', code: '00502' },
        { name: "Oltinko'l filiali", code: '00049' },
        { name: 'Oltinsoy filiali', code: '00351' },
        { name: 'Oqdaryo filiali', code: '00265' },
        { name: 'Oqoltin filiali', code: '00390' },
        { name: "Oqqo'rg'on filiali", code: '00457' },
        { name: 'Parkent filiali', code: '00482' },
        { name: "Pastdarg'om filiali", code: '00273' },
        { name: 'Paxtachi filiali', code: '00297' },
        { name: 'Paxtakor filiali', code: '00127' },
        { name: 'Paxtaobod filiali', code: '00031' },
        { name: 'Payariq filiali', code: '00294' },
        { name: 'Payshanba filiali', code: '00275' },
        { name: 'Peshku filiali', code: '00098' },
        { name: 'Pitnak filiali', code: '00572' },
        { name: 'Pop filiali', code: '00238' },
        { name: 'Pskent filiali', code: '00472' },
        { name: 'Qamashi filiali', code: '00160' },
        { name: 'Qarshi filiali', code: '00172' },
        { name: 'Qashqadaryo viloyat xududiy filiali', code: '00151' },
        { name: 'Qiziltepa filiali', code: '00212' },
        { name: 'Qiziriq filiali', code: '00345' },
        { name: "Qo'ng'irot filiali", code: '00592' },
        { name: "Qo'qon filiali", code: '00546' },
        { name: "Qo'rg'ontepa filiali", code: '00056' },
        { name: "Qo'shko'pir filiali", code: '00565' },
        { name: "Qo'shrabod filiali", code: '00313' },
        { name: "Qo'shtepa filiali", code: '00515' },
        { name: "Qonliko'l filiali", code: '00609' },
        { name: "Qorako'l filiali", code: '00094' },
        { name: "Qoraqalpog'iston xududiy filiali", code: '00583' },
        { name: 'Qorauzak filiali', code: '00612' },
        { name: 'Qorovulbozor filiali', code: '00099' },
        { name: "Qumqo'rg'on filiali", code: '00347' },
        { name: 'Quva filiali', code: '00529' },
        { name: 'Quvasoy filiali', code: '00517' },
        { name: 'Quyichirchiq filiali', code: '00474' },
        { name: 'Rishton filiali', code: '00519' },
        { name: 'Romitan filiali', code: '00095' },
        { name: 'Samarqand filiali', code: '00929' },
        { name: 'Samarqand viloyat xududiy filiali', code: '00279' },
        { name: 'Sardoba filiali', code: '00374' },
        { name: 'Sariosiyo filiali', code: '00355' },
        { name: 'Sayxunobod filiali', code: '00388' },
        { name: 'Shahrisabz filiali', code: '00166' },
        { name: 'Shaxrixon filiali', code: '00037' },
        { name: 'Sherobod filiali', code: '00337' },
        { name: 'Shirin filiali', code: '00383' },
        { name: "Sho'rchi filiali", code: '00339' },
        { name: 'Shofirkon filiali', code: '00093' },
        { name: 'Shovot filiali', code: '00567' },
        { name: 'Shumanay filiali', code: '00590' },
        { name: 'Sirdaryo filiali', code: '00375' },
        { name: 'Sirdaryo viloyat xududiy filiali', code: '00365' },
        { name: 'Siyob filiali', code: '00280' },
        { name: "So'x filiali", code: '00542' },
        { name: 'Surxondaryo viloyat xududiy filiali', code: '00325' },
        { name: "Taxtako'pir filiali", code: '00597' },
        { name: 'Termiz filiali', code: '00349' },
        { name: "To'raqo'rg'on filiali", code: '00241' },
        { name: "To'rtko'l filiali", code: '00598' },
        { name: 'Tomdi filiali', code: '00216' },
        { name: 'Toshkent shahar filiali', code: '00394' },
        { name: 'Toshkent viloyat xududiy filiali', code: '00454' },
        { name: 'Toshloq filiali', code: '00522' },
        { name: 'Toyloq filiali', code: '00321' },
        { name: "Uchko'prik filiali", code: '00511' },
        { name: "Uchqo'rg'on filiali", code: '00246' },
        { name: "Ulug'nor filiali", code: '00055' },
        { name: 'Urganch filiali', code: '00569' },
        { name: 'Urgut filiali', code: '00300' },
        { name: 'Uychi filiali', code: '00244' },
        { name: 'Uzun filiali', code: '00341' },
        { name: 'Vobkent filiali', code: '00091' },
        { name: 'Xatirchi filiali', code: '00219' },
        { name: 'Xiva filiali', code: '00577' },
        { name: "Xo'jaobod filiali", code: '00042' },
        { name: "Xo'jayli filiali", code: '00602' },
        { name: 'Xonqa filiali', code: '00560' },
        { name: 'Xorazm viloyat xududiy filiali', code: '00548' },
        { name: 'Xovos filiali', code: '00392' },
        { name: 'Xozorasp filiali', code: '00558' },
        { name: "Yakkabog' filiali", code: '00169' },
        { name: 'Yangiariq filiali', code: '00563' },
        { name: 'Yangibozor filiali', code: '00574' },
        { name: "Yangiqo'rg'on filiali", code: '00251' },
        { name: 'Yangiyer filiali', code: '00378' },
        { name: "Yangiyo'l filiali", code: '00485' },
        { name: 'Yozyovon filiali', code: '00541' },
        { name: 'Yuqorichirchiq filiali', code: '00480' },
        { name: 'Zafarobod filiali', code: '00134' },
        { name: 'Zarbdor filiali', code: '00125' },
        { name: 'Zomin filiali', code: '00131' }
    ],
    'JSCB HAMKORBANK': [
        { name: 'Angren filiali', code: '01149' },
        { name: 'Asaka filiali', code: '00070' },
        { name: 'Aviasozlik filiali', code: '01008' },
        { name: "Bog'ishamol filiali", code: '01126' },
        { name: 'Bosh ofisi', code: '00083' },
        { name: 'Buxoro filiali', code: '00966' },
        { name: 'Chilonzor filiali', code: '01108' },
        { name: 'Chinobod filiali', code: '00962' },
        { name: 'Chinoz filiali', code: '00960' },
        { name: 'Chirchiq filiali', code: '01159' },
        { name: 'Chust filiali', code: '01160' },
        { name: 'Denov filiali', code: '01090' },
        { name: "Farg'ona mintaqaviy filiali", code: '01155' },
        { name: 'Guliston filiali', code: '01118' },
        { name: 'Istiqbol filiali', code: '00965' },
        { name: 'Jalaquduq filiali', code: '00977' },
        { name: 'Jizzax filiali', code: '01117' },
        { name: "Marg'ilon filiali", code: '00991' },
        { name: 'Namangan mintaqaviy filiali', code: '01156' },
        { name: 'Navoiy filiali', code: '01064' },
        { name: 'Nodirabegim filiali', code: '00079' },
        { name: 'Nukus filiali', code: '01068' },
        { name: 'Olmaliq filiali', code: '00988' },
        { name: 'Qarshi filiali', code: '01031' },
        { name: "Qo'qon filiali", code: '00956' },
        { name: "Qo'rg'ontepa filiali", code: '00071' },
        { name: 'Quvasoy filiali', code: '01003' },
        { name: 'Samarqand filiali', code: '01030' },
        { name: 'Shaxrixon filiali', code: '00039' },
        { name: 'Shayxontoxur filiali', code: '01127' },
        { name: 'Termiz filiali', code: '01001' },
        { name: 'Toshkent filiali', code: '00443' },
        { name: 'Urganch filiali', code: '01020' },
        { name: 'Urgut filiali', code: '01157' },
        { name: 'Xazorasp filiali', code: '01162' },
        { name: "Xo'jaobod filiali", code: '00072' },
        { name: 'Xorazm mintaqaviy filiali', code: '01161' },
        { name: 'Yakkasaroy filiali', code: '01013' },
        { name: "Yangiyo'l filiali", code: '00976' },
        { name: 'Yuksalish filiali', code: '00992' }
    ],
    'JSCB SAVDOGAR': [
        { name: 'Andijon filiali', code: '00081' },
        { name: "Bo'ka filiali", code: '00465' },
        { name: 'Bosh ofisi', code: '00439' },
        { name: 'Buxoro filiali', code: '00112' },
        { name: 'Denov filiali', code: '00362' },
        { name: "Do'stlik filiali", code: '00138' },
        { name: "Farg'ona filiali", code: '00500' },
        { name: "G'azalkent filiali", code: '00418' },
        { name: 'Ishtixon filiali', code: '00311' },
        { name: 'Namangan filiali', code: '00227' },
        { name: 'Navoiy viloyat filiali', code: '00201' },
        { name: 'Ohangaron filiali', code: '01134' },
        { name: 'Qashqadaryo viloyat filiali', code: '00154' },
        { name: 'Qibray filiali', code: '00410' },
        { name: 'Savdogarbank Samarqand viloyat filiali', code: '00286' },
        { name: 'Shayxontohur filiali', code: '01105' },
        { name: "To'rtko'l filiali", code: '00600' },
        { name: 'Toshkent shahar filiali', code: '00835' },
        { name: "Ulug'bek filiali", code: '00304' },
        { name: 'Urgut filiali', code: '00303' },
        { name: 'Xorazm viloyat filiali', code: '00552' },
        { name: 'Zangiota filiali', code: '00414' }
    ],
    'JSCB TBC BANK': [{ name: 'JSCB TBC BANK', code: '00000' }],
    'JSCB TENGE BANK': [{ name: 'JSCB TENGE BANK', code: '00000' }],
    'JSCB TURONBANK': [
        { name: 'Bukhara filiali', code: '00111' },
        { name: 'Chilanzar filiali', code: '01084' },
        { name: 'Fergana filiali', code: '00498' },
        { name: 'Jizzah filiali', code: '00982' },
        { name: 'Karshi filiali', code: '00192' },
        { name: 'Khamza filiali', code: '01083' },
        { name: 'Khorezm filiali', code: '00551' },
        { name: 'Mirobod filiali', code: '00986' },
        { name: "Mirzo Ulug'bek filiali", code: '01154' },
        { name: 'Namangan filiali', code: '00226' },
        { name: 'Navoi filiali', code: '00200' },
        { name: 'Nukus filiali', code: '00585' },
        { name: 'Samarkand filiali', code: '00282' },
        { name: 'Shakhrisabz filiali', code: '00989' },
        { name: 'Surkhandarya filiali', code: '00328' },
        { name: 'Syrdarya filiali', code: '00368' },
        { name: "To'lov markazi", code: '09011' },
        { name: 'Yunusobod filiali', code: '01144' },
        { name: 'Zangiota filiali', code: '01019' }
    ],
    'JSCB UZAGROEXPORTBANK': [{ name: 'JSCB UZAGROEXPORTBANK', code: '00000' }],
    'JSIC BANK IPAK YULI': [
        { name: 'Andijan', code: '01120' },
        { name: 'Bosh ofisi', code: '00444' },
        { name: 'Chilanzar', code: '01067' },
        { name: 'Karshi', code: '01119' },
        { name: 'Mirabad filiali', code: '01101' },
        { name: 'Mirzo Ulugbek filiali', code: '00421' },
        { name: 'Namangan', code: '01054' },
        { name: 'Navoi filiali', code: '00199' },
        { name: "Regional filiali 'Umar'", code: '00283' },
        { name: 'Sagbon', code: '01036' },
        { name: 'Shakhrisabz', code: '01048' },
        { name: 'Shayhontohur', code: '01145' },
        { name: 'Urikzor filiali', code: '00999' },
        { name: 'Yakkasaray filiali', code: '01028' },
        { name: 'Yangiyul', code: '01081' }
    ],
    'KAPITALBANK, JSCB': [
        { name: 'Amaliyotlar boshqarmasi', code: '00974' },
        { name: 'Bosh ofisi', code: '01088' },
        { name: 'Buxoro filiali', code: '01082' },
        { name: 'Chorsu filiali', code: '01033' },
        { name: "Farg'ona filiali", code: '01043' },
        { name: "Mirzo-Ulug'bek filiali", code: '01018' },
        { name: 'Namangan filiali', code: '01085' },
        { name: 'Nukus filiali', code: '01038' },
        { name: "Qo'qon filiali", code: '01087' },
        { name: 'Samarqand filiali', code: '01061' },
        { name: "Sirg'ali filiali", code: '01042' },
        { name: 'Termiz filiali', code: '01032' },
        { name: 'Toshkent shahar filiali', code: '00445' },
        { name: 'Yashnobod filiali', code: '01136' }
    ],
    'KDB BANK UZBEKISTAN JSC': [
        { name: "O'zbekiston Bosh ofisi", code: '00842' },
        { name: "O'zbekiston Yakkasaroy filiali", code: '01065' },
        { name: "O'zbekiston Yunusobod filiali", code: '00831' }
    ],
    MICROCREDITBANK: [
        { name: 'Andijon viloyat filiali', code: '00078' },
        { name: 'Angor filiali', code: '00344' },
        { name: 'Asaka filiali', code: '00034' },
        { name: 'Baliqchi filiali', code: '00067' },
        { name: 'Beshkent filiali', code: '00173' },
        { name: "Bog'ot filiali", code: '00557' },
        { name: 'Boyovut filiali', code: '00384' },
        { name: "Bulung'ur filiali", code: '00289' },
        { name: 'Buxoro viloyat filiali', code: '00109' },
        { name: 'Chimboy filiali', code: '01093' },
        { name: 'Chiroqchi filiali', code: '00175' },
        { name: 'Chortoq filiali', code: '01044' },
        { name: 'Chust filiali', code: '00250' },
        { name: 'Denov filiali', code: '00361' },
        { name: "Ellikqal'a filiali", code: '01055' },
        { name: "Farg'ona viloyat filiali", code: '00496' },
        { name: "G'allaorol filiali", code: '00142' },
        { name: "G'azalkent filiali", code: '00467' },
        { name: "G'ijduvon filiali", code: '00104' },
        { name: "Jarqo'rg'on filiali", code: '00333' },
        { name: 'Jizzax viloyat filiali', code: '00135' },
        { name: 'Jondor filiali', code: '00108' },
        { name: 'Juma filiali', code: '01047' },
        { name: "Jumasho'y filiali", code: '00233' },
        { name: 'Karmana filiali', code: '00211' },
        { name: "Kattaqo'rg'on filiali", code: '00315' },
        { name: 'Keles filiali', code: '00470' },
        { name: 'Kitob filiali', code: '00177' },
        { name: 'Kogon filiali', code: '00106' },
        { name: 'Koson filiali', code: '00163' },
        { name: 'Kosonsoy filiali', code: '01049' },
        { name: 'Kuygan-yor filiali', code: '00063' },
        { name: 'Loish filiali', code: '00266' },
        { name: "Mang'it filiali", code: '00620' },
        { name: "Mirzacho'l filiali", code: '00144' },
        { name: 'Muborak filiali', code: '00188' },
        { name: 'Muzrabod filiali', code: '00335' },
        { name: 'Namangan viloyat filiali', code: '00260' },
        { name: 'Navoiy viloyat filiali', code: '00198' },
        { name: "Oltinko'l filiali", code: '00050' },
        { name: "Oqqo'rg'on filiali", code: '00458' },
        { name: 'Oqtosh filiali', code: '00268' },
        { name: 'Oxunboboyev filiali', code: '00041' },
        { name: 'Parkent filiali', code: '00483' },
        { name: 'Paxtaobod filiali', code: '00032' },
        { name: 'Pop filiali', code: '00239' },
        { name: 'Pskent filiali', code: '00473' },
        { name: 'Qamashi filiali', code: '00161' },
        { name: 'Qashqadaryo viloyat filiali', code: '00152' },
        { name: 'Qiziltepa filiali', code: '00213' },
        { name: 'Qiziriq filiali', code: '00346' },
        { name: "Qo'qon filiali", code: '01089' },
        { name: "Qoraqalpog'iston Respublikasi filiali", code: '00584' },
        { name: 'Qorasaroy filiali', code: '01147' },
        { name: 'Qorovul filiali', code: '00570' },
        { name: "Qumqo'rg'on filiali", code: '00348' },
        { name: 'Rishton filiali', code: '00520' },
        { name: 'Romiton filiali', code: '00110' },
        { name: 'Samarqand viloyat filiali', code: '00281' },
        { name: 'Shahrisabz filiali', code: '00167' },
        { name: 'Shaxrixon filiali', code: '00038' },
        { name: 'Sherobod filiali', code: '00338' },
        { name: 'Shofirkon filiali', code: '00101' },
        { name: 'Shovot filiali', code: '00568' },
        { name: 'Sirdaryo filiali', code: '00376' },
        { name: 'Sirdaryo viloyat filiali', code: '00366' },
        { name: 'Surxondaryo viloyat filiali', code: '00326' },
        { name: "To'rtko'l filiali", code: '00599' },
        { name: 'Toshbuloq filiali', code: '00254' },
        { name: 'Toshkent viloyat filiali', code: '00455' },
        { name: "Uchko'prik filiali", code: '01052' },
        { name: 'Uchqizil filiali', code: '00350' },
        { name: 'Uchquduq filiali', code: '01073' },
        { name: 'Urgut filiali', code: '00301' },
        { name: 'Uzun filiali', code: '00342' },
        { name: 'Xiva filiali', code: '00578' },
        { name: "Xo'jayli filiali", code: '01106' },
        { name: 'Xorazm viloyat filiali', code: '00549' },
        { name: "Yakkabog' filiali", code: '01058' },
        { name: 'Yangi Nishon filiali', code: '00182' },
        { name: 'Yangibozor filiali', code: '00100' },
        { name: "Yangiqo'rg'on filiali", code: '00252' },
        { name: 'Zarbdor filiali', code: '00149' },
        { name: 'Ziyovuddin filiali', code: '00298' },
        { name: 'Zomin filiali', code: '00145' }
    ],
    'MINISTRY OF FINANCE THE REPUBLIC OF UZBEKISTAN': [{ name: 'MINISTRY OF FINANCE THE REPUBLIC OF UZBEKISTAN', code: '00000' }],
    'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN': [
        { name: 'Almazar filiali', code: '00876' },
        { name: 'Andijan filiali', code: '00074' },
        { name: 'Bektemir filiali', code: '00930' },
        { name: 'Bukhara filiali', code: '00084' },
        { name: 'Djizzak filiali', code: '00121' },
        { name: 'Fergana filiali', code: '00493' },
        { name: 'Karakalpak filiali', code: '00582' },
        { name: 'Mirabad filiali', code: '00875' },
        { name: 'Mirzo Ulugbek filiali', code: '00895' },
        { name: 'Namangan filiali', code: '00222' },
        { name: 'Navoi filiali', code: '00196' },
        { name: 'Samarkand filiali', code: '00278' },
        { name: 'Sayokhat filiali', code: '00905' },
        { name: 'Sergeli filiali', code: '00869' },
        { name: 'Shaykhontohur filiali', code: '00878' },
        { name: 'Sirdarya filiali', code: '00363' },
        { name: 'Tashkent Regional filiali', code: '00832' },
        { name: 'Termez filiali', code: '00324' },
        { name: 'Uchtepa filiali', code: '00918' },
        { name: 'Urgench filiali', code: '00547' },
        { name: 'Yakkasaray filiali', code: '00870' },
        { name: 'Yangiabad filiali', code: '01077' },
        { name: 'Yashnabad filiali', code: '00847' },
        { name: 'Yunusabad filiali', code: '00836' }
    ],
    'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN': [
        { name: 'Akademiya filiali', code: '00431' },
        { name: 'Angren filiali', code: '00890' },
        { name: 'Asaka filiali', code: '00893' },
        { name: 'Bekobod filiali', code: '00912' },
        { name: 'Beshariq filiali', code: '00885' },
        { name: "Bosh amaliyot bo'limi filiali", code: '00407' },
        { name: "Bulung'ur filiali", code: '00900' },
        { name: 'Buvayda filiali', code: '00887' },
        { name: 'Chimboy filiali', code: '00908' },
        { name: 'Chirchiq filiali', code: '00919' },
        { name: 'Chortoq filiali', code: '00932' },
        { name: 'Chust filiali', code: '00843' },
        { name: 'Denov filiali', code: '00845' },
        { name: "G'azalkent filiali", code: '00931' },
        { name: "G'ijduvon filiali", code: '00849' },
        { name: "G'uzor filiali", code: '00894' },
        { name: 'Gurlan filiali', code: '00859' },
        { name: 'Hisob-kitob kliring markazi', code: '09002' },
        { name: 'Izboskan filiali', code: '00903' },
        { name: 'Jomboy filiali', code: '00913' },
        { name: "Kattaqo'rg'on filiali", code: '00840' },
        { name: 'Kogon filiali', code: '00899' },
        { name: 'Korovulbozor filiali', code: '00925' },
        { name: 'Kosonsoy filiali', code: '00889' },
        { name: 'Malikrabot filiali', code: '01094' },
        { name: "Marg'ilon filiali", code: '00536' },
        { name: "Markaziy amaliyotlar bo'limi filiali", code: '00882' },
        { name: 'Marxamat filiali', code: '00955' },
        { name: "Mirzacho'l filiali", code: '00916' },
        { name: 'Muborak filiali', code: '00844' },
        { name: 'Nurobod filiali', code: '00939' },
        { name: 'Olmaliq filiali', code: '00906' },
        { name: "Pastdarg'om filiali", code: '00941' },
        { name: 'Paxtakor filiali', code: '00892' },
        { name: 'Paxtaobod filiali', code: '00879' },
        { name: "Qarshi bo'limi", code: '00150' },
        { name: 'Qiziltepa filiali', code: '00945' },
        { name: "Qo'ng'irot filiali", code: '00888' },
        { name: "Qo'qon filiali", code: '00545' },
        { name: "Qo'rg'ontepa filiali", code: '00841' },
        { name: 'Qorakul filiali', code: '00910' },
        { name: "Qumqo'rg'on filiali", code: '00914' },
        { name: 'Quva filiali', code: '00886' },
        { name: 'Quvasoy filiali', code: '00856' },
        { name: 'Registon filiali', code: '01112' },
        { name: 'Rishton filiali', code: '00946' },
        { name: 'Romiton filiali', code: '00943' },
        { name: 'Shahrisabz filiali', code: '00191' },
        { name: 'Shaxrixon filiali', code: '00922' },
        { name: 'Sherobod filiali', code: '00938' },
        { name: 'Shofirkon filiali', code: '00927' },
        { name: 'Shovot filiali', code: '00907' },
        { name: "To'rtko'l filiali", code: '00846' },
        { name: "To'ytepa filiali", code: '00920' },
        { name: 'Toshkent shahar Bosh boshqarmasi', code: '00451' },
        { name: "Turaqo'rg'on filiali", code: '00891' },
        { name: "Uchqo'rg'on filiali", code: '00881' },
        { name: 'Uchquduq filiali', code: '00880' },
        { name: 'Urgut filiali', code: '00865' },
        { name: 'Uychi filiali', code: '00877' },
        { name: 'Vobkent filiali', code: '00942' },
        { name: 'Xiva filiali', code: '00833' },
        { name: "Xo'jayli filiali", code: '00622' },
        { name: 'Xonqa filiali', code: '00915' },
        { name: 'Xozorasp filiali', code: '00860' },
        { name: 'Yangi Nishon filiali', code: '00921' },
        { name: 'Yangiariq filiali', code: '00940' },
        { name: "Yangiyo'l filiali", code: '00911' },
        { name: 'Zarafshon filiali', code: '00204' }
    ],
    'OJSC MICROCREDITBANK': [{ name: 'OJSC MICROCREDITBANK', code: '00000' }],
    "PJSCB 'ORIENT FINANCE'": [
        { name: 'Bosh ofisi', code: '01071' },
        { name: 'Chilonzor filiali', code: '01096' },
        { name: 'Markaziy amaliyet fililali', code: '01110' },
        { name: 'Samarkand filiali', code: '01111' },
        { name: 'Shayxontoxur filiali', code: '01115' },
        { name: 'Yakkasaroy filiali', code: '01078' },
        { name: 'Yunusobod filiali', code: '01075' }
    ],
    'PJSCB DAVR BANK': [
        { name: 'Bosh ofisi', code: '00981' },
        { name: 'Chilonzor filiali', code: '01046' },
        { name: "Mirzo Ulug'bek filiali", code: '01072' },
        { name: 'Olmazor filiali', code: '01121' },
        { name: 'Uchtepa filiali', code: '01086' },
        { name: 'Yakkasaroy filiali', code: '01069' },
        { name: 'Yunusobod filiali', code: '01122' }
    ],
    'PJSCB TURKISTON': [
        { name: 'Bosh ofisi', code: '01104' },
        { name: 'Yunusobod filiali', code: '00898' }
    ],
    'PJSCB UNIVERSAL BANK': [
        { name: 'Bosh ofisi', code: '00973' },
        { name: 'Buvayda filiali', code: '01151' },
        { name: "Farg'ona filiali", code: '01131' },
        { name: 'PJSCB UNIVERSAL BANK', code: '00000' },
        { name: 'Toshkent filiali', code: '00996' }
    ],
    'QISHLOQ QURILISH BANK JSCB': [
        { name: 'Andijon viloyat mintaqaviy filiali', code: '00082' },
        { name: 'Bekobod filiali', code: '00924' },
        { name: 'Beruniy filiali', code: '00953' },
        { name: 'Buxoro viloyat mintaqaviy filiali', code: '00115' },
        { name: 'Chimboy filiali', code: '00626' },
        { name: "Farg'ona viloyat mintaqaviy filiali", code: '00501' },
        { name: "G'alaosiyo filiali", code: '00975' },
        { name: "G'azalkent filiali", code: '00466' },
        { name: "Jarqo'rg'on filiali", code: '00933' },
        { name: 'Jizzax viloyat mintaqaviy filiali', code: '00139' },
        { name: "Kattaqo'rg'on filiali", code: '00316' },
        { name: "Ko'kdala filiali", code: '00156' },
        { name: 'Kogon filiali', code: '00087' },
        { name: "Marg'ilon filiali", code: '00527' },
        { name: 'Namangan viloyat mintaqaviy filiali', code: '00229' },
        { name: 'Navoiy viloyat mintaqaviy filiali', code: '00202' },
        { name: 'Ohangaron filiali', code: '00866' },
        { name: 'Pop filiali', code: '00240' },
        { name: 'Qashqadaryo viloyat mintaqaviy filiali', code: '00155' },
        { name: "Qo'ng'irot filiali", code: '00611' },
        { name: "Qo'qon filiali", code: '00630' },
        { name: "Qo'rg'ontepa filiali", code: '00058' },
        { name: "Qoraqalpog'iston mintaqaviy filiali", code: '00588' },
        { name: "Qumqo'rg'on filiali", code: '01010' },
        { name: 'Quva filiali', code: '00867' },
        { name: 'Samarqand viloyat mintaqaviy filiali', code: '00287' },
        { name: 'Sariosiyo filiali', code: '00934' },
        { name: "Sho'rchi filiali", code: '00359' },
        { name: 'Sirdaryo viloyat mintaqaviy filiali', code: '00370' },
        { name: 'Surxondaryo viloyat mintaqaviy filiali', code: '00330' },
        { name: "To'lov markazi", code: '09009' },
        { name: 'Toshkent shahar mintaqaviy filiali', code: '00452' },
        { name: 'Toshkent viloyat mintaqaviy filiali', code: '00987' },
        { name: "Uchqo'rg'on filiali", code: '00248' },
        { name: 'Xiva filiali', code: '00576' },
        { name: "Xo'jaobod filiali", code: '01079' },
        { name: 'Xonqa filiali', code: '00562' },
        { name: 'Xorazm viloyat mintaqaviy filiali', code: '00553' },
        { name: "Yakkabog' filiali", code: '00171' },
        { name: 'Yangiobod filiali', code: '01016' },
        { name: 'Yangiyer filiali', code: '00909' },
        { name: "Yangiyo'l filiali", code: '00484' }
    ],
    'RAVNAQ-BANK': [{ name: 'RAVNAQ-BANK', code: '00000' }],
    'REPUBLICAN STOCK EXCHANGE (TOSHKENT)': [{ name: 'REPUBLICAN STOCK EXCHANGE (TOSHKENT)', code: '00000' }],
    "THE STATE ENTERPRISE 'CENTRAL SECURITIES DEPOSITORY'": [{ name: "THE STATE ENTERPRISE 'CENTRAL SECURITIES DEPOSITORY'", code: '00000' }],
    'THE UZBEKISTAN REPUBLICAN CURRENCY EXCHANGE': [{ name: 'THE UZBEKISTAN REPUBLICAN CURRENCY EXCHANGE', code: '00000' }],
    'TRUSTBANK, PJSB': [
        { name: 'Andijon filiali', code: '01074' },
        { name: 'Bosh ofisi', code: '00491' },
        { name: 'Darxon filiali', code: '00954' },
        { name: "Farg'ona filiali", code: '01123' },
        { name: 'Namangan filiali', code: '01060' },
        { name: 'Qarshi filiali', code: '01109' },
        { name: 'Samarqand filiali', code: '01097' },
        { name: 'Termiz filiali', code: '01063' },
        { name: 'Toshkent filiali', code: '00850' },
        { name: 'Yakkasaroy filiali', code: '01098' }
    ],
    'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB': [
        { name: 'Al-Xorazmiy filiali', code: '00434' },
        { name: 'Amir Temur filiali', code: '00994' },
        { name: 'Andijon mintaqaviy filiali', code: '00075' },
        { name: 'Angren filiali', code: '01022' },
        { name: 'Bekobod filiali', code: '01100' },
        { name: 'Bosh ofisi', code: '00440' },
        { name: 'Bunyodkor filiali', code: '00076' },
        { name: 'Buxoro mintaqaviy filiali', code: '00086' },
        { name: 'Chilonzor filiali', code: '00427' },
        { name: 'Chirchiq filiali', code: '00863' },
        { name: "Farg'ona mintaqaviy filiali", code: '00494' },
        { name: 'Jizzax mintaqaviy filiali', code: '01152' },
        { name: 'Markaziy amaliyot mintaqaviy filiali', code: '00442' },
        { name: 'Mirobod filiali', code: '00405' },
        { name: "Mirzo Ulug'bek filiali", code: '00399' },
        { name: 'Muborak filiali', code: '00186' },
        { name: 'Namangan mintaqaviy filiali', code: '00224' },
        { name: 'Navoiy mintaqaviy filiali', code: '00207' },
        { name: 'Nodirabegim filiali', code: '00402' },
        { name: 'Olmaliq filiali', code: '01034' },
        { name: 'Olmazor filiali', code: '00398' },
        { name: 'Qashqadaryo mintaqaviy filiali', code: '00854' },
        { name: 'Qatortol filiali', code: '00426' },
        { name: 'Qibray filiali', code: '00884' },
        { name: 'Qirguli filiali', code: '00524' },
        { name: "Qo'ng'irot filiali", code: '00591' },
        { name: "Qo'qon filiali", code: '00531' },
        { name: "Qoraqalpog'iston mintaqaviy filiali", code: '00868' },
        { name: 'Qorovulbozor filiali', code: '00085' },
        { name: 'Rakat filiali', code: '00435' },
        { name: 'Samarqand mintaqaviy filiali', code: '00317' },
        { name: 'Shahriston filiali', code: '00400' },
        { name: "Sharg'un filiali", code: '00354' },
        { name: 'Sirdaryo mintaqaviy filiali', code: '00855' },
        { name: "Sirg'ali filiali", code: '00403' },
        { name: 'Surxondaryo mintaqaviy filiali', code: '01045' },
        { name: 'Taxiatosh filiali', code: '00616' },
        { name: "To'qimachilik filiali", code: '00862' },
        { name: 'Toshkent shahar mintaqaviy filiali', code: '00424' },
        { name: 'Toshkent viloyat mintaqaviy filiali', code: '00490' },
        { name: 'Uchtepa filiali', code: '00397' },
        { name: 'Xonobod filiali', code: '00051' },
        { name: 'Xorazm mintaqaviy filiali', code: '00853' },
        { name: 'Yashnabod filiali', code: '00432' },
        { name: 'Yunusobod filiali', code: '00428' }
    ]
};

// Not currently being used, but is the data fetched from STRIPE, all other data with this is something that has been modified to work with the current system

export const branches: BankType[] = [
    {
        bank_code: 'ANOBUZ22XXX',
        bank_name: 'ANOR BANK UZ',
        code: '00000',
        name: 'ANOR BANK UZ'
    },
    {
        bank_code: 'ASACUZ22XXX',
        bank_name: 'ASIA ALLIANCE BANK',
        code: '01095',
        name: 'Bosh ofisi'
    },
    {
        bank_code: 'ASACUZ22XXX',
        bank_name: 'ASIA ALLIANCE BANK',
        code: '01137',
        name: 'Buxoro filiali'
    },
    {
        bank_code: 'ASACUZ22XXX',
        bank_name: 'ASIA ALLIANCE BANK',
        code: '01153',
        name: 'Chilonzor filial'
    },
    {
        bank_code: 'ASACUZ22XXX',
        bank_name: 'ASIA ALLIANCE BANK',
        code: '01124',
        name: 'Mirobod filiali'
    },
    {
        bank_code: 'ASACUZ22XXX',
        bank_name: 'ASIA ALLIANCE BANK',
        code: '01103',
        name: "Mirzo Ulug'bek filiali"
    },
    {
        bank_code: 'ASACUZ22XXX',
        bank_name: 'ASIA ALLIANCE BANK',
        code: '01102',
        name: 'Olmazor filiali'
    },
    {
        bank_code: 'ASACUZ22XXX',
        bank_name: 'ASIA ALLIANCE BANK',
        code: '01099',
        name: 'Qarshi filiali'
    },
    {
        bank_code: 'ASACUZ22XXX',
        bank_name: 'ASIA ALLIANCE BANK',
        code: '01143',
        name: 'Samarqand filiali'
    },
    {
        bank_code: 'ASACUZ22XXX',
        bank_name: 'ASIA ALLIANCE BANK',
        code: '01057',
        name: 'Shayxontoxur filiali'
    },
    {
        bank_code: 'CBUZUZ22XXX',
        bank_name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN',
        code: '00016',
        name: 'Andijon viloyati hisob-kitob kassa markazi'
    },
    {
        bank_code: 'CBUZUZ22XXX',
        bank_name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN',
        code: '00017',
        name: 'Buxoro viloyati hisob-kitob kassa markazi'
    },
    {
        bank_code: 'CBUZUZ22XXX',
        bank_name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN',
        code: '00026',
        name: "Farg'ona viloyati hisob-kitob kassa markazi"
    },
    {
        bank_code: 'CBUZUZ22XXX',
        bank_name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN',
        code: '00018',
        name: 'Jizzax viloyati hisob-kitob kassa markazi'
    },
    {
        bank_code: 'CBUZUZ22XXX',
        bank_name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN',
        code: '09001',
        name: "Markaziy bankning to'lov markazi"
    },
    {
        bank_code: 'CBUZUZ22XXX',
        bank_name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN',
        code: '00021',
        name: 'Namangan viloyati hisob-kitob kassa markazi'
    },
    {
        bank_code: 'CBUZUZ22XXX',
        bank_name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN',
        code: '00020',
        name: 'Navoiy viloyati hisob-kitob kassa markazi'
    },
    {
        bank_code: 'CBUZUZ22XXX',
        bank_name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN',
        code: '00019',
        name: 'Qashqadaryo viloyati hisob-kitob kassa markazi'
    },
    {
        bank_code: 'CBUZUZ22XXX',
        bank_name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN',
        code: '00015',
        name: "Qoraqalpog'iston Respublikasi hisob-kitob kassa markazi"
    },
    {
        bank_code: 'CBUZUZ22XXX',
        bank_name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN',
        code: '00022',
        name: 'Samarqand viloyati hisob-kitob kassa markazi'
    },
    {
        bank_code: 'CBUZUZ22XXX',
        bank_name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN',
        code: '00023',
        name: 'Sirdaryo viloyati hisob-kitob kassa markazi'
    },
    {
        bank_code: 'CBUZUZ22XXX',
        bank_name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN',
        code: '00024',
        name: 'Surxondaryo viloyati hisob-kitob kassa markazi'
    },
    {
        bank_code: 'CBUZUZ22XXX',
        bank_name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN',
        code: '00014',
        name: 'Toshkent shahar hisob-kitob kassa markazi'
    },
    {
        bank_code: 'CBUZUZ22XXX',
        bank_name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN',
        code: '00025',
        name: 'Toshkent viloyati hisob-kitob kassa markazi'
    },
    {
        bank_code: 'CBUZUZ22XXX',
        bank_name: 'CENTRAL BANK OF THE REPUBLIC UZBEKISTAN',
        code: '00027',
        name: 'Xorazm viloyati hisob-kitob kassa markazi'
    },
    {
        bank_code: 'CRIDUZ21XXX',
        bank_name: 'CREDIT-STANDARD BANK',
        code: '00000',
        name: 'CREDIT-STANDARD BANK'
    },
    {
        bank_code: 'HPCJUZ22XXX',
        bank_name: 'HI-TECH BANK PCJSB',
        code: '00000',
        name: 'HI-TECH BANK PCJSB'
    },
    {
        bank_code: 'INFBUZ2XXXX',
        bank_name: 'INVEST FINANCE BANK',
        code: '01114',
        name: 'Andijon viloyat filiali'
    },
    {
        bank_code: 'INFBUZ2XXXX',
        bank_name: 'INVEST FINANCE BANK',
        code: '01041',
        name: 'Bosh ofisi'
    },
    {
        bank_code: 'INFBUZ2XXXX',
        bank_name: 'INVEST FINANCE BANK',
        code: '01107',
        name: 'Buxoro viloyat filiali'
    },
    {
        bank_code: 'INFBUZ2XXXX',
        bank_name: 'INVEST FINANCE BANK',
        code: '01128',
        name: "Farg'ona viloyat filiali"
    },
    {
        bank_code: 'INFBUZ2XXXX',
        bank_name: 'INVEST FINANCE BANK',
        code: '01113',
        name: 'Namangan viloyat filiali'
    },
    {
        bank_code: 'INFBUZ2XXXX',
        bank_name: 'INVEST FINANCE BANK',
        code: '01148',
        name: 'Navoiy viloyati filiali'
    },
    {
        bank_code: 'INFBUZ2XXXX',
        bank_name: 'INVEST FINANCE BANK',
        code: '01135',
        name: 'Qashqadaryo viloyat filiali'
    },
    {
        bank_code: 'INFBUZ2XXXX',
        bank_name: 'INVEST FINANCE BANK',
        code: '01116',
        name: "Qo'qon filiali"
    },
    {
        bank_code: 'INFBUZ2XXXX',
        bank_name: 'INVEST FINANCE BANK',
        code: '01139',
        name: "Qoraqalpog'iston filiali"
    },
    {
        bank_code: 'INFBUZ2XXXX',
        bank_name: 'INVEST FINANCE BANK',
        code: '01133',
        name: 'Samarqand viloyat filiali'
    },
    {
        bank_code: 'INFBUZ2XXXX',
        bank_name: 'INVEST FINANCE BANK',
        code: '01141',
        name: 'Shayhontohur filiali'
    },
    {
        bank_code: 'INFBUZ2XXXX',
        bank_name: 'INVEST FINANCE BANK',
        code: '01070',
        name: "Sirg'ali filiali"
    },
    {
        bank_code: 'INFBUZ2XXXX',
        bank_name: 'INVEST FINANCE BANK',
        code: '01056',
        name: 'Toshkent viloyat filiali'
    },
    {
        bank_code: 'INFBUZ2XXXX',
        bank_name: 'INVEST FINANCE BANK',
        code: '01129',
        name: 'Xorazm viloyat filiali'
    },
    {
        bank_code: 'INFBUZ2XXXX',
        bank_name: 'INVEST FINANCE BANK',
        code: '01080',
        name: 'Zangiota filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00059',
        name: 'Andijon viloyat filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00461',
        name: 'Angren filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00489',
        name: 'Bekobod filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00937',
        name: 'Bosh ofisi'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00088',
        name: 'Buxoro viloyat filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00997',
        name: 'Chilonzor filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '01039',
        name: 'Chinoz filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00478',
        name: 'Chirchiq filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00544',
        name: "Farg'ona viloyat filiali"
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00068',
        name: 'Istiklol filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00122',
        name: 'Jizzax viloyat filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00262',
        name: "Ko'ksaroy filiali"
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00423',
        name: 'Mexnat filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00420',
        name: 'Mirobod filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00223',
        name: 'Namangan viloyat filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00205',
        name: 'Navoiy filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00487',
        name: 'Ohangaron filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00459',
        name: 'Olmaliq filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00901',
        name: 'Olmazor filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00190',
        name: 'Qashqadaryo viloyat filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '01076',
        name: "Qo'qon filiali"
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00621',
        name: "Qoraqalpog'iston Respublikasi filiali"
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00312',
        name: 'Samarkand viloyat filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00119',
        name: 'Sardor filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00165',
        name: 'Shahrisabz filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00425',
        name: 'Shayxontoxur filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00364',
        name: 'Sirdaryo viloyat filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00978',
        name: "Sirg'ali filiali"
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00356',
        name: 'Surxondaryo viloyat filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00580',
        name: 'Tinchlik filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '09033',
        name: "To'lov markazi"
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00408',
        name: 'Toshkent viloyat filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00579',
        name: 'Xorazm viloyat filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '01017',
        name: 'Yakkasaroy filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00959',
        name: 'Yashnobod filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00837',
        name: 'Yunusobod filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '01130',
        name: 'Zangiota filiali'
    },
    {
        bank_code: 'UZHOUZ22XXX',
        bank_name: 'IPOTEKA-BANK',
        code: '00203',
        name: 'Zarafshon filiali'
    },
    {
        bank_code: 'JSCLUZ22XXX',
        bank_name: 'JSC ALOQABANK',
        code: '00923',
        name: 'Andijon filiali'
    },
    {
        bank_code: 'JSCLUZ22XXX',
        bank_name: 'JSC ALOQABANK',
        code: '00961',
        name: 'Buxoro filiali'
    },
    {
        bank_code: 'JSCLUZ22XXX',
        bank_name: 'JSC ALOQABANK',
        code: '01011',
        name: "Farg'ona filiali"
    },
    {
        bank_code: 'JSCLUZ22XXX',
        bank_name: 'JSC ALOQABANK',
        code: '01026',
        name: 'Jizzax filiali'
    },
    {
        bank_code: 'JSCLUZ22XXX',
        bank_name: 'JSC ALOQABANK',
        code: '01091',
        name: 'Mirobod filiali'
    },
    {
        bank_code: 'JSCLUZ22XXX',
        bank_name: 'JSC ALOQABANK',
        code: '00874',
        name: 'Namangan filiali'
    },
    {
        bank_code: 'JSCLUZ22XXX',
        bank_name: 'JSC ALOQABANK',
        code: '01007',
        name: 'Navoiy filiali'
    },
    {
        bank_code: 'JSCLUZ22XXX',
        bank_name: 'JSC ALOQABANK',
        code: '00990',
        name: 'Qashqadaryo filiali'
    },
    {
        bank_code: 'JSCLUZ22XXX',
        bank_name: 'JSC ALOQABANK',
        code: '01092',
        name: "Qo'qon filiali"
    },
    {
        bank_code: 'JSCLUZ22XXX',
        bank_name: 'JSC ALOQABANK',
        code: '00623',
        name: "Qoraqalpog'iston filiali"
    },
    {
        bank_code: 'JSCLUZ22XXX',
        bank_name: 'JSC ALOQABANK',
        code: '00985',
        name: 'Samarqand filiali'
    },
    {
        bank_code: 'JSCLUZ22XXX',
        bank_name: 'JSC ALOQABANK',
        code: '00967',
        name: 'Surxondaryo filiali'
    },
    {
        bank_code: 'JSCLUZ22XXX',
        bank_name: 'JSC ALOQABANK',
        code: '09031',
        name: "To'lov markazi"
    },
    {
        bank_code: 'JSCLUZ22XXX',
        bank_name: 'JSC ALOQABANK',
        code: '00861',
        name: 'Xorazm filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '01004',
        name: 'Afrosiyob filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00069',
        name: 'Andijon viloyat filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00073',
        name: 'Asaka filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00417',
        name: 'Avtotransport filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00873',
        name: 'Bosh ofisi'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '01027',
        name: 'Buxoro shahar filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00120',
        name: 'Buxoro viloyat filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00539',
        name: "Farg'ona viloyat filiali"
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00972',
        name: 'Farxod filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00140',
        name: 'Jizzax viloyat filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00971',
        name: "Marg'ilon filiali"
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00231',
        name: 'Namangan viloyat filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00206',
        name: 'Navoiy viloyat filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00904',
        name: 'Oltiariq filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00194',
        name: 'Qashqadaryo viloyat filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00631',
        name: "Qo'qon filiali"
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00625',
        name: "Qoraqalpog'iston filiali"
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00264',
        name: 'Samarqand viloyat filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00969',
        name: 'Shayxontoxur filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00373',
        name: 'Sirdaryo viloyat filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00958',
        name: "Sirg'ali filiali"
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00358',
        name: 'Surxondaryo viloyat filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00416',
        name: 'Toshkent shahar filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00411',
        name: 'Toshkent viloyat filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00581',
        name: 'Xorazm viloyat filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '00963',
        name: 'Yunusobod filiali'
    },
    {
        bank_code: 'ASBKUZ22XXX',
        bank_name: 'JSC ASAKABANK',
        code: '01021',
        name: 'Zarafshon filiali'
    },
    {
        bank_code: 'APELUZ22XXX',
        bank_name: 'JSC BANK APELSIN',
        code: '00000',
        name: 'JSC BANK APELSIN'
    },
    {
        bank_code: 'JSMIUZ22XXX',
        bank_name: 'JSC MADAD INVEST BANK',
        code: '00000',
        name: 'JSC MADAD INVEST BANK'
    },
    {
        bank_code: 'JOCPUZ22XXX',
        bank_name: 'JSC POYTAXT BANK',
        code: '00000',
        name: 'JSC POYTAXT BANK'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00634',
        name: 'Amudaryo filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00649',
        name: 'Andijon viloyat filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00751',
        name: 'Angor filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00784',
        name: 'Angren filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00683',
        name: 'Arnasoy filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00650',
        name: 'Asaka filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00655',
        name: 'Baliqchi filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00681',
        name: 'Baxmal filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00783',
        name: 'Bekobod filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00830',
        name: 'Bektemir filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00636',
        name: 'Beruniy filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00797',
        name: 'Beshariq filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00695',
        name: 'Beshkent filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00782',
        name: "Bo'ka filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00770',
        name: "Bo'stonliq filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00628',
        name: "Bo'z filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00794',
        name: "Bog'dod filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00724',
        name: "Bog'ishamol filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00816',
        name: "Bog'ot filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00762',
        name: 'Boyovut filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00746',
        name: 'Boysun filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00664',
        name: 'Buloqboshi filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00727',
        name: "Bulung'ur filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00793',
        name: 'Buvayda filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00116',
        name: 'Buxoro viloyat filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00741',
        name: 'Chelak filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00825',
        name: 'Chilonzor filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00635',
        name: 'Chimboy filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00780',
        name: 'Chinoz filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00781',
        name: 'Chirchiq filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00689',
        name: 'Chiroqchi filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00721',
        name: 'Chortoq filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00715',
        name: 'Chust filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00800',
        name: "Dang'ara filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00693',
        name: 'Dehqonobod filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00747',
        name: 'Denov filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00682',
        name: "Do'stlik filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00773',
        name: "Do'stobod filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00646',
        name: "Ellikqal'a filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00118',
        name: "F.Xo'jayev filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00497',
        name: "Farg'ona viloyat filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00678',
        name: 'Forish filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00807',
        name: 'Furqat filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00672',
        name: "G'alaosiyo filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00677',
        name: "G'allaorol filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00666',
        name: "G'ijduvon filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00692',
        name: "G'uzor filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00764',
        name: 'Guliston filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00809',
        name: 'Gurlan filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '09006',
        name: 'Hisob-kitob markazi'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00735',
        name: 'Ishtixon filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00654',
        name: 'Izboskan filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00653',
        name: 'Jalaquduq filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00749',
        name: "Jarqo'rg'on filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00676',
        name: 'Jizzax viloyat filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00732',
        name: 'Jomboy filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00671',
        name: 'Jondor filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00697',
        name: 'Kasbi filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00725',
        name: "Kattaqo'rg'on filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00640',
        name: 'Kegeyli filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00786',
        name: 'Keles filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00694',
        name: 'Kitob filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00665',
        name: 'Kogon filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00688',
        name: 'Koson filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00718',
        name: 'Kosonsoy filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00658',
        name: 'Kuyganyor filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00790',
        name: "Marg'ilon filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00652',
        name: 'Marxamat filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00713',
        name: 'Mingbuloq filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00699',
        name: 'Mirishkor filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00820',
        name: 'Mirobod filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00679',
        name: "Mirzacho'l filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00767',
        name: 'Mirzaobod filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00822',
        name: "Mirzo Ulug'bek filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00637',
        name: "Mo'ynoq filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00700',
        name: 'Muborak filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00752',
        name: 'Muzrabod filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00711',
        name: 'Namangan viloyat filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00731',
        name: 'Narpay filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00710',
        name: 'Navbaxor filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00708',
        name: 'Navoiy shahar filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00703',
        name: 'Navoiy viloyat filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00698',
        name: 'Nishon filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00722',
        name: 'Norin filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00740',
        name: 'Nurobod filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00702',
        name: 'Nurota filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00792',
        name: "O'zbekiston filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00772',
        name: 'Ohangaron filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00785',
        name: 'Olmaliq filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00828',
        name: 'Olmazor filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00673',
        name: 'Olot filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00796',
        name: 'Oltiariq filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00662',
        name: "Oltinko'l filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00755',
        name: 'Oltinsoy filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00728',
        name: 'Oqdaryo filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00647',
        name: "Oqmang'it filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00766',
        name: 'Oqoltin filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00779',
        name: "Oqqo'rg'on filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00788',
        name: 'Parkent filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00729',
        name: "Pastdarg'om filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00733',
        name: 'Paxtachi filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00680',
        name: 'Paxtakor filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00657',
        name: 'Paxtaobod filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00726',
        name: 'Payariq filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00737',
        name: 'Payshanba filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00674',
        name: 'Peshku filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00818',
        name: 'Pitnak filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00714',
        name: 'Pop filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00774',
        name: 'Pskent filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00696',
        name: 'Qamashi filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00687',
        name: 'Qashqadaryo viloyat filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00776',
        name: 'Qibray filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00632',
        name: 'Qirguli filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00706',
        name: 'Qiziltepa filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00753',
        name: 'Qiziriq filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00639',
        name: "Qo'ng'irot filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00789',
        name: "Qo'qon filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00659',
        name: "Qo'rg'ontepa filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00815',
        name: "Qo'shko'pir filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00739',
        name: "Qo'shrabod filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00803',
        name: "Qo'shtepa filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00644',
        name: "Qonliko'l filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00667',
        name: "Qorako'l filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00638',
        name: "Qoraqalpog'iston Respublikasi filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00663',
        name: 'Qorasuv filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00817',
        name: 'Qoraul filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00645',
        name: 'Qorauzak filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00754',
        name: "Qumqo'rg'on filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00799',
        name: 'Quva filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00802',
        name: 'Quvasoy filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00795',
        name: 'Rishton filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00669',
        name: 'Romiton filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00734',
        name: 'Samarqand filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '01005',
        name: 'Samarqand viloyat filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00763',
        name: 'Sardoba filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00757',
        name: 'Sariosiyo filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00765',
        name: 'Sayxunobod filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00690',
        name: 'Shahrisabz filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00651',
        name: 'Shaxrixon filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00821',
        name: 'Shayxontoxur filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00748',
        name: 'Sherobod filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00750',
        name: "Sho'rchi filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00670',
        name: 'Shofirkon filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00810',
        name: 'Shovot filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00642',
        name: 'Shumanay filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00760',
        name: 'Sirdaryo shahar filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00759',
        name: 'Sirdaryo viloyati filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00826',
        name: "Sirg'ali filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00738',
        name: 'Siyob filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00806',
        name: "So'g' filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00744',
        name: 'Surxondaryo viloyati filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00643',
        name: 'Taxiatosh filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00641',
        name: "Taxtako'pir filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00736',
        name: "Temiryo'l filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00719',
        name: "To'raqo'rg'on filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00633',
        name: "To'rtko'l filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00775',
        name: "To'ytepa filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00720',
        name: 'Toshbuloq filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '01132',
        name: "Toshkent shahar filiali amaliyot bo'limi"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00883',
        name: "Toshkent viloyat filiali amaliyot bo'limi"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00798',
        name: 'Toshloq filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00742',
        name: 'Toyloq filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00804',
        name: "Uchko'prik filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00756',
        name: 'Uchqizil filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00717',
        name: "Uchqo'rg'on filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00684',
        name: 'Uchtepa filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00829',
        name: 'Uchtepa filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00730',
        name: 'Urgut filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00716',
        name: 'Uychi filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00745',
        name: 'Uzun filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00668',
        name: 'Vobkent filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00801',
        name: 'Vodil filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00704',
        name: 'Xatirchi filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00813',
        name: 'Xazorasp filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00811',
        name: 'Xiva filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00656',
        name: "Xo'jaobod filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00839',
        name: "Xo'jayli filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00660',
        name: 'Xonobod filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00812',
        name: 'Xonqa filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00808',
        name: 'Xorazm viloyat filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '01146',
        name: 'Xovos filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00691',
        name: "Yakkabog' filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00824',
        name: 'Yakkasaroy filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00814',
        name: 'Yangiariq filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00819',
        name: 'Yangibozor filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00712',
        name: "Yangiqo'rg'on filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00761',
        name: 'Yangiyer filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00777',
        name: "Yangiyo'l filiali"
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00827',
        name: 'Yashnobod filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00805',
        name: 'Yozyovon filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00823',
        name: 'Yunusobod filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00771',
        name: 'Yuqorichirchiq filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00787',
        name: 'Zafar filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00686',
        name: 'Zafarobod filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00778',
        name: 'Zangiota filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00709',
        name: 'Zarafshon filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00685',
        name: 'Zarbdor filiali'
    },
    {
        bank_code: 'SCPEUZ22XXX',
        bank_name: 'JSC XALQ BANK OF THE REPUBLIC OF UZBEKISTAN',
        code: '00675',
        name: 'Zomin filiali'
    },
    {
        bank_code: 'TCZBUZ22XXX',
        bank_name: 'JSC ZIRAAT BANK UZBEKISTAN',
        code: '00395',
        name: 'AJ'
    },
    {
        bank_code: 'TCZBUZ22XXX',
        bank_name: 'JSC ZIRAAT BANK UZBEKISTAN',
        code: '01138',
        name: 'AJ Yunusobod filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00619',
        name: 'Amudaryo filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00062',
        name: 'Andijon filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00077',
        name: 'Andijon viloyat xududiy boshqarmasi'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00343',
        name: 'Angor filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00124',
        name: 'Arnasoy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00033',
        name: 'Asaka filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00066',
        name: 'Baliqchi filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00183',
        name: 'Batash filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00132',
        name: 'Baxmal filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00468',
        name: 'Bekobod filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00607',
        name: 'Beruniy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00513',
        name: 'Beshariq filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00463',
        name: "Bo'ka filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00053',
        name: "Bo'z filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00504',
        name: "Bog'dod filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00556',
        name: "Bog'ot filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00382',
        name: 'Boyovut filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00331',
        name: 'Boysun filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00064',
        name: 'Buloqboshi filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00290',
        name: "Bulung'ur filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00525',
        name: 'Buvayda filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00097',
        name: 'Buxoro filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00089',
        name: 'Buxoro viloyat xududiy boshqarmasi'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00318',
        name: 'Chelak filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00605',
        name: 'Chimboy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00476',
        name: 'Chinoz filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00174',
        name: 'Chiroqchi filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00256',
        name: 'Chortoq filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00249',
        name: 'Chust filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00510',
        name: "Dang'ara filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00259',
        name: 'Davlatobod filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00158',
        name: 'Dehqonobod filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00360',
        name: 'Denov filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00129',
        name: "Do'stlik filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00614',
        name: "Ellikqal'a filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00507',
        name: "Farg'ona filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00495',
        name: "Farg'ona viloyat xududiy filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00133',
        name: 'Forish filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00543',
        name: 'Furkat filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00128',
        name: "G'allaorol filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00092',
        name: "G'ijduvon filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00157',
        name: "G'uzor filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00307',
        name: "Go'zalkent filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00386',
        name: 'Guliston filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00554',
        name: 'Gurlan filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '09004',
        name: 'Hisob-kitob markazi'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00309',
        name: 'Ishtixon filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00045',
        name: 'Izboskan filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00040',
        name: 'Jalaquduq filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00332',
        name: "Jarqo'rg'on filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00123',
        name: 'Jizzax filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00126',
        name: 'Jizzax viloyat xududiy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00270',
        name: 'Jomboy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00096',
        name: 'Jondor filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00210',
        name: 'Karmana filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00179',
        name: 'Kasbi filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00314',
        name: "Kattaqo'rg'on filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00589',
        name: 'Kegeyli filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00176',
        name: 'Kitob filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00209',
        name: 'Konimex filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00162',
        name: 'Koson filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00234',
        name: 'Kosonsoy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00528',
        name: "Marg'ilon filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00035',
        name: 'Marxamat filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00232',
        name: 'Mingbuloq filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00184',
        name: 'Mirishkor filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00130',
        name: "Mirzacho'l filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00391',
        name: 'Mirzaobod filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00594',
        name: "Mo'ynoq filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00187',
        name: 'Muborak filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00334',
        name: 'Muzrabod filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00253',
        name: 'Namangan filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00225',
        name: 'Namangan viloyat xududiy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00267',
        name: 'Narpay filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00217',
        name: 'Navbaxor filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00197',
        name: 'Navoiy viloyat xududiy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00181',
        name: 'Nishon filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00236',
        name: 'Norin filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00595',
        name: 'Nukus filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00291',
        name: 'Nurobod filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00214',
        name: 'Nurota filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00475',
        name: "O'rtachirchiq filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00533',
        name: "O'zbekiston filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00413',
        name: 'Ohangaron filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00090',
        name: 'Olot filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00502',
        name: 'Oltiariq filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00049',
        name: "Oltinko'l filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00351',
        name: 'Oltinsoy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00265',
        name: 'Oqdaryo filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00390',
        name: 'Oqoltin filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00457',
        name: "Oqqo'rg'on filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00482',
        name: 'Parkent filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00273',
        name: "Pastdarg'om filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00297',
        name: 'Paxtachi filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00127',
        name: 'Paxtakor filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00031',
        name: 'Paxtaobod filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00294',
        name: 'Payariq filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00275',
        name: 'Payshanba filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00098',
        name: 'Peshku filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00572',
        name: 'Pitnak filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00238',
        name: 'Pop filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00472',
        name: 'Pskent filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00160',
        name: 'Qamashi filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00172',
        name: 'Qarshi filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00151',
        name: 'Qashqadaryo viloyat xududiy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00212',
        name: 'Qiziltepa filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00345',
        name: 'Qiziriq filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00592',
        name: "Qo'ng'irot filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00546',
        name: "Qo'qon filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00056',
        name: "Qo'rg'ontepa filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00565',
        name: "Qo'shko'pir filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00313',
        name: "Qo'shrabod filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00515',
        name: "Qo'shtepa filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00609',
        name: "Qonliko'l filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00094',
        name: "Qorako'l filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00583',
        name: "Qoraqalpog'iston xududiy filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00612',
        name: 'Qorauzak filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00099',
        name: 'Qorovulbozor filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00347',
        name: "Qumqo'rg'on filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00529',
        name: 'Quva filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00517',
        name: 'Quvasoy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00474',
        name: 'Quyichirchiq filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00519',
        name: 'Rishton filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00095',
        name: 'Romitan filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00929',
        name: 'Samarqand filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00279',
        name: 'Samarqand viloyat xududiy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00374',
        name: 'Sardoba filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00355',
        name: 'Sariosiyo filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00388',
        name: 'Sayxunobod filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00166',
        name: 'Shahrisabz filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00037',
        name: 'Shaxrixon filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00337',
        name: 'Sherobod filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00383',
        name: 'Shirin filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00339',
        name: "Sho'rchi filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00093',
        name: 'Shofirkon filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00567',
        name: 'Shovot filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00590',
        name: 'Shumanay filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00375',
        name: 'Sirdaryo filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00365',
        name: 'Sirdaryo viloyat xududiy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00280',
        name: 'Siyob filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00542',
        name: "So'x filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00325',
        name: 'Surxondaryo viloyat xududiy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00597',
        name: "Taxtako'pir filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00349',
        name: 'Termiz filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00241',
        name: "To'raqo'rg'on filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00598',
        name: "To'rtko'l filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00216',
        name: 'Tomdi filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00394',
        name: 'Toshkent shahar filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00454',
        name: 'Toshkent viloyat xududiy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00522',
        name: 'Toshloq filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00321',
        name: 'Toyloq filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00511',
        name: "Uchko'prik filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00246',
        name: "Uchqo'rg'on filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00055',
        name: "Ulug'nor filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00569',
        name: 'Urganch filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00300',
        name: 'Urgut filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00244',
        name: 'Uychi filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00341',
        name: 'Uzun filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00091',
        name: 'Vobkent filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00219',
        name: 'Xatirchi filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00577',
        name: 'Xiva filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00042',
        name: "Xo'jaobod filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00602',
        name: "Xo'jayli filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00560',
        name: 'Xonqa filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00548',
        name: 'Xorazm viloyat xududiy filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00392',
        name: 'Xovos filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00558',
        name: 'Xozorasp filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00169',
        name: "Yakkabog' filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00563',
        name: 'Yangiariq filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00574',
        name: 'Yangibozor filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00251',
        name: "Yangiqo'rg'on filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00378',
        name: 'Yangiyer filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00485',
        name: "Yangiyo'l filiali"
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00541',
        name: 'Yozyovon filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00480',
        name: 'Yuqorichirchiq filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00134',
        name: 'Zafarobod filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00125',
        name: 'Zarbdor filiali'
    },
    {
        bank_code: 'PAKHUZ22XXX',
        bank_name: 'JSCB AGROBANK',
        code: '00131',
        name: 'Zomin filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01149',
        name: 'Angren filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00070',
        name: 'Asaka filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01008',
        name: 'Aviasozlik filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01126',
        name: "Bog'ishamol filiali"
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00083',
        name: 'Bosh ofisi'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00966',
        name: 'Buxoro filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01108',
        name: 'Chilonzor filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00962',
        name: 'Chinobod filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00960',
        name: 'Chinoz filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01159',
        name: 'Chirchiq filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01160',
        name: 'Chust filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01090',
        name: 'Denov filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01155',
        name: "Farg'ona mintaqaviy filiali"
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01118',
        name: 'Guliston filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00965',
        name: 'Istiqbol filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00977',
        name: 'Jalaquduq filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01117',
        name: 'Jizzax filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00991',
        name: "Marg'ilon filiali"
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01156',
        name: 'Namangan mintaqaviy filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01064',
        name: 'Navoiy filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00079',
        name: 'Nodirabegim filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01068',
        name: 'Nukus filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00988',
        name: 'Olmaliq filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01031',
        name: 'Qarshi filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00956',
        name: "Qo'qon filiali"
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00071',
        name: "Qo'rg'ontepa filiali"
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01003',
        name: 'Quvasoy filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01030',
        name: 'Samarqand filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00039',
        name: 'Shaxrixon filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01127',
        name: 'Shayxontoxur filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01001',
        name: 'Termiz filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00443',
        name: 'Toshkent filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01020',
        name: 'Urganch filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01157',
        name: 'Urgut filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01162',
        name: 'Xazorasp filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00072',
        name: "Xo'jaobod filiali"
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01161',
        name: 'Xorazm mintaqaviy filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '01013',
        name: 'Yakkasaroy filiali'
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00976',
        name: "Yangiyo'l filiali"
    },
    {
        bank_code: 'KHKKUZ22XXX',
        bank_name: 'JSCB HAMKORBANK',
        code: '00992',
        name: 'Yuksalish filiali'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00081',
        name: 'Andijon filiali'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00465',
        name: "Bo'ka filiali"
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00439',
        name: 'Bosh ofisi'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00112',
        name: 'Buxoro filiali'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00362',
        name: 'Denov filiali'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00138',
        name: "Do'stlik filiali"
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00500',
        name: "Farg'ona filiali"
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00418',
        name: "G'azalkent filiali"
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00311',
        name: 'Ishtixon filiali'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00227',
        name: 'Namangan filiali'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00201',
        name: 'Navoiy viloyat filiali'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '01134',
        name: 'Ohangaron filiali'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00154',
        name: 'Qashqadaryo viloyat filiali'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00410',
        name: 'Qibray filiali'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00286',
        name: 'Savdogarbank Samarqand viloyat filiali'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '01105',
        name: 'Shayxontohur filiali'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00600',
        name: "To'rtko'l filiali"
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00835',
        name: 'Toshkent shahar filiali'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00304',
        name: "Ulug'bek filiali"
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00303',
        name: 'Urgut filiali'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00552',
        name: 'Xorazm viloyat filiali'
    },
    {
        bank_code: 'JSCSUZ22XXX',
        bank_name: 'JSCB SAVDOGAR',
        code: '00414',
        name: 'Zangiota filiali'
    },
    {
        bank_code: 'JOCBUZ22XXX',
        bank_name: 'JSCB TBC BANK',
        code: '00000',
        name: 'JSCB TBC BANK'
    },
    {
        bank_code: 'HSBKUZ22XXX',
        bank_name: 'JSCB TENGE BANK',
        code: '00000',
        name: 'JSCB TENGE BANK'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '00111',
        name: 'Bukhara filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '01084',
        name: 'Chilanzar filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '00498',
        name: 'Fergana filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '00982',
        name: 'Jizzah filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '00192',
        name: 'Karshi filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '01083',
        name: 'Khamza filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '00551',
        name: 'Khorezm filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '00986',
        name: 'Mirobod filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '01154',
        name: "Mirzo Ulug'bek filiali"
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '00226',
        name: 'Namangan filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '00200',
        name: 'Navoi filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '00585',
        name: 'Nukus filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '00282',
        name: 'Samarkand filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '00989',
        name: 'Shakhrisabz filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '00328',
        name: 'Surkhandarya filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '00368',
        name: 'Syrdarya filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '09011',
        name: "To'lov markazi"
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '01144',
        name: 'Yunusobod filiali'
    },
    {
        bank_code: 'TUROUZ22XXX',
        bank_name: 'JSCB TURONBANK',
        code: '01019',
        name: 'Zangiota filiali'
    },
    {
        bank_code: 'UZGOUZ22XXX',
        bank_name: 'JSCB UZAGROEXPORTBANK',
        code: '00000',
        name: 'JSCB UZAGROEXPORTBANK'
    },
    {
        bank_code: 'INIPUZ22XXX',
        bank_name: 'JSIC BANK IPAK YULI',
        code: '01120',
        name: 'Andijan'
    },
    {
        bank_code: 'INIPUZ22XXX',
        bank_name: 'JSIC BANK IPAK YULI',
        code: '00444',
        name: 'Bosh ofisi'
    },
    {
        bank_code: 'INIPUZ22XXX',
        bank_name: 'JSIC BANK IPAK YULI',
        code: '01067',
        name: 'Chilanzar'
    },
    {
        bank_code: 'INIPUZ22XXX',
        bank_name: 'JSIC BANK IPAK YULI',
        code: '01119',
        name: 'Karshi'
    },
    {
        bank_code: 'INIPUZ22XXX',
        bank_name: 'JSIC BANK IPAK YULI',
        code: '01101',
        name: 'Mirabad filiali'
    },
    {
        bank_code: 'INIPUZ22XXX',
        bank_name: 'JSIC BANK IPAK YULI',
        code: '00421',
        name: 'Mirzo Ulugbek filiali'
    },
    {
        bank_code: 'INIPUZ22XXX',
        bank_name: 'JSIC BANK IPAK YULI',
        code: '01054',
        name: 'Namangan'
    },
    {
        bank_code: 'INIPUZ22XXX',
        bank_name: 'JSIC BANK IPAK YULI',
        code: '00199',
        name: 'Navoi filiali'
    },
    {
        bank_code: 'INIPUZ22XXX',
        bank_name: 'JSIC BANK IPAK YULI',
        code: '00283',
        name: "Regional filiali 'Umar'"
    },
    {
        bank_code: 'INIPUZ22XXX',
        bank_name: 'JSIC BANK IPAK YULI',
        code: '01036',
        name: 'Sagbon'
    },
    {
        bank_code: 'INIPUZ22XXX',
        bank_name: 'JSIC BANK IPAK YULI',
        code: '01048',
        name: 'Shakhrisabz'
    },
    {
        bank_code: 'INIPUZ22XXX',
        bank_name: 'JSIC BANK IPAK YULI',
        code: '01145',
        name: 'Shayhontohur'
    },
    {
        bank_code: 'INIPUZ22XXX',
        bank_name: 'JSIC BANK IPAK YULI',
        code: '00999',
        name: 'Urikzor filiali'
    },
    {
        bank_code: 'INIPUZ22XXX',
        bank_name: 'JSIC BANK IPAK YULI',
        code: '01028',
        name: 'Yakkasaray filiali'
    },
    {
        bank_code: 'INIPUZ22XXX',
        bank_name: 'JSIC BANK IPAK YULI',
        code: '01081',
        name: 'Yangiyul'
    },
    {
        bank_code: 'KACHUZ22XXX',
        bank_name: 'KAPITALBANK, JSCB',
        code: '00974',
        name: 'Amaliyotlar boshqarmasi'
    },
    {
        bank_code: 'KACHUZ22XXX',
        bank_name: 'KAPITALBANK, JSCB',
        code: '01088',
        name: 'Bosh ofisi'
    },
    {
        bank_code: 'KACHUZ22XXX',
        bank_name: 'KAPITALBANK, JSCB',
        code: '01082',
        name: 'Buxoro filiali'
    },
    {
        bank_code: 'KACHUZ22XXX',
        bank_name: 'KAPITALBANK, JSCB',
        code: '01033',
        name: 'Chorsu filiali'
    },
    {
        bank_code: 'KACHUZ22XXX',
        bank_name: 'KAPITALBANK, JSCB',
        code: '01043',
        name: "Farg'ona filiali"
    },
    {
        bank_code: 'KACHUZ22XXX',
        bank_name: 'KAPITALBANK, JSCB',
        code: '01018',
        name: "Mirzo-Ulug'bek filiali"
    },
    {
        bank_code: 'KACHUZ22XXX',
        bank_name: 'KAPITALBANK, JSCB',
        code: '01085',
        name: 'Namangan filiali'
    },
    {
        bank_code: 'KACHUZ22XXX',
        bank_name: 'KAPITALBANK, JSCB',
        code: '01038',
        name: 'Nukus filiali'
    },
    {
        bank_code: 'KACHUZ22XXX',
        bank_name: 'KAPITALBANK, JSCB',
        code: '01087',
        name: "Qo'qon filiali"
    },
    {
        bank_code: 'KACHUZ22XXX',
        bank_name: 'KAPITALBANK, JSCB',
        code: '01061',
        name: 'Samarqand filiali'
    },
    {
        bank_code: 'KACHUZ22XXX',
        bank_name: 'KAPITALBANK, JSCB',
        code: '01042',
        name: "Sirg'ali filiali"
    },
    {
        bank_code: 'KACHUZ22XXX',
        bank_name: 'KAPITALBANK, JSCB',
        code: '01032',
        name: 'Termiz filiali'
    },
    {
        bank_code: 'KACHUZ22XXX',
        bank_name: 'KAPITALBANK, JSCB',
        code: '00445',
        name: 'Toshkent shahar filiali'
    },
    {
        bank_code: 'KACHUZ22XXX',
        bank_name: 'KAPITALBANK, JSCB',
        code: '01136',
        name: 'Yashnobod filiali'
    },
    {
        bank_code: 'KODBUZ22XXX',
        bank_name: 'KDB BANK UZBEKISTAN JSC',
        code: '00842',
        name: "O'zbekiston Bosh ofisi"
    },
    {
        bank_code: 'KODBUZ22XXX',
        bank_name: 'KDB BANK UZBEKISTAN JSC',
        code: '01065',
        name: "O'zbekiston Yakkasaroy filiali"
    },
    {
        bank_code: 'KODBUZ22XXX',
        bank_name: 'KDB BANK UZBEKISTAN JSC',
        code: '00831',
        name: "O'zbekiston Yunusobod filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00078',
        name: 'Andijon viloyat filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00344',
        name: 'Angor filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00034',
        name: 'Asaka filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00067',
        name: 'Baliqchi filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00173',
        name: 'Beshkent filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00557',
        name: "Bog'ot filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00384',
        name: 'Boyovut filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00289',
        name: "Bulung'ur filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00109',
        name: 'Buxoro viloyat filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '01093',
        name: 'Chimboy filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00175',
        name: 'Chiroqchi filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '01044',
        name: 'Chortoq filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00250',
        name: 'Chust filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00361',
        name: 'Denov filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '01055',
        name: "Ellikqal'a filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00496',
        name: "Farg'ona viloyat filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00142',
        name: "G'allaorol filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00467',
        name: "G'azalkent filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00104',
        name: "G'ijduvon filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00333',
        name: "Jarqo'rg'on filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00135',
        name: 'Jizzax viloyat filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00108',
        name: 'Jondor filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '01047',
        name: 'Juma filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00233',
        name: "Jumasho'y filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00211',
        name: 'Karmana filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00315',
        name: "Kattaqo'rg'on filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00470',
        name: 'Keles filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00177',
        name: 'Kitob filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00106',
        name: 'Kogon filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00163',
        name: 'Koson filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '01049',
        name: 'Kosonsoy filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00063',
        name: 'Kuygan-yor filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00266',
        name: 'Loish filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00620',
        name: "Mang'it filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00144',
        name: "Mirzacho'l filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00188',
        name: 'Muborak filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00335',
        name: 'Muzrabod filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00260',
        name: 'Namangan viloyat filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00198',
        name: 'Navoiy viloyat filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00050',
        name: "Oltinko'l filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00458',
        name: "Oqqo'rg'on filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00268',
        name: 'Oqtosh filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00041',
        name: 'Oxunboboyev filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00483',
        name: 'Parkent filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00032',
        name: 'Paxtaobod filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00239',
        name: 'Pop filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00473',
        name: 'Pskent filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00161',
        name: 'Qamashi filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00152',
        name: 'Qashqadaryo viloyat filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00213',
        name: 'Qiziltepa filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00346',
        name: 'Qiziriq filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '01089',
        name: "Qo'qon filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00584',
        name: "Qoraqalpog'iston Respublikasi filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '01147',
        name: 'Qorasaroy filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00570',
        name: 'Qorovul filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00348',
        name: "Qumqo'rg'on filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00520',
        name: 'Rishton filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00110',
        name: 'Romiton filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00281',
        name: 'Samarqand viloyat filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00167',
        name: 'Shahrisabz filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00038',
        name: 'Shaxrixon filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00338',
        name: 'Sherobod filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00101',
        name: 'Shofirkon filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00568',
        name: 'Shovot filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00376',
        name: 'Sirdaryo filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00366',
        name: 'Sirdaryo viloyat filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00326',
        name: 'Surxondaryo viloyat filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00599',
        name: "To'rtko'l filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00254',
        name: 'Toshbuloq filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00455',
        name: 'Toshkent viloyat filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '01052',
        name: "Uchko'prik filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00350',
        name: 'Uchqizil filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '01073',
        name: 'Uchquduq filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00301',
        name: 'Urgut filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00342',
        name: 'Uzun filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00578',
        name: 'Xiva filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '01106',
        name: "Xo'jayli filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00549',
        name: 'Xorazm viloyat filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '01058',
        name: "Yakkabog' filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00182',
        name: 'Yangi Nishon filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00100',
        name: 'Yangibozor filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00252',
        name: "Yangiqo'rg'on filiali"
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00149',
        name: 'Zarbdor filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00298',
        name: 'Ziyovuddin filiali'
    },
    {
        bank_code: 'MICDUZ22XXX',
        bank_name: 'MICROCREDITBANK',
        code: '00145',
        name: 'Zomin filiali'
    },
    {
        bank_code: 'MFRUUZ22XXX',
        bank_name: 'MINISTRY OF FINANCE THE REPUBLIC OF UZBEKISTAN',
        code: '00000',
        name: 'MINISTRY OF FINANCE THE REPUBLIC OF UZBEKISTAN'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00876',
        name: 'Almazar filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00074',
        name: 'Andijan filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00930',
        name: 'Bektemir filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00084',
        name: 'Bukhara filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00121',
        name: 'Djizzak filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00493',
        name: 'Fergana filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00582',
        name: 'Karakalpak filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00875',
        name: 'Mirabad filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00895',
        name: 'Mirzo Ulugbek filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00222',
        name: 'Namangan filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00196',
        name: 'Navoi filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00278',
        name: 'Samarkand filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00905',
        name: 'Sayokhat filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00869',
        name: 'Sergeli filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00878',
        name: 'Shaykhontohur filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00363',
        name: 'Sirdarya filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00832',
        name: 'Tashkent Regional filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00324',
        name: 'Termez filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00918',
        name: 'Uchtepa filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00547',
        name: 'Urgench filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00870',
        name: 'Yakkasaray filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '01077',
        name: 'Yangiabad filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00847',
        name: 'Yashnabad filiali'
    },
    {
        bank_code: 'NBFAUZ31XXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REP. UZBEKISTAN',
        code: '00836',
        name: 'Yunusabad filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00431',
        name: 'Akademiya filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00890',
        name: 'Angren filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00893',
        name: 'Asaka filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00912',
        name: 'Bekobod filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00885',
        name: 'Beshariq filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00407',
        name: "Bosh amaliyot bo'limi filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00900',
        name: "Bulung'ur filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00887',
        name: 'Buvayda filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00908',
        name: 'Chimboy filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00919',
        name: 'Chirchiq filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00932',
        name: 'Chortoq filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00843',
        name: 'Chust filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00845',
        name: 'Denov filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00931',
        name: "G'azalkent filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00849',
        name: "G'ijduvon filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00894',
        name: "G'uzor filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00859',
        name: 'Gurlan filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '09002',
        name: 'Hisob-kitob kliring markazi'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00903',
        name: 'Izboskan filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00913',
        name: 'Jomboy filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00840',
        name: "Kattaqo'rg'on filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00899',
        name: 'Kogon filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00925',
        name: 'Korovulbozor filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00889',
        name: 'Kosonsoy filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '01094',
        name: 'Malikrabot filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00536',
        name: "Marg'ilon filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00882',
        name: "Markaziy amaliyotlar bo'limi filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00955',
        name: 'Marxamat filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00916',
        name: "Mirzacho'l filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00844',
        name: 'Muborak filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00939',
        name: 'Nurobod filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00906',
        name: 'Olmaliq filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00941',
        name: "Pastdarg'om filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00892',
        name: 'Paxtakor filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00879',
        name: 'Paxtaobod filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00150',
        name: "Qarshi bo'limi"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00945',
        name: 'Qiziltepa filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00888',
        name: "Qo'ng'irot filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00545',
        name: "Qo'qon filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00841',
        name: "Qo'rg'ontepa filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00910',
        name: 'Qorakul filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00914',
        name: "Qumqo'rg'on filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00886',
        name: 'Quva filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00856',
        name: 'Quvasoy filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '01112',
        name: 'Registon filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00946',
        name: 'Rishton filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00943',
        name: 'Romiton filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00191',
        name: 'Shahrisabz filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00922',
        name: 'Shaxrixon filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00938',
        name: 'Sherobod filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00927',
        name: 'Shofirkon filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00907',
        name: 'Shovot filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00846',
        name: "To'rtko'l filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00920',
        name: "To'ytepa filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00451',
        name: 'Toshkent shahar Bosh boshqarmasi'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00891',
        name: "Turaqo'rg'on filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00881',
        name: "Uchqo'rg'on filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00880',
        name: 'Uchquduq filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00865',
        name: 'Urgut filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00877',
        name: 'Uychi filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00942',
        name: 'Vobkent filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00833',
        name: 'Xiva filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00622',
        name: "Xo'jayli filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00915',
        name: 'Xonqa filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00860',
        name: 'Xozorasp filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00921',
        name: 'Yangi Nishon filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00940',
        name: 'Yangiariq filiali'
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00911',
        name: "Yangiyo'l filiali"
    },
    {
        bank_code: 'NBFAUZ2XXXX',
        bank_name: 'NATIONAL BANK FOR FOREIGN ECONOMIC ACTIVITY OF THE REPUBLIC OF UZBEKISTAN',
        code: '00204',
        name: 'Zarafshon filiali'
    },
    {
        bank_code: 'OJMIUZ21XXX',
        bank_name: 'OJSC MICROCREDITBANK',
        code: '00000',
        name: 'OJSC MICROCREDITBANK'
    },
    {
        bank_code: 'ORFBUZ22XXX',
        bank_name: "PJSCB 'ORIENT FINANCE'",
        code: '01071',
        name: 'Bosh ofisi'
    },
    {
        bank_code: 'ORFBUZ22XXX',
        bank_name: "PJSCB 'ORIENT FINANCE'",
        code: '01096',
        name: 'Chilonzor filiali'
    },
    {
        bank_code: 'ORFBUZ22XXX',
        bank_name: "PJSCB 'ORIENT FINANCE'",
        code: '01110',
        name: 'Markaziy amaliyet fililali'
    },
    {
        bank_code: 'ORFBUZ22XXX',
        bank_name: "PJSCB 'ORIENT FINANCE'",
        code: '01111',
        name: 'Samarkand filiali'
    },
    {
        bank_code: 'ORFBUZ22XXX',
        bank_name: "PJSCB 'ORIENT FINANCE'",
        code: '01115',
        name: 'Shayxontoxur filiali'
    },
    {
        bank_code: 'ORFBUZ22XXX',
        bank_name: "PJSCB 'ORIENT FINANCE'",
        code: '01078',
        name: 'Yakkasaroy filiali'
    },
    {
        bank_code: 'ORFBUZ22XXX',
        bank_name: "PJSCB 'ORIENT FINANCE'",
        code: '01075',
        name: 'Yunusobod filiali'
    },
    {
        bank_code: 'DVRBUZ22XXX',
        bank_name: 'PJSCB DAVR BANK',
        code: '00981',
        name: 'Bosh ofisi'
    },
    {
        bank_code: 'DVRBUZ22XXX',
        bank_name: 'PJSCB DAVR BANK',
        code: '01046',
        name: 'Chilonzor filiali'
    },
    {
        bank_code: 'DVRBUZ22XXX',
        bank_name: 'PJSCB DAVR BANK',
        code: '01072',
        name: "Mirzo Ulug'bek filiali"
    },
    {
        bank_code: 'DVRBUZ22XXX',
        bank_name: 'PJSCB DAVR BANK',
        code: '01121',
        name: 'Olmazor filiali'
    },
    {
        bank_code: 'DVRBUZ22XXX',
        bank_name: 'PJSCB DAVR BANK',
        code: '01086',
        name: 'Uchtepa filiali'
    },
    {
        bank_code: 'DVRBUZ22XXX',
        bank_name: 'PJSCB DAVR BANK',
        code: '01069',
        name: 'Yakkasaroy filiali'
    },
    {
        bank_code: 'DVRBUZ22XXX',
        bank_name: 'PJSCB DAVR BANK',
        code: '01122',
        name: 'Yunusobod filiali'
    },
    {
        bank_code: 'TRKSUZ22XXX',
        bank_name: 'PJSCB TURKISTON',
        code: '01104',
        name: 'Bosh ofisi'
    },
    {
        bank_code: 'TRKSUZ22XXX',
        bank_name: 'PJSCB TURKISTON',
        code: '00898',
        name: 'Yunusobod filiali'
    },
    {
        bank_code: 'PJUNUZ22XXX',
        bank_name: 'PJSCB UNIVERSAL BANK',
        code: '00973',
        name: 'Bosh ofisi'
    },
    {
        bank_code: 'PJUNUZ22XXX',
        bank_name: 'PJSCB UNIVERSAL BANK',
        code: '01151',
        name: 'Buvayda filiali'
    },
    {
        bank_code: 'PJUNUZ22XXX',
        bank_name: 'PJSCB UNIVERSAL BANK',
        code: '01131',
        name: "Farg'ona filiali"
    },
    {
        bank_code: 'POUNUZ21XXX',
        bank_name: 'PJSCB UNIVERSAL BANK',
        code: '00000',
        name: 'PJSCB UNIVERSAL BANK'
    },
    {
        bank_code: 'PJUNUZ22XXX',
        bank_name: 'PJSCB UNIVERSAL BANK',
        code: '00996',
        name: 'Toshkent filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00082',
        name: 'Andijon viloyat mintaqaviy filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00924',
        name: 'Bekobod filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00953',
        name: 'Beruniy filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00115',
        name: 'Buxoro viloyat mintaqaviy filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00626',
        name: 'Chimboy filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00501',
        name: "Farg'ona viloyat mintaqaviy filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00975',
        name: "G'alaosiyo filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00466',
        name: "G'azalkent filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00933',
        name: "Jarqo'rg'on filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00139',
        name: 'Jizzax viloyat mintaqaviy filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00316',
        name: "Kattaqo'rg'on filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00156',
        name: "Ko'kdala filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00087',
        name: 'Kogon filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00527',
        name: "Marg'ilon filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00229',
        name: 'Namangan viloyat mintaqaviy filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00202',
        name: 'Navoiy viloyat mintaqaviy filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00866',
        name: 'Ohangaron filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00240',
        name: 'Pop filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00155',
        name: 'Qashqadaryo viloyat mintaqaviy filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00611',
        name: "Qo'ng'irot filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00630',
        name: "Qo'qon filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00058',
        name: "Qo'rg'ontepa filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00588',
        name: "Qoraqalpog'iston mintaqaviy filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '01010',
        name: "Qumqo'rg'on filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00867',
        name: 'Quva filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00287',
        name: 'Samarqand viloyat mintaqaviy filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00934',
        name: 'Sariosiyo filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00359',
        name: "Sho'rchi filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00370',
        name: 'Sirdaryo viloyat mintaqaviy filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00330',
        name: 'Surxondaryo viloyat mintaqaviy filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '09009',
        name: "To'lov markazi"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00452',
        name: 'Toshkent shahar mintaqaviy filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00987',
        name: 'Toshkent viloyat mintaqaviy filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00248',
        name: "Uchqo'rg'on filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00576',
        name: 'Xiva filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '01079',
        name: "Xo'jaobod filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00562',
        name: 'Xonqa filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00553',
        name: 'Xorazm viloyat mintaqaviy filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00171',
        name: "Yakkabog' filiali"
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '01016',
        name: 'Yangiobod filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00909',
        name: 'Yangiyer filiali'
    },
    {
        bank_code: 'GJSOUZ22XXX',
        bank_name: 'QISHLOQ QURILISH BANK JSCB',
        code: '00484',
        name: "Yangiyo'l filiali"
    },
    {
        bank_code: 'RVBKUZ22XXX',
        bank_name: 'RAVNAQ-BANK',
        code: '00000',
        name: 'RAVNAQ-BANK'
    },
    {
        bank_code: 'XSTEUZ21XXX',
        bank_name: 'REPUBLICAN STOCK EXCHANGE (TOSHKENT)',
        code: '00000',
        name: 'REPUBLICAN STOCK EXCHANGE (TOSHKENT)'
    },
    {
        bank_code: 'SNCUUZ22XXX',
        bank_name: "THE STATE ENTERPRISE 'CENTRAL SECURITIES DEPOSITORY'",
        code: '00000',
        name: "THE STATE ENTERPRISE 'CENTRAL SECURITIES DEPOSITORY'"
    },
    {
        bank_code: 'XCUEUZ21XXX',
        bank_name: 'THE UZBEKISTAN REPUBLICAN CURRENCY EXCHANGE',
        code: '00000',
        name: 'THE UZBEKISTAN REPUBLICAN CURRENCY EXCHANGE'
    },
    {
        bank_code: 'TRSAUZ22XXX',
        bank_name: 'TRUSTBANK, PJSB',
        code: '01074',
        name: 'Andijon filiali'
    },
    {
        bank_code: 'TRSAUZ22XXX',
        bank_name: 'TRUSTBANK, PJSB',
        code: '00491',
        name: 'Bosh ofisi'
    },
    {
        bank_code: 'TRSAUZ22XXX',
        bank_name: 'TRUSTBANK, PJSB',
        code: '00954',
        name: 'Darxon filiali'
    },
    {
        bank_code: 'TRSAUZ22XXX',
        bank_name: 'TRUSTBANK, PJSB',
        code: '01123',
        name: "Farg'ona filiali"
    },
    {
        bank_code: 'TRSAUZ22XXX',
        bank_name: 'TRUSTBANK, PJSB',
        code: '01060',
        name: 'Namangan filiali'
    },
    {
        bank_code: 'TRSAUZ22XXX',
        bank_name: 'TRUSTBANK, PJSB',
        code: '01109',
        name: 'Qarshi filiali'
    },
    {
        bank_code: 'TRSAUZ22XXX',
        bank_name: 'TRUSTBANK, PJSB',
        code: '01097',
        name: 'Samarqand filiali'
    },
    {
        bank_code: 'TRSAUZ22XXX',
        bank_name: 'TRUSTBANK, PJSB',
        code: '01063',
        name: 'Termiz filiali'
    },
    {
        bank_code: 'TRSAUZ22XXX',
        bank_name: 'TRUSTBANK, PJSB',
        code: '00850',
        name: 'Toshkent filiali'
    },
    {
        bank_code: 'TRSAUZ22XXX',
        bank_name: 'TRUSTBANK, PJSB',
        code: '01098',
        name: 'Yakkasaroy filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00434',
        name: 'Al-Xorazmiy filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00994',
        name: 'Amir Temur filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00075',
        name: 'Andijon mintaqaviy filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '01022',
        name: 'Angren filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '01100',
        name: 'Bekobod filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00440',
        name: 'Bosh ofisi'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00076',
        name: 'Bunyodkor filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00086',
        name: 'Buxoro mintaqaviy filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00427',
        name: 'Chilonzor filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00863',
        name: 'Chirchiq filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00494',
        name: "Farg'ona mintaqaviy filiali"
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '01152',
        name: 'Jizzax mintaqaviy filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00442',
        name: 'Markaziy amaliyot mintaqaviy filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00405',
        name: 'Mirobod filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00399',
        name: "Mirzo Ulug'bek filiali"
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00186',
        name: 'Muborak filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00224',
        name: 'Namangan mintaqaviy filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00207',
        name: 'Navoiy mintaqaviy filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00402',
        name: 'Nodirabegim filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '01034',
        name: 'Olmaliq filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00398',
        name: 'Olmazor filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00854',
        name: 'Qashqadaryo mintaqaviy filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00426',
        name: 'Qatortol filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00884',
        name: 'Qibray filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00524',
        name: 'Qirguli filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00591',
        name: "Qo'ng'irot filiali"
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00531',
        name: "Qo'qon filiali"
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00868',
        name: "Qoraqalpog'iston mintaqaviy filiali"
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00085',
        name: 'Qorovulbozor filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00435',
        name: 'Rakat filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00317',
        name: 'Samarqand mintaqaviy filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00400',
        name: 'Shahriston filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00354',
        name: "Sharg'un filiali"
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00855',
        name: 'Sirdaryo mintaqaviy filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00403',
        name: "Sirg'ali filiali"
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '01045',
        name: 'Surxondaryo mintaqaviy filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00616',
        name: 'Taxiatosh filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00862',
        name: "To'qimachilik filiali"
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00424',
        name: 'Toshkent shahar mintaqaviy filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00490',
        name: 'Toshkent viloyat mintaqaviy filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00397',
        name: 'Uchtepa filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00051',
        name: 'Xonobod filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00853',
        name: 'Xorazm mintaqaviy filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00432',
        name: 'Yashnabod filiali'
    },
    {
        bank_code: 'UJSIUZ22XXX',
        bank_name: 'UZBEK INDUSTRIAL AND CONSTRUCTION BANK, JSCB',
        code: '00428',
        name: 'Yunusobod filiali'
    }
];
