<div class="container mb-5">

    <div class="title-area">
        <div>
            <h4 class="page-title">Developer</h4>
        </div>


        <div class="title-area-inner">
            <app-animated-button menuTitle="Version">
                <div class="animated-button-btns-wrapper">
                    <div *ngFor="let version of versions">
                        <button class="btn btn-secondary btn-tiny" (click)="versionSelect(version)">
                            {{version}}</button>
                    </div>
                </div>
            </app-animated-button>
            <button class="btn btn-primary btn-tiny ms-2"
                (click)="openModal(selectedVersion === '1.0.0'?legacy_api_keys:api_keys)" [disabled]="!apiKeysReady">
                <div *ngIf="apiKeysReady">
                    <img class="plus-icon-on-btn" src='../../../assets/icons/plus-for-create.svg' alt='API keys'>
                    API keys
                </div>
                <loading-indicator *ngIf="!apiKeysReady" [size]="'small'" [color]="'white'"></loading-indicator>
            </button>
        </div>
    </div>

    <hr class="aw-hr">






    <div class="mt-4 px-4 pt-2 pb-3 col-12 box">
        <div #swagger></div>
    </div>

    <ng-template #api_keys let-modal>
        <app-custom-modal>
            <ng-container modal-title>
                <h4>API keys</h4>
            </ng-container>
            <ng-container modal-dismiss>
                <a tabindex="undefined" (keydown.enter)="closeModal()" (click)="closeModal()"><img
                        src="../../../assets/icons/modal-close-icon.svg" alt="close dialog"></a>
            </ng-container>
            <ng-container modal-body>
                <div class="modal-padding-wrapper">
                    <div class="input-description" style="margin-bottom:3px;">{{
                        selectedVersion==='1.0.0'?'Public key':'API key'
                        }}</div>

                    <div class="mb-2" style="display: flex; flex-wrap: wrap; justify-content: space-between;">
                        <div class="flex-grow-1 me-2">
                            <div class="input-group-wrapper">
                                <input type="text" class="form-control rounded-corners"
                                    value="{{api_key ?? 'Loading...'}}" disabled="true">
                            </div>
                        </div>
                        <button class="btn btn-primary btn-tiny fixed-height btn-fixed-width"
                            (click)="copy(api_key); copied('api');">{{api_key_copied ? 'Copied' :
                            'Copy'}}</button>
                    </div>

                    <div *ngFor="let prefix of (prefixes ? prefixes : [])" class="mb-2">
                        <div class="input-description" style="margin-bottom:3px;">Secret key prefix</div>
                        <div class="mb-3">
                            <div style="display: flex; justify-content: space-between;">
                                <div class="me-2 flex-grow-1">
                                    <div class="input-group-wrapper m-0">
                                        <input type="text" class="form-control rounded-corners" value="{{ prefix }}"
                                            disabled="true">
                                    </div>
                                </div>



                                <button class="btn btn-warning btn-tiny fixed-height btn-fixed-width"
                                    (click)="revokeSecret(prefix)">Revoke</button>
                            </div>

                            <div class="input-description" style="margin-bottom:3px;">Allowed paths</div>
                            <div class="flex-grow-1">
                                <ng-select placeholder="Allow access to paths" [multiple]="true"
                                    [(ngModel)]="selectedEndpointAccess[prefix]">
                                    <ng-option *ngFor="let path of endpointPaths"
                                        [value]="path.id">{{path.name}}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>


                    <div style="display: flex; flex-wrap: wrap; justify-content: space-between;"
                        class="flex-grow-1 mb-2">
                        <button class="btn btn-secondary btn-tiny fixed-height mb-3"
                            (click)="generateSecretKey()">Generate
                            new secret key</button>
                    </div>
                    <div *ngIf="new_secret_key">
                        <label class="mb-2">Private key</label>
                        <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
                            <div class="flex-grow-1 me-2 mb-2">
                                <div class="input-group-wrapper">
                                    <input type="text" class="form-control rounded-corners" value="{{new_secret_key}}"
                                        disabled="true">
                                    <div class="input-description">Copy and save this key, it is not possible to
                                        retrieve it
                                        again.</div>
                                </div>
                            </div>
                            <div class="mb-5">
                                <button class="btn btn-primary btn-tiny fixed-height btn-fixed-width"
                                    (click)="copy(new_secret_key);  copied('secret');">{{secret_key_copied ? 'Copied' :
                                    'Copy'}}</button>
                            </div>
                        </div>
                    </div>


                </div>


            </ng-container>
            <ng-container modal-footer>
                <div class="d-grid gap-2">
                    <button class="btn btn-secondary btn-block" (click)="saveAPIKeys()"
                        [disabled]="showSmallLoadingIndicator">
                        {{showSmallLoadingIndicator ? '' :('account.save' | translate)}}
                        <loading-indicator *ngIf="showSmallLoadingIndicator" [size]="'large'"
                            [color]="'white'"></loading-indicator>
                    </button>
                </div>
            </ng-container>
        </app-custom-modal>
    </ng-template>


    <!-- NOTE: LEGACY MODAL BELOW -->
    <ng-template #legacy_api_keys let-modal>
        <app-custom-modal>
            <ng-container modal-title>
                <h4>API keys</h4>
            </ng-container>
            <ng-container modal-dismiss>
                <a (click)="legacyCloseModal()"><img src="../../../assets/icons/modal-close-icon.svg"
                        alt="close dialog"></a>
            </ng-container>
            <ng-container modal-body>
                <div class="modal-padding-wrapper">
                    <label class="mb-2 my-1">Public key</label>
                    <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
                        <div class="flex-grow-1 me-2">
                            <div class="input-group-wrapper">
                                <input type="text" class="form-control rounded-corners" value="{{public_key}}"
                                    disabled="true">
                            </div>
                        </div>
                        <div class="">
                            <button class="btn btn-primary btn-tiny fixed-height btn-fixed-width"
                                (click)="legacyCopy(public_key); legacyCopied('private');">{{legacy_private_key_copied ?
                                'Copied' :
                                'Copy'}}</button>
                        </div>
                    </div>

                    <label class="mb-2 my-1">Private keys prefix</label>
                    <div *ngFor="let key of (legacy_prefixes ? legacy_prefixes : [])">
                        <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
                            <div class="flex-grow-1 me-2">
                                <div class="input-group-wrapper">
                                    <input type="text" class="form-control rounded-corners" value="{{ key }}"
                                        disabled="true">
                                </div>
                            </div>
                            <button class="btn btn-warning btn-tiny fixed-height btn-fixed-width"
                                (click)="legacyRevokePrivateKey(key)">Revoke</button>
                        </div>
                    </div>
                    <div style="display: flex; flex-wrap: wrap; justify-content: space-between;"
                        class="flex-grow-1 mb-2 my-1">
                        <button class="btn btn-secondary btn-tiny fixed-height"
                            (click)="legacyGeneratePrivateKey()">Generate
                            new private key</button>
                    </div>
                    <div *ngIf="legacy_new_private_key">
                        <label class="mb-2 my-1">Private key</label>
                        <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
                            <div class="flex-grow-1 me-2 mb-2 my-1">
                                <div class="input-group-wrapper">
                                    <input type="text" class="form-control rounded-corners"
                                        value="{{legacy_new_private_key}}" disabled="true">
                                    <div class="input-description">Copy and save this key, it is not possible to
                                        retrieve it
                                        again.</div>
                                </div>
                            </div>
                            <div class="mb-2 my-1">
                                <button class="btn btn-primary btn-tiny fixed-height btn-fixed-width"
                                    (click)="legacyCopy(legacy_new_private_key);  legacyCopied('public');">{{legacy_public_key_copied
                                    ? 'Copied' :
                                    'Copy'}}</button>
                            </div>
                        </div>

                    </div>
                </div>
            </ng-container>
            <ng-container modal-footer>
                <div class="no-footer"></div>
            </ng-container>
        </app-custom-modal>
    </ng-template>
</div>