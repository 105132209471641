import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DeviceService } from "../../../services/device/device.service";
import { HelperService } from "../../../services/helper/helper.service";
import moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { DashboardUser } from 'shared_models/dashboard-user';
import { Basic } from 'shared_models/device';
import { updateMachineSpecificationServiceParams } from 'shared_models/machine-specifications';
import { ProductType } from 'shared_models/product-type';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingComponent } from '../../loading/loading.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { NgIf } from '@angular/common';
import { CustomToolTipComponent } from '../../misc/custom-tool-tip/custom-tool-tip.component';
import { CustomModalComponent } from '../../misc/custom-modal/custom-modal.component';

@Component({
    selector: 'app-machine-services-edit-modal',
    templateUrl: './machine-services-edit-modal.component.html',
    styleUrls: ['./machine-services-edit-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, FormsModule, ReactiveFormsModule, CustomToolTipComponent, NgIf, MatCheckbox, LoadingComponent, TranslateModule]
})
export class MachineServicesEditModalComponent implements OnInit {

    @Input() device: Basic;

    machineSpecForm: UntypedFormGroup;
    formBuilder: UntypedFormBuilder;
    formSubmitted = false

    sendingData = false;
    user: DashboardUser;
    uid: string

    soapEnabled = false;
    constructor(
        private route: ActivatedRoute,
        private deviceService: DeviceService,
        public modalService: NgbModal,
        private helperService: HelperService
    ) {
        this.machineSpecForm = new FormGroup({
            current_maintenance_count: new UntypedFormControl(null, [Validators.required, Validators.min(0)]),
            maintenance_limit: new UntypedFormControl(null, [Validators.required, Validators.min(0)]),
            current_soap_count: new UntypedFormControl(null),
            soap_limit: new UntypedFormControl(null),
        })
    }

    get current_maintenance_count() { return this.machineSpecForm.get('current_maintenance_count') }
    get maintenance_limit() { return this.machineSpecForm.get('maintenance_limit') }
    get current_soap_count() { return this.machineSpecForm.get('current_soap_count') }
    get soap_limit() { return this.machineSpecForm.get('soap_limit') }

    ngOnInit(): void {
        this.user = this.helperService.getUser()
        this.uid = this.device.customer_uid ? this.device.customer_uid : this.user.uid
        this.handleFormChange()
        this.current_maintenance_count.setValue(this.device.machine_specifications.machine_spec_service.current_maintenance_count)
        this.maintenance_limit.setValue(this.device.machine_specifications.machine_spec_service.maintenance_limit)
        if (this.device.machine_specifications.machine_spec_service.soap_limit) {
            this.soapEnabled = true;
            this.soap_limit.setValue(this.device.machine_specifications.machine_spec_service.soap_limit);
            this.current_soap_count.setValue(this.device.machine_specifications.machine_spec_service.current_soap_count);
        }
        if (!this.soapEnabled) {
            this.current_soap_count.disable()
            this.soap_limit.disable()
        }
    }

    disableSoap(event) {
        if (event.checked === false) {
            this.soapEnabled = false
            this.disableInputfieldAndValidators()
        } else {
            this.soapEnabled = true
            this.enableInputfieldAndValidators()
        }
    }

    disableInputfieldAndValidators() {
        this.soap_limit.setValue(0)
        this.current_soap_count.setValue(0)
        this.current_soap_count.disable()
        this.soap_limit.disable()
        this.machineSpecForm.controls['soap_limit'].setValidators(null);
        this.machineSpecForm.controls['soap_limit'].updateValueAndValidity()
    }

    enableInputfieldAndValidators() {
        this.current_soap_count.enable()
        this.soap_limit.enable()
        this.machineSpecForm.controls['soap_limit'].setValidators([Validators.min(0), Validators.required]);
        this.machineSpecForm.controls['soap_limit'].updateValueAndValidity()
    }

    handleFormChange() {
        this.machineSpecForm.get('maintenance_limit').valueChanges.subscribe(val => {
            this.machineSpecForm.controls['current_maintenance_count'].setValidators([Validators.min(0), Validators.required, Validators.max(val)]);
            this.machineSpecForm.controls['current_maintenance_count'].updateValueAndValidity()
        })
        this.machineSpecForm.get('soap_limit').valueChanges.subscribe(val => {
            if (this.soapEnabled) {
                this.machineSpecForm.controls['current_soap_count'].setValidators([Validators.min(0), Validators.required, Validators.max(val)]);
                this.machineSpecForm.controls['current_soap_count'].updateValueAndValidity()
            } else {
                this.machineSpecForm.controls['current_soap_count'].setValidators(null);
                this.machineSpecForm.controls['current_soap_count'].updateValueAndValidity()
            }
        })
    }

    async onSubmit() {
        this.formSubmitted = true
        if (this.machineSpecForm.valid) {
            const formData: updateMachineSpecificationServiceParams = {
                machine_spec_service: this.machineSpecForm.value,
                deviceKey: this.device.id,
                locationKey: this.device.location_id,
            }
            this.sendingData = true
            const onbehalf: string = this.route.snapshot.paramMap.get('sub_customer_id');
            await this.deviceService.updateDeviceMachineServices(formData, onbehalf ? onbehalf : null).then((response) => {
                this.sendingData = false
                this.modalService.dismissAll()
            })
        }
    }

    protected readonly ProductType = ProductType;
}
