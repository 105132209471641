import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { InviteMemberDetails, MemberForDisplay } from '../../../../../shared_models/invited-user';
import { HttpService, RequestTypes } from '@services/helper/http.service';

@Injectable({
    providedIn: 'root'
})
export class TeamMemberService {
    constructor(private httpService: HttpService) {}

    async getTeamMembers(): Promise<MemberForDisplay[]> {
        return this.httpService.dynamicHttp<MemberForDisplay[]>(`api_account/team_member`, RequestTypes.GET);
    }

    async inviteOrEditMember(invitedUserParams: InviteMemberDetails, isEdit: boolean) {
        if (isEdit) {
            return await this.editTeamMember(invitedUserParams);
        } else {
            return await this.inviteNewTeamMember(invitedUserParams);
        }
    }

    async inviteNewTeamMember(invitedUserParams: InviteMemberDetails) {
        return this.httpService.dynamicHttp(`api_account/team_member`, RequestTypes.POST, { body: invitedUserParams });
    }
    async editTeamMember(invitedUserParams: InviteMemberDetails) {
        return this.httpService.dynamicHttp(`api_account/team_member`, RequestTypes.PUT, { body: invitedUserParams });
    }

    async removeTeamMember(teamMember: MemberForDisplay) {
        const options = {
            body: teamMember
        };
        return this.httpService.dynamicHttp(`api_account/team_member`, RequestTypes.DELETE, options);
    }

    async resendInviteLink(teamMember: MemberForDisplay, inviteFrom: string) {
        return this.httpService.dynamicHttp(`api_account/team_member/resend_invite`, RequestTypes.POST, {
            body: {
                email: teamMember.mail,
                role: teamMember.role,
                inviteSentFrom: inviteFrom
            }
        });
    }
}
