import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';
@Component({
    selector: 'app-apply-credit-payment-modal',
    templateUrl: './apply-credit-payment-modal.component.html',
    styleUrls: ['./apply-credit-payment-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, TranslateModule]
})
export class ApplyCreditPaymentModalComponent implements OnInit {
  @Input() userId: string;

  constructor(
    private modal: NgbModal
  ) { }


  ngOnInit(): void {
    
  }

  closeModal() {
    this.modal.dismissAll();
  }

}
