import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoggedInDashboardUser } from 'shared_models/dashboard-user';
import { ActivityLoggingService } from 'src/app/services/users/logging/activity-logging.service';
import { ShallowActivityDetails } from 'shared_models/user-management';
import { LoadingComponent } from '../../../loading/loading.component';
import { NgIf } from '@angular/common';
import { CustomModalComponent } from '../../../misc/custom-modal/custom-modal.component';
import { LocalStorageService } from '@services/local-storage/local-storage.service';

@Component({
    selector: 'app-add-comment-modal',
    templateUrl: './add-comment-modal.component.html',
    styleUrls: ['./add-comment-modal.component.scss'],
    standalone: true,
    imports: [CustomModalComponent, FormsModule, ReactiveFormsModule, NgIf, LoadingComponent, TranslateModule]
})
export class AddCommentModalComponent implements OnInit {
    @Input() userId: string;
    @Input() onBehalf: string;
    @Output() onStateChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    showSmallIndicator = false;
    commentGroup: FormGroup;
    nameOfLoggedInUser: string;
    constructor(
        private modal: NgbModal,
        private activityLoggingService: ActivityLoggingService,
        private toast: ToastrService,
        private translate: TranslateService,
        private localStorageService: LocalStorageService
    ) {}

    ngOnInit(): void {
        this.nameOfLoggedInUser = this.localStorageService.getItem('loggedInUser').name;
        this.commentGroup = new FormGroup({
            comment: new FormControl('', [Validators.required])
        });
    }

    closeModal() {
        this.modal.dismissAll();
    }

    addComment() {
        this.showSmallIndicator = true;
        const params: ShallowActivityDetails = {
            comment: this.comment.value,
            author: this.nameOfLoggedInUser,
            status_type: 'comment'
        };

        this.activityLoggingService.logActivity(params, this.userId, this.onBehalf).then(response => {
            this.toast.success(`${this.translate.instant(`users.user.success_add_comment`)}`, this.translate.instant('misc.success'));
            setTimeout(() => {
                this.onStateChange.emit(true);
                this.showSmallIndicator = false;
                this.closeModal();
            });
        });
    }

    get comment() {
        return this.commentGroup.get('comment');
    }
}
