<app-custom-modal>
    <ng-container modal-title>
        <h4>
            {{ 'operator.anton_health.incident_comment' | translate }}
        </h4>
    </ng-container>
    <ng-container modal-body>
        <div class="comment-scroll-box" #scrollBox>
            <div *ngFor="let note of incidentToEdit.notes" class="comment-box">
                <div class="wrapper">
                    <p><strong>{{ 'users.user.user' | translate }}</strong>: {{ note.user_name }}</p>
                    <p><strong>{{ helperService.getLocalizedDateAndTime(note.created) }}</strong></p>
                </div>
                <pre>
                    {{ note.note }}
                </pre>
                <div class="wrapper">
                    <div>
                        <div *ngIf="note.solution" class="solution-box">{{ ('operator.anton_health.solution' | translate) }}</div>
                    </div>
                    <div>
                        <u *ngIf="note.solution"
                           [ngClass]="{'show-on-hover': incidentToEdit.solved,
                                       'dont-show': loadingNoteId !== null && loadingNoteId !== note.id,
                                       'always-show' : isMobile,
                                       'remove-solution-text': true}"
                           (click)="sendingData ? null : changeSolutionStatus(note.id,false)">
                            {{ loadingNoteId == note.id ? '' : ('operator.anton_health.remove_solution_mark' |translate) }}
                            <loading-indicator *ngIf="loadingNoteId == note.id" [size]="'small'"
                                               [color]="'secondary'"></loading-indicator>
                        </u>
                        <u *ngIf="!note.solution"
                           [ngClass]="{'show-on-hover': !incidentToEdit.solved,
                                       'dont-show' : incidentToEdit.solved || (loadingNoteId !== null && loadingNoteId !== note.id),
                                       'always-show' : isMobile,
                                       'mark-solution-text': true }"
                           (click)="sendingData ? null : changeSolutionStatus(note.id,true)">
                            {{ loadingNoteId == note.id ? '' : ('operator.anton_health.mark_as_solution' |translate) }}
                            <loading-indicator *ngIf="loadingNoteId == note.id" [size]="'small'"
                                               [color]="'secondary'"></loading-indicator>
                        </u>
                    </div>
                </div>
            </div>
        </div>
        <form [formGroup]="incidentForm">
            <div class="wrapper">
                <p><strong>{{ 'operator.anton_health.new_comment' | translate }}</strong></p>
                <app-aw-checkbox [text]="('operator.anton_health.mark_as_solution' | translate)" [textPosition]="'after'"
                                 (checkStateEmitter)="isSolution.setValue($event)"/>
            </div>
            <textarea class="form-control"
                      #textarea
                      style="min-height: 105px;"
                      required
                      [placeholder]="'operator.anton_health.write_comment_here' | translate"
                      name="comment"
                      (input)="countCharacters(textarea, 'char-counter')"
                      formControlName="comment"></textarea>
            <div [className]="comment.errors?.maxlength ? 'character-count invalid' : 'character-count'"
                 id="char-counter">0/{{ maxLength }}
            </div>
        </form>
    </ng-container>
    <ng-container modal-footer>
        <button class="btn btn-secondary" style="width: 100%" [disabled]="sendingData || comment.errors?.maxlength"
                (click)="onSubmit()">
            {{ sendingData ? '' : ('account.save' |translate) }}
            <loading-indicator *ngIf="sendingData" [size]="'large'" [color]="'white'"></loading-indicator>
        </button>
    </ng-container>
</app-custom-modal>
