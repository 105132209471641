<section>
    <!--Desktop-->
    <div class="container target" *ngIf="!isMobile">
        <div class="operator-buttons-div">
            <div title="{{'customers.customers' | translate}}">
                <p class="{{this.view === 'Customers' ? 'operator-active-button' : 'operator-button'}}" id="Customers"
                    (click)="toggle($event)" [routerLink]="['/operator/customers']">{{'customers.customers' |
                    translate}}</p>
            </div>
            <div title="{{'operator.filter_bar.actions' | translate}}">
                <p class="{{this.view === 'Actions' ? 'operator-active-button' : 'operator-button'}}" id="Actions"
                    (click)="toggle($event)" [routerLink]="['/operator/actions']">{{'operator.filter_bar.actions' |
                    translate}}</p>
            </div>
            <div title="{{'operator.filter_bar.contracts' | translate}}"
                *ngIf="authService.allowAccess('contracts', (role$ | async))">
                <p class="{{this.view === 'Contracts' ? 'operator-active-button' : 'operator-button'}}" id="Contracts"
                    (click)="toggle($event)" [routerLink]="['/operator/contracts']">{{'operator.filter_bar.contracts' |
                    translate}}</p>
            </div>
            <div title="{{'operator.filter_bar.devices' | translate}}">
                <p class="{{this.view === 'Devices' ? 'operator-active-button' : 'operator-button'}}" id="Devices"
                    (click)="toggle($event)" [routerLink]="['/operator/devices']">{{'operator.filter_bar.devices' |
                    translate}}</p>
            </div>
            <div title="{{'operator.filter_bar.anton-health' | translate}}">
                <p class="{{this.view === 'anton-health' ? 'operator-active-button' : 'operator-button'}}"
                    id="anton-health" (click)="toggle($event)" [routerLink]="['/operator/anton-health']">
                    {{'operator.filter_bar.anton-health' |
                    translate}}</p>
            </div>
        </div>
        <app-filter-bar *ngIf="!(this.view === 'Actions' || this.view === 'anton-health')" [filter-title]="view"
            (fromDateEmitter)="getFromDate($event)" (toDateEmitter)="getToDate($event)"
            (showOnlyMaintenanceEmitter)="filterByMaintenance($event)"></app-filter-bar>
        <router-outlet *ngIf="view !== 'anton-health'"></router-outlet>
    </div>
    <div *ngIf="view === 'anton-health' && !isMobile">
        <router-outlet></router-outlet>
    </div>

    <!-- Mobile-->
    <div class="container" *ngIf="isMobile">
        <div class="d-flex justify-content-between align-items-center mobile-top">
            <p style="font-size: 30px; margin-bottom: 0;"><strong>{{this.pageTitle}}</strong></p>
            <app-filter-bar [filter-title]="this.view" (fromDateEmitter)="getFromDate($event)"
                (toDateEmitter)="getToDate($event)"></app-filter-bar>
        </div>
        <div class="mobile-body">
            <router-outlet></router-outlet>
        </div>
    </div>
</section>