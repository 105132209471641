<app-custom-modal>
    <ng-container modal-title>
        <h4 class="modal-title">{{title}}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a (click)="deleteModalService.closeAndResetModal()">
            <img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog">
        </a>
    </ng-container>
    <ng-container modal-body>
        <p name="confirmDeleteDevice">{{description}}</p>
    </ng-container>
    <ng-container modal-footer>
        <div class="d-grid gap-2">
            <button class="btn btn-danger" tabindex="-1" (click)="confirmDelete()">
                {{!isLoading ? buttonText : ''}}
                <loading-indicator *ngIf="isLoading" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
        </div>
    </ng-container>
</app-custom-modal>
