<app-custom-modal>
    <ng-container modal-title>
        <h4>{{'users.user.apply_coupon' | translate}}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a (click)="closeModal()"><img src="../../../assets/icons/modal-close-icon.svg" alt="close dialog"></a>
    </ng-container>
    <ng-container modal-body>
        <div class="modal-body-wrapper">

            <div class="mb-2">
                <strong class="modal-body-title">{{'users.user.coupons.select_a_coupon' | translate}}</strong>
            </div>

            <form [formGroup]="couponForm">
                <select #select (focus)="isFocused()" (blur)="isBlurred()" formControlName="selected_coupon"
                    [className]="shouldBeGrey && !selected_coupon.value ? 'form-select coupon-select greyed-out' : 'form-select coupon-select'">
                    <option selected disabled [ngValue]="null">
                        {{
                        ('users.user.coupons.choose_a_coupon' |
                        translate)
                        }}
                    </option>
                    <option [ngValue]="coupon.key" *ngFor="let coupon of availableCoupons; let i = index">
                        {{coupon.name + ' - ' + getTerms(coupon)}}
                    </option>

                </select>
            </form>


        </div>
    </ng-container>
    <ng-container modal-footer>
        <div class="d-flex flex-column align-items-center">
            <button class="btn btn-secondary mt-3" style="width: 100%"
                [disabled]="hasSubmitted || !selected_coupon.value" (click)="applyCoupon()">
                {{hasSubmitted ? '' : ('device.save_changes' | translate)}}
                <loading-indicator *ngIf="hasSubmitted" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
        </div>
    </ng-container>
</app-custom-modal>