import {Injectable} from '@angular/core';
import {AngularFireDatabase, AngularFireObject} from "@angular/fire/compat/database";
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {Serial} from 'shared_models/serial';
import * as Terminal from 'shared_models/terminal';
import {TerminalCreateParams} from 'shared_models/create-terminal';
import {HttpService, RequestTypes} from "../helper/http.service";

@Injectable({
    providedIn: 'root'
})
export class TerminalService {

    serialInUseBool: Observable<any>;
    stripeMinChargeAmount: AngularFireObject<any>;
    terminal: AngularFireObject<any>;
    serial: Serial;
    baseUrl: string = environment.baseUrl

    constructor(
        private db: AngularFireDatabase,
        private httpService: HttpService,
    ) {
    }

    readTerminalReaders(uid: string, location_id: string): AngularFireObject<Record<string, Terminal.TerminalReader>> {
        return this.db.object(`customers/${uid}/locations/${location_id}/terminal_readers`);
    }

    async createTerminal(uid: string, terminal: TerminalCreateParams ): Promise<Terminal.Unit> {
        return this.httpService.dynamicHttp('api_terminal/create_terminal', RequestTypes.POST, {body: terminal, onBehalf: uid})
    }

    async updateTerminal(req: { uid: string, locationId: string, terminal: Terminal.TerminalReader }): Promise<{terminals: Record<string, Terminal.Unit>}> {
        return this.httpService.dynamicHttp('api_terminal/update_terminal', RequestTypes.PUT, {body: req, onBehalf: req.uid})
    }

    async deleteTerminal(uid: string, location_id: string, terminal_id: string, terminal_uid: string): Promise<{terminals: Record<string, Terminal.Unit>}> {
        return this.httpService.dynamicHttp('api_terminal/delete_terminal', RequestTypes.DELETE, {body: {location_id, terminal_id, terminal_uid}, onBehalf: uid})
    }

    // control is used from admin anton health page, to put this in the header 
    async restartTerminal(uid: string, locationId: string, serial: string, control?:string): Promise<any> {
        try {
            return this.httpService.dynamicHttp(`api_terminal/restart/`, RequestTypes.GET, {params: {locationId, serial}, onBehalf: uid, control: control})

        } catch (error) {
            console.error(error)
        }
    }
}



