<div class="container mb-5" id="device-setup-page">
    <h4 class="mt-4 mb-3 newDeviceTitle">{{'device_setup.setup' | translate}}</h4>
    <div class="box cont row custom-check-box-list-steps">

        <div class="p-4">

            <div *ngIf="!deviceSetupForm" class="loading-height">
                <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
            </div>

            <form autocomplete="off" class="device-setup-form" id="deviceSetupForm" *ngIf="deviceSetupForm"
                [formGroup]="deviceSetupForm">

                <aw-wizard [disableNavigationBar]="true" [defaultStepIndex]="getDefaultStartStep()">

                    <!-- STEP: Serial number -->
                    <aw-wizard-step stepTitle="{{'device_setup.serial_num' | translate}}"
                        [awCompletedStep]="reconfigValues">
                        <div class="form-group">
                            <h5 class="mb-3">{{'device_setup.provide_serial_num' | translate}}</h5>
                            <div class="serial-number-wrapper">
                                <div class="serial-number-validation-fields">
                                    <div>
                                        <label class="mb-1 mt-3">{{'device_setup.serial_num' | translate}}</label>
                                        <input class="form-control mb-2" type="text" formControlName="guid">
                                        <div class="input-description pt-0" id="serial-validation-msg">
                                            {{setupVars.guidVars.guidValidationMsg}}
                                        </div>
                                    </div>
                                </div>
                                <div class="box-illustration">
                                    <img src="assets/illustrations/box-illustration.png" class="illustration-image"
                                        alt="Box illustration">
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-primary" type="button"
                                (click)="goToProductCategory()">{{validatingGuid ? loading : nextStep}}</button>
                        </div>
                    </aw-wizard-step>

                    <!-- STEP: Select product category -->
                    <aw-wizard-step stepTitle="{{'device_setup.product_category' | translate}}">
                        <div class="row">
                            <div class="col-lg-4 col-sm-12 mb-5">
                                <div class="form-group" id="product-category">
                                    <h5 class="mb-1">{{'device_setup.product_category' | translate}}</h5>

                                    <label class="radio-card mb-2">
                                        <input type="radio" id="card-input-element-id" class="card-input-element d-none"
                                            value="1" name="productCategory"
                                            (click)="setProductCategory(eProductType.Washer)">
                                        <div
                                            class="card small-card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                                            {{'device_setup.washer' | translate}}
                                        </div>
                                    </label>

                                    <label class="radio-card mb-2">
                                        <input type="radio" id="card-input-element-id" class="card-input-element d-none"
                                            value="2" name="productCategory"
                                            (click)="setProductCategory(eProductType.Dryer)">
                                        <div
                                            class="card small-card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                                            {{'device_setup.dryer' | translate}}
                                        </div>
                                    </label>

                                    <label class="radio-card mb-2">
                                        <input type="radio" id="card-input-element-id" class="card-input-element d-none"
                                            value="3" name="productCategory"
                                            (click)="setProductCategory(eProductType.Solarium)">
                                        <div
                                            class="card small-card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                                            {{'device_setup.solarium' | translate}}
                                        </div>
                                    </label>

                                    <label class="radio-card mb-2">
                                        <input type="radio" id="card-input-element-id" class="card-input-element d-none"
                                            value="4" name="productCategory"
                                            (click)="setProductCategory(eProductType.Other)">
                                        <div
                                            class="card small-card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                                            {{'device_setup.other' | translate}}
                                        </div>
                                    </label>

                                    <div
                                        *ngIf="!setupVars.productCategoryVars.productType && setupVars.productCategoryVars.goToPricingStepFired">
                                        <div class="error-msg">
                                            {{'device_setup.select_usage' | translate}}
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                        <div class="d-flex {{guidStartParams ? 'justify-content-end' : 'justify-content-between'}}">
                            <button class="btn btn-secondary" type="button" (click)="restartSetup()"
                                *ngIf="!guidStartParams">{{'device_setup.start_over' | translate}}</button>
                            <button class="btn btn-primary" type="button"
                                (click)="nextStepFromProductOrSignal('product')">{{'device_setup.next_step' |
                                translate}}</button>
                        </div>
                    </aw-wizard-step>

                    <!-- STEP: Select pricing model -->
                    <aw-wizard-step stepTitle="{{'device_setup.pricing_model' | translate}}"
                        *ngIf="setupVars.productCategoryVars.productType !== eProductType.Solarium">
                        <div class="row">
                            <div class="col-lg-4 col-sm-12 mb-5">
                                <div class="form-group" id="pricing-model">
                                    <h5 class="mb-1">{{'device_setup.select_pricing' | translate}}</h5>

                                    <label class="radio-card mb-2">
                                        <input type="radio" id="card-input-element-id" class="card-input-element d-none"
                                            value="1" name="pricingModel"
                                            (click)="setPricingModel(ePricingModel.Variable)">
                                        <div
                                            class="card small-card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                                            {{'device_setup.variable' | translate}}
                                        </div>
                                    </label>

                                    <label class="radio-card mb-2">
                                        <input type="radio" id="card-input-element-id" class="card-input-element d-none"
                                            value="2" name="pricingModel"
                                            (click)="setPricingModel(ePricingModel.Fixed)">
                                        <div
                                            class="card small-card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                                            {{'device_setup.fixed' | translate}}
                                        </div>
                                    </label>

                                    <div
                                        *ngIf="!setupVars.pricingModelVars.pricingModel && setupVars.pricingModelVars.goToStartSignalStepFired">
                                        <div class="error-msg">
                                            {{'device_setup.select_pricing_above' | translate}}
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-secondary" type="button"
                                (click)="goBackFromPricingModelStep()">{{'device_setup.previous_step' |
                                translate}}</button>
                            <button class="btn btn-primary" type="button"
                                (click)="goToStartSignalStep()">{{'device_setup.next_step' | translate}}</button>
                        </div>
                    </aw-wizard-step>

                    <!-- STEP: Select start signal -->
                    <aw-wizard-step stepTitle="{{'device_setup.start_signal' | translate}}"
                        *ngIf="setupVars.productCategoryVars.productType !== eProductType.Solarium">
                        <div class="row">
                            <div class="col-lg-4 col-sm-12 mb-5">
                                <div class="form-group" id="start-signal">
                                    <h5 class="mb-1">{{'device_setup.select_signal' | translate}}</h5>

                                    <label class="radio-card mb-0">
                                        <input type="radio" id="card-input-element-id" class="card-input-element d-none"
                                            value="1" name="startSignal" (click)="setStartSignal(eSignal.Pulse)">
                                        <div
                                            class="card small-card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                                            {{'device_setup.pulse_operated' | translate}}
                                        </div>
                                    </label>
                                    <div class="input-description mb-2"
                                        *ngIf="setupVars.pricingModelVars.pricingModel === ePricingModel.Fixed">
                                        {{'device_setup.short_start_signal' | translate}}</div>

                                    <label class="radio-card mb-0 mt-2"
                                        *ngIf="setupVars.pricingModelVars.pricingModel === ePricingModel.Variable">
                                        <input type="radio" id="card-input-element-id" class="card-input-element d-none"
                                            value="2" name="startSignal" (click)="setStartSignal(eSignal.FullDuration)">
                                        <div
                                            class="card small-card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                                            {{'device_setup.full_duration' | translate}}
                                        </div>
                                    </label>

                                    <label class="radio-card mb-0 mt-2"
                                        *ngIf="setupVars.pricingModelVars.pricingModel === ePricingModel.Fixed">
                                        <input type="radio" id="card-input-element-id" class="card-input-element d-none"
                                            value="3" name="startSignal"
                                            (click)="setStartSignal(eSignal.UserStartTime)">
                                        <div
                                            class="card small-card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                                            {{'device_setup.user_start_time' | translate}}
                                        </div>
                                    </label>
                                    <div class="input-description"
                                        *ngIf="setupVars.pricingModelVars.pricingModel === ePricingModel.Fixed">
                                        {{'device_setup.lang_start_signal' | translate}}</div>

                                    <div
                                        *ngIf="!setupVars.startSignalVars.signal && setupVars.startSignalVars.goToOptionsStepFired">
                                        <div class="error-msg">
                                            {{'device_setup.select_start_signal' | translate}}
                                        </div>
                                    </div>

                                </div>

                                <!-- Select pulse option -->
                                <div class="form-group" id="start-options"
                                    *ngIf="setupVars.startSignalVars.signal === eSignal.Pulse && setupVars.pricingModelVars.pricingModel === ePricingModel.Variable">
                                    <h5 class="mb-1">{{'device_setup.pulse_option' | translate}}</h5>

                                    <label class="radio-card mb-2">
                                        <input type="radio" id="card-input-element-id" class="card-input-element d-none"
                                            value="1" name="pulseOption" (click)="setPulseOption(eIncrementUnit.Time)">
                                        <div
                                            class="card small-card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                                            {{'device_setup.time_controlled' | translate}}
                                        </div>
                                    </label>

                                    <label class="radio-card mb-2">
                                        <input type="radio" id="card-input-element-id" class="card-input-element d-none"
                                            value="2" name="pulseOption" (click)="setPulseOption(eIncrementUnit.Money)">
                                        <div
                                            class="card small-card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                                            {{'device_setup.cost_controlled' | translate}}
                                        </div>
                                    </label>

                                    <label class="radio-card mb-2">
                                        <input type="radio" id="card-input-element-id" class="card-input-element d-none"
                                            value="3" name="pulseOption" (click)="setPulseOption('program')">
                                        <div
                                            class="card small-card card-body bg-light d-flex flex-row justify-content-between align-items-center">
                                            {{'device_setup.program_controlled' | translate}}
                                        </div>
                                    </label>

                                    <div
                                        *ngIf="(!setupVars.startSignalVars.pulseOptions && !isProgramPicked) && setupVars.startSignalVars.goToOptionsStepFired">
                                        <div class="error-msg">
                                            {{'device_setup.select_pulse' | translate}}
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-secondary" type="button"
                                (click)="goBackFromStartSignalStep()">{{'device_setup.previous_step' |
                                translate}}</button>
                            <button class="btn btn-primary" type="button"
                                (click)="nextStepFromProductOrSignal('signal')">{{'device_setup.next_step' |
                                translate}}</button>
                        </div>
                    </aw-wizard-step>

                    <!-- STEP: Details -->
                    <aw-wizard-step stepTitle="{{'device.details' | translate}}">
                        <div class="form-group mb-5">

                            <h5 class="mb-0">{{'device_setup.machine_spec' | translate}}</h5>
                            <!-- MACHINE SPECIFICATIONS GROUP -->
                            <div class="row">
                                <!-- BRAND -->
                                <div class="col-6">
                                    <label class="mb-1 mt-3">{{'misc.brand' | translate}}</label>
                                    <select class="form-select" formControlName="brand">
                                        <option selected disabled value="null">{{'misc.select_opt' | translate}}
                                        </option>
                                        <option value="{{brand}}" *ngFor="let brand of brandList">{{brand}}</option>
                                    </select>
                                    <div class="input-description">{{'device_setup.machine_brand' | translate}}</div>
                                    <div *ngIf="setupVars.detailsVars.formSubmitted && brand.invalid">
                                        <div class="error-msg" *ngIf="brand.errors.required">
                                            {{'device_setup.brand_req' | translate}}
                                        </div>
                                    </div>
                                </div>

                                <!-- MODEL -->
                                <div class="col-6">
                                    <label class="mb-1 mt-3">{{'device_setup.model' | translate}}</label>
                                    <input name="model" class="form-control" type="text" formControlName="model">
                                    <div class="input-description">{{'device_setup.machine_model' | translate}}</div>
                                    <div *ngIf="setupVars.detailsVars.formSubmitted && model.invalid">
                                        <div class="error-msg" *ngIf="model.errors.required">
                                            {{'device_setup.model_req' | translate}}
                                        </div>
                                        <div class="error-msg" *ngIf="model.errors.maxlength">
                                            {{'device_setup.model_max' | translate}}
                                            {{model.errors.maxlength.requiredLength}} {{'misc.characters_long' |
                                            translate}}
                                        </div>
                                    </div>
                                </div>

                                <!-- MACHINE SERIAL NUMBER -->
                                <div class="col-6">
                                    <label class="mb-1 mt-3">{{'device_setup.machine_serial' | translate}}</label>
                                    <input name="serial_number" class="form-control" type="text"
                                        formControlName="serial_number">
                                    <div class="input-description">{{'device_setup.serial_num_machine' | translate}}
                                    </div>
                                    <div *ngIf="setupVars.detailsVars.formSubmitted && serial_number.invalid">
                                        <div class="error-msg" *ngIf="serial_number.errors.maxlength">
                                            {{'device_setup.serial_max' | translate}}
                                            {{serial_number.errors.maxlength.requiredLength}} {{'misc.characters_long' |
                                            translate}}
                                        </div>
                                    </div>
                                </div>

                                <!-- PRODUCTION YEAR -->
                                <div class="col-6">
                                    <label class="mb-1 mt-3">{{'device_setup.production_year' | translate}}</label>
                                    <input name="production_year" class="form-control" step="1" type="number"
                                        formControlName="production_year">
                                    <div class="input-description">{{'device_setup.machine_produced_year' | translate}}
                                    </div>
                                    <div *ngIf="setupVars.detailsVars.formSubmitted && production_year.invalid">
                                        <div class="error-msg" *ngIf="production_year.errors.required">
                                            {{'device_setup.production_year_req' | translate}}
                                        </div>
                                        <div class="error-msg" *ngIf="production_year.errors.min">
                                            {{'device_setup.production_year_max' | translate}}
                                        </div>
                                        <div class="error-msg" *ngIf="production_year.errors.max">
                                            {{'device_setup.production_year_min' | translate}}
                                        </div>
                                        <div class="error-msg" *ngIf="production_year.errors.pattern">
                                            {{'device_setup.productions_year_format' | translate}}
                                        </div>
                                    </div>
                                </div>

                                <!-- NOTE -->
                                <div class="col-6">
                                    <label class="mb-1 mt-3">{{'device_setup.comments' | translate}}</label>
                                    <input name="note" class="form-control" type="text" formControlName="note">
                                    <div class="input-description">{{'device_setup.additional_details' | translate}}
                                    </div>
                                    <div *ngIf="setupVars.detailsVars.formSubmitted && note.invalid">
                                        <div class="error-msg" *ngIf="note.errors.maxlength">
                                            {{'device_setup.additional_details' | translate}}
                                            {{note.errors.maxlength.requiredLength}} {{'misc.characters_long' |
                                            translate}}
                                        </div>
                                    </div>
                                </div>

                                <!-- Coin drop installed -->
                                <div class="col-6">
                                    <label class="mb-1 mt-3">{{'device.coin_drop_installed' | translate}}</label>
                                    <div class='coin-drop-wrapper'>
                                        <div class="coin-drop-radio-wrapper">
                                            <div class="coin-drop-radio coin-drop-true">
                                                <input type="radio" class="" [value]="true" name="coin_drop_installed"
                                                    id="coin_dropTrue" [checked]='true'
                                                    formControlName="coin_drop_installed">
                                                <label class="" for="coin_dropTrue">
                                                    {{'misc.yes' | translate}}
                                                </label>
                                            </div>
                                            <div class="coin-drop-radio">
                                                <input type="radio" class="" [value]="false" name="coin_drop_installed"
                                                    id="coin_dropFalse" formControlName="coin_drop_installed">
                                                <label class="" for="coin_dropFalse">
                                                    {{'misc.no' | translate}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <!-- Installation Type -->

                                <div class="col-6"
                                    *ngIf="setupVars.startSignalVars.pulseOptions === eIncrementUnit.Time && setupVars.startSignalVars.signal === eSignal.FullDuration">
                                    <label class="mb-1 mt-3">{{'device.installation_type' | translate}}</label>
                                    <select class="form-select" formControlName="open_door">
                                        <option [ngValue]="false">{{'device.with_fixed_power' | translate}}</option>
                                        <option [ngValue]="true">{{'device.only_when_paid_for' | translate}}</option>
                                    </select>
                                    <div class="input-description">{{'device_setup.installation_type_desc' | translate}}
                                    </div>
                                </div>



                            </div>

                            <h5 class="mb-0 mt-5">{{'device.details' | translate}}</h5>
                            <!-- Device configuration input GROUP -->
                            <div class="row">
                                <!-- NAME, universal -->
                                <div class="col-6">
                                    <label class="mb-1 mt-3">{{'device_setup.name_in_app' | translate}}</label>
                                    <input name="name" class="form-control" type="text" formControlName="name">
                                    <div class="input-description">{{'device_setup.name_tied_to_machine' | translate}}
                                    </div>
                                    <div *ngIf="setupVars.detailsVars.formSubmitted && name.invalid">
                                        <div class="error-msg" *ngIf="name.errors.required">
                                            {{'misc.name_required' | translate}}
                                        </div>
                                        <div class="error-msg" *ngIf="name.errors.maxlength">
                                            {{'misc.name_max' | translate}} {{name.errors.maxlength.requiredLength}}
                                            {{'misc.characters_long' | translate}}
                                        </div>
                                        <div class="error-msg" *ngIf="name.errors.uniqueName">
                                            {{'device_setup.name_unique' | translate}}
                                            <strong>{{name.errors.uniqueName.value}}</strong>
                                            {{'device_setup.already_exists' |
                                            translate}}
                                        </div>
                                    </div>
                                </div>

                                <!-- PRICE -->
                                <div class="col-6"
                                    *ngIf="setupVars.startSignalVars.pulseOptions !== eIncrementUnit.Money && !isProgramPicked">
                                    <label class="mb-1 mt-3">{{'device_setup.price' | translate}}</label>
                                    <div class="input-group">
                                        <input name="price" class="form-control" type="number" min="0.01" step="0.01"
                                            formControlName="price">
                                        <span *ngIf="setupVars.startSignalVars.pulseOptions !== eIncrementUnit.Time"
                                            class="input-group-text">
                                            {{
                                            helperService.getPriceUnit(
                                            setupVars.productCategoryVars.productType,
                                            setupVars.pricingModelVars.pricingModel,
                                            currency?currency:null
                                            )
                                            }}</span>
                                        <span #hour
                                            *ngIf="setupVars.startSignalVars.pulseOptions === eIncrementUnit.Time"
                                            [className]="overwritePriceUnit && overwritePriceUnit.unit === ePriceUnit.Hour ? 'input-group-text price-unit-selected' : 'input-group-text price-unit-unselected'"
                                            type="button" (click)="togglePriceUnit(ePriceUnit.Hour)">
                                            {{
                                            helperService.getPriceUnit(
                                            setupVars.productCategoryVars.productType,
                                            setupVars.pricingModelVars.pricingModel,
                                            currency?currency:null,
                                            ePriceUnit.Hour
                                            )
                                            }}</span>
                                        <span #minute
                                            *ngIf="setupVars.startSignalVars.pulseOptions === eIncrementUnit.Time"
                                            [className]="overwritePriceUnit && overwritePriceUnit.unit === ePriceUnit.Minute ? 'input-group-text price-unit-selected' : 'input-group-text price-unit-unselected'"
                                            type="button" (click)="togglePriceUnit(ePriceUnit.Minute)"> {{
                                            helperService.getPriceUnit(
                                            setupVars.productCategoryVars.productType,
                                            setupVars.pricingModelVars.pricingModel,
                                            currency?currency:null,
                                            ePriceUnit.Minute
                                            )
                                            }}</span>
                                        <span #increment
                                            *ngIf="setupVars.startSignalVars.pulseOptions === eIncrementUnit.Time"
                                            [className]="overwritePriceUnit && overwritePriceUnit.unit === ePriceUnit.Increment ? 'input-group-text price-unit-selected' : 'input-group-text price-unit-unselected'"
                                            type="button" (click)="togglePriceUnit(ePriceUnit.Increment)"> {{
                                            helperService.getPriceUnit(
                                            setupVars.productCategoryVars.productType,
                                            setupVars.pricingModelVars.pricingModel,
                                            currency?currency:null,
                                            ePriceUnit.Increment,
                                            pulse_increment.value
                                            )
                                            }}</span>
                                    </div>
                                    <div *ngIf="setupVars.detailsVars.formSubmitted && price.invalid">
                                        <div class="error-msg" *ngIf="price.errors.required">
                                            {{'device_setup.price_req' | translate}}
                                        </div>
                                        <div class="error-msg" *ngIf="price.errors.pattern">
                                            {{'device_setup.price_requirements' | translate}}
                                        </div>
                                    </div>
                                </div>

                                <!-- START UP TIME, productType === Solarium -->
                                <div class="col-6"
                                    *ngIf="setupVars.productCategoryVars.productType === eProductType.Solarium">
                                    <label class="mb-1 mt-3">{{'device_setup.start_up_time' | translate}}</label>
                                    <select class="form-select" formControlName="startup_time">
                                        <option selected disabled value="null">{{'misc.select_opt' | translate}}
                                        </option>
                                        <option [ngValue]="0">{{'device_setup.start_immeediately' | translate}}</option>
                                        <option [ngValue]="90000">{{'device_setup.one_min_thrity_sec' | translate}}
                                        </option>
                                    </select>
                                    <div class="input-description">{{'device_setup.time_pay_start' | translate}}</div>
                                    <div *ngIf="setupVars.detailsVars.formSubmitted && startup_time.invalid">
                                        <div class="error-msg" *ngIf="startup_time.errors.required">
                                            {{'device_setup.start_up_time_req' | translate}}
                                        </div>
                                    </div>
                                </div>

                                <!-- PULSE INCREMENT, On all, except Solariums, and FixedPrice devices -->
                                <div class="col-6" *ngIf="
                                !(setupVars.productCategoryVars.productType === eProductType.Solarium ||
                                (setupVars.startSignalVars.signal === eSignal.Pulse && !setupVars.startSignalVars.pulseOptions || isProgramPicked) ||
                                (setupVars.startSignalVars.signal === eSignal.UserStartTime && !setupVars.startSignalVars.pulseOptions))
                                ">
                                    <label class="mb-1 mt-3">{{setupVars.startSignalVars.pulseOptions ===
                                        eIncrementUnit.Money ? cost : time}} {{'device_setup.increment' |
                                        translate}}</label>
                                    <div *ngIf="setupVars.startSignalVars.pulseOptions === eIncrementUnit.Time">
                                        <div class="input-group">
                                            <select class="form-select" formControlName="pulse_increment">
                                                <option selected disabled value="null">{{'misc.select_opt' | translate}}
                                                </option>
                                                <option value="{{increment}}"
                                                    *ngFor="let increment of PULSE_INCREMENT_ARR_FOR_TIME">{{increment}}
                                                </option>
                                            </select>
                                            <div class="input-group-append">
                                                <span class="input-group-text">{{'device_setup.min' | translate}}</span>
                                            </div>
                                        </div>
                                        <div class="input-description">{{'device_setup.user_time_selecet' | translate}}
                                        </div>
                                    </div>
                                    <div *ngIf="setupVars.startSignalVars.pulseOptions === eIncrementUnit.Money">
                                        <div class="input-group">
                                            <input min="0.01" max="1000" step="0.01" name="pulse_increment"
                                                class="form-control" type="number" formControlName="pulse_increment">
                                            <div class="input-group-append">
                                                <span
                                                    class="input-group-text">{{getCurrencyInUppercase(currency)}}</span>
                                            </div>
                                        </div>
                                        <div class="input-description">{{'device_setup.user_amount_select' | translate}}
                                        </div>
                                    </div>
                                    <div *ngIf="setupVars.detailsVars.formSubmitted && pulse_increment.invalid">
                                        <div
                                            *ngIf="setupVars.startSignalVars.pulseOptions === eIncrementUnit.Time || !setupVars.guidVars.isR1">
                                            <div *ngIf="pulse_increment.errors.required" class="error-msg">
                                                {{'device_setup.time_increment_req' | translate}}
                                            </div>
                                            <div *ngIf="pulse_increment === undefined" class="error-msg">
                                                {{'device_setup.time_increment_req' | translate}}
                                            </div>
                                            <div *ngIf="pulse_increment.errors.min" class="error-msg">
                                                {{'device_setup.time_increment_more' | translate}}
                                            </div>
                                            <div *ngIf="pulse_increment.errors.max" class="error-msg">
                                                {{'device_setup.time_increment_less' | translate}}
                                            </div>
                                        </div>
                                        <div *ngIf="setupVars.startSignalVars.pulseOptions === eIncrementUnit.Money">
                                            <div *ngIf="pulse_increment.errors.required" class="error-msg">
                                                {{'device_setup.cost_increment_req' | translate}}
                                            </div>
                                            <div *ngIf="pulse_increment.errors.min" class="error-msg">
                                                {{'device_setup.cost_increment_more' | translate}}
                                                {{getCurrencyInUppercase(currency)}}
                                            </div>
                                            <div *ngIf="pulse_increment.errors.max" class="error-msg">
                                                {{'device_setup.cost_increment_less' | translate}}
                                                {{getCurrencyInUppercase(currency)}}
                                            </div>
                                        </div>
                                    </div>

                                </div>



                                <!-- PULSE DURATION, On all, except Solariums, Full duration, and FixedPrice devices -->
                                <div class="col-6" *ngIf="
                                  !(setupVars.startSignalVars.signal === eSignal.FullDuration ||
                                  setupVars.productCategoryVars.productType === eProductType.Solarium) &&
                                  this.setupVars.startSignalVars.signal !== eSignal.UserStartTime ||
                                  setupVars.pricingModelVars.pricingModel === ePricingModel.Fixed
                                  ">
                                    <label class="mb-1 mt-3">{{'device_setup.pulse_length' | translate}}</label>
                                    <div class="input-group">
                                        <input name="pulse_duration" class="form-control" type="number"
                                            formControlName="pulse_duration">
                                        <div class="input-group-append">
                                            <span class="input-group-text">{{this.setupVars.startSignalVars.signal ===
                                                eSignal.UserStartTime ? 'min.' : 'ms'}}</span>
                                        </div>
                                    </div>
                                    <div class="input-description"
                                        *ngIf="setupVars.pricingModelVars.pricingModel === ePricingModel.Fixed && this.setupVars.startSignalVars.signal === eSignal.UserStartTime">
                                        {{'device_setup.value_between' | translate}}
                                        {{helperService.protocolVersionValidator(protocolVersion).legacy?'4':'180'}}
                                        {{'device_setup.min' | translate}}</div>
                                    <div
                                        *ngIf="setupVars.detailsVars.formSubmitted && pulse_duration.invalid &&  this.setupVars.startSignalVars.signal !== eSignal.UserStartTime">
                                        <div class="error-msg" *ngIf="pulse_duration.errors.required">
                                            {{'device_setup.pulse_length_req' | translate}}
                                        </div>
                                        <div class="error-msg" *ngIf="pulse_duration.errors.pattern">
                                            {{'device_setup.pulse_length_requirements' | translate}}
                                        </div>
                                        <div *ngIf="pulse_duration.errors.pulseDurationOnLegacy" class="error-msg">
                                            {{'device_setup.pulse_length_divisible' | translate}}
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="setupVars.detailsVars.formSubmitted && pulse_duration.invalid && this.setupVars.startSignalVars.signal === eSignal.UserStartTime">
                                        <div class="error-msg" *ngIf="pulse_duration.errors.required">
                                            {{'device_setup.pulse_length_req' | translate}}
                                        </div>
                                        <div class="error-msg" *ngIf="pulse_duration.errors.pattern">
                                            {{'device_setup.between_one_and' | translate}}
                                            {{helperService.protocolVersionValidator(protocolVersion).legacy?'4':'180'}}
                                            {{'device_setup.min' | translate}}
                                        </div>
                                    </div>
                                </div>

                                <!-- PULSE NUMBER  -->
                                <div class="col-6" *ngIf="
                                   !(
                                       setupVars.startSignalVars.signal === eSignal.FullDuration ||
                                       setupVars.startSignalVars.signal === eSignal.UserStartTime ||
                                       setupVars.productCategoryVars.productType === eProductType.Solarium ||
                                       isProgramPicked
                                   )
                                   ">
                                    <label class="mb-1 mt-3">{{'device_setup.pulse_reps' | translate}}</label>
                                    <input name="pulse_number" step="1" min="1" max="100" class="form-control"
                                        type="number" formControlName="pulse_number">
                                    <div class="input-description">{{'device.number_pulses' | translate}}</div>
                                    <div *ngIf="setupVars.detailsVars.formSubmitted && pulse_number.invalid">
                                        <div class="error-msg" *ngIf="pulse_number.errors.required">
                                            {{'device_setup.pulse_reps_req' | translate}}
                                        </div>
                                        <div *ngIf="pulse_number.errors.min" class="error-msg">
                                            {{'device_setup.pulse_reps_more' | translate}}
                                            {{pulse_number.errors.min.min}}
                                        </div>
                                        <div *ngIf="pulse_number.errors.max" class="error-msg">
                                            {{'device_setup.pulse_reps_less' | translate}}
                                            {{pulse_number.errors.max.max}}
                                        </div>
                                    </div>
                                </div>

                                <!-- BUSY SIGNAL (ac detect mode) -->
                                <div class="col-6" *ngIf="isProgramPicked ||
                                (setupVars.guidVars.isR1 && setupVars.productCategoryVars.productType !== eProductType.Solarium)
                                ">
                                    <label class="mb-1 mt-3">{{'device.busy_signal' | translate}}</label>
                                    <select class="form-select" formControlName="ac_detect_mode"
                                        (change)="onAcDetectModeChange()">
                                        <option selected disabled [ngValue]="null">{{'misc.select_opt' | translate}}
                                        </option>
                                        <option [ngValue]="1">{{'device_setup.volt_high' | translate}}</option>
                                        <option [ngValue]="3">{{'device_setup.volt_low' | translate}}</option>
                                        <option [ngValue]="2">{{'device_setup.signal_end' | translate}}</option>
                                        <option [ngValue]="0">{{'device_setup.signal_none' | translate}}</option>
                                    </select>
                                    <div class="input-description"></div>
                                    <div *ngIf="setupVars.detailsVars.formSubmitted && ac_detect_mode.invalid">
                                        <div class="error-msg" *ngIf="ac_detect_mode.errors.required">
                                            {{'device.busy_signal_req' | translate}}
                                        </div>
                                    </div>
                                </div>

                                <!-- Fixed duration -->
                                <div class="col-6" *ngIf="ac_detect_mode.value === 0 && !hideFixedDuration">
                                    <label class="mb-1 mt-3">{{'device_setup.fixed_duration' | translate}}</label>
                                    <div class="input-group">
                                        <input name="fixed_duration" step="1" min="0" max="180" class="form-control"
                                            [readonly]="ac_detect_mode.value ? true : false" type="number"
                                            formControlName="fixed_duration">
                                        <div class="input-group-append">
                                            <span class="input-group-text">{{'device_setup.min' | translate}}</span>
                                        </div>
                                    </div>
                                    <div *ngIf="setupVars.detailsVars.formSubmitted && fixed_duration.invalid">
                                        <div class="error-msg" *ngIf="fixed_duration.errors.required">
                                            {{'device_setup.fixed_duration_req' | translate}}
                                        </div>
                                        <div *ngIf="fixed_duration.errors.min" class="error-msg">
                                            {{'device_setup.fixed_duration_more' | translate}}
                                            {{fixed_duration.errors.min.min}}
                                        </div>
                                        <div *ngIf="fixed_duration.errors.max" class="error-msg">
                                            {{'device_setup.fixed_duration_less' | translate}}
                                            {{fixed_duration.errors.max.max}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-secondary" type="button"
                                (click)="goBackFromOptionsStep()">{{'device_setup.previous_step' | translate}}</button>
                            <button class="btn btn-primary" type="button"
                                [disabled]="setupVars.detailsVars.disableSubmitBtn"
                                (click)="complete(continueWithNextRelay)">
                                {{setupDeviceLoading ? loading : complete_tr}}
                            </button>
                        </div>
                    </aw-wizard-step>
                </aw-wizard>
            </form>
        </div>
    </div>

    <ng-template #continueWithNextRelay let-modal>
        <app-custom-modal>
            <ng-container modal-title>
                <h4>{{'device_setup.setup_relay' | translate}}</h4>
            </ng-container>
            <ng-container modal-dismiss>
                <a (click)="modalService.dismissAll('Cancel')"><img src="../../../assets/icons/modal-close-icon.svg"
                        alt="close dialog"></a>
            </ng-container>
            <ng-container modal-body>
                <p>{{'device_setup.setup_second_relay' | translate}}</p>
            </ng-container>
            <ng-container modal-footer>
                <div class="d-flex flex-column align-items-center">
                    <button class="btn btn-secondary mb-3 mt-3" style="width: 100%" (click)="nextRelay(true)">
                        {{'device_setup.yes_continue' | translate}}
                    </button>
                    <a tabindex="-1" (click)="nextRelay(false)">{{'device_setup.no_done' | translate}}</a>
                </div>
            </ng-container>
        </app-custom-modal>
    </ng-template>

</div>