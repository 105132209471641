<app-custom-modal>
    <ng-container modal-title>
        <h4>{{renewCode ? ('location.new_payment_code'|translate) : ('location.payment_code'|translate)}}</h4>
    </ng-container>
    <ng-container modal-dismiss>
        <a (click)="closePaymentCodeModal(false,renewCode)"><img src="../../../assets/icons/modal-close-icon.svg"
                alt="close dialog"></a>
    </ng-container>
    <ng-container modal-body>
        <div class="modal-padding-wrapper payment-code-modal-content">
            <p>
                {{
                isEdit ? ('location.administrator_should_inform_payment_code_alt'|translate) :
                ('location.administrator_should_inform_payment_code'|translate)
                }}
            </p>

            <label class="payment-code-label">
                {{('location.the_user_payment_code'|translate)}}
            </label>

            <div class="code-block-wrapper">
                <div class="code-block" *ngIf="renewCode">
                    <div>
                        <p [hidden]="copied" class="number">{{newCode?formatPaymentCode(newCode).partOne:"•••"}}</p>
                        <p [hidden]="copied" class="dash">-</p>
                        <p [hidden]="copied" class="number">{{newCode?formatPaymentCode(newCode).partTwo:"•••"}}</p>
                        <p [hidden]="!copied" class="copied">{{('transactions.copied'|translate)}}</p>
                    </div>
                    <button *ngIf="newCode" class="my-mini-btn" (click)="copyCodeToClip(newCode)">
                        <img src="../../../assets/icons/copy-icon.svg" alt="">
                    </button>
                </div>

                <div class="code-block" *ngIf="!renewCode">
                    <div>
                        <p [hidden]="copied" class="number">{{formatPaymentCode(creditUser.payment_code).partOne}}</p>
                        <p [hidden]="copied" class="dash">-</p>
                        <p [hidden]="copied" class="number">{{formatPaymentCode(creditUser.payment_code).partTwo}}</p>
                        <p [hidden]="!copied" class="copied">{{('transactions.copied'|translate)}}</p>
                    </div>
                    <button class="my-mini-btn" (click)="copyCodeToClip(creditUser.payment_code)">
                        <img src="../../../assets/icons/copy-icon.svg" alt="">
                    </button>
                </div>

                <button class="btn btn-secondary new-code-btn" *ngIf="isEdit" (click)="requestNewCode()">
                    {{ ('location.new_code'|translate) }}
                </button>
            </div>

            <p class="please-note">
                <span class="strong">{{('location.please_note'|translate)}}</span><span>
                    {{('location.cannot_be_viewed_after_close'|translate)}}</span>
            </p>
        </div>
    </ng-container>
    <ng-container modal-footer>
        <div *ngIf="isEdit" class="d-flex align-items-center flex-column">
            <button class="btn btn-secondary mt-3 mb-3" style="width: 100%"
                [disabled]="renewCodeProcessing || renewCode && !newCode" (click)="closePaymentCodeModal(true,false)">
                {{
                renewCodeProcessing?""
                :!creditUser.phone_number?('location.save_and_inform_user'|translate):('location.save_and_send_code_on_sms'|translate)
                }}
                <loading-indicator *ngIf="renewCodeProcessing" [size]="'large'" [color]="'white'"></loading-indicator>
            </button>
            <a *ngIf="creditUser.phone_number" tabindex="-1"
                (click)="(renewCodeProcessing || renewCode && !newCode) ? null : closePaymentCodeModal(false,false)"
                [disabled]="renewCodeProcessing || renewCode && !newCode"
                [className]="(renewCodeProcessing || renewCode && !newCode)?'disabled-link':''">{{'location.save_and_inform_user'
                | translate}}</a>
        </div>
        <div *ngIf="!isEdit">
            <div class="d-grid gap-2">
                <button class="btn btn-secondary mt-3 mb-3" (click)="closePaymentCodeModal(false,false)">
                    {{ ('location.close_window'|translate) }}
                </button>
            </div>
        </div>
    </ng-container>
</app-custom-modal>