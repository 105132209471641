export interface Contract {
    billable: boolean; // the amount that is billable
    contract_id: string; //Input from customer - we dont use this
    contract_key?: string //key from object, used to sort, is put on create form backend
    currency: string;
    customer_id: string;
    customer_name: string;
    end_date: number; // Unix timestamp of the contract start date
    locations: Record<string, boolean>; // list of locations the contract is based on
    revenue_target: number; // the revenue target in cents
    start_date: number; // Unix timestamp of the contract start date
    timespan: 'monthly' | 'yearly'; // monthly or yearly, wether the operator are allowed to bill for missing revenue on a monthly basis or yearly
}

export interface DetailedContract {
    customer_name: string;
    contract_id: string;
    customer_id:string;
    summed_revenue: number;
    contract_key?: string;
    performance: number; // based on revenue and target revenue
    revenue_target: number;
    start_date: number;
    end_date: number;
    timespan: string;
    locations: Record<string, ContractLocation>;
    billable_amount: number | null; // How much te operator can bill the customer
    billable: boolean; // If the amount is billable
    summed_users:number;
    avg_adoption: number;
    summed_starts_day: number;
    currency: string;
    location_names: Array<string>;
    org_revenue_target: number;
    location_addresses?: Array<string>;
    location_custom_ids?: Array<string>;
}

export interface ContractLocation {
    name: string; //name pf loaction
    users: number; // customers input on how many users
    adoption: number; // based on customers estimated users and actual users
    starts_day: number; // average start pr day in whole location
    revenue: number; // revenue from date to date
    loc_id?: string;
}

export interface SearchParams {
    sortBy: sortParams;
}

export enum sortParams {
    contract = 'contract_id' , // on contract
    users = 'summed_users' ,
    adoption = 'avg_adoption',
    starts = 'summed_starts_day',
    target = 'revenue_target',
    revenue = 'summed_revenue',
    performance = 'performance',
    billable_amount = 'billable_amount',
    default = 'contract_key', // on contract
    location = 'location_names',
    endDate = "end_date"
  }

export interface ExpiringContract {
    contract_id: string,
    contract_key: string,
    location_names: Array<string>,
    end_date: number,
}

export interface PerformingContract {
    contract_key: string,
    contract_id: string,
    location_names: Array<string>,
    performance: number,
}

export interface ContractResponse {
    info: {
        fingerprint: string;
        contract_count: number;
    },
    data: Array<DetailedContract>
}


