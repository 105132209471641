import { CountryCode } from './external_accounts';

export enum BusinessType {
    INDIVIDUAL = 'individual',
    COMPANY = 'company'
}

export interface Address {
    country: string; // short code for country, full name is only fetched and displayed in UI (i.e. not saved in DB)
    state?: string; // based on country, called "Province" in CA, IT, and ES
    line1: string;
    line2?: string; // always optional
    city?: string; // not a field in singapore
    postal_code?: string; // for ireland, it is displayed as Eircode
}

export interface RequiredAddressFields {
    country: boolean;
    state: boolean;
    line1: boolean;
    line2?: boolean; // always optional
    city: boolean;
    postal_code: boolean; // for ireland, it is displayed as Eircode
}

export enum StripeRegions {
    EU = 'eu',
    US = 'us'
}

// For the path in realtime db

export interface StripePath {
    regions: Record<StripeRegions, Record<CountryCode, boolean>>;
}

export interface StripePaymentIntentMetadata {
    deviceId?: string;
    deviceName?: string;
    external_account_key?: string;
    firebase_customer_id?: string;
    firmware_version?: string;
    locationId?: string;
    locationName?: string;
    minutes?: string;
    productType?: string;
    projectId?: string;
    protocol_version?: string;
    reclaim_time?: string;
    userUid?: string;
    v2?: string;
    vat_number?: string;
}
