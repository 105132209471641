import { Roles } from './claims';
import { TermsOfService } from './details';
import { StripeRegions } from './stripe';

export interface InvitedUser {
    owner: string;
    details: InvitedUserDetails;
    last_active: number;
}

export interface InivtedUserLinkData {
    email: string;
    uid: string;
    expiration_time: number;
}

export interface InvitedUserDetails {
    uid: string;
    first_name?: string;
    last_name?: string;
    email_groups: Record<EmailGroups, boolean>;
    email: string;
    phone_number_call_code?: string; // always with leading "+" sign
    phone_number?: string; // we do not know all phone_numbers; if they are numbers or have dashes etc.
    stripe_region: StripeRegions;
}

export interface InviteMemberDetails {
    email: string;
    role: Roles;
    inviteSentFrom?: string;
    tos: TermsOfService;
}

export interface GetAllInvitedUsers {
    users: Array<MemberForDisplay>;
}

export interface MemberForDisplay {
    uid: string;
    name?: string;
    mail: string;
    phone?: string;
    last_active?: number; //timestamp
    role: Roles;
}

export interface SetInvitedUserInfoParams {
    password: string;
    email: string;
    first_name: string;
    last_name: string;
    tokenIdVerification: string;
    phone_number_call_code: string; // always with leading "+" sign
    phone_number: string; // we do not know all phone_numbers; if they are numbers or have dashes etc.
}

export enum EmailGroups {
    all = 'all',
    accounting_emails = 'accounting_emails',
    action_emails = 'action_emails'
}

export interface InvitedUserList {
    role: Roles;
    email?: string;
}
