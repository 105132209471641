import { Component, ElementRef, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule, FormGroup, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap, RouterLink } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SnapshotAction } from '@angular/fire/compat/database';
import { CustomerService } from '../../services/customer/customer.service';
import { AddressValidation, Location, Week } from 'shared_models/location';
import { LocationService } from '../../services/location/location.service';
import { HelperService } from '../../services/helper/helper.service';
import { ExternalAccountService } from '../../services/external-account/external-account.service';
import { UserGroup } from 'shared_models/user-group';
import { BookingSettings } from 'shared_models/booking-settings';
import { ProductTypeSpecific } from 'shared_models/product-type-specific';
import { ProductType } from 'shared_models/product-type';
import { Address, RequiredAddressFields } from 'shared_models/stripe';
import { DashboardUser } from 'shared_models/dashboard-user';
import { Subscription, Observable, BehaviorSubject } from 'rxjs';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { countries } from 'shared_data/countries';
import { ExternalAccount } from 'shared_models/external_accounts';
import * as Claims from 'shared_models/claims';
import { AuthService } from '../../services/auth/auth.service';
import { PayoutSettings } from '../../../../shared_models/payouts';
import { statesData } from '../locations/states';
import * as Terminal from 'shared_models/terminal';
import * as Device from 'shared_models/device';
import { DeviceOrderModalComponent } from './device-order-modal/device-order-modal.component';
import { LoadingComponent } from '../loading/loading.component';
import { CustomToolTipComponent } from '../misc/custom-tool-tip/custom-tool-tip.component';
import { CustomModalComponent } from '../misc/custom-modal/custom-modal.component';
import { TerminalCreateModalComponent } from './terminal-create-modal/terminal-create-modal.component';
import { CreditUserTableComponent } from './credit-user-table/credit-user-table.component';
import { TerminalCardsListComponent } from './terminal-cards-list/terminal-cards-list.component';
import { DeviceCardsListComponent } from './device-cards-list/device-cards-list.component';
import { AnimatedButtonComponent } from '../misc/animated-button/animated-button.component';
import { NgIf, NgFor, NgStyle, AsyncPipe, KeyValuePipe, CommonModule } from '@angular/common';
import { GoogleMap, MapAdvancedMarker } from '@angular/google-maps';
import { environment } from 'src/environments/environment';
import { AwCalendarGridComponent } from '@components/misc/aw-calendar-grid/aw-calendar-grid.component';
import { AwCalendarLayoutComponent } from '@components/misc/aw-calendar-layout/aw-calendar-layout.component';
import { Weekdays } from '@shared_models/coupon';
import { PaginateFetchCacheService } from '@services/paginate-fetch-cache/paginate-fetch-cache.service';
import { BillingDevice } from '@shared_models/billing/billing';
import { CountryDetails } from '@shared_models/country-details';
import { Details } from '@shared_models/details';
@Component({
    selector: 'app-location',
    templateUrl: './location.component.html',
    styleUrls: ['./location.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        AwCalendarGridComponent,
        AwCalendarLayoutComponent,
        NgIf,
        MapAdvancedMarker,
        GoogleMap,
        AnimatedButtonComponent,
        RouterLink,
        DeviceCardsListComponent,
        TerminalCardsListComponent,
        CreditUserTableComponent,
        TerminalCreateModalComponent,
        CustomModalComponent,
        FormsModule,
        ReactiveFormsModule,
        CustomToolTipComponent,
        NgFor,
        NgStyle,
        LoadingComponent,
        DeviceOrderModalComponent,
        AsyncPipe,
        KeyValuePipe,
        TranslateModule
    ]
})
export class LocationComponent implements OnInit, OnDestroy {
    countryDetails: CountryDetails;
    role$: Observable<Claims.Roles> = this.authService.getRole;
    showLoadingIndicator = true;
    showSmallLoadingIndicator = false;
    loadingHeaderButtons = true;
    locationSettingsForm: UntypedFormGroup;
    creatingTerminal: boolean;
    locationSettingsFormSubmitted: boolean;
    bookingSettingsForm: UntypedFormGroup;
    savingBookingSettingsForm: boolean;
    bookingSettingsFormSubmitted: boolean;
    location_id: string;
    location: Location;
    booking_settings: unknown;
    user_groups: Record<string, string> = {};
    countries: Record<string, string>[] = []; // [{code,name}]
    productTypeSpecificSelector: string = ProductType.Washer;
    eProductTypeSpecificSelector = ProductType;
    defaultBookingSettings: BookingSettings;
    user: DashboardUser;
    defaultBookinSettingsSub: Subscription;
    locationSub: Subscription;
    locationSettingsFormSub: Subscription;
    subCustomerAllowed = true; // IS this real???
    currency: string;
    requiredFields: RequiredAddressFields;
    backendErrors: boolean;
    uid: string;
    isCustomerOperated: boolean;
    operatorExternalAccounts: Record<string, ExternalAccount> = {};
    externalAccounts: Record<string, ExternalAccount> = {};
    operatorFilteredExternalAccounts: Record<string, ExternalAccount> = {};
    filteredExternalAccounts: Record<string, ExternalAccount> = {};
    navigationSub: Subscription;
    isNewDevice: boolean = false;
    isDeleteDevice: boolean = false;
    modalRef: NgbModalRef;
    showPayoutSection: boolean;
    payoutSettings: PayoutSettings;
    operatorPayoutSettings: PayoutSettings | undefined;
    // closeNumbersArray: number[] = Array.from({ length: 24 }, (_, index) => index + 1);
    // openNumbersArray: number[] = Array.from({ length: 24 }, (_, index) => index);
    states: { code: string; name: string }[];
    deviceList: BehaviorSubject<Device.Unit[]> = new BehaviorSubject<Device.Unit[]>(null);
    terminalList: BehaviorSubject<Terminal.Unit[]> = new BehaviorSubject<Terminal.Unit[]>(null);
    showDeviceSortBtn = false;
    validateLoading = false;
    isConfirmedAddress = false;
    center: { lat: number; lng: number } = { lat: 0, lng: 0 };
    requiresMap = false;
    markerOptions: google.maps.marker.AdvancedMarkerElementOptions;
    markerPosition: google.maps.LatLngLiteral;
    mapOptions: google.maps.MapOptions = {
        clickableIcons: false,
        disableDoubleClickZoom: true,
        draggableCursor: 'crosshair',
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false
    };
    validationCheck = false;
    mapId: string = environment.googleMapsID;
    locationNames: string[] = [];
    template: TemplateRef<unknown> = undefined;
    savedOpenTimes: Week;
    open = 0;
    close = 24;
    showCalendar = false;
    lastSavedSpecificHours: Week;
    showMap = false;
    customerDetailsSub: Subscription;

    constructor(
        public authService: AuthService, // used in html
        private helperService: HelperService,
        private pfcService: PaginateFetchCacheService<BillingDevice>,
        private customerService: CustomerService,
        private router: Router,
        private route: ActivatedRoute,
        private toast: ToastrService,
        private modalService: NgbModal,
        private locationService: LocationService,
        private externalAccountService: ExternalAccountService,
        private fb: UntypedFormBuilder,
        private translate: TranslateService
    ) {}

    ngOnDestroy(): void {
        this.defaultBookinSettingsSub ? this.defaultBookinSettingsSub.unsubscribe() : null;
        this.locationSub ? this.locationSub.unsubscribe() : null;
        this.locationSettingsFormSub ? this.locationSettingsFormSub.unsubscribe() : null;
        this.navigationSub ? this.navigationSub.unsubscribe() : null;
        this.customerDetailsSub ? this.customerDetailsSub.unsubscribe() : null;
    }

    async ngOnInit() {
        this.user = this.helperService.getUser();
        this.location_id = this.route.snapshot.paramMap.get('location_id');
        this.isCustomerOperated = window.location.pathname.split('/').includes('customers') ? true : false;
        this.uid = this.isCustomerOperated ? `${this.route.snapshot.paramMap.get('sub_customer_id')}_operated_by_${this.user.uid}` : this.user.uid;
        this.locationService.getDeviceStats(this.uid, this.location_id).then(value => {
            this.deviceList.next(Object.values(value.devices));
            this.showDeviceSortBtn = Object.values(value.devices).length > 0;
            this.terminalList.next(Object.values(value.terminals));
        });

        this.payoutSettings = await this.locationService.readPayoutSettings(this.uid);
        if (this.isCustomerOperated) {
            this.operatorPayoutSettings = await this.locationService.readPayoutSettings(this.user.uid);
        }
        await this.getExternalAccounts();
        this.getTranslatedCountries();
        this.handleSubCustomer();
        this.setSubscriptions();
        for (const group in UserGroup) {
            this.user_groups[group.toUpperCase()] = group;
        }
        this.showLoadingIndicator = false;
    }

    onChanges(): void {
        let lastValues = (this.locationSettingsForm.controls.location_specifications as FormGroup).controls.address.value;
        this.locationSettingsFormSub = this.locationSettingsForm.valueChanges.subscribe(val => {
            this.backendErrors = false; // resets the backend errors

            const hasChange = Object.keys(lastValues).some(key => lastValues[key] !== val.location_specifications.address[key]);

            if (hasChange && !this.validateLoading) {
                this.isConfirmedAddress = false;
                this.requiresMap = false;
                this.removeErrorsOnAddress();
            }

            // for changes made to the "country" select
            if (val && val.location_specifications && val.location_specifications.address && val.location_specifications.address.country) {
                this.requiredFields = this.helperService.validateStripeLocationAddressFields(val.location_specifications.address.country);
                this.state.setValidators(this.requiredFields.state ? [Validators.required] : null);
                this.line1.setValidators(this.requiredFields.line1 ? [Validators.required] : null);
                this.city.setValidators(this.requiredFields.city ? [Validators.required] : null);
                this.postal_code.setValidators(this.requiredFields.postal_code ? [Validators.required] : null);
                this.state.updateValueAndValidity({ emitEvent: false });
                this.line1.updateValueAndValidity({ emitEvent: false });
                this.city.updateValueAndValidity({ emitEvent: false });
                this.postal_code.updateValueAndValidity({ emitEvent: false });
            }

            lastValues = (this.locationSettingsForm.controls.location_specifications as FormGroup).controls.address.value;
        });
    }

    uniqueNamesOnlyValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const forbidden = this.locationNames.includes(control.value) && control.value !== this.location.name;
            return forbidden ? { uniqueName: { value: control.value } } : null;
        };
    }

    newTerminal(event: Terminal.Unit) {
        this.pfcService.bustAllCacheByUrl(`${environment.baseUrl}/api_billing/get_active_billing`);
        this.pfcService.bustAllCacheByUrl(`${environment.baseUrl}/api_billing/get_inactive_billing`);
        this.terminalList.next([...this.terminalList.value, event]);
    }

    updateList(deviceList: Device.Unit[]) {
        this.deviceList.next(deviceList);
    }

    updateTerminalList(terminalList: Terminal.Unit[]) {
        this.terminalList.next(terminalList);
    }

    getHourLabel(hour: number): string {
        const amPm: string = hour < 12 ? 'AM' : 'PM';
        const isMidnight: boolean = (hour === 0 || hour === 24) && this.checkIfUS();
        const isNoon: boolean = hour === 12 && this.checkIfUS();
        if (isMidnight) {
            return 'Midnight';
        }
        if (isNoon) {
            return 'Noon';
        }
        if (hour >= 12 && this.checkIfUS()) {
            hour -= 12;
        }
        const formattedTime = `${(hour.toString().length < 2 ? '0' : '') + hour}:00`;
        if (this.checkIfUS()) {
            return `${formattedTime} ${amPm}`;
        } else {
            return `${formattedTime}`;
        }
    }

    checkIfUS(): boolean {
        return navigator.language === 'en-US';
    }

    filterAccountsByCurrency(currency: string) {
        this.filteredExternalAccounts = {};
        for (const accountKey in this.externalAccounts) {
            if (this.externalAccounts[accountKey].currency === currency) {
                this.filteredExternalAccounts[accountKey] = this.externalAccounts[accountKey];
            }
        }
        this.operatorFilteredExternalAccounts = {};
        for (const accountKey in this.operatorExternalAccounts) {
            if (this.operatorExternalAccounts[accountKey].currency === currency) {
                this.operatorFilteredExternalAccounts[accountKey] = this.operatorExternalAccounts[accountKey];
            }
        }
    }

    async getExternalAccounts(): Promise<void> {
        try {
            this.externalAccounts = await this.externalAccountService.getAllExternalAccounts(this.uid).catch(() => {
                throw new Error('Client message: Could not fetch my external accounts');
            });
            if (this.user.uid !== this.uid) {
                this.operatorExternalAccounts = await this.externalAccountService.getAllExternalAccounts().catch(() => {
                    throw new Error('Client message: Could not fetch sub customers external accounts');
                });
            }
        } catch (error) {
            this.toast.warning(this.translate.instant('location.something_wrong'), this.translate.instant('misc.attention'));
        }
    }

    getTranslatedCountries() {
        for (const code in countries) {
            this.countries.push({ code: code, name: this.helperService.getCountryTranslated(code, true) });
        }
        this.countries = this.countries.sort((a: Record<string, string>, b: Record<string, string>) => {
            if (a.name && b.name) return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
        });
    }

    handleSubCustomer() {
        if (this.user.uid.includes('_operated_by_')) {
            const subCustomerPermissionSub = this.customerService
                .readSubCustomerPermission(this.uid)
                .snapshotChanges()
                .subscribe(action => {
                    subCustomerPermissionSub.unsubscribe();
                    this.subCustomerAllowed = action.payload.val().allow_location;
                    this.loadingHeaderButtons = false;
                });
        } else {
            this.loadingHeaderButtons = false;
        }
    }

    shouldShowPayoutSettings(): boolean {
        if (this.isCustomerOperated) {
            if (!this.payoutSettings.auto || !this.operatorPayoutSettings.auto) {
                return true;
            }
        } else if (!this.payoutSettings.auto) {
            return true;
        }
        return false;
    }

    setSubscriptions() {
        this.defaultBookinSettingsSub = this.locationService
            .readDefaultBookingSettings()
            .snapshotChanges()
            .subscribe((action: any) => {
                this.defaultBookinSettingsSub.unsubscribe();
                this.defaultBookingSettings = action.payload.val();
            });

        this.locationSub = this.locationService
            .readLocation(this.uid, this.location_id)
            .snapshotChanges()
            .subscribe(async (locationSnapAction: SnapshotAction<Location>) => {
                this.locationSub.unsubscribe();
                this.setLocationAndBookingSettings(locationSnapAction);
                this.setupLocationSettingsForm();
                this.setupBookingSettingsForm();
                this.onChanges();
                this.filterAccountsByCurrency(this.currency);
                this.currencies.patchValue(this.currency);
                this.currencies.disable();
                this.onChanges();
            });

        this.customerDetailsSub = this.customerService
            .readCustomerDetails(this.uid)
            .valueChanges()
            .subscribe((details: Details) => {
                this.countryDetails = countries[details.country];
            });

        this.locationService.getAllLocationNames(this.uid).then(locationNames => {
            for (const locationName of locationNames) {
                this.locationNames.push(locationName.name);
            }
        });

        this.navigationSub = this.route.queryParamMap.pipe().subscribe(async (params: ParamMap) => {
            if (params.has('new_device')) {
                this.isNewDevice = params.get('new_device').toLowerCase() === 'true' ? true : false;
            }

            if (params.has('delete_device')) {
                this.isDeleteDevice = params.get('delete_device').toLowerCase() === 'true' ? true : false;
            }
        });
    }

    setLocationAndBookingSettings(locationSnapAction: SnapshotAction<Location>) {
        if (!locationSnapAction.payload.exists()) {
            this.router.navigate([this.isCustomerOperated ? `customers/${this.route.snapshot.paramMap.get('sub_customer_id')}/locations` : `locations`]);
            return;
        }

        this.location = locationSnapAction.payload.val();
        this.currency = this.location.currency ? this.location.currency : this.user.settings.currency;

        this.isConfirmedAddress = this.location.valid_address ? this.location.valid_address : false;
        this.requiredFields = this.helperService.validateStripeLocationAddressFields(this.location.location_specifications?.address.country ? this.location.location_specifications?.address.country : 'DK');

        if (locationSnapAction.payload.child('booking_settings').child('product_type_specific').exists()) {
            this.booking_settings = locationSnapAction.payload.child('booking_settings').val();
        } else {
            this.booking_settings = this.defaultBookingSettings;
        }
    }

    setupLocationSettingsForm() {
        this.locationSettingsForm = this.fb.group({
            name: [this.location.name || null, [Validators.required, Validators.maxLength(30), Validators.minLength(3), this.uniqueNamesOnlyValidator()]],
            opening_schedule: [this.location.opening_schedule || null, [Validators.required]],
            location_specifications: this.fb.group({
                address: this.fb.group({
                    city: [this.location.location_specifications?.address.city || null],
                    country: [this.getCountryCodeFromCountryName() || null, [Validators.required]],
                    line1: [this.location.location_specifications?.address.line1 || null, [Validators.required]],
                    line2: [this.location.location_specifications?.address.line2 || null],
                    postal_code: [this.location.location_specifications?.address.postal_code || null],
                    state: [this.location.location_specifications?.address.state || null]
                }),
                number_of_users: [this.location.location_specifications?.number_of_users || null, [Validators.required, Validators.min(1)]],
                utc_offset: [this.location.location_specifications?.utc_offset || null],
                user_group: [this.location.location_specifications?.user_group || null, [Validators.required]]
            }),
            location_custom_id: [this.location?.location_custom_id || null, [Validators.maxLength(30), Validators.minLength(1), Validators.pattern('^[a-zA-Z0-9]+$')]],
            currency: [this.location?.currency || null],
            external_account_key: [this.location?.external_account_key || null, this.location?.external_account_key ? [Validators.required] : null],
            operator_external_account_key: [this.location?.operator_external_account_key || null, this.isCustomerOperated && this.location?.operator_external_account_key ? [Validators.required] : null]
        });
        this.savedOpenTimes = this.location.opening_schedule;
        const roleSub: Subscription = this.role$.subscribe((role: Claims.Roles) => {
            if (!this.authService.hasLimitedAccess('location_settings', role)) {
                this.locationSettingsForm.disable();
            }
        });
        roleSub.unsubscribe();
        this.countrySelect();
    }

    getCountryCodeFromCountryName = (): string => {
        // Previously we stored the entire name of the country everywhere, but now we only want the country code stored for reference
        let countryCode = this.location.location_specifications?.address.country ? this.location.location_specifications?.address.country : this.user.settings.country;
        if (countryCode.length !== 2) {
            for (const code in countries) {
                if (countries[code].country === countryCode) {
                    countryCode = countries[code].code;
                }
            }
        }
        return countryCode;
    };

    setupBookingSettingsForm() {
        const washerValues: ProductTypeSpecific = this.location.booking_settings?.product_type_specific?.washer ? this.location.booking_settings?.product_type_specific?.washer : null;

        const dryerValues: ProductTypeSpecific = this.location.booking_settings?.product_type_specific?.dryer ? this.location.booking_settings?.product_type_specific?.dryer : null;

        const otherValues: ProductTypeSpecific = this.location.booking_settings?.product_type_specific?.other ? this.location.booking_settings?.product_type_specific?.other : null;

        this.bookingSettingsForm = this.fb.group({
            future_limit: [this.location.booking_settings?.future_limit || 14, [Validators.required, Validators.min(1), Validators.max(90)]],
            product_type_specific: this.fb.group({
                washer: this.fb.group({
                    booking_enabled: [washerValues?.booking_enabled || null],
                    duration: [washerValues?.duration || null],
                    limit: [washerValues?.limit || null],
                    post_reservation_time_limit: [washerValues?.post_reservation_time_limit || null],
                    pre_reservation_time_limit: [washerValues?.pre_reservation_time_limit || null]
                }),
                dryer: this.fb.group({
                    booking_enabled: [dryerValues?.booking_enabled || null],
                    duration: [dryerValues?.duration || null],
                    limit: [dryerValues?.limit || null],
                    post_reservation_time_limit: [dryerValues?.post_reservation_time_limit || null],
                    pre_reservation_time_limit: [dryerValues?.pre_reservation_time_limit || null]
                }),
                other: this.fb.group({
                    booking_enabled: [otherValues?.booking_enabled || null],
                    duration: [otherValues?.duration || null],
                    limit: [otherValues?.limit || null],
                    post_reservation_time_limit: [otherValues?.post_reservation_time_limit || null],
                    pre_reservation_time_limit: [otherValues?.pre_reservation_time_limit || null]
                })
            })
        });
        const roleSub: Subscription = this.role$.subscribe((role: Claims.Roles) => {
            if (!this.authService.hasLimitedAccess('booking_settings', role)) {
                this.bookingSettingsForm.disable();
            }
        });
        roleSub.unsubscribe();
    }

    handleClick(event: google.maps.MapMouseEvent) {
        this.markerPosition = event.latLng.toJSON();

        if (this.markerPosition) {
            this.location.lat = this.markerPosition.lat;
            this.location.lng = this.markerPosition.lng;
            this.location.valid_address = true;
            this.isConfirmedAddress = true;

            this.removeErrorsOnAddress();
        }
    }

    enableMap() {
        this.showMap = !this.showMap;
    }

    removeErrorsOnAddress() {
        this.country.setErrors(null);
        this.state.setErrors(null);
        this.line1.setErrors(null);
        this.line2.setErrors(null);
        this.city.setErrors(null);
        this.postal_code.setErrors(null);
    }

    async validateAddress() {
        this.validationCheck = true;

        if (this.locationSettingsForm.controls.location_specifications.get('address').invalid) {
            this.helperService.defaultHtmlToast('', this.translate.instant('locations.err_check_form'), 'Warning');
            return;
        }
        this.removeErrorsOnAddress();

        this.validateLoading = true;

        const address: Address = {
            country: this.locationSettingsForm.value.location_specifications.address.country,
            city: this.requiredFields.city ? this.locationSettingsForm.value.location_specifications.address.city : null,
            line1: this.locationSettingsForm.value.location_specifications.address.line1,
            line2: this.locationSettingsForm.value.location_specifications.address.line2,
            postal_code: this.locationSettingsForm.value.location_specifications.address.postal_code,
            state: this.locationSettingsForm.value.location_specifications.address.state
        };
        await this.locationService.validateAddress(address).then((addressValidation: AddressValidation) => {
            this.location.valid_address = addressValidation.confirmed;
            this.isConfirmedAddress = addressValidation.confirmed;
            this.requiresMap = addressValidation.requireMapConfirmation;

            this.center = { lat: addressValidation.lat, lng: addressValidation.lng };
            // reset marker
            this.markerPosition = this.center;

            if (addressValidation.confirmed) {
                this.location.lat = addressValidation.lat;
                this.location.lng = addressValidation.lng;
                this.locationSettingsForm.patchValue({
                    location_specifications: {
                        address: {
                            city: addressValidation.address.city ? addressValidation.address.city : this.city.value,
                            line1: addressValidation.address.line1 ? addressValidation.address.line1 : this.line1.value,
                            line2: addressValidation.address.line2 ? addressValidation.address.line2 : this.line2.value,
                            postal_code: addressValidation.address.postal_code ? addressValidation.address.postal_code : this.postal_code.value,
                            state: addressValidation.address.state ? addressValidation.address.state : this.state.value
                        }
                    }
                });

                this.locationSettingsForm.updateValueAndValidity();
            } else {
                // set errors based on missingComponentTypes and unconfirmedComponentTypes
                for (const missingComponent of addressValidation.missingComponentTypes) {
                    if (missingComponent === 'street_number') this.line1.setErrors({ street_number_missing: true });
                    if (missingComponent === 'route' || missingComponent === 'premise') this.line1.setErrors({ route_missing: true });
                    if (missingComponent === 'subpremise' || missingComponent === 'point_of_interest') this.line2.setErrors({ subpremise_missing: true });
                    if (missingComponent === 'postal_code') this.postal_code.setErrors({ postal_code_missing: true });
                    if (missingComponent === 'locality' || missingComponent === 'postal_town') this.city.setErrors({ city_missing: true });
                    if (missingComponent === 'country') this.country.setErrors({ country_missing: true });
                }
                for (const unconfirmedComponent of addressValidation.unconfirmedComponentTypes) {
                    if (unconfirmedComponent === 'street_number') this.line1.setErrors({ street_number_unconfirmed: true });
                    if (unconfirmedComponent === 'route' || unconfirmedComponent === 'premise') this.line1.setErrors({ route_unconfirmed: true });
                    if (unconfirmedComponent === 'subpremise' || unconfirmedComponent === 'point_of_interest') this.line2.setErrors({ subpremise_unconfirmed: true });
                    if (unconfirmedComponent === 'postal_code') this.postal_code.setErrors({ postal_code_unconfirmed: true });
                    if (unconfirmedComponent === 'locality' || unconfirmedComponent === 'postal_town') this.city.setErrors({ city_unconfirmed: true });
                    if (unconfirmedComponent === 'country') this.country.setErrors({ country_unconfirmed: true });
                }

                if (addressValidation.error) {
                    this.line1.setErrors({ street_number_unconfirmed: true });
                    this.postal_code.setErrors({ postal_code_unconfirmed: true });
                    this.city.setErrors({ city_unconfirmed: true });
                }
            }
        });
        this.validateLoading = false;
    }

    async updateLocation() {
        this.locationSettingsFormSubmitted = true;
        this.showSmallLoadingIndicator = true;

        if (!this.isConfirmedAddress) {
            await this.validateAddress();
        }

        if (this.location.valid_address === false) {
            this.toastFactory('warning', this.translate.instant('location.no_marker'));
            this.showSmallLoadingIndicator = false;
            return;
        }

        if (this.backendErrors) {
            this.toastFactory('warning', this.translate.instant('location.check_form'));
            this.showSmallLoadingIndicator = false;
            return;
        }

        if (this.location_custom_id.value && this.location_custom_id.value !== this.location.location_custom_id && (await this.locationService.checkIfCustomIdExistsAnyLocation(this.uid, this.location_custom_id.value)).length) {
            this.location_custom_id.setErrors({ alreadyExists: true });
        }

        if (this.locationSettingsForm.valid && this.location.valid_address) {
            if (this.location.devices) {
                for (const key in this.location.devices) {
                    this.location.devices[key].location_name = this.name.value;
                }
            }

            // START: backwards compatible open and close hours for booking settings
            if (this.location.booking_settings?.product_type_specific?.washer) {
                this.location.booking_settings.product_type_specific.washer.open = this.open;
                this.location.booking_settings.product_type_specific.washer.close = this.close;
            }
            if (this.location.booking_settings?.product_type_specific?.dryer) {
                this.location.booking_settings.product_type_specific.dryer.open = this.open;
                this.location.booking_settings.product_type_specific.dryer.close = this.close;
            }
            if (this.location.booking_settings?.product_type_specific?.other) {
                this.location.booking_settings.product_type_specific.other.open = this.open;
                this.location.booking_settings.product_type_specific.other.close = this.close;
            }
            // END: backwards compatible open and close hours for booking settings

            this.location.open = this.open;
            this.location.close = this.close;

            const updatedLocationReq = {
                location: {
                    ...this.location,
                    ...this.locationSettingsForm.value,
                    external_account_key: this.external_account_key.value,
                    operator_external_account_key: this.operator_external_account_key.value
                }
            };

            // cleaning the not displayed address fields away (e.g. state, city etc. for some countries)
            if (this.location.location_specifications) {
                for (const addrProp in this.location.location_specifications.address) {
                    if (addrProp !== 'line2' && !this.requiredFields[addrProp]) {
                        // line2 is always optional and should not be removed
                        delete this.location.location_specifications.address[addrProp];
                        delete updatedLocationReq.location.location_specifications.address[addrProp];
                    }
                }
            }

            for (const day in updatedLocationReq.location.opening_schedule) {
                for (const time in updatedLocationReq.location.opening_schedule[day]) {
                    if (updatedLocationReq.location.opening_schedule[day][time].end === '2400') {
                        updatedLocationReq.location.opening_schedule[day][time].end = '2359';
                    }
                }
            }

            await this.locationService
                .updateLocation(this.uid, updatedLocationReq.location)
                .then(() => {
                    this.toastFactory('success', this.translate.instant('location.saved'));
                    this.showSmallLoadingIndicator = false;
                    this.locationSettingsFormSubmitted = false;
                    this.backendErrors = false;
                    this.modalRef.close();
                })
                .catch((res: HttpErrorResponse) => {
                    if (res.error && res.error.error) {
                        this.toast.warning(this.translate.instant('locations.err_check_form'), this.translate.instant('misc.attention'));
                        this.backendErrors = true;
                        switch (res.error.error) {
                            case 'postal_code_invalid':
                                this.locationSettingsForm.get('location_specifications.address.postal_code').setErrors({ stripeValidation: true });

                                break;

                            default:
                                break;
                        }
                    }

                    this.showSmallLoadingIndicator = false;
                });
        } else if (this.locationSettingsForm.invalid) {
            console.error(this.locationSettingsForm);
        }

        this.showSmallLoadingIndicator = false;
    }

    saveBookingSettings(typeSaved?: string): void {
        this.savingBookingSettingsForm = true;

        // fix for booleans in form
        this.washer_booking_enabled.value === null ? this.washer_booking_enabled.setValue(false) : null;
        this.dryer_booking_enabled.value === null ? this.dryer_booking_enabled.setValue(false) : null;
        this.other_booking_enabled.value === null ? this.other_booking_enabled.setValue(false) : null;

        if (this.bookingSettingsForm.valid) {
            const updatedBookingSettings: BookingSettings = {
                ...this.location.booking_settings,
                ...this.bookingSettingsForm.value
            };

            // START: backwards compatible open and close hours for booking settings
            if (updatedBookingSettings.product_type_specific?.washer) {
                updatedBookingSettings.product_type_specific.washer.opening_schedule = this.location.opening_schedule;
            }
            if (updatedBookingSettings.product_type_specific?.dryer) {
                updatedBookingSettings.product_type_specific.dryer.opening_schedule = this.location.opening_schedule;
            }
            if (updatedBookingSettings.product_type_specific?.other) {
                updatedBookingSettings.product_type_specific.other.opening_schedule = this.location.opening_schedule;
            }
            // END: backwards compatible opening_schedule and close hours for booking settings

            // conver to new opening_schedule format, if it is not already
            if (updatedBookingSettings.product_type_specific?.washer && !updatedBookingSettings.product_type_specific.washer.opening_schedule) {
                updatedBookingSettings.product_type_specific.washer.opening_schedule = this.location.opening_schedule;
            }

            this.locationService
                .updateBookingSettings({ uid: this.uid, locationId: this.location_id, settings: updatedBookingSettings })
                .then(() => {
                    this.toastFactory('success', `${this.getProductTypeSpecificSelectorLabel(typeSaved ? typeSaved : null)} ${this.translate.instant('location.settings_saved')}`);
                    this.location.booking_settings = updatedBookingSettings;
                    this.savingBookingSettingsForm = false;
                })
                .catch(() => {
                    this.toastFactory('warning', this.translate.instant('location.settings_not_saved'));
                    this.savingBookingSettingsForm = false;
                });
        } else {
            this.toastFactory('warning', this.translate.instant('location.check_form_req_fields'));
            this.bookingSettingsFormSubmitted = true;
            this.savingBookingSettingsForm = false;
        }
    }

    deleteLocation() {
        this.showLoadingIndicator = true;
        this.modalService.dismissAll();
        this.locationService
            .deleteLocation(this.uid, this.location.id)
            .then(() => {
                this.router.navigate([this.isCustomerOperated ? `customers/${this.route.snapshot.paramMap.get('sub_customer_id')}/locations` : `locations`]);
                this.toast.success(this.translate.instant('location.success_delete'), this.translate.instant('misc.success'));
            })
            .catch(() => {
                this.toast.warning(this.translate.instant('location.something_wrong'), this.translate.instant('misc.attention'));
                this.showLoadingIndicator = false;
            });
    }

    applyDefaultBookingSettings() {
        const type = this.productTypeSpecificSelector.toLowerCase();
        this.bookingSettingsForm.patchValue(
            {
                product_type_specific: {
                    [type]: this.defaultBookingSettings.product_type_specific[type]
                }
            },
            { emitEvent: false }
        );
        this.toastFactory('info', `${this.translate.instant('location.default_booking_settings')}`);
    }

    getProductTypeSpecificSelectorLabel(typeSaved?: string): string {
        return this.translate.instant(`misc.${typeSaved ? typeSaved.toLowerCase() : this.productTypeSpecificSelector.toLowerCase()}`);
    }

    async setProductType(type: string) {
        if (!this.authService.hasLimitedAccess('booking_settings', await this.authService.getRoleStatus())) {
            this.productTypeSpecificSelector = type;
        }

        this.bookingSettingsFormSubmitted = true;
        if (this.bookingSettingsForm.valid) {
            this.saveBookingSettings(this.productTypeSpecificSelector);
            this.bookingSettingsFormSubmitted = false;
            this.productTypeSpecificSelector = type;
            for (const productKey in this.bookingSettingsForm.controls.product_type_specific['controls']) {
                for (const fieldKey in this.bookingSettingsForm.controls.product_type_specific['controls'][productKey].controls) {
                    if (type.toLowerCase() !== productKey) {
                        this.bookingSettingsForm.controls.product_type_specific['controls'][productKey].controls[fieldKey].setValidators(null);
                    } else {
                        this.bookingSettingsForm.controls.product_type_specific['controls'][productKey].controls[fieldKey].setValidators([Validators.required]);
                    }
                    this.bookingSettingsForm.controls.product_type_specific['controls'][productKey].controls[fieldKey].updateValueAndValidity();
                }
            }
        }
    }

    selectedTerminal(modal: ElementRef, modalTemplateString: string) {
        this.openModal(modal, modalTemplateString);
    }

    async openModal(modal: ElementRef, modalTemplateString?: string) {
        if (modalTemplateString === 'createTerminalModal') {
            if (!this.location.terminal_location_id) {
                console.log('Creating terminal location');
                await this.locationService
                    .updateLocation(
                        // this will make a stripe terminal location
                        this.uid,
                        this.location
                    )
                    .catch(() => {
                        this.toast.info(this.translate.instant('locations.new_address_requirements'), this.translate.instant('misc.info'), { timeOut: 20000 });
                    });
            }
            this.creatingTerminal = false;
        }
        this.modalRef = this.modalService.open(modal, {
            ariaLabelledBy: 'modal-basic-title'
        });

        if (modalTemplateString === 'locationSettingsModal') {
            const sub = this.modalRef.shown.subscribe(() => {
                const checkboxEl: HTMLInputElement = document.getElementById('open_hours') as HTMLInputElement;
                checkboxEl.checked = this.checkIf24Hours();
                sub.unsubscribe();
            });
        }

        this.modalRef.result.then(() => {}).catch((err: any) => {});
    }

    toastFactory(type: string, msg: string, timeOut?: number): void {
        for (const toast of this.toast.toasts) {
            if (toast.message !== msg) this.toast.remove(toast.toastId); // clear other messages
        }

        if (!this.toast.findDuplicate(msg, '', true, false)) this.toast[type](msg, `${type.charAt(0).toUpperCase()}${type.substring(1)}`, timeOut ? { timeOut } : null);
    }

    getUserGroup = (item: string): string => {
        return this.translate.instant('misc.' + item.toLowerCase());
    };

    countrySelect() {
        this.states = [];
        const country: string = this.country.value;
        if (statesData[country]) {
            for (const code in statesData[country]) {
                this.states.push({ code, name: statesData[country][code] });
            }
        }
        this.states = this.states.sort((a: Record<string, string>, b: Record<string, string>) => {
            if (a.name && b.name) return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' });
            return;
        });
    }

    showOpeningHours() {
        this.showCalendar = true;
    }

    updateOpen(event: Week) {
        if (event) {
            this.savedOpenTimes = event;
            this.lastSavedSpecificHours = event;
            this.showCalendar = false;
            this.locationSettingsForm.patchValue({
                opening_schedule: event
            });
        }

        const checkboxEl: HTMLInputElement = document.getElementById('open_hours') as HTMLInputElement;

        checkboxEl.checked = this.checkIf24Hours();
    }

    checkIf24Hours() {
        if (this.savedOpenTimes) {
            if (Object.keys(this.savedOpenTimes).length === 0) {
                return false;
            }
            for (const day in this.savedOpenTimes) {
                // Check if a day is missing from Weekdays
                for (const key of Object.values(Weekdays)) {
                    if (this.savedOpenTimes[key] && Object.keys(this.savedOpenTimes[key]).length < 1) {
                        return false;
                    } else if (!this.savedOpenTimes[key]) {
                        return false;
                    }
                }

                for (const key in this.savedOpenTimes[day]) {
                    if ((this.savedOpenTimes[day][key].start !== '0000' && this.savedOpenTimes[day][key].end !== '2359') || (this.savedOpenTimes[day][key].start !== '0000' && this.savedOpenTimes[day][key].end !== '2400')) {
                        return false;
                    }
                }
            }

            return true;
        }
    }

    onOpen24HoursChange(event: boolean) {
        if (event) {
            const week: Week = {};
            for (const day of Object.values(Weekdays)) {
                week[day] = {};
                week[day][this.helperService.createPushKey()] = { start: '0000', end: '2400' };
            }
            this.savedOpenTimes = week;
            this.locationSettingsForm.patchValue({
                opening_schedule: week
            });
        } else {
            if (this.lastSavedSpecificHours) {
                this.savedOpenTimes = Object.assign({}, this.lastSavedSpecificHours);
            } else {
                this.savedOpenTimes = {};
            }

            this.locationSettingsForm.patchValue({
                opening_schedule: this.savedOpenTimes
            });
        }
    }

    getClass(control: AbstractControl, more?: object): object {
        return {
            'err-border': control.invalid && this.locationSettingsFormSubmitted,
            'form-control': true,
            ...more
        };
    }

    // for locationSettingsForm
    get name() {
        return this.locationSettingsForm.get('name');
    }
    get opening_schedule() {
        return this.locationSettingsForm.get('opening_schedule');
    }

    get city() {
        return this.locationSettingsForm.get('location_specifications.address.city');
    }
    get country() {
        return this.locationSettingsForm.get('location_specifications.address.country');
    }
    get line1() {
        return this.locationSettingsForm.get('location_specifications.address.line1');
    }
    get line2() {
        return this.locationSettingsForm.get('location_specifications.address.line2');
    }
    get postal_code() {
        return this.locationSettingsForm.get('location_specifications.address.postal_code');
    }
    get state() {
        return this.locationSettingsForm.get('location_specifications.address.state');
    }
    get number_of_users() {
        return this.locationSettingsForm.get('location_specifications.number_of_users');
    }
    get utc_offset() {
        return this.locationSettingsForm.get('location_specifications.utc_offset');
    }
    get user_group() {
        return this.locationSettingsForm.get('location_specifications.user_group');
    }
    get location_custom_id() {
        return this.locationSettingsForm.get('location_custom_id');
    }
    get currencies() {
        return this.locationSettingsForm.get('currency');
    }
    get operator_external_account_key() {
        return this.locationSettingsForm.get('operator_external_account_key');
    }
    get external_account_key() {
        return this.locationSettingsForm.get('external_account_key');
    }

    // for bookingSettingsForm
    get future_limit() {
        return this.bookingSettingsForm.get('future_limit');
    }
    get washer_booking_enabled() {
        return this.bookingSettingsForm.get('product_type_specific.washer.booking_enabled');
    }
    get washer_duration() {
        return this.bookingSettingsForm.get('product_type_specific.washer.duration');
    }
    get washer_limit() {
        return this.bookingSettingsForm.get('product_type_specific.washer.limit');
    }
    get washer_pre_reservation_time_limit() {
        return this.bookingSettingsForm.get('product_type_specific.washer.pre_reservation_time_limit');
    }
    get washer_post_reservation_time_limit() {
        return this.bookingSettingsForm.get('product_type_specific.washer.post_reservation_time_limit');
    }

    get dryer_booking_enabled() {
        return this.bookingSettingsForm.get('product_type_specific.dryer.booking_enabled');
    }
    get dryer_duration() {
        return this.bookingSettingsForm.get('product_type_specific.dryer.duration');
    }
    get dryer_limit() {
        return this.bookingSettingsForm.get('product_type_specific.dryer.limit');
    }
    get dryer_pre_reservation_time_limit() {
        return this.bookingSettingsForm.get('product_type_specific.dryer.pre_reservation_time_limit');
    }
    get dryer_post_reservation_time_limit() {
        return this.bookingSettingsForm.get('product_type_specific.dryer.post_reservation_time_limit');
    }
    get other_booking_enabled() {
        return this.bookingSettingsForm.get('product_type_specific.other.booking_enabled');
    }
    get other_duration() {
        return this.bookingSettingsForm.get('product_type_specific.other.duration');
    }
    get other_limit() {
        return this.bookingSettingsForm.get('product_type_specific.other.limit');
    }
    get other_pre_reservation_time_limit() {
        return this.bookingSettingsForm.get('product_type_specific.other.pre_reservation_time_limit');
    }
    get other_post_reservation_time_limit() {
        return this.bookingSettingsForm.get('product_type_specific.other.post_reservation_time_limit');
    }
}
