import { environment } from '../../environments/environment';

export const DatePickerLanguages = () => {
    const langs = {};

    for (const lang of environment.locals) {
        if (lang !== 'en') {
            const _lang = require(`../../../node_modules/vanillajs-datepicker/js/i18n/locales/${lang}.js`);
            langs[lang] = _lang['default'][lang];
        }
    }

    return langs;
}