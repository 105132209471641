import {Injectable} from "@angular/core";
import {Action, ActionLog, actionState, actionType, closedStatus} from "../../../../shared_models/operator/actions";
import {TranslateService} from "@ngx-translate/core";
import moment from "moment";


@Injectable({
    providedIn: 'root'
})
export class ActionCardService {

    constructor(
        private translate: TranslateService
    ) {
    }

    getTitle(actionLog: ActionLog, isForLog: boolean, isSkeleton: boolean, action?: Action): string {
        let title = this.translate.instant(`operator.actions.titles.${actionLog.action_type}`);

        if (this.checkIfBasicAction(actionLog)) {
            if (actionLog.state === actionState.complete) {
                title = this.translate.instant(`operator.actions.completed_titles.${actionLog.action_type}`);
            }
        }

        if (actionLog.action_type === actionType.locationRemoveMachine) {
            title = title + ` ${(this.translate.instant(`misc.${(actionLog.action.product_type).toLowerCase()}`)).toLowerCase()}`
        }

        if (actionLog.action_type === actionType.machineMaintenanceDue || actionLog.action_type === actionType.outOfSoap) {
            if ("device_name" in action.statics) {
                title = title + ` ${action.statics.device_name}`
            }
        }

        if (actionLog.action_type === actionType.outOfSoap && actionLog.state !== actionState.complete) {
            if ("device_name" in action.statics) {
                title = this.translate.instant(`operator.actions.titles.${actionLog.action_type}_${actionLog.state}`) + ` ${action.statics.device_name}`
            }
        }


        if (isForLog) {
                if (actionLog.closed === closedStatus.closed_by_user) {
                    return `${title} - ${this.translate.instant('operator.actions.closed_by_user')} ${actionLog.user_name ? actionLog.user_name : this.translate.instant('operator.actions.user')}`
                } else if (actionLog.closed === closedStatus.unclosed_by_user) {
                    return title
                    + `- ${this.translate.instant('operator.actions.unclosed_by_user_1')}`
                    + ` ${actionLog.state}`
                    + ` ${this.translate.instant('operator.actions.unclosed_by_user_2')}` 
                    + ` ${actionLog.user_name ? actionLog.user_name : this.translate.instant('operator.actions.user')}`
                }
            
        } else {
            if (actionLog.action_type === actionType.antonLow && actionLog.state !== actionState.complete && !isSkeleton) {
                title = title + `: ${Math.round(action.dynamics.battery_status * 100)}%`
            }

        }
        return title
    }

    checkIfBasicAction(actionLog: ActionLog): boolean {
        return (
            actionLog.action_type === actionType.antonOff
            || actionLog.action_type === actionType.antonLow
            || actionLog.action_type === actionType.unitNotUsed
            || actionLog.action_type === actionType.unitUsedLess
            || actionLog.action_type === actionType.outOfSoap
            || actionLog.action_type === actionType.machineMaintenanceDue
        )
    }

    getDescription(actionLog: ActionLog, action: Action): string {
        if (actionLog.state === actionState.complete) {
            return this.translate.instant(`operator.actions.completed_descriptions.${actionLog.action_type}`)
        }
        switch (actionLog.action_type) {
            case actionType.unitNotUsed: {
                moment.duration(moment().diff(moment(actionLog.action.last_used))).asDays()
                return `
                    ${this.translate.instant("operator.actions.descriptions.not_used_descrip_1")}
                    ${Math.floor((moment().unix() - actionLog.action.last_used) / 86400)}
                    ${this.translate.instant("operator.actions.descriptions.not_used_descrip_2")}
                    `
            }
            case actionType.locationRemoveMachine: {
                return `
                    ${this.translate.instant(`operator.actions.descriptions.${actionLog.action_type}_1`)}
                    ${this.translate.instant(`operator.actions.${(actionLog.action.product_type).toLowerCase()}`)}.
                    ${this.translate.instant(`operator.actions.descriptions.${actionLog.action_type}_2`)}
                    `
            }
            case actionType.outOfSoap: {
                if (actionLog.state === actionState.observing) {
                    return `${action.dynamics.current_soap_count}/${action.dynamics.soap_limit} ${this.translate.instant(`operator.actions.descriptions.${actionLog.action_type}_${actionLog.state}`)}`
                } else {
                    return this.translate.instant(`operator.actions.descriptions.${actionLog.action_type}_${actionLog.state}`)
                }
            }
            case actionType.machineMaintenanceDue: {
                return `
                    ${this.translate.instant(`operator.actions.descriptions.${actionLog.action_type}_1`)}
                    ${action.dynamics.current_maintenance_count}
                    ${this.translate.instant(`operator.actions.descriptions.${actionLog.action_type}_2`)}
                    `
            }
            default: {
                return this.translate.instant(`operator.actions.descriptions.${actionLog.action_type}`)
            }
        }
    }

}
