<div class="page">
    <div class="main-container">
        <img src="../../../assets/logo/aw_logo.svg" alt="Airwallet logo" class="logo" />

        <div class="sign-in-and-signup-container">
            <div class="sign-in-and-signup-card">
                <form tabindex="undefined" [formGroup]="setPasswordForm" autocomplete="off" [hidden]="setPasswordDone">
                    <div class="input-group-wrapper">
                        <label for="choose_password">{{ "customers-set-password.choose_password" | translate }}</label>
                        <input autocomplete="new-password" name="password" class="form-control" type="password" formControlName="password" />
                        <div *ngIf="formSubmitted && password.invalid">
                            <div class="error-msg" *ngIf="password.errors.required">
                                {{ "misc.password_required" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="password.errors.maxlength">
                                {{ "misc.password_too_long" | translate }}
                            </div>
                            <div class="error-msg" *ngIf="password.errors.weakPassword">
                                {{ "misc.password_is_weak" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="input-group-wrapper">
                        <label for="repeat-password">{{ "customers-set-password.repeat_password" | translate }}</label>
                        <input id="repeat-password" autocomplete="new-repeatPassword" name="repeatPassword" class="form-control" type="password" formControlName="repeatPassword" />
                        <div class="error-msg" *ngIf="repeatPassword.errors && repeatPassword.errors.pwNoMatch">
                            {{ "customers-set-password.password_not_matching" | translate }}
                        </div>
                        <div *ngIf="formSubmitted && repeatPassword.invalid">
                            <div class="error-msg" *ngIf="repeatPassword.errors.required">
                                {{ "customers-set-password.password_need_repeat" | translate }}
                            </div>
                        </div>
                    </div>

                    <div class="input-group-wrapper mt-4">
                        <div class="checkbox">
                            <input type="checkbox" id="customCheck1" name="terms" (click)="toggleConsent('terms')" />
                            <label class="checkbox-label" for="customCheck1"
                                >{{ "sign_up.accept" | translate }} <a class="primary-color" href="{{ termsOfUse }}" target="_blank" tabindex="-1">{{ "sign_up.terms_n_conds" | translate }}</a></label
                            >
                        </div>
                        <div class="error-msg" *ngIf="formSubmitted && !termsChecked">
                            {{ "sign_up.terms_need_accept" | translate }}
                        </div>
                    </div>

                    <div class="input-group-wrapper terms">
                        <div class="checkbox">
                            <input type="checkbox" id="customCheck2" name="policy" (click)="toggleConsent('gdpr')" />
                            <label class="checkbox-label" for="customCheck2"
                                >{{ "sign_up.accept" | translate }} <a class="primary-color" href="{{ gdprPolicy }}" target="_blank" tabindex="-1">{{ "misc.gdpr" | translate }}</a></label
                            >
                        </div>
                        <div class="error-msg" *ngIf="formSubmitted && !gdprChecked">
                            {{ "sign_up.gdpr_needs_accept" | translate }}
                        </div>
                    </div>
                    <div class="d-grid gap-2">
                        <button class="btn btn-secondary" (click)="setPassword()" [disabled]="loading">
                            {{ !loading ? ("account.save" | translate) : "" }}
                            <loading-indicator *ngIf="loading" [size]="'large'" [color]="'white'"></loading-indicator>
                        </button>
                    </div>
                </form>

                <p *ngIf="setPasswordDone" class="text-center mb-4">
                    {{ "customers-set-password.Welcome" | translate }}
                </p>

                <div class="d-grid gap-2 mb-2" *ngIf="setPasswordDone">
                    <button class="btn btn-secondary" (click)="signIn()" [disabled]="loading">
                        {{ !loading ? ("sign-in.sign_in" | translate) : "" }}
                        <loading-indicator *ngIf="loading" [size]="'large'" [color]="'white'"></loading-indicator>
                    </button>
                </div>

                <p class="support-call" *ngIf="!setPasswordDone">
                    {{ "misc.need_help" | translate }}
                    <br />
                    <a href="{{ supportPhone.telLink }}" tabindex="-1">{{ supportPhone.phoneNumber }}</a>
                </p>
            </div>
        </div>
    </div>
    <ng-lottie [options]="options" class="lottie-waves"></ng-lottie>
</div>
