<div class="container mb-5">
    <h4 class="settlementsTitle mt-4 mb-3">{{ "settlements.settlements" | translate }}</h4>

    <loading-indicator [size]="'large'" [color]="'secondary'" *ngIf="showLoadingIndicator"></loading-indicator>

    <div class="box divTable p-2 col-12" *ngIf="!showLoadingIndicator">
        <div *ngIf="noSettlementsYet" class="p-3" style="max-width: 750px">
            <p>
                {{ "settlements.no_settlements" | translate }}
            </p>
            <p>
                {{ "settlements.to_recieve_settlements" | translate }}
            </p>
            <p>
                {{ "settlements.if_doubt" | translate }} <a class="primary-color" [routerLink]="['/overview']">{{ "settlements.overview" | translate }}</a
                >.
            </p>
        </div>
        <app-aw-table
            [tableHeaders]="tableHeaderOptions"
            [initalSort]="'date'"
            [totalItems]="settlements.length"
            *ngIf="!noSettlementsYet"
            (handlePageChange)="handlePageActive($event)"
            (sortBy)="catchSortChanged($event)"
            [sidescrollable]="isMobile"
        >
            <ng-container table-body>
                <row *ngFor="let data of settlements | paginate: pageSizeActive : pageNumberActive; let i = index">
                    <!-- Status -->
                    <cell [style.width]="tableHeaderOptions[0].width">
                        <app-aw-hover-icon
                            [hoverText]="
                                (data.status === 'paid'
                                    ? ('settlements.paid_state' | translate)
                                    : data.status === 'in_transit' || data.status === 'pending'
                                      ? ('settlements.est_arrival' | translate)
                                      : ('settlements.failed_state' | translate)) + getDate(data.failed_date ?? data.estimated_arrival)
                            "
                            [label]="(data.status === 'paid' ? 'settlements.paid' : data.status === 'in_transit' ? 'settlements.in_transit' : data.status === 'pending' ? 'settlements.pending' : 'settlements.failed') | translate"
                            [hoverIcon]="'../../../assets/icons/' + (data.status === 'paid' ? 'check-circle-white' : data.status === 'in_transit' ? 'clock-white' : data.status === 'pending' ? 'pending-white' : 'cross-white') + '.svg'"
                            [icon]="'../../../assets/icons/' + (data.status === 'paid' ? 'check' : data.status === 'in_transit' ? 'clock-blue' : data.status === 'pending' ? 'pending' : 'cross') + '.svg'"
                            [startColor]="data.status === 'paid' ? '#67C2AC' : data.status === 'in_transit' ? '#03045e' : data.status === 'pending' ? '#03045E' : '#d81e5b'"
                            [endColor]="data.status === 'paid' ? '#29E1B4' : data.status === 'in_transit' ? '#0150b7' : data.status === 'pending' ? '#0150B7' : '#fe5e92'"
                            [iconWidth]="13"
                        />
                    </cell>

                    <!-- Date -->
                    <cell [style.width]="tableHeaderOptions[1].width">
                        <span>{{ getDate(data.created) }}</span>
                    </cell>

                    <!-- Bank name -->
                    <cell [style.width]="tableHeaderOptions[3].width">
                        <span>{{ data.bank_name ?? "BANK" }}</span>
                    </cell>

                    <!-- Amount -->
                    <cell [style.width]="tableHeaderOptions[2].width" style="text-align: right">
                        <div style="width: 100%">
                            <span>{{ formatAmount(data.amount ? data.amount : data.payoutAmount ? data.payoutAmount : 0) }}</span>
                        </div>
                    </cell>

                    <!-- View Excel -->
                    <cell [style.width]="tableHeaderOptions[4].width" style="text-align: right">
                        <div style="width: 100%">
                            <a (click)="export(data.excel_link)" class="viewExcel">{{ data.excel_link ? ("settlements.download" | translate) : "" }}</a>
                        </div>
                    </cell>

                    <!-- View PDF -->
                    <cell [style.width]="tableHeaderOptions[5].width" style="text-align: right">
                        <div style="width: 100%">
                            <a target="_blank" href="{{ data.link }}" class="viewPdf">{{ "settlements.view_pdf" | translate }}</a>
                        </div>
                    </cell>
                </row>
            </ng-container>
        </app-aw-table>
    </div>
</div>
