<section class="animation-outer">
  @if (!mfaEnabled()) {
    <form [formGroup]="signInForm" (keydown.enter)="signIn()" autocomplete="on" class="animation-inner" @fade>
      <!--Email-->
      <div class="input-group-wrapper">
        <label for="email-input">{{ "misc.email" | translate }}</label>
        <input class="form-control" type="email" autocomplete="email" formControlName="email" name="email"
               id="email-input" />
        <div class="feedback-container">
          @if (formSubmitted && email.invalid) {
            <div class="error-msg" *ngIf="email.errors.required">
              {{ "misc.email_required" | translate }}
            </div>
          }
        </div>
      </div>

      <!--Password-->
      <div class="input-group-wrapper-feedback">
        <div class="password-label-container">
          <label for="password-input">{{ "misc.password" | translate }}</label>
          <button class="sign-up-link" (click)="openModal(forgotPw)" [disabled]="loading()" tabindex="-1">
            {{ "sign-in.forgot_password" | translate }}
          </button>
        </div>
        <input name="password" id="password-input" class="form-control" type="password" formControlName="password" />
        <div class="feedback-container">
          @if (formSubmitted && password.invalid) {
            <div class="error-msg" *ngIf="password.errors.required">
              {{ "misc.password_required" | translate }}
            </div>
            <div class="error-msg" *ngIf="password.errors.maxlength">
              {{ "misc.password_too_long" | translate }}
            </div>
          }
        </div>
      </div>
    </form>
  }

  @if (mfaEnabled()) {
    <div class="animation-inner" @fade>
      <p class="mfa-text"><strong>{{ "account.mfa.enter_ot_code" | translate }}</strong></p>
      <div>
        <app-aw-otp-input (otpComplete)="catchOtpEvent($event)" />
      </div>
    </div>
  }
</section>

<ng-template #forgotPw let-modal>
  <app-custom-modal>
    <ng-container modal-title>
      <h4 class="modal-title">{{ "sign-in.forgot_password" | translate }}</h4>
    </ng-container>
    <ng-container modal-dismiss>
      <a (click)="modalService.dismissAll('Cancel')"><img src="../../../assets/icons/modal-close-icon.svg"
                                                          alt="close dialog" /></a>
    </ng-container>
    <ng-container modal-body>
      <div class="input-group-wrapper">
        <label>{{ "misc.email" | translate }}</label>
        <input id="forgot-password" name="forgot-email" class="form-control" value="{{ signInForm.get('email').value }}"
               type="email" />
      </div>
    </ng-container>
    <ng-container modal-footer>
      <div class="d-grid gap-2">
        <button class="btn btn-secondary mt-3" (click)="resetPassword()" [disabled]="resetPWLoading">
          {{ !resetPWLoading ? ("sign-in.send_email" | translate) : "" }}
          <loading-indicator *ngIf="resetPWLoading" [size]="'large'" [color]="'white'"></loading-indicator>
        </button>
      </div>
    </ng-container>
  </app-custom-modal>
</ng-template>
