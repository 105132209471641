<div class="nav-search-wrapper" *ngIf="ready && hasResult">
    <div *ngIf="isMobile" class="divider"></div>
    <ul class="ul-list">
        <div class="ul-inner-wrapper">
            <li
                [className]="isCurrentId(resultMap['all'] + '-' + 0) ? 'search-result-wrapper hover-effect' : 'search-result-wrapper'"
                [id]="resultMap['all'] + '-' + 0"
                (click)="viewFullSearch(resultMap['all'] + '-' + 0)"
                (mouseenter)="changeHoverStyle($event)"
                (mouseleave)="changeHoverStyle($event)"
                data-category="all"
            >
                <div class="label-div">
                    <label
                        >{{ "search.common.show_all_results" | translate }} <strong>{{ searchQuery }}</strong></label
                    >
                </div>

                <div class="label-div">
                    <label>{{ "search.common.view" | translate }} {{ searchResultAmount }} {{ "search.common.results" | translate }}</label>
                </div>
            </li>
        </div>
    </ul>

    <ul class="ul-list" *ngFor="let res of searchResult | keyvalue">
        <div class="ul-inner-wrapper" *ngIf="res.value.length > 0">
            <div class="divider"></div>

            <div class="category-title">
                <h6>{{ "misc." + res.key | translate }}</h6>
            </div>

            <li
                [className]="isCurrentId(resultMap[res.key] + '-' + i) ? 'search-result-wrapper hover-effect' : 'search-result-wrapper'"
                *ngFor="let item of res.value; let i = index"
                (mouseenter)="changeHoverStyle($event)"
                (mouseleave)="changeHoverStyle($event)"
                [id]="resultMap[res.key] + '-' + i"
                (click)="navigate(res.key, item, resultMap[res.key] + '-' + i)"
                [attr.data-category]="res.key"
            >
                <div class="icon-label">
                    <img class="icon-img" [src]="'../../../../assets/icons/search-icons/' + res.key + '-icon' + (isCurrentId(resultMap[res.key] + '-' + i) ? '-white' : '') + '.svg'" />
                    <div
                        class="label-div"
                        *ngIf="(item.name || item.customer_name || item.serial) && res.key !== 'transactions'"
                        [innerHTML]="
                            searchService.highlightText(res.key === 'users' && item.user_type === 'terminal' ? item.id : item.name ? item.name.replace('_', ' ') : item.customer_name && !item.serial ? item.customer_name : item.serial)
                        "
                    ></div>
                    <div class="label-div" *ngIf="res.key === 'contracts'" [innerHTML]="searchService.highlightText(item.id)"></div>
                    <div class="label-div" *ngIf="res.key === 'settlements'" [innerHTML]="searchService.highlightText(getDate(item.created))"></div>
                    <div class="label-div" *ngIf="res.key === 'transactions'" [innerHTML]="searchService.highlightText(item.id)"></div>
                </div>

                <!-- Locations -->
                <ng-container *ngIf="res.key === 'locations'">
                    <div class="label-wrapper">
                        <div class="label-div" [innerHTML]="searchService.highlightText(helperService.localizeNumberWithCurrency(item.revenue / 100, 2, 2, item.currency))"></div>
                    </div>
                </ng-container>

                <!-- Devices -->
                <ng-container *ngIf="res.key === 'devices'">
                    <div class="label-wrapper">
                        <div class="label-div" [innerHTML]="searchService.highlightText(item.location_name)"></div>
                    </div>
                </ng-container>

                <!-- Terminal -->
                <ng-container *ngIf="res.key === 'terminals'">
                    <div class="label-wrapper">
                        <!-- on operators we wnat to show the status of the anton instead of the location name -->

                        <span *ngIf="(isOperator$ | async) && finishedSearching" [className]="'dot ' + (item.status === 'active' ? 'green' : 'red')"></span>
                        <div *ngIf="(isOperator$ | async) === false" class="label-div" [innerHTML]="searchService.highlightText(item.location_name)"></div>
                    </div>
                </ng-container>

                <!-- Customers -->
                <ng-container *ngIf="res.key === 'customers'">
                    <div class="icon-label">
                        <img
                            class="check-icon"
                            [src]="
                                item.status === 'complete'
                                    ? '../../../assets/icons/search-icons/check-circle' + (isCurrentId(resultMap[res.key] + '-' + i) ? '-white' : '') + '.svg'
                                    : item.status === 'inactive_devices'
                                      ? '../../../assets/search-icons/cross' + (isCurrentId(resultMap[res.key] + '-' + i) ? '-white' : '') + '.svg'
                                      : item.status === 'pending'
                                        ? '../../../assets/icons/search-icons/pending' + (isCurrentId(resultMap[res.key] + '-' + i) ? '-white' : '') + '.svg'
                                        : item.status === 'restricted_soon'
                                          ? '../../../assets/icons/search-icons/warning' + (isCurrentId(resultMap[res.key] + '-' + i) ? '-white' : '') + '.svg'
                                          : '../../../assets/icons/search-icons/cross' + (isCurrentId(resultMap[res.key] + '-' + i) ? '-white' : '') + '.svg'
                            "
                            alt=""
                        />
                        <label
                            [className]="isCurrentId(resultMap[res.key] + '-' + i) ? 'customer-status hovered' : 'customer-status'"
                            [style]="
                                item.status === 'complete'
                                    ? 'color: #67C2AC'
                                    : item.status === 'inactive_devices'
                                      ? 'color: #D81E5B'
                                      : item.status === 'pending'
                                        ? 'color:transparent linear-gradient(90deg, var(--secondary-color-dark) 0%, #0150B7 100%)'
                                        : item.status === 'restricted_soon'
                                          ? 'color: #FC9124'
                                          : 'color: #D81E5B'
                            "
                            >{{ getStatusString(item.status) }}
                        </label>
                    </div>
                </ng-container>

                <!-- Contracts -->
                <ng-container *ngIf="res.key === 'contracts'">
                    <div class="label-wrapper">
                        <div
                            class="label-div"
                            [style]="isCurrentId(resultMap[res.key] + '-' + i) ? 'color: #fff' : item.performance < 0 ? 'color: #D81E5B;' : 'color: #29E1B4;'"
                            [innerHTML]="searchService.highlightText(roundPerform(item.performance))"
                        ></div>
                    </div>
                </ng-container>

                <!-- Settlements -->
                <ng-container *ngIf="res.key === 'settlements'">
                    <div class="label-wrapper">
                        <div class="label-div" [innerHTML]="searchService.highlightText(helperService.localizeNumberWithCurrency(item.amount / 100, 2, 2, item.currency))"></div>
                    </div>
                </ng-container>

                <!-- Transactions -->
                <ng-container *ngIf="res.key === 'transactions'">
                    <div class="label-wrapper">
                        <div class="label-div" [innerHTML]="searchService.highlightText(helperService.localizeNumberWithCurrency(item.amount / 100, 2, 2, item.currency))"></div>
                    </div>
                </ng-container>

                <!-- Users (APP) -->
                <ng-container *ngIf="res.key === 'users' && item.user_type !== 'terminal'">
                    <div class="label-wrapper">
                        <div class="label-div" [innerHTML]="searchService.highlightText(item.phone_number_call_code + ' ' + item.phone_number, true)"></div>
                    </div>
                </ng-container>

                <!-- Users (Terminal) -->
                <ng-container *ngIf="res.key === 'users' && item.user_type === 'terminal'">
                    <div class="label-wrapper">
                        <div class="label-div" [innerHTML]="searchService.highlightText(translate.instant('users.payments') + ': ' + item.payments)"></div>
                    </div>
                </ng-container>
            </li>
        </div>
    </ul>
</div>

<div class="search-no-data" *ngIf="ready && !hasResult">
    <img class="search-icon mb-2" src="../../../assets/icons/search-icon-active.svg" />
    <h4>{{ "search.common.no_results" | translate }}</h4>
    <label>{{ "search.common.try_again" | translate }}</label>
</div>

<!-- search suggestions -->
<div class="nav-search-wrapper" *ngIf="showSearchSuggestions && !hasResult">
    <div class="category-title">
        <h6>{{ "search.suggested_filters" | translate }}</h6>
    </div>
    <div class="divider"></div>
    <ul class="ul-list">
        <div class="ul-inner-wrapper">
            <li
                [className]="isCurrentId(suggestion.id) ? 'preview-suggestion-line search-result-wrapper hover-effect' : 'preview-suggestion-line search-result-wrapper'"
                *ngFor="let suggestion of searchSuggestions"
                (mouseenter)="changeHoverStyle($event)"
                (mouseleave)="changeHoverStyle($event)"
                [id]="suggestion.id"
                (click)="selectSuggestion(suggestion.short_code)"
                [attr.data-category]="suggestion.id"
            >
                <div class="preview-suggestion-content">
                    <div>
                        <div>
                            <div class="short-code">
                                <span class="short-code-highlight">
                                    {{ suggestion.short_code }}
                                </span>
                            </div>
                            <div class="suggestion-label">{{ "search.suggestions." + suggestion.id | translate }}</div>
                        </div>
                    </div>
                    <div>
                        <div class="suggestion-example">
                            {{ suggestion.example }}
                        </div>
                    </div>
                </div>
            </li>
        </div>
    </ul>
    <div class="divider"></div>
</div>
