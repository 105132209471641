<app-custom-modal>
    <ng-container modal-title>
        <h4>{{ 'discount_tool.apply_discount' | translate }}</h4>
    </ng-container>
    <ng-container modal-body>
        <form>
            <p><strong>
                {{selectedDevices.length}}
                {{(selectedDevices.length > 1 ? 'discount_tool.units_selected' : 'discount_tool.unit_selected') | translate}}
            </strong></p>

            <div class="form-group">
                <label for="discount"><strong>{{ 'billing.discount' | translate }}</strong></label>
                <input type="number" class="form-control" id="discount" required [(ngModel)]="discount" name="discount">
            </div>
            <div class="form-group">
                <label for="expires">
                    <span><strong>{{ 'users.user.expires' | translate }} </strong></span>
                    <span><i>({{ 'misc.optional' | translate }})</i></span>
                </label>
                <app-aw-datepicker [(date)]="expireDate"
                                   id="expires"
                                   size="large"
                                   placeholder="operator.filter_bar.date_to"/>
            </div>
        </form>
    </ng-container>
    <ng-container modal-footer>
        <button class="btn btn-secondary" style="width: 100%" (click)="applyDiscount()" [disabled]="!discount">
            <span *ngIf="!applyingDiscount">{{'discount_tool.apply_discount' | translate}}</span>
            <loading-indicator *ngIf="applyingDiscount" [size]="'large'" [color]="'white'"></loading-indicator>
        </button>
    </ng-container>
</app-custom-modal>