import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HelperService } from '@services/helper/helper.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CustomModalComponent } from '@components/misc/custom-modal/custom-modal.component';
import { loadStripe, Stripe, StripeCardElement, StripeElements } from '@stripe/stripe-js';
import { LoadingComponent } from '@components/loading/loading.component';
import { NgIf } from '@angular/common';
import { BillingService } from '@services/billing/billing.service';
import { environment } from '../../../../../environments/environment';
import { StripeRegions } from '@shared_models/stripe';
import { DashboardUser } from '@shared_models/dashboard-user';

@Component({
    selector: 'app-billing-card-modal',
    standalone: true,
    imports: [FormsModule, TranslateModule, CustomModalComponent, LoadingComponent, NgIf],
    templateUrl: './billing-card.component.html',
    styleUrl: './billing-card.component.scss'
})
export class BillingCardComponent implements OnInit {
    @Output() hasCard: EventEmitter<boolean> = new EventEmitter<boolean>();

    stripe: Stripe;
    stripeElements: StripeElements;
    clientSecret: string;
    newClientSecret: string;
    loading: boolean = false;
    initialLoading: boolean = true;
    paymentMethod: any; //CommentTag: Find Stripe type
    user: DashboardUser = this.helperService.getUser();
    isUpdate: boolean = false;

    constructor(
        private helperService: HelperService,
        protected translate: TranslateService,
        private billingService: BillingService
    ) {}

    async ngOnInit(): Promise<void> {
        this.initialLoading = true;
        const stripeRegion = this.user.settings.stripe_region;
        const stripeKey: string = stripeRegion === StripeRegions.EU ? environment.stripePublicKeyEU : stripeRegion === StripeRegions.US ? environment.stripePublicKeyUS : 'no key';
        this.stripe = await loadStripe(stripeKey);
        const data = await this.getPaymentMethodOrClientSecret();
        if (data && data.paymentMethod) {
            this.paymentMethod = data.paymentMethod;
            this.hasCard.emit(true);
        }
        if (data && !data.paymentMethod && data.clientSecret) {
            this.clientSecret = data.clientSecret;
            this.createStripeCardElement(this.clientSecret);
        }
        this.initialLoading = false;
    }

    private async getPaymentMethodOrClientSecret() {
        const data = await this.billingService.getCard();
        return data;
    }

    private createStripeCardElement(clientSecret: string) {
        this.initialLoading = false;

        const options = {
            clientSecret: clientSecret,
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,300;0,400;0,600;1,300;1,400;1,600&display=swap'
                }
            ]
        };

        this.stripeElements = this.stripe.elements(options);
        // @ts-ignore
        const cardElement: StripeCardElement = this.stripeElements.create('card', {
            hidePostalCode: true,
            disableLink: true,
            style: {
                base: {
                    color: '#03045E',
                    fontWeight: '400',
                    fontFamily: '"Barlow", sans-serif',
                    fontSize: '16px',
                    '::placeholder': {
                        color: '#bcc0c4'
                    }
                }
            }
        });
        cardElement.mount('#card-element');
    }

    async saveCard(): Promise<void> {
        this.loading = true;
        const cardElement: StripeCardElement = this.stripeElements.getElement('card');
        const clientSecret: string = this.isUpdate ? this.newClientSecret : this.clientSecret;
        //CommentTag: Maybe use .then and .catch instead.
        const { error, setupIntent } = await this.stripe.confirmCardSetup(clientSecret, {
            payment_method: {
                card: cardElement
            }
        });

        //CommentTag: Maybe differentiate between error and deliberately cancelling 3DSecure
        if (error) {
            this.helperService.defaultHtmlToast(this.translate.instant('billing.card_failed'), error.message, 'Error');
            throw error;
        } else {
            if (setupIntent.status === 'succeeded') {
                if (this.isUpdate) {
                    await this.billingService.updateCard(this.paymentMethod.id); // Deletes the old payment method from its current id
                    this.paymentMethod = (await this.billingService.getCard()).paymentMethod; // Updates the payment method var with the new card
                    this.isUpdate = false;
                } else {
                    this.paymentMethod = (await this.billingService.getCard()).paymentMethod;
                }
                this.hasCard.emit(true);
                this.helperService.defaultHtmlToast('', this.translate.instant('billing.card_setup_success'), 'Success');
            } else {
                console.log('SetupIntent not succeeded:');
            }
        }
        this.loading = false;
    }

    async toggleUpdateCard() {
        this.isUpdate = !this.isUpdate; // Flip update status
        this.hasCard.emit(false);
        if (!this.newClientSecret) {
            this.newClientSecret = await this.billingService.getClientSecret();
        }
        this.createStripeCardElement(this.newClientSecret);
    }
}


