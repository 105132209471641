<div class="location-hr-wrapper">
    <div class="custom-location-hr">
        <span>{{ "misc.devices" | translate }}</span>
    </div>
</div>

<!-- LOADING -->
<div *ngIf="showLoadingIndicator" class="loading-height">
    <loading-indicator [size]="'large'" [color]="'secondary'"></loading-indicator>
</div>

<div *ngIf="!showLoadingIndicator">
    <div *ngIf="hasDevices" class="card-list">
        <div *ngFor="let unit of deviceList">
            <div class="card-item" (click)="cardClickAction(unit)">
                <table>
                    <tr class="card-item-title-area">
                        <td colspan="2" style="width: 100%">
                            <div class="wrapper">
                                <div class="title">
                                    {{ unit.name }}
                                </div>
                                <div class="subscription-state mobile">
                                    <img
                                        [src]="
                                            unit.config === 'PROGRAM_PICKER' && !unit.machine_programs
                                                ? '../../../assets/icons/cross.svg'
                                                : unit.maintenance
                                                  ? '../../../assets/icons/maintenance-icon.svg'
                                                  : unit.subscribed || unit.subscribed === undefined
                                                    ? '../../../assets/icons/check-circle.svg'
                                                    : '../../../assets/icons/cross.svg'
                                        "
                                        alt="{{
                                            unit.config === 'PROGRAM_PICKER' && !unit.machine_programs
                                                ? ('device.no_programs' | translate)
                                                : unit.maintenance
                                                  ? ('device.under_maintenance' | translate)
                                                  : unit.subscribed || unit.subscribed === undefined
                                                    ? ('location.active' | translate)
                                                    : ('location.inactive' | translate)
                                        }}"
                                    />
                                    <span
                                        [class]="
                                            unit.config === 'PROGRAM_PICKER' && !unit.machine_programs
                                                ? 'inactive'
                                                : unit.maintenance
                                                  ? 'maintenance'
                                                  : unit.subscribed || unit.subscribed === undefined
                                                    ? 'active'
                                                    : 'inactive'
                                        "
                                    >
                                        {{
                                            unit.config === "PROGRAM_PICKER" && !unit.machine_programs
                                                ? ("device.no_programs" | translate)
                                                : unit.maintenance
                                                  ? ("device.under_maintenance" | translate)
                                                  : unit.subscribed || unit.subscribed === undefined
                                                    ? ("location.active" | translate)
                                                    : ("location.inactive" | translate)
                                        }}
                                    </span>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="12" class="">
                            <hr class="customized" />
                        </td>
                    </tr>

                    <!-- For devices -->
                    <tr *ngIf="!cardService.getLastUsedLabel(unit.last_used, unit.created_on).value && unit.subscribed !== false">
                        <td class="not-yet-used" colspan="2">
                            {{ "location.ready" | translate }}
                        </td>
                    </tr>

                    <tr *ngIf="cardService.getLastUsedLabel(unit.last_used, unit.created_on).value">
                        <td class="label">{{ cardService.getLastUsedLabel(unit.last_used, unit.created_on).label }}</td>
                        <td class="value">{{ cardService.getLastUsedLabel(unit.last_used, unit.created_on).value }}</td>
                    </tr>

                    <tr *ngIf="authService.hasLimitedAccess('device_stats', role$ | async)">
                        <td class="label">{{ "location.last_thirty" | translate }}:</td>
                        <td class="value">
                            {{ cardService.localizeNumber(unit.stats.revenue, unit.currency) }}
                            <span *ngIf="unit.stats && unit.stats.subCount">*</span>
                        </td>
                    </tr>

                    <tr *ngIf="!unit.is_terminal && unit.price_unit">
                        <td class="label" *ngIf="unit.price_unit && unit.price_unit !== 'none'">{{ "location.price" | translate }}:</td>
                        <td class="label" *ngIf="unit.price_unit && unit.price_unit === 'none'"></td>
                        <td class="value" *ngIf="unit.price_unit && unit.price_unit !== 'none'">
                            {{ cardService.localizeNumber(unit.price, unit.currency) }}{{ cardService.getPriceUnit(unit) }}
                        </td>
                    </tr>

                    <tr *ngIf="!cardService.getLastUsedLabel(unit.last_used, unit.created_on).value && unit.subscribed === false">
                        <td>
                            <!-- empty -->
                        </td>
                    </tr>

                    <!-- Both devices and terminals -->
                    <tr>
                        <td class="subscription-state desktop" colspan="2">
                            <img
                                [src]="
                                    unit.config === 'PROGRAM_PICKER' && !unit.machine_programs
                                        ? '../../../assets/icons/cross.svg'
                                        : unit.maintenance
                                          ? '../../../assets/icons/maintenance-icon.svg'
                                          : unit.subscribed || unit.subscribed === undefined
                                            ? '../../../assets/icons/check-circle.svg'
                                            : '../../../assets/icons/cross.svg'
                                "
                                alt="{{
                                    unit.config === 'PROGRAM_PICKER' && !unit.machine_programs
                                        ? ('device.no_programs' | translate)
                                        : unit.maintenance
                                          ? ('device.under_maintenance' | translate)
                                          : unit.subscribed || unit.subscribed === undefined
                                            ? ('location.active' | translate)
                                            : ('location.inactive' | translate)
                                }}"
                            />
                            <span
                                [class]="
                                    unit.config === 'PROGRAM_PICKER' && !unit.machine_programs
                                        ? 'inactive'
                                        : unit.maintenance
                                          ? 'maintenance'
                                          : unit.subscribed || unit.subscribed === undefined
                                            ? 'active'
                                            : 'inactive'
                                "
                            >
                                {{
                                    unit.config === "PROGRAM_PICKER" && !unit.machine_programs
                                        ? ("device.no_programs" | translate)
                                        : unit.maintenance
                                          ? ("device.under_maintenance" | translate)
                                          : unit.subscribed || unit.subscribed === undefined
                                            ? ("location.active" | translate)
                                            : ("location.inactive" | translate)
                                }}
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>

    <div *ngIf="!hasDevices" class="empty-page-list">
        <p>{{ "location.no_devices_on_location" | translate }}</p>
    </div>
</div>
